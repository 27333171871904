import { styled } from "@mui/material";
import { OrderResponseStatus } from "@shared/types/ordersResponses";
import { ReactNode } from "react";

type Props = {
  text?: string;
  color?: string;
  textColor?: string;
  borderColor?: string;
};

export const Tag = styled(
  ({ text, textColor, borderColor, ...otherProps }: Props) => {
    return <div {...otherProps}>{text}</div>;
  }
)(({ theme, borderColor, color, textColor }) => ({
  display: "inline-block",
  border: borderColor === undefined ? undefined : `1px solid ${borderColor}`,
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(0.5, 1),
  minHeight: "1.5rem",
  minWidth: "4rem",
  maxWidth: "8rem",
  backgroundColor: color ?? theme.palette.background.paper,
  color: textColor ?? theme.palette.text.primary,
  textAlign: "center",
  fontSize: "0.75rem",
  lineHeight: 1.35,
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
}));

export const TagPrimary = () => <Tag text="Основная" color="#FCFA3C" />;
export const TagCancelled = () => <Tag text="Отозван" color="#A9A9A9" />;
export const TagDeclined = () => <Tag text="Отклонен" color="#FF4B4B" />;
export const TagPublished = ({ text = "Опубликован" }) => (
  <Tag text={text} color="#B9FFAE" />
);
export const TagInWork = () => <Tag text="В работе" color="#FCFCCF" />;
export const TagAccepted = () => <Tag text="Принят" color="#C1CFF4" />;
export const TagCompleted = () => <Tag text="Завершен" color="#ddd" />;
export const TagClosed = () => <Tag text="Закрыт" color="#FABBAD" />;

export const tagsByStatus: Record<OrderResponseStatus, ReactNode> = {
  PUBLISHED: <TagPublished />,
  ACCEPTED: <TagAccepted />,
  IN_WORK: <TagInWork />,
  COMPLETED: <TagCompleted />,
  CANCELLED: <TagCancelled />,
  DECLINED: <TagDeclined />,
  CLOSED: <TagClosed />,
};
