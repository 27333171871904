import { styled } from "@mui/material";
import { ReactComponent as CommentsIcon } from "@shared/assets/comments.svg";

type Props = {
  count: number;
};

export const CommentCount = styled(({ count, ...otherProps }: Props) => {
  return (
    <div {...otherProps}>
      <CommentsIcon color="text.disabled" />
      <span>{count}</span>
    </div>
  );
})(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  columnGap: theme.spacing(1),
  color: theme.palette.text.disabled,
  fontSize: "0.875rem",
  lineHeight: 1.4,
}));
