import { api } from "@shared/api";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { convertToMyOrderCard } from "../model/orderCardMapper";
import { showErrorMessages } from "@shared/lib/error";
import { ordersResponsesKeys } from "./ordersResponsesKeys";

export const useOrder = () => {
  const { id = "" } = useParams();

  const query = useQuery(
    ordersResponsesKeys.order(id),
    () => api.getOrder(id),
    {
      onError: showErrorMessages,
    }
  );

  return {
    ...query,
    data: query.data ? convertToMyOrderCard(query.data) : undefined,
  };
};
