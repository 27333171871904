import { Box, styled } from "@mui/material";
import { Column, Rating } from "@shared/ui";
import { ReactNode } from "react";

type Props = {
  name: string;
  rating?: number;
  reviewsCount?: number;
  tags?: ReactNode;
};

export const ViewBusinessCardHeader = styled(
  ({ name, rating, reviewsCount, tags, ...otherProps }: Props) => {
    return (
      <div {...otherProps}>
        <Box>
          <Box
            sx={{
              fontSize: "1.5rem",
              lineHeight: 1.35,
              fontWeight: 700,
              color: "text.primary",
              mb: 1.25,
            }}
          >
            {name}
          </Box>
          <Rating
            value={rating ?? 0}
            count={reviewsCount ?? 0}
            showCount
            sx={{ fontSize: "1.125rem" }}
          />
        </Box>
        {tags && <Column sx={{ gap: 1 }} children={tags} />}
      </div>
    );
  }
)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: theme.spacing(5),
}));
