import { AnchorHTMLAttributes } from "react";
import { styled } from "@mui/material";

type Props = Omit<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  "href" | "children"
> & {
  to: string;
  label?: string;
};

export const NavListItem = styled(({ to, label, ...otherProps }: Props) => {
  return (
    <a href={to} {...otherProps}>
      {label}
    </a>
  );
})({
  display: "inline-block",
  color: "#363634",
  textDecoration: "none",
  whiteSpace: "nowrap",
  transition: "color 0.3s",

  "&:hover": {
    color: "#2386e0",
  },
});
