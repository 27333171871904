import { styled } from "@mui/material";

type Props = {
  selected?: boolean;
};

export const TabPanel = styled("div")<Props>(({ selected }) => ({
  display: selected ? "block" : "none",
  height: "100%",
  width: "100%",
}));
