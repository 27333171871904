import {
  MarketplaceCard,
  OrdersResponsesHeader,
  OrdersShortDetails,
  ServicePrice,
} from "@entities/marketplace";
import { Box } from "@mui/material";
import { Paths } from "@shared/lib/router";
import { Button, tagsByStatus } from "@shared/ui";
import { IncomingOrderCard } from "../types";
import { CloseButton } from "./CloseButton";
import { useNavigate } from "react-router-dom";

type Props = {
  item: IncomingOrderCard;
  onLike?: () => void;
  onDislike?: () => void;
  onDecline: (id: string) => void;
};

export const IncomingOrdersListItem = ({
  item,
  onLike,
  onDislike,
  onDecline,
}: Props) => {
  const url = `${Paths.IncomingOrders}${item.id}`;
  const navigate = useNavigate();
  const handleAnswerClick = () => navigate(`${url}/#answer`);

  return (
    <MarketplaceCard
      key={item.id}
      sx={{ mt: 2, "&:first-of-type": { mt: 0 } }}
      avatarSrc={item.avatarSrc}
      firstName={item.company}
      header={
        <OrdersResponsesHeader
          title={item.company}
          name={item.name}
          date={item.date}
        />
      }
      rating={item.rating}
      reviewsCount={item.reviewsCount}
      linkUrl={url}
      body={
        <Box sx={{ display: "flex", columnGap: 10, mr: 3, py: 2.5 }}>
          <OrdersShortDetails
            serviceName={item.title}
            serviceTypes={item.services}
            targetType="receiver"
            target={item.target}
            text={item.text}
            statistics={item.statistics}
            onLike={onLike}
            onDislike={onDislike}
          />
          {item.price.value && (
            <ServicePrice value={item.price.value} type={item.price.type} />
          )}
        </Box>
      }
      tags={tagsByStatus[item.status]}
      buttons={
        (["PUBLISHED", "ACCEPTED"].includes(item.status) && (
          <>
            <Button
              sx={{ width: "11.25rem" }}
              text="Ответить"
              onClick={handleAnswerClick}
            />
            <CloseButton text="Отклонить" onClick={() => onDecline(item.id)} />
          </>
        )) || <Box sx={{ minWidth: "11.5rem" }} />
      }
    />
  );
};
