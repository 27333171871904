import { ReactNode } from "react";
import { Box, SxProps, Theme } from "@mui/material";

type Props = {
  className?: string;
  sx?: SxProps<Theme>;
  children?: ReactNode;
  error?: string;
  hideError?: boolean;
};

export const FieldError = ({
  sx = [],
  className,
  children,
  error,
  hideError = false,
}: Props) => {
  return (
    <Box
      sx={[
        { flex: 1, display: "flex", flexDirection: "column", rowGap: 0.75 },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      className={className}
    >
      {children}
      {!hideError && !!error && (
        <Box
          sx={{
            fontWeight: 400,
            fontSize: "0.75rem",
            lineHeight: 1.5,
            color: "error.main",
          }}
        >
          {error}
        </Box>
      )}
    </Box>
  );
};
