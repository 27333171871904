import { MarketplaceHeader } from "@entities/marketplace";
import {
  AllParticipantsCard,
  BusinessCards,
  CreateBusinessCard,
  HasQuestionsCard,
  IncomingOrders,
  IncomingResponses,
  MyMarketplaceContainer,
  MyOrders,
  MyResponses,
  useMyMarketplace,
} from "@features/my-marketplace";
import { BaseLayout } from "@shared/ui";
import { Header, Footer } from "@widgets";
import { Box } from "@mui/material";
import { navChain } from "@shared/lib/navChain";

export const MyMarketplacePage = () => {
  const { businessSlides, hasBusinessCards, title, description, isLoading } =
    useMyMarketplace();

  const cards = hasBusinessCards ? (
    <>
      <HasQuestionsCard />
      <CreateBusinessCard />
      <AllParticipantsCard />
      <Box sx={{ height: (theme) => theme.spacing(3.8) }} />
    </>
  ) : (
    <HasQuestionsCard />
  );

  return (
    <BaseLayout header={<Header />} footer={<Footer />}>
      <MarketplaceHeader
        title={title}
        description={description}
        cards={cards}
        businessCards={
          (hasBusinessCards || isLoading) && <BusinessCards slides={businessSlides} isLoading={isLoading} />
        }
        extended
        breadcrumbs={[navChain.myMarketplace]}
      />
      {hasBusinessCards && (
        <MyMarketplaceContainer>
          <MyOrders />
          <IncomingOrders />
          <MyResponses />
          <IncomingResponses />
        </MyMarketplaceContainer>
      )}
    </BaseLayout>
  );
};
