import { Filters } from "@entities/filters";
import { client } from "@shared/lib/client";
import { getFromDate } from "@shared/lib/period";
import {
  BusinessCardBasicDto,
  BusinessCardDto,
  BusinessCardUpdateDto,
  NotificationDto,
} from "@shared/types/businessCard";

type Params = Filters & { offset?: number; limit?: number };

const convertParams = (params: Params) => ({
  ...params,
  sort: params?.sort ? `BY_${params.sort}` : undefined,
  fromDate: getFromDate(params.fromDate),
});

export const getAllBusinessCards = async (params: Params) => {
  const response = await client.get<BusinessCardBasicDto[]>(
    "/business-cards/",
    { params: convertParams(params) }
  );
  return response.data;
};

export const getBusinessCard = async (id: string) => {
  const response = await client.get<BusinessCardDto>(`/business-cards/${id}`);
  return response.data;
};

export const updateBusinessCard = async (
  id: string,
  data: BusinessCardUpdateDto
) => await client.put(`/business-cards/${id}`, data);

export const getMyBusinessCards = async (params: Params) => {
  const response = await client.get<BusinessCardBasicDto[]>(
    "/business-cards/account",
    { params: convertParams(params) }
  );
  return response.data;
};

export const getNotifications = async () => {
  const response = await client.get<NotificationDto[]>(`/notifications/`);

  return response.data;
};

export const getNotification = async (id: string) => {
  const response = await client.get<NotificationDto>(`/notifications/${id}`);

  return response.data;
};
