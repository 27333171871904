import { LocaleString } from "./lang";

export enum DictionaryName {
  ServiceType = "serviceType",
  BusinessCard = "cardType",
}

type DictionaryChild = Omit<Dictionary, "children"> & { parent: string };

export type Dictionary = {
  id: string;
  children?: DictionaryChild[];
  disabled: boolean;
  text: LocaleString;
};

export type DictionaryContent = {
  code: string;
  display: LocaleString;
};
