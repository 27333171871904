import { Partners, ReviewCard, businessModel } from "@entities/business";
import { Box } from "@mui/material";
import { Column } from "@shared/ui";
import { EmptyTabText } from "./EmptyTabText";
import { useAppSelector } from "@app/store";
import { useQueryClient } from "react-query";
import { businessCardsKeys } from "../hooks/businessCardsKeys";

type Props = {
  isCommentAllowed: boolean;
};

export const ReviewsTab = ({ isCommentAllowed }: Props) => {
  const card = useAppSelector(businessModel.cardDetailsSelector);
  const reviews = useAppSelector(businessModel.cardReviewsSelector);
  const partners = useAppSelector(businessModel.cardPartnersSelector);
  const { id } = useAppSelector(businessModel.cardSelector) ?? { id: "" };

  const queryClient = useQueryClient();

  const onComment = () =>
    queryClient.invalidateQueries(businessCardsKeys.card(id));

  return reviews?.length ? (
    <Box sx={{ display: "grid", gridTemplateColumns: "auto 24rem", gap: 3 }}>
      <Column sx={{ gap: 2.5 }}>
        {reviews.map((review) => (
          <ReviewCard
            key={review.text}
            {...review}
            isCommentAllowed={isCommentAllowed && card?.status === "PUBLISHED"}
            businessCardId={id}
            onComment={onComment}
          />
        ))}
      </Column>
      {!!partners?.length && <Partners items={partners} />}
    </Box>
  ) : (
    <EmptyTabText text="У карточки бизнеса пока нет отзывов" />
  );
};
