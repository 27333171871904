import { Box } from "@mui/material";
import { Link as UiLink } from "@shared/ui";

type Props = { text: string };

export const Title = ({ text }: Props) => (
  <Box sx={{ color: "secondary.light" }}>{text}:</Box>
);

export const Value = ({ text }: Props) => (
  <Box sx={{ fontSize: "1.125rem" }}>{text}</Box>
);

export const Link = ({ text }: Props) => (
  <UiLink
    href={text}
    text={text}
    target="_blank"
    sx={{ fontSize: "1.125rem" }}
  />
);
