import {
  Box,
  ModalProps,
  Modal as MuiModal,
  styled,
  SxProps,
  Theme,
} from "@mui/material";
import { ReactNode } from "react";
import { ReactComponent as CloseIcon } from "@shared/assets/close.svg";

const Header = styled("h6")(({ theme }) => ({
  position: "relative",
  margin: 0,
  padding: theme.spacing(2, 15),
  borderBottom: "1px solid #e8e8e8",
  textAlign: "center",
  fontWeight: 700,
  fontSize: "1.5rem",
  lineHeight: 1.35,
  color: theme.palette.text.primary,
}));

type PaperProps = {
  height?: string | number;
  width?: string | number;
};

const Paper = styled("div")<PaperProps>(
  ({ theme, height, width = "28.125rem" }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    height,
    width,
    maxHeight: "calc(100vh - 2rem)",
    backgroundColor: theme.palette.background.paper,
    boxShadow:
      "0px 8px 16px rgba(0, 0, 0, 0.1), 0px 8px 48px rgba(0, 0, 0, 0.2)",
    outline: "none",
    overflowY: "auto",
  })
);

export type Props = ModalProps &
  PaperProps & {
    header?: string | ReactNode;
    headerSx?: SxProps<Theme>;
  };

export const Modal = ({
  header,
  width,
  height,
  children,
  headerSx,
  ...other
}: Props) => {
  const handleClose = () => other.onClose?.({}, "backdropClick");

  return (
    <MuiModal {...other}>
      <Paper height={height} width={width}>
        {typeof header === "string" ? (
          <Header sx={headerSx}>
            {header}
            <Box
              component="button"
              sx={{
                position: "absolute",
                top: "50%",
                right: "24px",
                transform: "translateY(-50%)",
                border: 0,
                padding: 0,
                background: "none",
                "&:hover": { cursor: "pointer" },
              }}
              onClick={handleClose}
            >
              <CloseIcon height={24} width={24} />
            </Box>
          </Header>
        ) : (
          header
        )}
        {children}
      </Paper>
    </MuiModal>
  );
};
