import { Attachment } from "./attachment";
import { PublishedBusinessCard } from "./business";
import { LocaleString } from "./lang";

export enum Roles {
  OWNER = "OWNER",
  ADMINISTRATOR = "ADMINISTRATOR",
  OPERATOR = "OPERATOR",
}

export enum Status {
  CONFIRMING = "CONFIRMING",
  ACTIVE = "ACTIVE",
  BLOCKED = "BLOCKED",
  DELETED = "DELETED",
}

interface User {
  agreementSigned: boolean;
  created: number;
  id: string;
  lastName: string;
  name: string;
  role: Roles;
  settings: object;
  updated: number;
  logo?: Attachment;
  lastLoggedIn: number;
  status?: Status;
  confirmed?: boolean;
  accountId?: string;
  account?: {
    id: string;
    name: string;
  };
}

interface Account {
  id: string;
  name: string;
  country: string;
  email: string;
  created: number;
  updated: number;
  lastLoggedIn: number;
  owner: User;
  isSystemAccount: boolean;
}

interface ExchangeStats {
  account: Account;
  businessCard: PublishedBusinessCard;
  created: number;
}

interface ExchangeContactsStats {
  countGrantedAccess: number;
  countGotAccess: number;
  grantedAccess: ExchangeStats[];
  gotAccess: ExchangeStats[];
}

export enum ServiceRequestStatus {
  DRAFT = "DRAFT",
  CANCELLED = "CANCELLED",
  PUBLISHED = "PUBLISHED",
  ACCEPTED = "ACCEPTED",
  IN_WORK = "IN_WORK",
  COMPLETED = "COMPLETED",
  DECLINED = "DECLINED",
}

interface OffersStatus {
  answers: number;
  draftAnswers: number;
  publishedAnswers: number;
  acceptedAnswers: number;
  inWorkAnswers: number;
  completedAnswers: number;
  canceledAnswers: number;
  declinedAnswers: number;
  offerStatus: ServiceRequestStatus;
  offers: number;
}

export enum AnswerType {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
}

interface IOffersName {
  answers: number;
  draftAnswers: number;
  publishedAnswers: number;
  acceptedAnswers: number;
  inWorkAnswers: number;
  completedAnswers: number;
  canceledAnswers: number;
  declinedAnswers: number;
  offerId: string;
  name: LocaleString;
  type: AnswerType;
}

interface OffersStats {
  outgoingOffers: OffersStatus[];
  incomingOffers: OffersStatus[];
  outgoingAnswers: IOffersName[];
  incomingAnswers: IOffersName[];
}

export enum QuestionStatus {
  ACTIVE = "ACTIVE",
  CLOSED = "CLOSED",
}

interface Questions {
  status: QuestionStatus;
  all: number;
  publicQuestions: number;
  privateQuestions: number;
}

interface QuestionsStats {
  outgoing: Questions[];
  incoming: Questions[];
}

interface News {
  newsId: string;
  name: LocaleString;
  comments: number;
  reactions: number;
}

interface NewsStats {
  count: number;
  news: News[];
}

interface ViewItemsStats {
  objectId: string;
  name: LocaleString;
  views: number;
}

interface ViewsStats {
  views: number;
  viewsInList: number;
  viewsServices: number;
  services: ViewItemsStats[];
  viewsQuestions: number;
  questions: ViewItemsStats[];
  viewsOffers: number;
  offers: ViewItemsStats[];
}

export enum ComplaintAboutType {
  BUSINESS_CARD = "BUSINESS_CARD",
  BUSINESS_SERVICE = "BUSINESS_SERVICE",
  QUESTION = "QUESTION",
  ANSWER = "ANSWER",
  NEWS = "NEWS",
  COMMENT = "COMMENT",
  OFFER = "OFFER",
  OFFER_ANSWER = "OFFER_ANSWER",
}

interface ComplaintsName {
  name: LocaleString;
  all: number;
  active: number;
  rejected: number;
  resolved: number;
}

interface ComplaintsValue {
  type: ComplaintAboutType;
  all: number;
  active: number;
  rejected: number;
  resolved: number;
  names?: ComplaintsName[];
}

interface ComplaintsStats {
  count: number;
  complaints: ComplaintsValue[];
}

interface Populars {
  objectId: string;
  name: LocaleString;
  responses: number;
  comments: number;
  reactions: number;
  views: number;
}

interface PopularsStats {
  offers: Populars[];
  questions: Populars[];
}

export interface ServicesStats {
  serviceType: LocaleString;
  all: number;
  draft: number;
  published: number;
  completed: number;
  canceled: number;
  declined: number;
  serviceCode: string;
  children?: ServicesStats[];
}

interface OfferServicesStats {
  outgoingOffers: ServicesStats[];
  incomingOffers: ServicesStats[];
}

interface CategoryStats {
  name: LocaleString;
  categoryId: string;
  subcategoryId: string;
  all: number;
  publicQuestions: number;
  privateQuestions: number;
  parent: string;
  id: string;
  children: CategoryStats[];
}

interface QuestionCategoryStats {
  categories: CategoryStats[];
}

export interface BusinessCardStats {
  businessCard: PublishedBusinessCard;
  fromDate: number | null;
  toDate: number | null;
  exchangeContacts: ExchangeContactsStats;
  offers: OffersStats;
  questions: QuestionsStats;
  news: NewsStats;
  views: ViewsStats;
  complaints: ComplaintsStats;
  populars: PopularsStats;
  offerServices: OfferServicesStats;
  questionCategory: QuestionCategoryStats;
}
