import { BaseSelect, Radio, RadioGroup, RadioGroupTitle } from "@shared/ui";
import { Sort as SortType } from "@shared/lib/sort";

type Props = {
  defaultValue?: string | undefined;
  hiddenPrice?: boolean;
  onChange?: (value: string, value2?: string) => void;
};

const names: Record<string, string> = {
  POPULARITY: "По популярности",
  NEWEST: "По новизне",
  PRICE: "По цене",
  RATING: "По рейтингу",
};

export const Sort = ({
  defaultValue,
  hiddenPrice = false,
  onChange,
}: Props) => {
  const [by = "", order = ""] = defaultValue?.split("_") ?? [];
  const displayValue = defaultValue
    ? `${names[by]} (${order === "ASC" ? "по возрастанию" : "по убыванию"})`
    : undefined;

  const handleChange = (value: string) => {
    const [by, order] = value.split("_");
    onChange?.(by, order);
  };

  return (
    <BaseSelect
      placeholder="Сортировать"
      sx={{
        maxWidth: "15rem",
        lineHeight: 1.4,
        py: 0,
        minHeight: "2rem",
      }}
      value={displayValue}
      dropdownSx={{ width: "15rem", px: 2 }}
    >
      <RadioGroup
        onChange={handleChange}
        gap="0"
        defaultValue={defaultValue ?? SortType.PopularityAsc}
      >
        <RadioGroupTitle>По популярности</RadioGroupTitle>
        <Radio value={SortType.PopularityAsc} label="по возрастанию" />
        <Radio value={SortType.PopularityDesc} label="по убыванию" />
        <RadioGroupTitle sx={{ mt: 2 }}>По новизне</RadioGroupTitle>
        <Radio value={SortType.NewestAsc} label="по возрастанию" />
        <Radio value={SortType.NewestDesc} label="по убыванию" />
        {!hiddenPrice && (
          <>
            <RadioGroupTitle sx={{ mt: 2 }}>По цене</RadioGroupTitle>
            <Radio value={SortType.PriceAsc} label="по возрастанию" />
            <Radio value={SortType.PriceDesc} label="по убыванию" />
          </>
        )}
        <RadioGroupTitle sx={{ mt: 2 }}>По рейтингу</RadioGroupTitle>
        <Radio value={SortType.RatingAsc} label="по возрастанию" />
        <Radio value={SortType.RatingDesc} label="по убыванию" />
      </RadioGroup>
    </BaseSelect>
  );
};
