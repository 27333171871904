import { CreateBusinessCard } from "@features/my-marketplace";
import { Box, styled } from "@mui/material";
import { Breadcrumbs, BreadcrumbsItem, Container } from "@shared/ui";
import { ReactNode } from "react";

const Header = styled("h1")(({ theme }) => ({
  margin: 0,
  marginBottom: theme.spacing(3.75),
  maxWidth: 678,
  fontWeight: 700,
  fontSize: "2.5rem",
  lineHeight: 1.25,
  color: theme.palette.text.primary,
}));

const Description = styled("p")(({ theme }) => ({
  margin: 0,
  fontSize: "1.25rem",
  lineHeight: 1.25,
  color: theme.palette.text.primary,
}));

type Props = {
  title: string;
  description: string | string[];
  cards?: ReactNode;
  businessCards?: ReactNode;
  extended?: boolean;
  breadcrumbs?: BreadcrumbsItem[];
};

export const MarketplaceHeader = ({
  title,
  description,
  cards,
  businessCards,
  extended = false,
  breadcrumbs,
}: Props) => {
  return (
    <Container
      sx={{
        position: "relative",
        display: "grid",
        gridTemplateColumns: "min(792px, 100%) 384px",
        justifyContent: "space-between",
        columnGap: (theme) => theme.spacing(3),
        rowGap: (theme) => theme.spacing(11),
        "&:before": {
          content: "''",
          position: "absolute",
          top: 0,
          left: 0,
          width: "54rem",
          height: "54rem",
          bgcolor: "#add5fa",
          transform: "translate(-50%, -70%) matrix(-1, 0, 0, 1, 0, 0)",
          opacity: 0.5,
          filter: "blur(12.5rem)",
          zIndex: -1,
        },
        "&:after": {
          content: "''",
          position: "absolute",
          top: 0,
          right: 0,
          width: "40.5rem",
          height: "40.5rem",
          bgcolor: "#f0ee0e",
          transform: "translate(40%, -30%) matrix(-1, 0, 0, 1, 0, 0)",
          opacity: 0.2,
          filter: "blur(9.375rem)",
          zIndex: -1,
        },
      }}
    >
      <Box sx={{ mr: (theme) => theme.spacing(11.5) }}>
        {breadcrumbs && <Breadcrumbs items={breadcrumbs} />}
        <Header>{title}</Header>

        {!Array.isArray(description) && (
          <Description>{description}</Description>
        )}

        {Array.isArray(description) &&
          description.map((paragraph, index) => (
            <Description key={index}>{paragraph}</Description>
          ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: businessCards ? "flex-start" : "flex-end",
          alignItems: "start",
          gridColumn: 2,
          gridRow: businessCards ? "1 / 3" : undefined,
          rowGap: (theme) => theme.spacing(3),
        }}
      >
        {cards}
      </Box>
      {businessCards && (
        <Box sx={{ gridColumn: "1 / 2", alignSelf: "flex-end" }}>
          {businessCards}
        </Box>
      )}
      {!businessCards && extended && (
        <Box
          sx={{
            gridColumn: "1 / 3",
            gap: (theme) => theme.spacing(4),
            display: "flex",
            mb: (theme) => theme.spacing(10),
          }}
        >
          <CreateBusinessCard />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              lineHeight: 1.25,
              color: (theme) => theme.palette.text.primary,
            }}
          >
            <Box sx={{ fontSize: "2.5rem", fontWeight: 700 }}>
              Есть потребность
              <br />в новых партнерах?
            </Box>
            <Box
              sx={{
                fontSize: "1.25rem",
                maxWidth: (theme) => theme.spacing(87.5),
              }}
            >
              КАРТОЧКА БИЗНЕСА - это портфолио организации для работы в
              Маркетплейсе. Расскажите о своей компании, опубликуйте портфолио и
              найдите потенциальных партнеров.
            </Box>
          </Box>
        </Box>
      )}
    </Container>
  );
};
