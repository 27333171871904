import { businessKeys } from "@entities/business";
import { styled } from "@mui/material";
import { api } from "@shared/api";
import { Checkbox, Column } from "@shared/ui";
import { useState } from "react";
import { useQuery } from "react-query";

const Title = styled("span")(({ theme }) => ({
  fontWeight: 700,
  fontSize: "0.875rem",
  lineHeight: 1.5,
  color: theme.palette.text.primary,
}));

type Props = {
  defaultValue?: string[] | null | undefined;
  onChange?: (value: string[]) => void;
};

export const BusinessCardsFilter = ({ defaultValue, onChange }: Props) => {
  const [value, setValue] = useState(defaultValue ?? []);
  const isChecked = (item: string) => value.includes(item);
  const handleChange = (item: string) => (checked: boolean) => {
    const newValue = checked
      ? [...value, item]
      : value.filter((el) => el !== item);

    setValue(newValue);
    onChange?.(newValue);
  };

  const { data = [] } = useQuery(
    businessKeys.userPublishedCards,
    api.getUserPublishedBusinessCards
  );

  return (
    <Column>
      <Title>Карточка бизнеса</Title>
      {data.map((card) => (
        <Checkbox
          key={card.id}
          label={card.name.ru}
          checked={isChecked(card.id)}
          onChange={handleChange(card.id)}
        />
      ))}
    </Column>
  );
};
