import {
  CommentsForm,
  OrdersResponsesCard,
  OrdersResponsesDetailButton,
} from "@entities/orders-responses";
import { Column, Link, Loader, tagsByStatus } from "@shared/ui";
import { OrdersShortDetails } from "./OrdersShortDetails";
import { Box } from "@mui/material";
import { ResponsePrice, ServicePrice } from "@entities/marketplace";
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { api } from "@shared/api";
import { mapComments, mapStatistics } from "../model/orderCardMapper";
import { showErrorMessages } from "@shared/lib/error";
import { ordersResponsesKeys } from "../hooks/ordersResponsesKeys";
import { useDislikePost, useLikePost } from "@entities/posts";
import { useMyResponsesActions } from "../hooks/useMyResponsesActions";
import { useIncomingResponsesActions } from "../hooks/useIncomingResponsesActions";
import { ViewReviewModal } from "./ViewReviewModal";
import { AddReviewModal } from "./AddReviewModal";
import { useBoolean } from "@shared/hooks";
import { useSendReview } from "../hooks/useSendReview";
import { toLocaleStr } from "@shared/lib/utils";
import { useShareContacts } from "../hooks/useShareContacts";

type Props = {
  showReceiver?: boolean;
};

export const ResponseDetails = ({ showReceiver }: Props) => {
  const likePost = useLikePost();
  const dislikePost = useDislikePost();

  const { id = "" } = useParams();

  const { data, refetch: refetchData } = useQuery(
    ordersResponsesKeys.response(id),
    () => api.getOrderAnswer(id),
    { onError: showErrorMessages }
  );
  const refetch = () => refetchData();
  const { confirm, discard } = useMyResponsesActions(id);
  const { handleAcceptOrder, handleCompleteOrder, handleDeclineOrder } =
    useIncomingResponsesActions(data?.offer.id ?? "", data?.id ?? "", refetch);
  const handleConfirm = () => confirm().then(refetch);
  const handleDiscard = () => discard().then(refetch);
  const handleComplete = () => handleCompleteOrder().then(sendReviewModal.on);
  const queryClient = useQueryClient();

  const handleLike = (id: string) =>
    !likePost.isLoading
      ? () => likePost.mutate(id, { onSuccess: refetch })
      : undefined;

  const handleDislike = (id: string) =>
    !dislikePost.isLoading
      ? () => dislikePost.mutate(id, { onSuccess: refetch })
      : undefined;

  const [sendReviewIsOpen, sendReviewModal] = useBoolean(false);
  const [viewReviewIsOpen, viewReviewModal] = useBoolean(false);

  const sendReview = useSendReview(data?.offer.id ?? "", data?.id ?? "");
  const shareContacts = useShareContacts(data?.offer.id ?? "", data?.id ?? "");

  const handleSendReview = async (review: { content: string; grade: number }) =>
    sendReview({
      grade: review.grade,
      content: toLocaleStr(review.content),
      attachments: [],
    })
      .then(() => refetch())
      .then(() =>
        queryClient.invalidateQueries(
          ordersResponsesKeys.reviews(data?.offer.id, data?.id)
        )
      )
      .then(sendReviewModal.off);

  const handleShare = () => shareContacts().then(refetch);

  if (!data) {
    return <Loader />;
  }

  const orderIsActive = ["PUBLISHED", "ACCEPTED", "IN_WORK"].includes(
    data.status
  );

  return (
    <Column sx={{ gap: 2, mb: 4.5 }}>
      <OrdersResponsesCard
        header="Заказ"
        avatarSrc={data.offer.businessCard.logo?.path}
        tag={tagsByStatus[data.offer.status]}
        rating={data.offer.businessCard?.rate ?? 0}
        reviewsCount={data.offer.businessCard?.reviewCount ?? 0}
        price={
          <ServicePrice value={data.offer.price} type={data.offer.priceUnits} />
        }
        title={data.offer.businessCard.name.ru}
        name={data.offer.creator.name}
        date={new Date(data.offer.createdDateTime)}
        body={
          <OrdersShortDetails
            type={data.offer.serviceTypes
              .map((service) => service.display.ru)
              .join(", ")}
            target={
              showReceiver && data.offer?.receiverBusinessCard?.name
                ? {
                    title: "Получатель отклика",
                    value: data.offer?.receiverBusinessCard?.name?.ru,
                  }
                : undefined
            }
            title={data.offer.title.ru}
            text={data.offer.content.ru}
          />
        }
        statistics={mapStatistics(data.offer)}
        showComments={false}
        onLike={handleLike(data.offer.id)}
        onDislike={handleDislike(data.offer.id)}
      />
      <OrdersResponsesCard
        header="Отклик"
        avatarSrc={data.businessCard.logo?.path}
        tag={tagsByStatus[data.status]}
        rating={data.businessCard.rate}
        reviewsCount={data.businessCard.reviewCount}
        price={<ResponsePrice value={data.price} type={data.priceUnits} />}
        title={data.businessCard.name.ru}
        name={data.creator.name}
        date={new Date(data.createdDateTime)}
        body={
          <OrdersShortDetails title={data.title?.ru} text={data.content?.ru} />
        }
        statistics={mapStatistics(data)}
        showComments={false}
        onLike={handleLike(data.id)}
        onDislike={handleDislike(data.id)}
      />
      <Box
        sx={{
          p: 2.5,
          pl: 5.5,
          fontWeight: 700,
          lineHeight: 1.5,
          border: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        Комментарии: {data.commentsCount}
      </Box>
      <CommentsForm
        relationId={id}
        businessCardId={
          showReceiver ? data.businessCard.id : data.offer.businessCard.id
        }
        comments={mapComments(data.comments)}
        isCommentAllowed={
          data.isCommentAllowed && data.businessCard.status === "PUBLISHED"
        }
        onLike={handleLike}
        onDislike={handleDislike}
        onSubmit={refetch}
      />
      {orderIsActive && data.businessCard.status === "PUBLISHED" && (
        <Link
          text="Поделитесь контактами своей организации"
          href=""
          onClick={handleShare}
        />
      )}
      {!data.hasCurrentAccountReview &&
        data.status === "COMPLETED" &&
        data.businessCard.status === "PUBLISHED" && (
          <Link text="Добавить отзыв" href="" onClick={sendReviewModal.on} />
        )}
      {data.hasCurrentAccountReview && data.status === "COMPLETED" && (
        <Link text="Посмотреть отзывы" href="" onClick={viewReviewModal.on} />
      )}
      <Box sx={{ display: "flex", justifyContent: "center", gap: 3.75 }}>
        {showReceiver && data.businessCard.status === "PUBLISHED" && (
          <>
            {(data.status === "PUBLISHED" || data.status === "ACCEPTED") && (
              <OrdersResponsesDetailButton
                text="Отозвать"
                variant="secondary-inverse"
                onClick={handleDiscard}
              />
            )}
            {data.status === "ACCEPTED" && (
              <OrdersResponsesDetailButton
                text="Подтвердить"
                onClick={handleConfirm}
              />
            )}
          </>
        )}
        {!showReceiver && data.businessCard.status === "PUBLISHED" && (
          <>
            {orderIsActive && (
              <OrdersResponsesDetailButton
                text="Отклонить"
                variant="secondary-inverse"
                onClick={handleDeclineOrder}
              />
            )}
            {data.status === "PUBLISHED" && (
              <OrdersResponsesDetailButton
                text="Принять"
                onClick={handleAcceptOrder}
              />
            )}
            {data.status === "IN_WORK" && (
              <OrdersResponsesDetailButton
                text="Завершить"
                onClick={handleComplete}
              />
            )}
          </>
        )}
      </Box>
      <AddReviewModal
        avatarSrc={
          (showReceiver ? data.offer.businessCard : data.businessCard).logo
            ?.path
        }
        company={
          (showReceiver ? data.offer.businessCard : data.businessCard).name.ru
        }
        open={sendReviewIsOpen}
        onClose={sendReviewModal.off}
        onConfirm={handleSendReview}
      />
      <ViewReviewModal
        offerId={data.offer.id}
        answerId={data.id}
        open={viewReviewIsOpen}
        onClose={viewReviewModal.off}
      />
    </Column>
  );
};
