import { Box } from "@mui/material";
import { AdviceCard, Button } from "@shared/ui";

type Props = {
  onMakeOrder?: () => void;
};

export const MakeOrderCard = ({ onMakeOrder }: Props) => {
  const mask = (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        "&:before": {
          content: "''",
          position: "absolute",
          top: 0,
          left: 0,
          width: "17.5rem",
          height: "17.5rem",
          bgcolor: "#8bffff",
          opacity: 0.7,
          filter: "blur(6.25rem)",
          transform: "translate(-20%, -35%)",
        },
        "&:after": {
          content: "''",
          position: "absolute",
          top: 0,
          right: 0,
          borderRadius: "50%",
          width: "13.75rem",
          height: "13.75rem",
          bgcolor: "#fffe95",
          filter: "blur(1.5625rem)",
          transform: "translate(20%, -5%) matrix(0.5, -0.87, 0.87, 0.5, 0, 0)",
        },
      }}
    />
  );

  return (
    <>
      <AdviceCard
        mask={mask}
        title="Вы не нашли подходящие услуги?"
        description="Опубликуйте заказ и поставщики услуг найдут Вас сами!"
        button={<Button text="Сформировать заказ" onClick={onMakeOrder} />}
      />
    </>
  );
};
