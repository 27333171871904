import { api } from "@shared/api";
import { showErrorMessages } from "@shared/lib/error";
import { useFilters } from "@entities/filters";
import { useInfiniteQuery } from "react-query";
import { useMemo } from "react";
import { limit, getNextPageParam } from "@shared/lib/infiniteList";
import { businessCardsKeys } from "./businessCardsKeys";

export const useMyBusinessCardsList = () => {
  const { filters } = useFilters();
  const query = useInfiniteQuery({
    queryKey: businessCardsKeys.my(filters),
    queryFn: ({ pageParam: offset = 0 }) =>
      api.getMyBusinessCards({
        ...filters,
        offset,
        limit,
      }),
    getNextPageParam,
    onError: showErrorMessages,
  });

  const items = useMemo(
    () => query.data?.pages.flatMap((item) => item) ?? [],
    [query.data?.pages]
  );

  return {
    isLoading: query.isFetching && !query.isFetchingNextPage,
    emptyList: "Нет карточек бизнеса",
    size: items.length,
    items: items,
    hasMore: query.hasNextPage ?? false,
    next: query.fetchNextPage,
  };
};
