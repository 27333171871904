import {
  OrdersResponsesCard,
  OrdersResponsesDetailButton,
} from "@entities/orders-responses";
import { Column, Loader, tagsByStatus } from "@shared/ui";
import { OrdersShortDetails } from "./OrdersShortDetails";
import { Box } from "@mui/material";
import { ServicePrice } from "@entities/marketplace";
import { useLocation, useNavigate } from "react-router-dom";
import { useDislikePost, useLikePost } from "@entities/posts";
import { DeclineIncomingOrderModal } from "./DeclineIncomingOrderModal";
import { useBoolean } from "@shared/hooks";
import { RespondForm } from "@features/service-order/ui/RespondForm";
import { useCreateRespond } from "@entities/service";
import { CreateRespond } from "@shared/types/respond";
import { useDeclineRespond } from "../hooks/useDeclineRespond";
import { useIncomingOrder } from "../hooks/useIncomingOrder";
import { Paths } from "@shared/lib/router";

export const IncomingOrderDetails = () => {
  const likePost = useLikePost();
  const dislikePost = useDislikePost();

  const location = useLocation();
  const navigate = useNavigate();
  const { data, refetch } = useIncomingOrder();

  const [writeResponse, writeResponseActions] = useBoolean(
    location.hash.startsWith("#answer")
  );
  const decline = useDeclineRespond();
  const createRespond = useCreateRespond("Сформирован ответ на входящий заказ");
  const handleCloseRespondForm = () =>
    location.hash.startsWith("#answer")
      ? navigate(Paths.IncomingOrders)
      : writeResponseActions.off();

  const [confirmCancelIsOpen, confirmCancelModal] = useBoolean();

  const handleClose = async (reason: string) =>
    decline
      .mutateAsync({ id: data?.id ?? "", reason })
      .then(confirmCancelModal.off)
      .then(() => refetch());
  const handleSubmit = (respond: CreateRespond) => {
    createRespond
      .mutateAsync({ id: data?.id ?? "", respond })
      .then(writeResponseActions.off)
      .then(() => refetch());
  };

  const handleLike =
    !likePost.isLoading && data?.id
      ? () => likePost.mutate(data.id, { onSuccess: () => refetch() })
      : undefined;

  const handleDislike =
    !dislikePost.isLoading && data?.id
      ? () => dislikePost.mutate(data.id, { onSuccess: () => refetch() })
      : undefined;

  if (!data) {
    return <Loader />;
  }

  return (
    <Column sx={{ gap: 2 }}>
      <OrdersResponsesCard
        header="Заказ"
        avatarSrc={data.avatarSrc}
        tag={tagsByStatus[data.status]}
        rating={data.rating}
        reviewsCount={data.reviewsCount}
        price={
          data.price.value && (
            <ServicePrice value={data.price.value} type={data.price.type} />
          )
        }
        title={data.company}
        name={data.name}
        date={data.date}
        body={
          <OrdersShortDetails
            type={data.services.join(", ")}
            target={{ title: "Получатель заказа", value: data.target }}
            title={data.title}
            text={data.text}
          />
        }
        statistics={data.statistics}
        showComments={false}
        onLike={handleLike}
        onDislike={handleDislike}
      />
      {["PUBLISHED", "ACCEPTED"].includes(data.status) && !writeResponse && (
        <Box sx={{ display: "flex", justifyContent: "center", gap: 3.75 }}>
          <OrdersResponsesDetailButton
            text="Отклонить"
            variant="secondary-inverse"
            onClick={confirmCancelModal.on}
          />
          <OrdersResponsesDetailButton
            text="Сформировать ответ"
            onClick={writeResponseActions.on}
          />
        </Box>
      )}
      {writeResponse && (
        <RespondForm
          sx={{
            "& .form": { border: "1px solid #ddd" },
            "& .actions": { borderTop: "none" },
          }}
          currentBusinessCard={data.targetId}
          canEditBusinessCard={!data.targetId}
          cancelButtonText="Отменить"
          onCancel={handleCloseRespondForm}
          onSubmit={handleSubmit}
        />
      )}
      <DeclineIncomingOrderModal
        open={confirmCancelIsOpen}
        onClose={confirmCancelModal.off}
        onConfirm={handleClose}
      />
    </Column>
  );
};
