import { Box } from "@mui/material";
import { useState } from "react";
import LinesEllipsis from "react-lines-ellipsis";

type Props = {
  text: string;
  maxLines: number;
};

export const EllipsesText = ({ text, maxLines }: Props) => {
  const [moreButtonVisible, setMoreButtonVisible] = useState(false);
  const [moreButtonExpanded, setMoreButtonExpanded] = useState(false);

  const handleReflow = ({ clamped }: { clamped: boolean }) =>
    setMoreButtonVisible(clamped);
  const handleMoreButtonClick = () => setMoreButtonExpanded((value) => !value);

  return (
    <>
      <LinesEllipsis
        text={text}
        onReflow={handleReflow}
        maxLine={moreButtonExpanded ? 1000 : maxLines}
      />
      {(moreButtonVisible || moreButtonExpanded) && (
        <Box
          onClick={handleMoreButtonClick}
          sx={{
            textAlign: "right",
            cursor: "pointer",
            fontSize: "0.75rem",
            color: (theme) => theme.palette.secondary.light,
          }}
        >
          {moreButtonExpanded ? "скрыть" : "Показать еще"}
        </Box>
      )}
    </>
  );
};
