import { api } from "@shared/api";
import { showErrorMessages } from "@shared/lib/error";
import { useMutation } from "react-query";

export const useBusinessCardStatistic = () =>
  useMutation(
    ({ id, fromDate, toDate }: { id: string; fromDate: Date; toDate: Date }) =>
      api.getBusinessCardStatistic(id, fromDate, toDate),
    { onError: showErrorMessages }
  );
