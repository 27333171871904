import { SxProps, Theme, styled } from "@mui/material";
import { unique } from "@shared/lib/utils";
import { Dictionary } from "@shared/types/dictionary";
import { Checkbox } from "@shared/ui";
import { Fragment } from "react";

const Title = styled("span")(({ theme }) => ({
  fontWeight: 700,
  fontSize: "0.875rem",
  lineHeight: 1.5,
  color: theme.palette.text.primary,
}));

const Container = styled("div")(({ theme }) => ({
  display: "inline-flex",
  flexDirection: "column",
  rowGap: theme.spacing(0.5),
}));

type Props = {
  className?: string;
  sx?: SxProps<Theme>;
  title?: string;
  sections: Dictionary[];
  value?: string[] | null | undefined;
  onChange?: (value: string[]) => void;
};

export const SectionFilter = ({
  className,
  sx,
  sections,
  title,
  value,
  onChange,
}: Props) => {
  const val = unique(value ?? []);

  const isCheckedSection = (section: Dictionary) => {
    return section.children?.length
      ? section.children.every((item) => val.includes(item.id))
      : val.includes(section.id);
  };

  const isCheckedItem = (item: Omit<Dictionary, "children">) => {
    return val.includes(item.id);
  };

  const handleChangeSection = (section: Dictionary) => (checked: boolean) => {
    const diff = section.children?.length
      ? section.children.map((sub) => sub.id)
      : [section.id];

    const newValue = checked
      ? [...val, ...diff]
      : val.filter((v) => !diff.includes(v));

    onChange?.(unique(newValue));
  };

  const handleChangeItem =
    (item: Omit<Dictionary, "children">) => (checked: boolean) => {
      const newValue = checked
        ? [...val, item.id]
        : val.filter((v) => item.id !== v);

      onChange?.(unique(newValue));
    };

  return (
    <Container className={className} sx={sx}>
      <Title>{title}</Title>
      {sections.map((section) => (
        <Fragment key={section.id}>
          <Checkbox
            label={section.text.ru}
            checked={isCheckedSection(section)}
            onChange={handleChangeSection(section)}
          />

          {section.children?.map((item) => (
            <Checkbox
              key={item.id}
              sx={{ columnGap: (theme) => theme.spacing(2) }}
              label={item.text.ru}
              checked={isCheckedItem(item)}
              onChange={handleChangeItem(item)}
            />
          ))}
        </Fragment>
      ))}
    </Container>
  );
};
