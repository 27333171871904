import axios from "axios";
import { config } from "./config";
import qs from "qs";

export const client = axios.create({
  baseURL: config.api,
  withCredentials: true,
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
});
