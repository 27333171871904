import { Box, styled } from "@mui/material";
import { ReactNode } from "react";

import { ReactComponent as ArrowRightIcon } from "@shared/assets/arrow-right.svg";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Carousel,
  Column,
  SliderCard,
  SliderCardProps,
  SliderCardSkeleton,
} from "@shared/ui";

type HintProps = {
  text: string;
};

const Hint = styled(({ text, ...otherProps }: HintProps) => {
  const mask = (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        height: "100%",
        pointerEvents: "none",
        "&:before": {
          content: "''",
          position: "absolute",
          top: 0,
          left: 0,
          width: "17.5rem",
          height: "17.5rem",
          bgcolor: "#8bffff",
          opacity: 0.7,
          filter: "blur(6.25rem)",
          transform: "translate(-50%, -50%)",
        },
      }}
    />
  );

  return (
    <Box
      sx={{
        pl: 2,
        position: "relative",
        "&:before": {
          content: "''",
          position: "absolute",
          top: "55%",
          left: "3px",
          borderStyle: "solid",
          borderWidth: "0 0 18px 18px",
          borderColor: "transparent transparent #c2fffc transparent",
        },
      }}
    >
      <Box {...otherProps}>
        {mask}
        <Box position="relative">{text}</Box>
      </Box>
    </Box>
  );
})(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  width: "49.5rem",
  padding: theme.spacing(1.25, 12, 1.25, 9),
  background: "#EAFFFA",
  borderRadius: theme.spacing(2),
  fontSize: "1.5rem",
  lineHeight: 1.35,
  fontWeight: 700,
  color: theme.palette.text.primary,
}));

type Props = {
  title: string;
  card: ReactNode;
  items: SliderCardProps[];
  hintText?: string;
  viewAllLink: string;
  isLoading: boolean
};
export const Section = ({
  title,
  card,
  items,
  hintText,
  viewAllLink,
  isLoading
}: Props) => {
  const navigate = useNavigate();
  const handleClick = () => navigate(viewAllLink);

  return (
    <Column
      sx={{
        gap: 4,
        position: "relative",
        minWidth: "75rem",
        maxWidth: "79rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            fontSize: "2rem",
            lineHeight: 1.25,
            fontWeight: 700,
            color: "text.primary",
          }}
        >
          {title}
        </Box>
        {hintText && <Hint text={hintText} />}
      </Box>
      {items.length === 0 && !isLoading && <Box>{card}</Box>}
      {(items.length > 0 || isLoading) && (
        <Carousel
          slidesToShow={3}
          button={
            <Button
              variant="link"
              text={`Посмотреть все ${title.toLowerCase()}`}
              onClick={handleClick}
              right={<ArrowRightIcon />}
              sx={{ lineHeight: 1.5, fontWeight: 700 }}
            />
          }
        >
          {isLoading && [1,2,3].map((item) => (
            <div key={item}>
              <SliderCardSkeleton />
            </div>
          ))}
          {items?.map((item) => (
            <div key={item.text}>
              <SliderCard {...item} isCompact={true} />
            </div>
          ))}
        </Carousel>
      )}
    </Column>
  );
};
