import { SxProps } from "@mui/joy/styles/types";
import { Box } from "@mui/material";

type Props = {
  sx?: SxProps;
  beforeStyles?: SxProps;
  afterStyles?: SxProps;
};

export const Mask = ({ sx, afterStyles, beforeStyles }: Props) => (
  <Box
    sx={[
      {
        position: "absolute",
        width: "100%",
        height: "100%",
        pointerEvents: "none",
        "&:before": {
          content: "''",
          position: "absolute",
          top: 0,
          left: 0,
          opacity: 0.7,
          filter: "blur(6.25rem)",
          zIndex: -1,
          ...beforeStyles,
        },
        "&:after": {
          content: "''",
          position: "absolute",
          top: 0,
          left: 0,
          opacity: 0.7,
          filter: "blur(6.25rem)",
          zIndex: -1,
          ...afterStyles,
        },
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  />
);
