import { styled, SxProps, Theme, Link as MuiLink } from "@mui/material";
import { HTMLAttributeAnchorTarget } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  href: string;
  text: string;
  type?: "normal" | "tel" | "email";
  target?: HTMLAttributeAnchorTarget | undefined;
  sx?: SxProps<Theme>;
  onClick?: () => void;
};

const phone = (phone: string) => `tel:${phone.replaceAll(/[^\d|+]/g, "")}`;
const email = (email: string) => `mailto:${email}`;

export const Link = styled(
  ({ href, text, type = "normal", sx, onClick, ...otherProps }: Props) => {
    const navigate = useNavigate();
    const handleClick = (
      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
      if (type !== "tel" && type !== "email") {
        event.preventDefault();
        event.stopPropagation();
      }
      if (onClick) {
        onClick();
      } else if (type === "normal") {
        navigate(href);
      }
    };
    return (
      <MuiLink
        sx={[...(Array.isArray(sx) ? sx : [sx])]}
        onClick={handleClick}
        href={
          type === "tel" ? phone(href) : type === "email" ? email(href) : href
        }
        {...otherProps}
        children={text}
      />
    );
  }
)({
  textDecoration: "none",
  color: "#2386E0",
  lineHeight: 1.5,
  cursor: "pointer",
  width: "fit-content",
});
