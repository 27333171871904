import { Box, SxProps, Theme } from "@mui/material";

type Props = {
  className?: string;
  sx?: SxProps<Theme>;
  serviceName: string;
  serviceTypes: string | string[];
  services?: string | string[];
  text: string;
};

export const ServiceShortDetails = ({
  className,
  sx = [],
  serviceName,
  serviceTypes,
  services,
  text,
}: Props) => {
  return (
    <Box className={className} sx={sx}>
      <Box sx={{ color: "text.primary" }}>{serviceName}</Box>
      <Box sx={{ color: "text.disabled", fontSize: ".875rem" }}>
        {`Тип услуги: ${
          Array.isArray(serviceTypes) ? serviceTypes.join(", ") : serviceTypes
        }`}
      </Box>
      <Box sx={{ mt: 2, color: "text.primary" }}>{text}</Box>
      {!!(Array.isArray(services) ? services.length : services) && (
        <Box sx={{ mt: 2, fontSize: "1rem", color: "text.primary" }}>
          <Box component="span" sx={{ color: "text.disabled" }}>
            Услуги
          </Box>{" "}
          {Array.isArray(services) ? services.join(", ") : services}
        </Box>
      )}
    </Box>
  );
};
