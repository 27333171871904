import { useAppSelector } from "@app/store";
import {
  FilterButton,
  FiltersContainer,
  PeriodFilter,
  Search,
  ServiceTypesFilter,
  Sort,
  useFilters,
} from "@entities/filters";
import { userModel } from "@entities/user";
import { Switch } from "@shared/ui";

export const OrderCompilationFilters = () => {
  const isSignedIn = useAppSelector(userModel.isSignedInSelector);

  const { filters, handleChange } = useFilters({ recommended: isSignedIn });

  return (
    <FiltersContainer>
      <Sort
        defaultValue={
          filters.sort
            ? `${filters.sort}_${filters.order ?? "ASC"}`
            : "POPULARITY_ASC"
        }
        onChange={handleChange("sort", "order")}
      />
      <FilterButton>
        <PeriodFilter
          defaultValue={filters.fromDate}
          onChange={handleChange("fromDate")}
        />
        <ServiceTypesFilter
          defaultValue={filters.serviceTypes}
          onChange={handleChange("serviceTypes")}
        />
      </FilterButton>
      <Switch
        sx={{ color: (theme) => theme.palette.text.disabled }}
        defaultChecked={filters.recommended}
        label="Только рекомендованные"
        disabled={!isSignedIn}
        onChange={handleChange("recommended")}
      />
      <Search
        sx={{ ml: "auto", width: "23.4375rem" }}
        defaultValue={filters.text}
        placeholder="Искать по разделу"
        onChange={handleChange("text")}
      />
    </FiltersContainer>
  );
};
