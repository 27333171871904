export enum AttachmentType {
  BusinessCard = "BusinessCard",
  BusinessCardLogo = "BusinessCardLogo",
  ServiceCard = "ServiceCard",
  NewsPost = "NewsPost",
  Question = "Question",
  Answer = "Answer",
  Comment = "Comment",
  Complaint = "Complaint",
  Offer = "Offer",
  OfferAnswer = "OfferAnswer",
  UserLogo = "UserLogo",
  Review = "Review",
  Feedback = "Feedback",
}

export type AttachmentIconType =
  | "pdf"
  | "word"
  | "excel"
  | "audio"
  | "video"
  | "archive"
  | "image"
  | "unidentified";

export type Attachment = {
  id: string;
  contentSize: number;
  contentType: string;
  name: string;
  digest: string;
  path: string;
  type?: AttachmentType;
  iconType: AttachmentIconType;
};
