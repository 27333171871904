import { ReactComponent as RadioIcon } from "@shared/assets/radio.svg";
import { ReactComponent as CheckedRadioIcon } from "@shared/assets/checked-radio.svg";
import {
  styled,
  Radio as MuiRadio,
  SxProps,
  Theme,
  RadioProps,
} from "@mui/material";

export const StyledRadio = styled(MuiRadio)(({ theme }) => ({
  padding: theme.spacing(0.5),
}));

const LabelContainer = styled("label")(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  columnGap: theme.spacing(1),
  fontSize: "0.875rem",
  lineHeight: 1.5,
  color: theme.palette.text.primary,
  cursor: "pointer",
}));

const Label = styled("span")({ whiteSpace: "nowrap" });

type Props = Omit<RadioProps, "className" | "sx" | "onChange"> & {
  className?: string;
  sx?: SxProps<Theme>;
  label?: string;
  onChange?: (value: boolean) => void;
};

export const Radio = ({ className, sx, label, onChange, ...other }: Props) => {
  const handleChange = (_: unknown, value: boolean) => onChange?.(value);

  return (
    <LabelContainer className={className} sx={sx}>
      <StyledRadio
        {...other}
        checkedIcon={<CheckedRadioIcon height="1rem" width="1rem" />}
        icon={<RadioIcon height="1rem" width="1rem" />}
        onChange={handleChange}
      />
      <Label>{label}</Label>
    </LabelContainer>
  );
};
