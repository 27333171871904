import { styled } from "@mui/material";
import { ViewCount } from "@shared/ui";
import { Link, Title, Value } from "./CoreTexts";

type Props = {
  id: string;
  name: string;
  type: string;
  address: string;
  website?: string;
  viewCount: number;
};

export const MyBusinessCardDetails = styled(
  ({ name, type, address, website, viewCount, ...otherProps }: Props) => {
    return (
      <div {...otherProps}>
        <Title text="Наименование" />
        <Value text={name} />
        <Title text="Тип" />
        <Value text={type} />
        <Title text="Адрес" />
        <Value text={address} />
        {!!website && (
          <>
            <Title text="Веб сайт" /> <Link text={website} />
          </>
        )}
        <ViewCount count={viewCount} />
      </div>
    );
  }
)({
  display: "grid",
  gridTemplateColumns: "auto auto",
  columnGap: "2.5rem",
  rowGap: "0.5rem",
  lineHeight: 1.5,
});
