import { styled } from "@mui/material";

import { ReactComponent as ArrowLeft } from "@shared/assets/arrow-left.svg";
import { ReactComponent as ArrowRight } from "@shared/assets/arrow-right.svg";

type Props = {
  className?: string;
  variant: "right" | "left";
  disabled?: boolean;
  onClick?: () => void;
};

export const ArrowButton = styled(
  ({ variant, disabled, ...otherProps }: Props) => (
    <button {...otherProps}>
      {variant === "right" ? <ArrowRight /> : <ArrowLeft />}
    </button>
  )
)(({ theme, disabled }) => ({
  margin: 0,
  width: "auto",
  overflow: "visible",
  background: "transparent",
  font: "inherit",
  appearance: "none",

  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "50%",
  padding: theme.spacing(1.5),
  color: disabled ? theme.palette.text.disabled : theme.palette.text.primary,
  backgroundColor: disabled
    ? theme.palette.divider
    : theme.palette.background.paper,
  transition: theme.transitions.create(["background-color", "color"]),
  cursor: disabled ? "default" : "pointer",
}));
