export const encodeHash = <T extends any>(val: T) => {
  try {
    return encodeURIComponent(btoa(JSON.stringify(val)));
  } catch {}
};

export const decodeHash = <T extends any>(
  val: string | undefined
): T | undefined => {
  try {
    if (typeof val === "undefined") return;
    return JSON.parse(atob(decodeURIComponent(val))) as T;
  } catch {}
};
