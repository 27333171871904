import { ReactNode } from "react";
import { Box, SxProps, Theme, styled } from "@mui/material";
import { Button, ButtonVariant } from "@shared/ui";

const Title = styled("h6")(({ theme }) => ({
  margin: 0,
  fontWeight: 700,
  fontSize: "1.5rem",
  lineHeight: 1.35,
  color: theme.palette.text.primary,
}));

type Props = {
  sx?: SxProps<Theme>;
  title: string;
  buttonText: string;
  buttonVariant?: ButtonVariant;
  imageSrc?: string;
  mask?: ReactNode;
  onClick?: () => void;
};

export const SmallCard = ({
  sx,
  title,
  buttonText,
  buttonVariant = "primary",
  imageSrc,
  mask,
  onClick,
}: Props) => {
  return (
    <Box
      sx={[
        {
          background: "#F2F6FA",
          position: "relative",
          px: (theme) => theme.spacing(3),
          py: (theme) => theme.spacing(4),
          borderRadius: (theme) => theme.spacing(2),
          width: "24rem",
          height: "13.5rem",
          overflow: "hidden",
          zIndex: 0,
          display: "flex",
          flex: "0 0 auto",
          flexDirection: "column",
          justifyContent: "space-between",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {mask}
      <Title>{title}</Title>
      {imageSrc && (
        <Box sx={{ position: "absolute", right: 0, bottom: 0 }}>
          <img src={imageSrc} alt="" />
        </Box>
      )}
      <Button
        sx={{
          width: "200px",
          fontSize: "0.875rem",
          padding: (theme) => theme.spacing(1.75, 0),
          justifyContent: "center",
          fontWeight: 700,
        }}
        text={buttonText}
        variant={buttonVariant}
        onClick={onClick}
      />
    </Box>
  );
};
