import { Radio } from "../Radio";
import { Select, SelectProps } from "./Select";
import { RenderSelectItemOptions } from "./types";

type Props = Omit<SelectProps, "renderItem">;

export const RadioSelect = (props: Props) => {
  const renderItem = ({
    id,
    value,
    selected,
    onClick,
  }: RenderSelectItemOptions) => {
    return (
      <Radio
        sx={{ px: 2 }}
        key={id}
        label={value}
        checked={selected}
        onChange={() => onClick(id, value)}
      />
    );
  };

  return <Select {...props} renderItem={renderItem} />;
};
