import qs from "qs";
import { Filters } from "@entities/filters";

export const serviceKeys = {
  service: (id: string) => ["service", id],
  serviceCompilation: (filters: Filters) => [
    "service",
    "compilation",
    qs.stringify(filters, { arrayFormat: "repeat" }),
  ],
  serviceOrderCompilation: (filters: Filters) => [
    "service",
    "order",
    "compilation",
    qs.stringify(filters, { arrayFormat: "repeat" }),
  ],
} as const;
