import { Tabs, businessModel } from "@entities/business";
import { ServicesTab } from "./ServicesTab";
import { ReviewsTab } from "./ReviewsTab";
import { Tab } from "@entities/business/ui/Tabs";
import { StatisticsTab } from "./StatisticsTab";
import { NotificationsTab } from "./NotificationsTab";
import { Box, Chip, styled } from "@mui/material";
import { useAppSelector } from "@app/store";
import { useRef } from "react";

const StyledChip = styled(Chip)(({ theme }) => ({
  fontSize: "0.75rem",
  lineHeight: "1rem",
  fontWeight: 400,
  height: "1rem",
  background: "#EF3C15",

  "& .MuiChip-label": {
    padding: theme.spacing(0.25, 0.5),
    color: "#fff",
  },
}));

export const BusinessCardTabs = () => {
  const createdByCurrentAccount =
    useAppSelector(businessModel.cardDetailsSelector)
      ?.createdByCurrentAccount ?? false;

  const notificationsCount =
    useAppSelector(businessModel.cardNotificationsSelector)?.filter(
      (item) => !item.read
    )?.length ?? 0;

  const anchor = useRef<HTMLElement>(null);

  const handleChange = () => {
    anchor.current && anchor.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box ref={anchor}>
      <Tabs
        sx={{
          position: "sticky",
          top: 0,
          borderBottom: "1px solid #F7F7F7",
          bgcolor: "background.paper",
          zIndex: 100,
          boxShadow: "none",
        }}
        tabs={[
          {
            label: "Услуги",
            component: <ServicesTab />,
          },
          {
            label: "Отзывы",
            component: (
              <ReviewsTab isCommentAllowed={createdByCurrentAccount} />
            ),
          },
          ...(createdByCurrentAccount
            ? [
                {
                  label: "Статистика",
                  component: <StatisticsTab />,
                },
                {
                  label: "Оповещения",
                  component: <NotificationsTab />,
                  icon:
                    notificationsCount > 0 ? (
                      <StyledChip
                        label={
                          notificationsCount >= 100 ? "99+" : notificationsCount
                        }
                      />
                    ) : null,
                },
              ]
            : []),
        ].filter((item): item is Tab => item !== null)}
        onChange={handleChange}
      />
    </Box>
  );
};
