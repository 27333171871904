import { InfinityList, ItemRenderOptions } from "@shared/ui";
import { useIncomingResponses } from "../hooks";
import { IncomingResponsesListItem } from "./IncomingResponsesListItem";
import { useDislikePost, useLikePost } from "@entities/posts";
import { IncomingResponseCard } from "../types";
import { ordersResponsesKeys } from "../hooks/ordersResponsesKeys";
import { useRefetchList } from "../hooks/useRefetchList";

export const IncomingResponsesList = () => {
  const listData = useIncomingResponses();
  const likePost = useLikePost();
  const dislikePost = useDislikePost();
  const refetch = useRefetchList(ordersResponsesKeys.incomingResponses);

  const renderItem = ({ item }: ItemRenderOptions<IncomingResponseCard>) => {
    const handleLike = !likePost.isLoading
      ? () => likePost.mutate(item.id, { onSuccess: refetch })
      : undefined;

    const handleDislike = !dislikePost.isLoading
      ? () => dislikePost.mutate(item.id, { onSuccess: refetch })
      : undefined;

    return (
      <IncomingResponsesListItem
        key={item.id}
        item={item}
        onLike={handleLike}
        onDislike={handleDislike}
        onRespond={refetch}
      />
    );
  };

  return <InfinityList {...listData} renderItem={renderItem} />;
};
