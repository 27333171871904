/* eslint-disable react/jsx-no-target-blank */

import { Box, styled } from "@mui/material";
import { getDomainUrl } from "@shared/lib/utils";
import { Button, Container, Input, NavList, NavListItem } from "@shared/ui";

const StyledForm = styled("form")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
}));

const Subscribe = () => (
  <Box
    sx={{
      gridColumn: "1 / 2",
      gridRow: "1 / 2",
      fontSize: ".857rem",
      lineHeight: 1.4,
      display: "flex",
      flexDirection: "column",
      gap: 2,
    }}
  >
    <div>Подпишитесь на рассылку, чтобы получать новости о маркировке</div>
    <StyledForm
      target="_blank"
      method="post"
      action="https://login.sendpulse.com/forms/simple/u/eyJ1c2VyX2lkIjo3NzM3OTcyLCJhZGRyZXNzX2Jvb2tfaWQiOjQ4MTkxOCwibGFuZyI6InJ1In0="
    >
      <Input
        type="email"
        placeholder="Ваш Email"
        required
        name="email"
        pattern="^([^ ]+@[^ ]+\.[a-z]{2,6}|)$"
        sx={{
          width: "22.5rem",
          borderColor: "#e8e8e8",
        }}
      />
      <input type="hidden" name="sender" value="news@markirovka.online"></input>
      <Button variant="secondary" sx={{ fontWeight: 700 }} text="Подписаться" />
    </StyledForm>
    <Box component="p" sx={{ "& a": { color: "inherit" } }}>
      Нажимая кнопку «Подписаться», я принимаю условия{" "}
      <a
        href={getDomainUrl("/upload/Пользовательское соглашение.docx")}
        target="_blank"
      >
        Пользовательского соглашения
      </a>{" "}
      и даю своё согласие на обработку персональных данных на условиях,
      определённых{" "}
      <a
        href={getDomainUrl("/upload/Политика конфеденциальности.docx")}
        target="_blank"
      >
        Политикой конфиденциальности
      </a>
      .
    </Box>
  </Box>
);

const Nav = () => (
  <Box sx={{ gridColumn: "1 / 3", gridRow: "2 / 3" }}>
    <NavList>
      <NavListItem to={getDomainUrl("/community")} label="Сообщество" />
      <NavListItem to={getDomainUrl("/#knowledge")} label="База знаний" />
      <NavListItem to="/" label="Маркетплейс" />
      <NavListItem
        to={getDomainUrl("/virtual_education")}
        label="Виртуальное обучающее пространство"
      />
      <NavListItem to={getDomainUrl("/#knowledge")} label="Видеоинструкции" />
    </NavList>
  </Box>
);

const Questions = () => (
  <Box
    sx={{
      gridColumn: "2 / 3",
      gridRow: "1 / 2",
      pt: 4.4,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "flex-start",
      gap: 2,
      fontWeight: 700,
    }}
  >
    <Button
      variant="primary"
      onClick={() =>
        (window.location.href = getDomainUrl("/createquestion.php"))
      }
      text="Спросить сообщество"
    />
    <Button
      variant="primary"
      onClick={() => (window.location.href = getDomainUrl("/#support"))}
      text="Вопрос команде"
    />
  </Box>
);

const Copyright = () => (
  <Box
    sx={{
      py: "1rem",
      borderTop: "1px solid #e8e8e8",
      display: "flex",
      "& a": { color: "inherit" },
      gap: 2,
      fontSize: ".857rem",
      lineHeight: 1.4,
    }}
  >
    <a href={getDomainUrl("/upload/Пользовательское соглашение.docx")}>
      Пользовательское соглашение
    </a>
    <a href={getDomainUrl("/upload/Политика конфеденциальности.docx")}>
      Политика конфиденциальности
    </a>
    <Box sx={{ ml: "auto", whiteSpace: "nowrap" }}>
      © {new Date().getFullYear()}, Честное сообщество
    </Box>
  </Box>
);

export const Footer = () => {
  return (
    <Box sx={{ bgcolor: "#f7f7f7" }}>
      <Container sx={{ py: 0 }}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridAutoColumns: "auto",
            gridAutoRows: "auto",
            gap: "2rem",
            py: "2rem",
          }}
        >
          <Subscribe />
          <Nav />
          <Questions />
        </Box>
        <Copyright />
      </Container>
    </Box>
  );
};
