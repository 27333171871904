import {
  MyOrderPreviewResponses,
  OrdersResponsesCard,
  OrdersResponsesDetailButton,
} from "@entities/orders-responses";
import { Box } from "@mui/material";
import { Column, Loader, tagsByStatus } from "@shared/ui";
import { OrdersShortDetails } from "./OrdersShortDetails";
import { useOrder } from "../hooks/useOrder";
import { ServicePrice } from "@entities/marketplace";
import { ConfirmCancelModal } from "./ConfirmCancelModal";
import { useBoolean } from "@shared/hooks";
import { useState } from "react";
import { getReviewsByStatusCounts } from "../model/utils";
import { useDislikePost, useLikePost } from "@entities/posts";
import { useCloseMyOrder } from "../hooks/useCloseMyOrder";
import { useLocation } from "react-router-dom";
import { Paths } from "@shared/lib/router";

export const MyOrderPreview = () => {
  const location = useLocation();
  const likePost = useLikePost();
  const dislikePost = useDislikePost();

  const { data, refetch } = useOrder();
  const id = data?.id;

  const [confirmCancelIsOpen, confirmCancelModal] = useBoolean();
  const [reviews, setReviews] = useState<Record<string, number>>({});

  const handleClose = useCloseMyOrder();

  const onClose = async () => {
    if (id) {
      handleClose(id).then(() => refetch());
    }
    confirmCancelModal.off();
  };

  const handleCancel = async () => {
    if (!id) {
      return;
    }
    const reviews = await getReviewsByStatusCounts(id);
    setReviews(reviews);
    if (Object.keys(reviews).length) {
      confirmCancelModal.on();
    } else {
      await onClose();
    }
  };

  const handleLike =
    !likePost.isLoading && data?.id
      ? () => likePost.mutate(data.id, { onSuccess: () => refetch() })
      : undefined;

  const handleDislike =
    !dislikePost.isLoading && data?.id
      ? () => dislikePost.mutate(data.id, { onSuccess: () => refetch() })
      : undefined;

  if (!data) {
    return <Loader />;
  }

  return (
    <Box sx={{ display: "grid", gridTemplateColumns: "32% auto", gap: 3 }}>
      <MyOrderPreviewResponses />
      <Column sx={{ minWidth: 0 }}>
        <OrdersResponsesCard
          avatarSrc={data.avatarSrc}
          header="Заказ"
          tag={tagsByStatus[data.status]}
          rating={data.rating}
          reviewsCount={data.reviewsCount}
          price={
            data.price.value && (
              <ServicePrice value={data.price.value} type={data.price.type} />
            )
          }
          title={data.company}
          name={data.name}
          date={data.date}
          body={
            <OrdersShortDetails
              type={data.services.join(", ")}
              target={{ title: "Получатель заказа", value: data.target }}
              title={data.title}
              text={data.text}
            />
          }
          statistics={data.statistics}
          showComments={false}
          sx={{ mb: 6 }}
          onLike={handleLike}
          onDislike={handleDislike}
        />
        {data.status === "PUBLISHED" &&
          location.state?.from !== Paths.OrderCompilation &&
          data.businessCardStatus === "PUBLISHED" && (
            <OrdersResponsesDetailButton
              variant="secondary-inverse"
              sx={{ alignSelf: "center" }}
              onClick={handleCancel}
              text="Закрыть заказ"
            />
          )}
      </Column>
      <ConfirmCancelModal
        reviews={reviews}
        open={confirmCancelIsOpen}
        onClose={confirmCancelModal.off}
        onConfirm={onClose}
      />
    </Box>
  );
};
