import { Box, SxProps, Theme } from "@mui/material";
import { Button } from "@shared/ui";
import { useState } from "react";
import { Status } from "../types";

const names = {
  all: "Все",
  published: "Опубликованные",
  opened: "Открытые",
  closed: "Закрытые",
};

const statuses = {
  all: undefined,
  published: ["PUBLISHED"],
  opened: ["PUBLISHED", "ACCEPTED", "IN_WORK"],
  closed: ["CANCELLED", "DECLINED", "COMPLETED"],
};

type Props = {
  defaultValue: Status;
  onChange?: (value?: string[]) => void;
  options?: Status[];
  sx?: SxProps<Theme>;
};

export const StatusFilter = ({
  defaultValue,
  onChange,
  options,
  sx,
}: Props) => {
  const [selectedOption, setSelectedOption] = useState(defaultValue);

  const handleChange = (value: Status) => {
    setSelectedOption(value);
    onChange?.(statuses[value]);
  };

  if (!options) {
    return null;
  }

  return (
    <Box sx={[{ display: "flex" }, ...(Array.isArray(sx) ? sx : [sx])]}>
      {options.map((option) => (
        <Button
          key={option}
          variant="outlined"
          text={names[option]}
          sx={{ bgcolor: option === selectedOption ? "#FCFCCF" : null }}
          onClick={() => handleChange(option)}
        />
      ))}
    </Box>
  );
};
