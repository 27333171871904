import { styled } from "@mui/material";
import { SelectItem as SelectItemType } from "./types";

type Props = SelectItemType & {
  selected?: boolean;
  onClick: (id: string, value: string) => void;
};

export const SelectItem = styled(
  ({ id, value, selected, onClick, ...otherProps }: Props) => {
    const handleClick = () => onClick(id, value);
    return (
      <div {...otherProps} id={id} onClick={handleClick}>
        {value}
      </div>
    );
  }
)(({ theme, selected }) => ({
  padding: "1em",
  display: "flex",
  alignItems: "center",
  textTransform: "none",
  background: selected ? "#fcfccf" : "#fff",
  color: theme.palette.text.primary,
  cursor: "pointer",
  "&: hover": {
    background: selected ? "$fcfccf" : "#f7f7f7",
  },
}));
