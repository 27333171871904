import { Box, SxProps, Theme } from "@mui/material";
import { PriceType, PriceTypes, PriceTypesOptions } from "@shared/lib/price";
import {
  Autocomplete,
  Button,
  Column,
  FieldError,
  Input,
  RadioSelect,
  RequiredFieldsError,
  Textarea,
} from "@shared/ui";
import { Controller, useForm } from "react-hook-form";
import { number, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUserPublishedBusinessCards } from "@entities/business";
import { useEffect, useMemo } from "react";
import { CreateRespond } from "@shared/types/respond";

export type FormData = {
  title: string;
  businessCardId: string;
  content: string;
  price: string;
  priceUnits: PriceType;
};

const convertToFormData = (
  data: Partial<CreateRespond>
): Partial<FormData> => ({
  ...data,
  title: data.title?.ru,
  content: data.content?.ru,
});

const convertToEntity = (data: FormData): CreateRespond => ({
  ...data,
  title: { ru: data.title },
  content: { ru: data.content },
});

const validationSchema = object({
  title: string().optional().nullable().max(250, "Максимум 250 символов"),
  businessCardId: string().optional().nullable().required("Обязательное поле"),
  content: string()
    .optional()
    .nullable()
    .required("Обязательное поле")
    .max(4000, "Максимум 4000 символов"),
  price: number()
    .typeError("Стоимость должна быть числом")
    .optional()
    .nullable()
    .positive("Должно быть положительным числом")
    .transform((_, val) => (val === "" ? null : Number(val))),
  priceUnits: string().optional().nullable(),
});

type Props = {
  sx?: SxProps<Theme>;
  className?: string;
  currentBusinessCard?: string;
  data?: Partial<CreateRespond>;
  cancelButtonText?: string;
  submitButtonText?: string;
  onSubmit?: (data: CreateRespond) => void;
  onCancel?: () => void;
  canEditBusinessCard?: boolean;
};

export const RespondForm = ({
  sx = [],
  className,
  currentBusinessCard,
  data,
  cancelButtonText = "Назад",
  submitButtonText = "Опубликовать",
  onSubmit,
  onCancel,
  canEditBusinessCard = true,
}: Props) => {
  const { data: businessCards = [] } = useUserPublishedBusinessCards();

  const mainBusinessCard = useMemo(
    () => businessCards.find((card) => !!card.mainCard),
    [businessCards]
  )?.id;

  const businessCardOptions = useMemo(
    () =>
      businessCards.map((type) => ({
        id: type.id,
        text: type.name,
        disabled: false,
      })),
    [businessCards]
  );

  const defaultBusinessCard = currentBusinessCard || mainBusinessCard;

  const { control, formState, handleSubmit, setValue, watch } =
    useForm<FormData>({
      defaultValues: data && convertToFormData(data),
      resolver: yupResolver(validationSchema),
    });

  const hasRequiredErrors = Object.values(formState.errors).some(
    (error) => error?.type === "required" || error?.type === "optionality"
  );

  const submit = (data: FormData) => {
    onSubmit?.(convertToEntity(data));
  };

  useEffect(() => {
    const isEmpty = !watch("businessCardId");
    isEmpty &&
      defaultBusinessCard &&
      setValue("businessCardId", defaultBusinessCard);
  }, [defaultBusinessCard, businessCardOptions, setValue, watch]);

  return (
    <Box
      component="form"
      className={className}
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      onSubmit={handleSubmit(submit)}
    >
      <Column
        className="form"
        sx={{
          rowGap: (theme) => theme.spacing(1.75),
          p: (theme) => theme.spacing(1, 3, 3),
        }}
      >
        {hasRequiredErrors && <RequiredFieldsError />}
        <Box
          sx={{
            fontWeight: 400,
            fontSize: "1rem",
            lineHeight: 1.5,
            color: (theme) => theme.palette.common.black,
          }}
        >
          Ваш ответ
        </Box>
        <Controller
          control={control}
          name="title"
          render={({ field, fieldState }) => (
            <FieldError
              error={fieldState.error?.message}
              hideError={
                fieldState.error?.type === "required" ||
                fieldState.error?.type === "optionality"
              }
            >
              <Input
                label="Какое решение Вы готовы предложить"
                placeholder="Заголовок предложения"
                value={field.value ?? ""}
                onChange={field.onChange}
              />
            </FieldError>
          )}
        />
        <Controller
          defaultValue={defaultBusinessCard}
          control={control}
          name="businessCardId"
          render={({ field, fieldState }) => (
            <FieldError
              error={fieldState.error?.message}
              hideError={
                fieldState.error?.type === "nullable" ||
                fieldState.error?.type === "required" ||
                fieldState.error?.type === "optionality"
              }
            >
              <Autocomplete
                label="Ваша компания"
                placeholder="Ваша компания"
                options={businessCardOptions}
                required
                value={field.value ?? null}
                onChange={field.onChange}
                disabled={!canEditBusinessCard}
              />
            </FieldError>
          )}
        />
        <Controller
          control={control}
          name="content"
          render={({ field, fieldState }) => (
            <FieldError
              error={fieldState.error?.message}
              hideError={
                fieldState.error?.type === "required" ||
                fieldState.error?.type === "optionality"
              }
            >
              <Textarea
                label="Детальное описание Вашего предложения на заказ"
                placeholder="Описание предложения"
                value={field.value ?? ""}
                required
                onChange={field.onChange}
              />
            </FieldError>
          )}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: (theme) => theme.spacing(0.5),
          }}
        >
          <Box
            sx={{
              fontWeight: 700,
              fontSize: "1rem",
              lineHeight: 1.5,
              color: (theme) => theme.palette.text.primary,
            }}
          >
            Предложенная стоимость
          </Box>
          <FieldError
            error={
              (formState.errors.price?.type !== "required" &&
              formState.errors.price?.type !== "optionality"
                ? formState.errors.price?.message
                : "") ||
              (formState.errors.priceUnits?.type !== "required" &&
              formState.errors.priceUnits?.type !== "optionality"
                ? formState.errors.priceUnits?.message
                : "")
            }
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: (theme) => theme.spacing(3),
              }}
            >
              <Controller
                control={control}
                name="price"
                render={({ field }) => (
                  <Input
                    sx={{ width: "28.8125rem" }}
                    placeholder="Стоимость"
                    value={field.value ?? ""}
                    type="number"
                    step="1"
                    endAdornment={
                      <Box
                        sx={{
                          fontWeight: 700,
                          fontSize: "1rem",
                          lineHeight: 1.5,
                          color: "common.black",
                        }}
                      >
                        ₽
                      </Box>
                    }
                    onChange={field.onChange}
                  />
                )}
              />
              <Controller
                control={control}
                name="priceUnits"
                defaultValue={PriceType.SERVICE}
                render={({ field }) => (
                  <RadioSelect
                    sx={{ flex: "0 0 auto", width: "11.25rem" }}
                    items={PriceTypesOptions}
                    selectedItem={PriceTypes[field.value!]}
                    onChange={(_, id) => field.onChange(id)}
                  />
                )}
              />
              <Box
                sx={{
                  width: "19.5rem",
                  fontWeight: 400,
                  fontSize: "0.875rem",
                  lineHeight: 1.4,
                  color: (theme) => theme.palette.text.disabled,
                }}
              >
                Стоимость услуги будет доступна для просмотра в Маркетплейсе для
                потребителей услуг
              </Box>
            </Box>
          </FieldError>
        </Box>
      </Column>
      <Box
        className="actions"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          columnGap: (theme) => theme.spacing(3.75),
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          p: (theme) => theme.spacing(8, 3),
        }}
      >
        <Button
          sx={{ minWidth: "15rem" }}
          type="button"
          variant="secondary-inverse"
          onClick={onCancel}
        >
          {cancelButtonText}
        </Button>
        <Button sx={{ minWidth: "15rem" }} type="submit" variant="primary">
          {submitButtonText}
        </Button>
      </Box>
    </Box>
  );
};
