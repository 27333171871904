import { useAppSelector } from "@app/store";
import { ViewBusinessCardBlockTitle, businessModel } from "@entities/business";
import { Box } from "@mui/material";
import { Carousel } from "@shared/ui";

export const Gallery = () => {
  const slides = useAppSelector(businessModel.cardGallerySelector);

  return slides?.length ? (
    <Box
      sx={{
        mb: 7,
        pl: 3,
        "& .slick-slider": { maxHeight: "15rem", overflow: "hidden" },
        "& .slick-slide": {
          mr: "1.5rem",
          width: "282px !important",
          boxSizing: "content-box",
        },
      }}
    >
      <Box sx={{ maxWidth: "1200px" }}>
        <ViewBusinessCardBlockTitle text="Галерея" sx={{ mb: 5.5 }} />
      </Box>
      <Carousel slidesToShow={4} buttonsSx={{ mt: 3 }}>
        {slides.map((slide) => (
          <div key={slide}>
            <img
              src={slide}
              alt=""
              style={{ height: 166, objectFit: "cover" }}
            />
          </div>
        ))}
      </Carousel>
    </Box>
  ) : null;
};
