import { api } from "@shared/api";

export const getReviewsByStatusCounts = async (
  id: string
): Promise<Record<string, number>> => {
  const data = await api.getOrderResponsesCount(id);

  return Object.fromEntries(
    Object.entries(data).filter(([key]) =>
      ["PUBLISHED", "ACCEPTED", "IN_WORK"].includes(key)
    )
  );
};
