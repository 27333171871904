import { Paths } from "./router";

const create = (name: string = "", href: string = "") => ({ name, href });

const myMarketplace = create("Мой Маркетплейс", Paths.MyMarketplace);

export const navChain = {
  myMarketplace,
  myBusiness: [myMarketplace, create("Мои карточки", Paths.MyBusiness)],
  business: [myMarketplace, create("Все карточки", Paths.Business)],
  incomingOrders: [
    myMarketplace,
    create("Входящие заказы", Paths.IncomingOrders),
  ],
  incomingResponses: [
    myMarketplace,
    create("Отклики на мои заказы", Paths.IncomingResponses),
  ],
  myOrders: [myMarketplace, create("Мои заказы", Paths.MyOrders)],
  myResponses: [myMarketplace, create("Мои отклики", Paths.MyResponses)],
  serviceCompilation: create("Подборка услуг", Paths.Home),
  orderCompilation: create("Подборка заказов", Paths.OrderCompilation),
  create,
};
