import { Filters } from "@entities/filters";
import { client } from "@shared/lib/client";
import { getFromDate } from "@shared/lib/period";
import { EntityStatus } from "@shared/types/entity";
import { CreateRespond } from "@shared/types/respond";
import {
  CreateServiceOrder,
  ServiceCompilationItem,
  ServiceOrderCompilationItem,
} from "@shared/types/service";

export const createServiceOrder = async (
  data: CreateServiceOrder,
  status: EntityStatus = EntityStatus.PUBLISHED
) => {
  const response = await client.post<void>("/offers", {
    ...data,
    status,
  });
  return response.data;
};

export const createRespond = async (
  id: string,
  data: CreateRespond,
  status: EntityStatus = EntityStatus.PUBLISHED
) => {
  const response = await client.post<void>(`/offers/${id}/answers`, {
    ...data,
    status,
  });
  return response.data;
};

export const getServiceOrderCompilation = async (
  filters: Filters & { offset: number; limit: number }
) => {
  const response = await client.get<ServiceOrderCompilationItem[]>("/offers", {
    params: {
      ...filters,
      sort: filters?.sort ? `BY_${filters.sort}` : undefined,
      fromDate: getFromDate(filters.fromDate),
    },
  });
  return response.data;
};

export const getServiceCompilation = async (
  filters: Filters & { offset: number; limit: number }
) => {
  const response = await client.get<ServiceCompilationItem[]>(
    "/business-cards/services",
    {
      params: {
        ...filters,
        sort: filters?.sort ? `BY_${filters.sort}` : undefined,
        fromDate: getFromDate(filters.fromDate),
      },
    }
  );
  return response.data;
};

export const getService = async (id: string) => {
  const response = await client.get<ServiceCompilationItem>(
    `/business-cards/services/${id}`
  );
  return response.data;
};
