export const config = {
  api: process.env.REACT_APP_API_URL!,
  domain: process.env.REACT_APP_DOMAIN_URL!,
};

(async () => {
  ["REACT_APP_API_URL", "REACT_APP_DOMAIN_URL"].forEach((key) => {
    if (process.env[key] == null) {
      throw new Error(`Environment variable ${key} doesn't found`);
    }
  });
  const data = await fetch('/bitrixUrl')
  config.domain = (await data.json()).bitrixUrl
})();
