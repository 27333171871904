import { useState } from "react";

export const useBoolean = (defaultValue: boolean | (() => boolean) = false) => {
  const [value, setValue] = useState(defaultValue);

  const actions = {
    toggle: () => setValue((prev) => !prev),
    on: () => setValue(true),
    off: () => setValue(false),
  };

  return [value, actions] as const;
};
