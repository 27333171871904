import { SxProps, Theme, styled } from "@mui/material";
import { Checkbox } from "./Checkbox";

const StyledCheckboxGroup = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginLeft: theme.spacing(-0.5),
  gap: theme.spacing(0.25),
}));

const Title = styled("span")(({ theme }) => ({
  fontWeight: 700,
  fontSize: "0.875rem",
  lineHeight: 1.5,
  color: theme.palette.text.primary,
}));

const Container = styled("div")(({ theme }) => ({
  display: "inline-flex",
  flexDirection: "column",
  rowGap: theme.spacing(0.5),
}));

type Item = {
  value: string;
  label: string;
};

type Props = {
  className?: string;
  sx?: SxProps<Theme>;
  items: Item[];
  title?: string;
  value?: string[] | null | undefined;
  onChange?: (value: string[]) => void;
};

export const CheckboxGroup = ({
  className,
  sx,
  items,
  title,
  value,
  onChange,
}: Props) => {
  const val = value ?? [];

  const handleChange = (value: string) => (checked: boolean) => {
    const newValue = checked ? [...val, value] : val.filter((v) => v !== value);
    onChange?.(newValue);
  };

  return (
    <Container className={className} sx={sx}>
      <Title>{title}</Title>
      <StyledCheckboxGroup>
        {items.map((item, index) => (
          <Checkbox
            key={index}
            label={item.label}
            checked={val.includes(item.value)}
            onChange={handleChange(item.value)}
          />
        ))}
      </StyledCheckboxGroup>
    </Container>
  );
};
