import { Box, SxProps, Theme, styled } from "@mui/material";
import { MouseEvent } from "react";
import { ReactComponent as ArrowDown } from "@shared/assets/arrow-down.svg";

const StyledButton = styled("button")(({ theme }) => ({
  fontSize: ".875rem",
  lineHeight: 1.15,
  color: theme.palette.text.primary,
  display: "inline-flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexBasis: "100%",
  gap: theme.spacing(0, 0.8),
  padding: "0.5em 0.5rem 0.5em 1em",
  userSelect: "none",
  whiteSpace: "nowrap",
  cursor: "pointer",
  overflow: "hidden",
  minWidth: theme.spacing(30),
  height: "100%",
  border: "1px solid #e8e8e8",
  borderRadius: theme.spacing(1),
}));

type Props = {
  sx?: SxProps<Theme>;
  value?: string;
  expanded: boolean;
  placeholder?: string;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
};

export const SelectValue = ({
  sx,
  value,
  expanded,
  placeholder,
  onClick,
}: Props) => {
  return (
    <StyledButton
      onClick={onClick}
      sx={[
        { background: expanded ? "#f7f7f7" : "#fff" },
        { borderColor: "#e8e8e8" },
        { color: value ? "text.primary" : "text.disabled" },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      type="button"
    >
      <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
        {value ? value : placeholder}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          transform: `rotate(${expanded ? "180" : "0"}deg)`,
          height: (theme) => theme.spacing(3),
        }}
      >
        <ArrowDown />
      </Box>
    </StyledButton>
  );
};
