import { BusinessCardsList, BusinessListFilters } from "@features/business";
import { navChain } from "@shared/lib/navChain";
import { BaseLayout, Breadcrumbs, Container, PageTitle } from "@shared/ui";
import { Header, Footer } from "@widgets";

export const BusinessPage = () => (
  <BaseLayout header={<Header />} footer={<Footer />}>
    <Container>
      <Breadcrumbs items={navChain.business} />
      <PageTitle text="Все карточки бизнеса" sx={{ mb: 1 }} />
      <BusinessListFilters />
      <BusinessCardsList />
    </Container>
  </BaseLayout>
);
