import { BusinessCardNotification } from "@entities/business";
import { Period } from "@shared/lib/period";
import { NotificationDto } from "@shared/types/businessCard";
import { startOfDay, subMonths, subWeeks } from "date-fns";
import { useEffect, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";

const now = new Date();
const timestamp = {
  [Period.All]: 0,
  [Period.Today]: startOfDay(now).valueOf(),
  [Period.Week]: subWeeks(now, 1).valueOf(),
  [Period.Month]: subMonths(now, 1).valueOf(),
};

export const mapNotification = (
  data: NotificationDto
): BusinessCardNotification => {
  const fullTitle = data.title.ru;
  const pos = fullTitle.indexOf(" ");

  return {
    id: data.id,
    category: data.category,
    type: fullTitle.substring(0, pos),
    title: fullTitle.substring(pos + 1),
    text: data.content.ru,
    date: data.createdDateTime,
    read: data.read,
  };
};

export const useNotificationsTab = (data: BusinessCardNotification[], handleNotificationRead: (id:string) => void) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [unreadOnly, setUnreadOnly] = useState(false);
  const toggleUnread = () => setUnreadOnly((value) => !value);
  const [notifications, setNotifications] = useState<BusinessCardNotification[]>([])

  const [fromDate, setFromDate] = useState<Period>(Period.All);
  const changePeriod = (from: Period) => {
    return setFromDate(from);
  };

  const selected = location.hash.replace("#notification-", "");

  const handleClick = (item: BusinessCardNotification) => {
    navigate({ hash: `notification-${item.id}` });
    handleNotificationRead(item.id)
  }

  const handleClickBack = () => navigate({hash: undefined})

  useEffect(() => {
    setNotifications(data
      .filter(
        (item) =>
          (unreadOnly ? !item.read : true) && item.date > timestamp[fromDate]
      ))
  },[data, fromDate, unreadOnly])

  return {
    notifications,
    selected,
    handleClick,
    toggleUnread,
    changePeriod,
    fromDate,
    handleClickBack,
    unreadOnly
  };
};
