import {
  Avatar,
  Button,
  Column,
  FieldError,
  Modal,
  RequiredFieldsError,
  Textarea,
} from "@shared/ui";
import { Box, Rating } from "@mui/material";
import { object, string } from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { ReactComponent as Star } from "@shared/assets/star-outlined.svg";
import { getLogoPlaceholder } from "@shared/lib/utils";

type FormData = {
  content: string;
  grade: number;
};

const validationSchema = object({
  content: string()
    .optional()
    .nullable()
    .required("Обязательное поле")
    .max(4000, "Максимум 4000 символов"),
});

type Props = {
  avatarSrc?: string;
  company: string;
  open: boolean;
  onClose: () => void;
  onConfirm: (data: FormData) => void;
};

export const AddReviewModal = ({
  avatarSrc,
  company,
  open,
  onClose,
  onConfirm,
}: Props) => {
  const { control, formState, handleSubmit } = useForm<FormData>({
    defaultValues: { content: "" },
    resolver: yupResolver(validationSchema),
  });
  const hasRequiredErrors = Object.values(formState.errors).some(
    (error) => error?.type === "required" || error?.type === "optionality"
  );

  return (
    <Modal
      header="Поделитесь отзывом к проделанной работе в рамках заказа на услугу"
      width="28.5rem"
      open={open}
      onClose={onClose}
      headerSx={{ fontSize: "1rem", px: 8 }}
    >
      <Box component="form" onSubmit={handleSubmit(onConfirm)}>
        <Column sx={{ px: 3, pb: 4, pt: 1, color: "#4B4B4B" }}>
          <Box sx={{ mb: 1.5 }}>Оцените сотрудничество</Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}>
            <Avatar src={avatarSrc}>{getLogoPlaceholder(company)}</Avatar>
            <Box
              sx={{ fontSize: "1.25rem", lineHeight: 1.35, fontWeight: 700 }}
            >
              {company}
            </Box>
          </Box>
          <Controller
            name="grade"
            control={control}
            rules={{ required: true }}
            defaultValue={1}
            render={({ field: { ref, ...rest } }) => (
              <Rating
                sx={{ "& .MuiRating-icon": { pr: 1 }, mb: 1.5 }}
                ref={ref}
                {...rest}
                icon={<Star fill="#F0EE0E" width="1.25rem" height="1.25rem" />}
                emptyIcon={<Star width="1.25rem" height="1.25rem" />}
              />
            )}
          />
          {hasRequiredErrors && <RequiredFieldsError />}
          <Controller
            control={control}
            name="content"
            render={({ field, fieldState }) => (
              <FieldError
                error={fieldState.error?.message}
                hideError={
                  fieldState.error?.type === "required" ||
                  fieldState.error?.type === "optionality"
                }
              >
                <Textarea
                  label="Добавьте отзыв"
                  placeholder="Написать комментарий"
                  value={field.value}
                  onChange={field.onChange}
                  sx={{ mb: 4 }}
                  required
                />
              </FieldError>
            )}
          />

          <Button
            text="Добавить отзыв"
            type="submit"
            sx={{
              px: 1,
              width: "9.5rem",
              alignSelf: "center",
              fontSize: "0.875rem",
              lineHeight: 1.5,
            }}
          />
        </Column>
      </Box>
    </Modal>
  );
};
