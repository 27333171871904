import { Box } from "@mui/material";
import {
  Button,
  Dislike,
  InfinityList,
  ItemRenderOptions,
  Like,
  ViewCount,
} from "@shared/ui";
import {
  MarketplaceCard,
  ServiceCardHeader,
  ServicePrice,
  ServiceShortDetails,
} from "@entities/marketplace";
import { Paths } from "@shared/lib/router";
import { generatePath, useNavigate } from "react-router-dom";
import { useServiceOrderCompilation } from "@entities/service";
import { ServiceOrderCompilationItem } from "@shared/types/service";
import { useAppSelector } from "@app/store";
import { userModel } from "@entities/user";
import { useLikePost } from "@entities/posts";
import { useDislikePost } from "@entities/posts";
import { ReactionType } from "@shared/types/posts";

type Props = {
  onRespond?: (id: string) => void;
};

export const OrderCompilationList = ({ onRespond }: Props) => {
  const navigate = useNavigate();
  const isSignedIn = useAppSelector(userModel.isSignedInSelector);

  const {
    list,
    isRefetching,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = useServiceOrderCompilation();

  const likePost = useLikePost();

  const dislikePost = useDislikePost();

  const handleRespond = (id: string) => () => {
    onRespond?.(id);
  };

  const handleClick = (id: string) => () => {
    navigate(generatePath(Paths.MyOrderPreview, { id }), {
      state: { from: Paths.OrderCompilation },
    });
  };

  const handleLike = (id: string) =>
    isSignedIn && !likePost.isLoading
      ? () =>
          likePost.mutate(id, {
            onSuccess: () =>
              refetch({
                refetchPage: (page: ServiceOrderCompilationItem[]) =>
                  !!page.find((item) => item.id === id),
              }),
          })
      : undefined;

  const handleDislike = (id: string) =>
    isSignedIn && !dislikePost.isLoading
      ? () =>
          dislikePost.mutate(id, {
            onSuccess: () =>
              refetch({
                refetchPage: (page: ServiceOrderCompilationItem[]) =>
                  !!page.find((item) => item.id === id),
              }),
          })
      : undefined;

  const renderItem = ({
    item,
  }: ItemRenderOptions<ServiceOrderCompilationItem>) => (
    <MarketplaceCard
      key={item.id}
      sx={{ mt: 2, "&:first-of-type": { mt: 0 } }}
      avatarSrc={item.businessCard.logo?.path}
      rating={item.businessCard.rate}
      reviewsCount={item.businessCard.reviewCount}
      firstName={item.creator.name}
      lastName={item.creator.lastName}
      header={
        <ServiceCardHeader
          title={item.businessCard.name.ru}
          name={[item.creator.lastName, item.creator.name].join(" ")}
          date={new Date(item.createdDateTime)}
        />
      }
      body={
        <>
          <Box sx={{ display: "flex", columnGap: 10, mr: 3, py: 2.5 }}>
            <ServiceShortDetails
              serviceName={item.title.ru}
              serviceTypes={item.serviceTypes.map((type) => type.display.ru)}
              text={item.content.ru}
            />
            {item.price && (
              <ServicePrice
                value={parseInt(item.price)}
                type={item.priceUnits}
              />
            )}
          </Box>

          <Box sx={{ display: "flex", columnGap: 2.25 }}>
            <Like
              active={item.reactions?.[ReactionType.Like]?.hasByUser ?? false}
              count={item.reactions?.[ReactionType.Like]?.count ?? 0}
              onClick={handleLike(item.id)}
            />
            <Dislike
              active={
                item.reactions?.[ReactionType.Dislike]?.hasByUser ?? false
              }
              count={item.reactions?.[ReactionType.Dislike]?.count ?? 0}
              onClick={handleDislike(item.id)}
            />
            <ViewCount
              active={item.viewsCounter?.viewedByCurrentUser ?? false}
              count={item.viewsCounter?.uniqueUsers ?? 0}
            />
          </Box>
        </>
      }
      buttons={<Button text="Отклик" onClick={handleRespond(item.id)} />}
      onClick={isSignedIn ? handleClick(item.id) : undefined}
    />
  );

  return (
    <InfinityList
      isLoading={isFetching && !isFetchingNextPage && !isRefetching}
      emptyList="Нет заказов"
      size={list.length}
      items={list}
      hasMore={Boolean(hasNextPage)}
      next={fetchNextPage}
      renderItem={renderItem}
    />
  );
};
