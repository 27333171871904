import { NotificationCard, NotificationDetailCard, businessModel } from "@entities/business";
import { FilterButton, PeriodFilter } from "@entities/filters";
import { Box } from "@mui/material";
import { Button, Column, Switch } from "@shared/ui";
import { EmptyTabText } from "./EmptyTabText";
import { useNotificationsTab } from "../hooks/useNotificationsTab";
import { Period } from "@shared/lib/period";
import { useAppDispatch, useAppSelector } from "@app/store";

export const NotificationsTab = () => {
  const dispatch = useAppDispatch()
  const data = useAppSelector(businessModel.cardNotificationsSelector);
  const handleNotificationRead = (id: string) => dispatch(businessModel.setNotificationRead(id));

  const {
    notifications,
    selected,
    handleClick,
    toggleUnread,
    changePeriod,
    fromDate,
    handleClickBack,
    unreadOnly
  } = useNotificationsTab(data, handleNotificationRead);

  return (
    <Column sx={{ gap: 2.5, pb: 5 }}>
      {!selected && (
        <Box alignSelf="flex-start">
          <FilterButton>
            <PeriodFilter
              defaultValue={fromDate.toString()}
              onChange={(value) => changePeriod(value as Period)}
            />
            <Switch
              sx={{ color: "text.primary" }}
              checked={unreadOnly}
              label="Только непрочитанные"
              onChange={toggleUnread}
            />
          </FilterButton>
        </Box>
      )}
      <Box sx={{ border: "1px solid #E4E4E4", bgcolor: "#fff" }}>
        {!notifications.length && (
          <Box
            sx={{ p: 2 }}
            children={<EmptyTabText text="Ничего не найдено" />}
          />
        )}
        {!!notifications.length &&
          !selected &&
          notifications.map((item, index) => (
            <NotificationCard
              key={index}
              onClick={() => handleClick(item)}
              {...item}
            />
          ))}
        {selected && <NotificationDetailCard id={selected} />}
      </Box>
      {selected && (
        <Button
          sx={{ alignSelf: "center" }}
          text="Назад"
          variant="secondary"
          onClick={handleClickBack}
        />
      )}
    </Column>
  );
};
