import { Box, styled } from "@mui/material";
import { ReactComponent as ViewCountIcon } from "@shared/assets/views.svg";

type Props = {
  count: number;
  active?: boolean;
};

export const ViewCount = styled(
  ({ count, active = false, ...otherProps }: Props) => {
    return (
      <div {...otherProps}>
        <Box
          sx={{ color: `text.${active ? "primary" : "disabled"}` }}
          component={ViewCountIcon}
        />
        {!!count && <span>{count}</span>}
      </div>
    );
  }
)(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  columnGap: theme.spacing(1),
  color: theme.palette.text.primary,
  fontSize: "0.875rem",
  lineHeight: 1.4,
}));
