import { api } from "@shared/api";
import { showErrorMessages } from "@shared/lib/error";
import { EntityStatus } from "@shared/types/entity";
import { CreateRespond } from "@shared/types/respond";
import { enqueueSnackbar } from "notistack";
import { useMutation } from "react-query";

export const useCreateRespond = (successMessage: string) => {
  return useMutation(
    ({
      id,
      respond,
      status,
    }: {
      id: string;
      respond: CreateRespond;
      status?: EntityStatus;
    }) => api.createRespond(id, respond, status),
    {
      onSuccess: () => {
        enqueueSnackbar(successMessage, { variant: "success" });
      },
      onError: showErrorMessages,
    }
  );
};
