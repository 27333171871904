import { useQuery } from "react-query";
import { dictionaryKeys } from "./dictionaryKeys";
import { DictionaryName } from "@shared/types/dictionary";
import { api } from "@shared/api";
import { showErrorMessages } from "@shared/lib/error";

export const useBusinessCardTypes = () =>
  useQuery(
    dictionaryKeys.dictionary(DictionaryName.BusinessCard),
    () => api.getDictionary(DictionaryName.BusinessCard),
    { onError: showErrorMessages }
  );
