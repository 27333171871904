import { api } from "@shared/api";
import { getFullName } from "@shared/lib/utils";
import { BusinessCardStatus, ReviewPostDto } from "@shared/types/businessCard";
import { useQuery } from "react-query";
import { ordersResponsesKeys } from "./ordersResponsesKeys";

export type Review = {
  id: string;
  businessCardId: string;
  avatarSrc?: string;
  company: string;
  name: string;
  rating: number;
  text: string;
  canProcessByCurrentUser?: boolean;
  comments?: { id: string; content: string }[];
  businessCardStatus: BusinessCardStatus;
  businessCardReviewStatus: BusinessCardStatus;
};

const map = ({
  id,
  businessCard,
  creator,
  grade,
  content,
  canProcessByCurrentUser,
  comments,
  businessCardReview,
}: ReviewPostDto): Review => ({
  id,
  businessCardId: businessCard.id,
  avatarSrc: businessCard.logo?.path,
  company: businessCard.name.ru,
  name: getFullName(creator.name, creator.lastName) ?? "",
  rating: grade,
  text: content.ru,
  canProcessByCurrentUser: canProcessByCurrentUser,
  comments: comments?.map((comment) => {
    return {
      id: comment.id,
      content: comment.content.ru,
    };
  }),
  businessCardStatus: businessCard.status,
  businessCardReviewStatus: businessCardReview.status,
});

export const useViewReview = (offerId: string, answerId: string) => {
  const { data, refetch } = useQuery(
    ordersResponsesKeys.reviews(offerId, answerId),
    () => api.getReviews(offerId, answerId)
  );

  return {
    order: data?.offerReview ? map(data.offerReview) : undefined,
    answer: data?.answerReview ? map(data.answerReview) : undefined,
    refetch,
  };
};
