import { useBusinessCardsSlider } from "@features/business";

export const useMyMarketplace = () => {
  const { businessSlides, hasBusinessCards, isLoading } = useBusinessCardsSlider();

  const title = `Есть потребность в новых ${
    hasBusinessCards ? "партнерах" : "поставщиках услуг"
  }?`;
  const description = hasBusinessCards
    ? [
        "КАРТОЧКА БИЗНЕСА - это портфолио организации для работы в Маркетплейсе.",
        "Расскажите о своей компании, опубликуйте портфолио и найдите потенциальных партнеров.",
      ]
    : [
        "Маркетплейс - это платформа для поиска партнеров.",
        "Заказ на услугу - ваша потребность, которую могут закрыть другие участники Честного сообщества.",
        "Отклик - предложение одного участника Честного сообщества другому с возможными вариантами реализации заказа.",
      ];

  return { businessSlides, hasBusinessCards, title, description, isLoading };
};
