import { BaseLayout, Container, Breadcrumbs, Loader } from "@shared/ui";
import { Header, Footer } from "@widgets";
import {
  DetailCard,
  Gallery,
  BusinessCardTabs,
  useViewBusinessCard,
} from "@features/business";
import { businessModel } from "@entities/business";
import { useAppSelector } from "@app/store";
import { navChain } from "@shared/lib/navChain";

export const ViewMyBusinessCardPage = () => {
  const { isLoading, isRefetching } = useViewBusinessCard();
  const name = useAppSelector(businessModel.cardDetailsSelector)?.title;

  return (
    <BaseLayout header={<Header />} footer={<Footer />}>
      {(isLoading || isRefetching) && <Loader />}

      {!isLoading && !isRefetching && (
        <>
          <Container>
            <Breadcrumbs
              items={[...navChain.myBusiness, navChain.create(name)]}
              sx={{ mb: 4 }}
            />
            <DetailCard />
            <Gallery />
          </Container>
          <BusinessCardTabs />
        </>
      )}
    </BaseLayout>
  );
};
