import { Box, styled } from "@mui/material";
import { BusinessCardPartner } from "../types";
import { Avatar, Column, Link } from "@shared/ui";
import { getLogoPlaceholder } from "@shared/lib/utils";

type PartnerProps = BusinessCardPartner;
const PartnerItem = styled(
  ({ avatarSrc, src, title, name, ...otherProps }: PartnerProps) => {
    return (
      <div {...otherProps}>
        <Avatar src={avatarSrc}>{getLogoPlaceholder(title)}</Avatar>
        <Column sx={{ gap: 0.5, lineHeight: 1.5 }}>
          <Link href={src} text={title} sx={{ fontWeight: 700 }} />
          <Box sx={{ fontSize: "0.875rem" }}>{name}</Box>
        </Column>
      </div>
    );
  }
)(({ theme }) => ({
  borderBottom: "1px solid #E8E8E8",
  padding: theme.spacing(2),
  display: "flex",
  gap: theme.spacing(2),
  "&:last-child": { borderBottom: "none" },
}));

type Props = {
  items: BusinessCardPartner[];
};

export const Partners = ({ items }: Props) => {
  return (
    <Column
      sx={{ bgcolor: "#fff", pb: 1, maxHeight: "32.5rem", overflow: "hidden" }}
    >
      <Box
        sx={{
          fontSize: "1.5rem",
          lineHeight: 1.35,
          color: "#000",
          fontWeight: 700,
          p: 2,
        }}
      >
        Сотрудничество
      </Box>
      <Box sx={{ overflow: "auto" }}>
        {items.map((item) => (
          <PartnerItem key={item.title} {...item} />
        ))}
      </Box>
    </Column>
  );
};
