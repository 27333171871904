import { client } from "@shared/lib/client";
import {CreateComment, ReactionType, UpdateComment} from "@shared/types/posts";

export const createComment = async (data: CreateComment) =>
  client.post("/posts/comments", data);

export const updateComment = async ({id, ...rest}: UpdateComment) =>
  client.put(`/posts/comments/${id}`, rest);

export const likePost = (id: string) =>
  client.post<void>(
    `/posts/reactions/${id}`,
    {},
    { params: { type: ReactionType.Like } }
  );

export const dislikePost = (id: string) =>
  client.post<void>(
    `/posts/reactions/${id}`,
    {},
    { params: { type: ReactionType.Dislike } }
  );
