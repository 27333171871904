import { ServiceCompilationItem } from "@shared/types/service";
import { Dislike, Like, User, ViewCount } from "@shared/ui";
import { Box } from "@mui/material";
import { ServiceCardHeader } from "./ServiceCardHeader";
import { ServiceShortDetails } from "./ServiceShordDetails";
import { ServicePrice } from "./ServicePrice";
import { ReactionType } from "@shared/types/posts";

type Props = {
  data: ServiceCompilationItem;
  onLike?: () => void;
  onDislike?: () => void;
};

export const ServiceDetailsCard = ({ data, onLike, onDislike }: Props) => {
  return (
    <Box
      sx={{
        borderRadius: 0.5,
        border: "1px solid #e4e4e4",
        padding: (theme) => theme.spacing(2, 3),
      }}
    >
      <Box sx={{ display: "flex" }}>
        <User
          sx={{ mr: 3.75 }}
          avatarSrc={data.businessCard.logo?.path}
          firstName={data.businessCard.name.ru}
          rating={data.businessCard.rate}
          reviewsCount={data.businessCard.reviewCount}
        />
        <ServiceCardHeader
          title={data.businessCard.name.ru}
          date={new Date(data.businessService.createdDateTime)}
        />
      </Box>
      <Box sx={{ display: "flex", columnGap: 10, mt: 4 }}>
        <ServiceShortDetails
          serviceName={data.businessService.name.ru}
          serviceTypes={data.businessService.serviceType.display.ru}
          text={data.businessService.description.ru}
        />
        {!!data.businessService.price && (
          <ServicePrice
            sx={{ flex: "0 0 auto", ml: "auto" }}
            value={parseInt(data.businessService.price)}
            type={data.businessService.priceUnits}
          />
        )}
      </Box>
      <Box sx={{ display: "flex", columnGap: 2.25, mt: 2.5 }}>
        <Like
          active={
            data.businessService.reactions?.[ReactionType.Like]?.hasByUser ??
            false
          }
          count={
            data.businessService.reactions?.[ReactionType.Like]?.count ?? 0
          }
          onClick={onLike}
        />
        <Dislike
          active={
            data.businessService.reactions?.[ReactionType.Dislike]?.hasByUser ??
            false
          }
          count={
            data.businessService.reactions?.[ReactionType.Dislike]?.count ?? 0
          }
          onClick={onDislike}
        />
        <ViewCount
          active={
            data.businessService.viewsCounter?.viewedByCurrentUser ?? false
          }
          count={data.businessService.viewsCounter?.uniqueUsers ?? 0}
        />
      </Box>
    </Box>
  );
};
