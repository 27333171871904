import { StatusFilter, useFilters } from "@entities/filters";

export const MyOrdersStatusFilter = () => {
  const { handleChange } = useFilters();

  return (
    <StatusFilter
      options={["all", "published", "closed"]}
      defaultValue={"all"}
      onChange={handleChange("statuses")}
      sx={{ gap: 1.5, mb: 4 }}
    />
  );
};
