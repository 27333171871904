import { Attachment } from "./attachment";

export enum UserRole {
  Guest = "GUEST",
  Owner = "OWNER",
}

export type UserStatus = "CONFIRMING" | "ACTIVE" | "BLOCKED" | "DELETED";

export type Account = {
  id: string;
  name: string;
  lastName: string;
  logo?: Attachment;
};

export type User = {
  id: string;
  role: UserRole;
  name: string;
  lastName: string;
  email: string;
  accountId: string;
  account: Account;
  agreementSigned: boolean;
  company?: string;
  confirmed: boolean;
  created: number;
  updated: number;
  lastLoggedIn: number;
};

export type UserShort = {
  id: string;
  name: string;
  lastName: string;
  email: string;
  logo: Attachment;
  status: UserStatus;
};
