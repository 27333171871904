import { debounce } from "@shared/lib/utils";
import { Search as SearchComponent } from "@shared/ui";
import { ComponentProps, useMemo, useState } from "react";

type Props = Omit<ComponentProps<typeof SearchComponent>, "value"> & {
  defaultValue?: string | null | undefined;
  delay?: number;
  minLength?: number;
};

export const Search = ({
  defaultValue,
  delay,
  minLength = 3,
  onChange,
  ...other
}: Props) => {
  const [value, setValue] = useState(defaultValue);

  const change = useMemo(() => debounce(onChange, delay), [delay, onChange]);

  const handleChange = (value: string) => {
    setValue(value);
    (value.length >= minLength || value.length === 0) && change(value);
  };

  return <SearchComponent {...other} value={value} onChange={handleChange} />;
};
