import {
  RadioGroup as MuiRadioGroup,
  RadioGroupProps,
  SxProps,
  Theme,
  styled,
} from "@mui/material";

const StyledRadioGroup = styled(MuiRadioGroup)(({ theme }) => ({
  marginLeft: theme.spacing(-0.5),
  gap: theme.spacing(0.25),
}));

export const RadioGroupTitle = styled("span")(({ theme }) => ({
  fontWeight: 700,
  fontSize: "0.875rem",
  lineHeight: 1.5,
  color: theme.palette.text.primary,
}));

const Container = styled("span")(({ theme }) => ({
  display: "inline-flex",
  flexDirection: "column",
  rowGap: theme.spacing(0.5),
}));

type Props = Omit<RadioGroupProps, "className" | "sx" | "onChange"> & {
  className?: string;
  sx?: SxProps<Theme>;
  title?: string;
  gap?: string;
  onChange?: (value: string) => void;
};

export const RadioGroup = ({
  className,
  sx,
  title,
  gap,
  onChange,
  ...other
}: Props) => {
  const handleChange = (_: unknown, value: string) => onChange?.(value);

  return (
    <Container className={className} sx={sx}>
      <RadioGroupTitle>{title}</RadioGroupTitle>
      <StyledRadioGroup {...other} onChange={handleChange} sx={{ gap }} />
    </Container>
  );
};
