import { ReactNode } from "react";
import { Box } from "@mui/material";

type Props = {
  header?: ReactNode;
  footer?: ReactNode;
  page?: ReactNode;
  children?: ReactNode;
};

export const BaseLayout = ({ header, footer, page, children }: Props) => {
  return (
    <Box
      id="list-scroll-target"
      sx={{
        position: "relative",
        height: "100%",
        width: "100%",
        zIndex: 0,
        overflowX: "hidden",
        minWidth: "80rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          borderBottom: "1px solid #e8e8e8",
          bgcolor: (theme) => theme.palette.background.paper,
        }}
        component="header"
      >
        {header}
      </Box>
      <Box component="main" sx={{ flex: 1, position: "relative" }}>
        {page ?? children}
      </Box>
      <Box
        sx={{ bgcolor: (theme) => theme.palette.background.paper }}
        component="footer"
      >
        {footer}
      </Box>
    </Box>
  );
};
