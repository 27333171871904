import { Button } from "@shared/ui";

type Props = {
  text: string;
  onClick?: () => void;
};
export const CloseButton = ({ text, onClick }: Props) => (
  <Button
    sx={{ width: "11.25rem", fontSize: "0.875rem", lineHeight: 1.5 }}
    text={text}
    variant="secondary-inverse"
    onClick={onClick}
  />
);
