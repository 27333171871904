import { client } from "@shared/lib/client";
import { User } from "@shared/types/user";

export const getCurrentUser = async () => {
  const response = await client.get<User>("/users/current");
  return response.data;
};

export const signOut = async () => {
  const response = await client.get<void>("/logout");
  return response.data;
};
