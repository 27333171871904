import { styled } from "@mui/material";
import { CommentCount, Dislike, Like, ViewCount } from "@shared/ui";
import { CardStatistics } from "../types";

type Props = CardStatistics & {
  showComments?: boolean;
  showViews?: boolean;
  onLike?: () => void;
  onDislike?: () => void;
};

export const OrdersResponsesStatistics = styled(
  ({
    activeLike,
    activeDislike,
    activeViews,
    likes,
    dislikes,
    views,
    comments,
    showComments = true,
    showViews = true,
    onLike,
    onDislike,
    ...otherProps
  }: Props) => {
    return (
      <div {...otherProps}>
        <Like active={activeLike} count={likes} onClick={onLike} />
        <Dislike active={activeDislike} count={dislikes} onClick={onDislike} />
        {showViews && <ViewCount active={activeViews} count={views} />}
        {showComments && !!comments && <CommentCount count={comments} />}
      </div>
    );
  }
)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  marginTop: theme.spacing(3),
}));
