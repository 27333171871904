import { InfinityList, ItemRenderOptions } from "@shared/ui";
import { useMyOrders } from "../hooks/useMyOrders";
import { MyOrderCard } from "../types";
import { MyOrdersListItem } from "./MyOrdersListItem";
import { useDislikePost, useLikePost } from "@entities/posts";
import { ordersResponsesKeys } from "../hooks/ordersResponsesKeys";
import { useRefetchList } from "../hooks/useRefetchList";
import { useBoolean } from "@shared/hooks";
import { useState } from "react";
import { useCloseMyOrder } from "../hooks/useCloseMyOrder";
import { getReviewsByStatusCounts } from "../model/utils";
import { ConfirmCancelModal } from "./ConfirmCancelModal";

export const MyOrdersList = () => {
  const listData = useMyOrders();
  const likePost = useLikePost();
  const dislikePost = useDislikePost();
  const refetch = useRefetchList(ordersResponsesKeys.myOrders);

  const [confirmCancelIsOpen, confirmCancelModal] = useBoolean();
  const [reviews, setReviews] = useState<Record<string, number>>({});
  const [cancelledId, setCancelledId] = useState<string | undefined>(undefined);
  const closeOrder = useCloseMyOrder();

  const onConfirm = async () =>
    closeOrder(cancelledId ?? "")
      .then(confirmCancelModal.off)
      .then(() => refetch());

  const handleCancel = async (id: string) => {
    const reviews = await getReviewsByStatusCounts(id);
    setReviews(reviews);
    if (Object.keys(reviews).length) {
      setCancelledId(id);
      confirmCancelModal.on();
    } else {
      await closeOrder(id)
        .then(confirmCancelModal.off)
        .then(() => refetch());
    }
  };

  const renderItem = ({ item }: ItemRenderOptions<MyOrderCard>) => {
    const handleClose = () => handleCancel(item.id).then(refetch);
    const handleLike = !likePost.isLoading
      ? () => likePost.mutate(item.id, { onSuccess: refetch })
      : undefined;

    const handleDislike = !dislikePost.isLoading
      ? () => dislikePost.mutate(item.id, { onSuccess: refetch })
      : undefined;

    return (
      <MyOrdersListItem
        key={item.id}
        item={item}
        onClose={handleClose}
        onLike={handleLike}
        onDislike={handleDislike}
      />
    );
  };

  return (
    <>
      <InfinityList {...listData} renderItem={renderItem} />
      <ConfirmCancelModal
        reviews={reviews}
        open={confirmCancelIsOpen}
        onClose={confirmCancelModal.off}
        onConfirm={onConfirm}
      />
    </>
  );
};
