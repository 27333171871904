import { startOfDay, subMonths, subWeeks } from "date-fns";

export enum Period {
  All = "",
  Today = "today",
  Week = "week",
  Month = "month",
}

export const getFromDate = (value?: Period) => {
  const now = new Date();
  if (value === Period.Today) {
    return startOfDay(now).valueOf();
  }

  if (value === Period.Week) {
    return subWeeks(now, 1).valueOf();
  }

  if (value === Period.Month) {
    return subMonths(now, 1).valueOf();
  }
};
