import { SxProps, Theme } from "@mui/material";
import { Button } from "@shared/ui";
import { ReactComponent as ArrowRightIcon } from "@shared/assets/arrow-right.svg";

type Props = {
  text: string;
  sx?: SxProps<Theme>;
  onClick?: () => void;
};

export const SectionViewButton = ({ text, sx, onClick }: Props) => (
  <Button
    variant="link"
    text={text}
    sx={[{ fontWeight: 700 }, ...(Array.isArray(sx) ? sx : [sx])]}
    right={<ArrowRightIcon />}
    onClick={onClick}
  />
);
