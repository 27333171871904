import { Box, styled } from "@mui/material";
import { MouseEvent, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { Column, User, UserData } from "@shared/ui";

type Props = {
  header: ReactNode;
  body?: ReactNode;
  tags?: ReactNode;
  buttons?: ReactNode;
  linkUrl?: string;
  onClick?: () => void;
} & UserData;

export const MarketplaceCard = styled(
  ({
    header,
    body,
    tags,
    buttons,
    avatarSrc,
    avatarSize,
    rating,
    reviewsCount,
    linkUrl,
    firstName,
    lastName,
    onClick,
    ...otherProps
  }: Props) => {
    const navigate = useNavigate();

    const handleClick = () => {
      linkUrl && navigate(linkUrl);
      onClick?.();
    };

    const preventButtonsClick = (event: MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <div {...otherProps} onClick={handleClick}>
        <User
          avatarSrc={avatarSrc}
          rating={rating}
          reviewsCount={reviewsCount}
          sx={{ mr: 3.75 }}
          avatarSize={avatarSize}
          firstName={firstName}
          lastName={lastName}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            lineHeight: 1.5,
            minWidth: 0,
          }}
        >
          {header}
          {body}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <Column gap={1} onClick={preventButtonsClick} children={tags} />
          <Column gap={2} onClick={preventButtonsClick} children={buttons} />
        </Box>
      </div>
    );
  }
)(({ theme, linkUrl, onClick }) => ({
  display: "flex",
  borderRadius: ".1875rem",
  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2, 2.5),
  cursor: linkUrl || onClick ? "pointer" : undefined,
}));
