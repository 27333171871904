import { Box, CircularProgress } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";

export type ItemRenderOptions<T> = { index: number; item: T };

type Props<T> = {
  emptyList?: string;
  isLoading?: boolean;
  items: T[];
  size: number;
  hasMore: boolean;
  next: () => void;
  renderItem: (options: ItemRenderOptions<T>) => JSX.Element;
};

export const InfinityList = <T extends unknown>({
  emptyList,
  isLoading = false,
  items,
  size,
  hasMore,
  next,
  renderItem,
}: Props<T>) => {
  const loader = <CircularProgress color="primary" size={32} />;

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "grid",
          placeItems: "center",
          py: 8,
          px: 1,
          h: "100%",
          w: "100%",
        }}
      >
        {loader}
      </Box>
    );
  }

  if (!items.length) {
    return (
      <Box
        sx={{
          display: "grid",
          placeItems: "center",
          py: 8,
          px: 1,
          h: "100%",
          w: "100%",
        }}
      >
        {emptyList}
      </Box>
    );
  }

  return (
    <InfiniteScroll
      scrollableTarget="list-scroll-target"
      loader={
        <Box
          sx={{
            display: "grid",
            placeItems: "center",
            p: 1,
          }}
        >
          {loader}
        </Box>
      }
      dataLength={size}
      hasMore={hasMore}
      next={next}
    >
      {items.map((item, index) => renderItem({ index, item }))}
    </InfiniteScroll>
  );
};
