import { Box } from "@mui/material";
import { SectionTitle, SectionViewButton } from "@entities/my-marketplace";
import { useNavigate } from "react-router";
import { Paths } from "@shared/lib/router";
import {Carousel, SliderCard, SliderCardProps, SliderCardSkeleton} from "@shared/ui";

type Props = {
  isLoading: boolean
  slides: SliderCardProps[];
};

export const BusinessCards = ({ slides, isLoading }: Props) => {
  const navigate = useNavigate();
  const handleClick = () => navigate(Paths.MyBusiness);

  return (
    <Box>
      <SectionTitle sx={{ mb: 5 }}>Мои карточки</SectionTitle>
      <Carousel
        slidesToShow={Math.min(slides.length, 2)}
        button={
          <SectionViewButton
            text="Посмотреть все мои карточки"
            onClick={handleClick}
          />
        }
      >
        {isLoading && [1,2,3].map((item) => (
          <div key={item}>
            <SliderCardSkeleton />
          </div>
        ))}
        {slides.map((slide) => (
          <SliderCard
            key={slide.text}
            {...slide}
            tag={slide.tag}
            isCompact={slides.length > 1}
          />
        ))}
      </Carousel>
    </Box>
  );
};
