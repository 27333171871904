import { useInfiniteQuery } from "react-query";
import { serviceKeys } from "./serviceKeys";
import { api } from "@shared/api";
import { showErrorMessages } from "@shared/lib/error";
import { useFilters } from "@entities/filters";
import { useEffect, useMemo, useRef } from "react";
import { useAppSelector } from "@app/store";
import { userModel } from "@entities/user";

const LIMIT = 10;

export const useServiceCompilation = () => {
  const isSignedIn = useAppSelector(userModel.isSignedInSelector);

  const { filters, handleChange } = useFilters({ recommended: isSignedIn });

  const query = useInfiniteQuery({
    queryKey: serviceKeys.serviceCompilation(filters),
    queryFn: ({ pageParam: offset = 0 }) =>
      api.getServiceCompilation({
        ...filters,
        offset,
        limit: LIMIT,
      }),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length) return pages.length * LIMIT;
    },
    onError: showErrorMessages,
  });

  const list = useMemo(
    () => query.data?.pages.flatMap((item) => item) ?? [],
    [query.data?.pages]
  );

  useEffect(() => {
    !isSignedIn && handleChange("recommended")(false);
  }, [handleChange, isSignedIn]);

  const isFirstFetched = useRef(false);

  useEffect(() => {
    if (query.isSuccess && !isFirstFetched.current) {
      const isNeedChange = !list.length && filters.recommended;
      isNeedChange && handleChange("recommended")(false);
      isFirstFetched.current = true;
    }
  }, [filters.recommended, list.length, query.isSuccess, handleChange]);

  return { ...query, list };
};
