import { useState } from "react";
import { SectionFilter } from "./SectionFilter";
import { BaseSelect } from "@shared/ui";
import { useBusinessCardTypes } from "@entities/dictionary";
import { Dictionary } from "@shared/types/dictionary";

const getSectionLabels = (
  values: string[],
  sections: Dictionary[]
): string[] => {
  return sections
    .map((section) => [
      ...(values.includes(section.id) ? [section.text.ru] : []),
      ...getSectionLabels(values, section.children ?? []),
    ])
    .flat();
};

type Props = {
  defaultValue?: string[] | undefined;
  onChange?: (value: string[]) => void;
};

export const BusinessCardCategoriesFilterSelect = ({
  defaultValue,
  onChange,
}: Props) => {
  const { data: sections = [] } = useBusinessCardTypes();

  const [value, setValue] = useState(defaultValue);

  const handleChange = (value: string[]) => {
    setValue(value);
    onChange?.(value);
  };

  const displayValue = !value?.length
    ? undefined
    : getSectionLabels(value, sections).join(", ");

  return (
    <BaseSelect
      value={displayValue}
      placeholder="Направления деятельности бизнеса"
      sx={{ maxWidth: "17.5rem", py: 0, height: "2rem" }}
      dropdownSx={{ px: 2, fontSize: "0.875rem" }}
    >
      <SectionFilter
        sections={sections}
        value={value}
        onChange={handleChange}
      />
    </BaseSelect>
  );
};
