import { Box, styled } from "@mui/material";
import { Button, Column, EllipsesText, Rating } from "@shared/ui";
import { BusinessCardService } from "../types";

const ItemTitle = styled("div")(({ theme }) => ({
  fontSize: "1.125rem",
  lineHeight: 1.5,
  fontWeight: 700,
  color: theme.palette.text.disabled,
}));

type ItemProps = { name: string; text: string };
const Item = ({ name, text }: ItemProps) => {
  return (
    <div>
      <ItemTitle>{name}:</ItemTitle>
      <Box sx={{ lineHeight: 1.5, color: "text.primary" }}>
        <EllipsesText text={text} maxLines={5} />
      </Box>
    </div>
  );
};

type Props = BusinessCardService & {
  onClick?: () => void;
};

export const ServiceCard = ({
  rating,
  reviewsCount,
  type,
  name,
  description,
  price,
  canOrder = false,
  onClick,
}: Props) => {
  return (
    <Column sx={{ bgcolor: "#fff", borderRadius: 1, p: 4, pt: 2.5 }}>
      {/*<Rating
        value={rating}
        count={reviewsCount}
        showCount
        sx={{
          alignSelf: "flex-end",
          minWidth: "9.5rem",
          fontSize: "1.125rem",
          mb: 0.75,
        }}
      />*/}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "25% 23% 1fr auto",
          gap: 2,
          mb: 1,
        }}
      >
        <Item name="Тип услуги" text={type} />
        <Item name="Наименование услуги" text={name} />
        <Item name="Описание" text={description} />
        <Item name="Стоимость услуги" text={price ? `${price} ₽` : ""} />
      </Box>
      {canOrder && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1.5 }}>
          <Button text="Заказать услугу" sx={{ px: 6.8 }} onClick={onClick} />
        </Box>
      )}
    </Column>
  );
};
