import { Filters } from "@entities/filters";

export const ordersResponsesKeys = {
  incoming: (filters: Filters) => ["offers", "incoming", filters],
  incomingResponses: (filters: Filters) => ["offers", "answers", filters],
  myOrder: (id: string) => ["offers", "answers", id],
  myOrders: (filters: Filters) => ["offers", "account", filters],
  myResponses: (filters: Filters) => ["offers", "answer", "account", filters],
  order: (id: string) => ["offers", id],
  response: (id: string) => ["offers", "answers", id],
  reviews: (offerId?: string, answerId?: string) => [
    "offers",
    offerId,
    "answers",
    answerId,
    "reviews",
  ],
} as const;
