import { styled } from "@mui/material";
import { ReactNode } from "react";
import { SectionViewButton } from "./SectionViewButton";
import { useNavigate } from "react-router";

const Title = styled("span")(({ theme }) => ({
  fontWeight: 700,
  fontSize: "1.25rem",
  lineHeight: 1.35,
  color: theme.palette.text.primary,
}));

const Description = styled("p")(({ theme }) => ({
  margin: 0,
  fontSize: "1.125rem",
  lineHeight: 1.25,
  color: theme.palette.text.primary,
}));

type Props = {
  title: string;
  description: string;
  buttonText: string;
  mask: ReactNode;
  linkUrl: string;
};

export const BigCard = styled(
  ({ title, description, buttonText, mask, linkUrl, ...otherProps }: Props) => {
    const navigate = useNavigate();
    const handleClick = () => navigate(linkUrl);

    return (
      <div {...otherProps}>
        {mask}
        <Title>{title}</Title>
        <Description>{description}</Description>
        <SectionViewButton
          text={buttonText}
          sx={{ alignSelf: "flex-end" }}
          onClick={handleClick}
        />
      </div>
    );
  }
)(({ theme }) => ({
  position: "relative",
  padding: theme.spacing(4),
  paddingLeft: theme.spacing(5),
  borderRadius: theme.spacing(2),
  gap: theme.spacing(4),
  width: "100%",
  height: "13.5rem",
  overflow: "hidden",
  zIndex: 0,
  background: "#F7F7F7",
  display: "flex",
  flexDirection: "column",
}));
