import { InfinityList, ItemRenderOptions } from "@shared/ui";
import { BusinessCardBasicDto as BusinessCardType } from "@shared/types/businessCard";
import { useBusinessCardsList } from "../hooks";
import { BusinessCardsListItem } from "./BusinessCardsListItem";

export const BusinessCardsList = () => {
  const listData = useBusinessCardsList();

  const renderItem = ({ item }: ItemRenderOptions<BusinessCardType>) => (
    <BusinessCardsListItem key={item.id} item={item} />
  );

  return <InfinityList {...listData} renderItem={renderItem} />;
};
