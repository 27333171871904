import { CircularProgress } from "@mui/material";
import { Modal, MultiStepModal } from "@shared/ui";
import { MakeOrderForm } from "./MakeOrderForm";
import { CreateBusinessCardForm } from "./CreateBusinessCardForm";
import { useEffect, useState } from "react";
import {
  useCreateBusinessCard,
  useUserPublishedBusinessCards,
} from "@entities/business";
import { CreateBusinessCard } from "@shared/types/business";
import { CreateServiceOrder } from "@shared/types/service";
import { useCreateServiceOrder } from "@entities/service";
import { CREATE_BUSINESS_CARD_OPTIONS } from "../lib";

type Props = {
  currentService?: string;
  title?: string;
  description?: string;
  open: boolean;
  onClose: () => void;
};

export const MakeOrderModal = ({
  currentService,
  title = "Сформировать заказ",
  description = "Сформировать заказ",
  open,
  onClose,
}: Props) => {
  const {
    data: businessCards = [],
    isLoading,
    isSuccess,
  } = useUserPublishedBusinessCards();

  const createBusinessCard = useCreateBusinessCard();
  const createServiceOrder = useCreateServiceOrder();

  const [hasBusinessCards, setHasBusinessCards] = useState(false);

  const [currentBusiness, setCurrentBusiness] = useState<string | null>(null);

  const [step, setStep] = useState(1);

  const next = () => setStep((prev) => prev + 1);

  const handleSubmitBusiness = (card: CreateBusinessCard) => {
    createBusinessCard
      .mutateAsync({ card })
      .then((response) => setCurrentBusiness(response.id))
      .then(next);
  };

  const handleClose = () => {
    setCurrentBusiness(null);
    setStep(1);
    onClose();
  };

  const handleSubmitOrder = (order: CreateServiceOrder) => {
    createServiceOrder.mutateAsync({ order }).then(handleClose);
  };

  useEffect(() => {
    isSuccess && setHasBusinessCards(!!businessCards.length);
  }, [businessCards.length, isSuccess]);

  if (isLoading && open) {
    return (
      <CircularProgress
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        color="primary"
        size={48}
      />
    );
  }

  return hasBusinessCards ? (
    <Modal header={title} width="75rem" open={open} onClose={handleClose}>
      <MakeOrderForm
        currentBusinessCard={currentBusiness ?? undefined}
        currentService={currentService}
        onCancel={handleClose}
        onSubmit={handleSubmitOrder}
      />
    </Modal>
  ) : (
    <MultiStepModal
      width="75rem"
      step={step}
      open={open}
      options={[CREATE_BUSINESS_CARD_OPTIONS, { title, description }]}
      onClose={handleClose}
    >
      <CreateBusinessCardForm
        cancelButtonText="Отменить"
        submitButtonText="Далее"
        onCancel={handleClose}
        onSubmit={handleSubmitBusiness}
      />
      <MakeOrderForm
        currentBusinessCard={currentBusiness ?? undefined}
        currentService={currentService}
        onCancel={handleClose}
        onSubmit={handleSubmitOrder}
      />
    </MultiStepModal>
  );
};
