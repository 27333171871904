import {
  OfferAnswerPostDto,
  OfferPostDto,
  Post,
} from "@shared/types/ordersResponses";
import {
  IncomingOrderCard,
  IncomingResponseCard,
  MyOrderCard,
  MyResponseCard,
} from "../types";
import { ReactionType } from "@shared/types/posts";
import { Paths } from "@shared/lib/router";

export const mapStatistics = (dto: any) => ({
  activeLike: dto?.reactions?.[ReactionType.Like]?.hasByUser ?? false,
  activeDislike: dto?.reactions?.[ReactionType.Dislike]?.hasByUser ?? false,
  activeViews: dto?.viewsCounter?.viewedByCurrentUser ?? false,
  likes: dto?.reactions?.[ReactionType.Like]?.count ?? 0,
  dislikes: dto?.reactions?.[ReactionType.Dislike]?.count ?? 0,
  views: dto?.viewsCounter?.uniqueUsers ?? 0,
  comments: dto?.answersCount,
});

export const mapComments = (comments: Post[]) =>
  comments?.map((comment) => ({
    id: comment.id,
    avatarSrc: comment.businessCard.logo?.path,
    rating: comment.businessCard.rate ?? 0,
    reviewsCount: comment.businessCard.reviewCount ?? 0,
    title: comment.businessCard.name?.ru,
    name: comment.creator.name,
    date: new Date(comment.createdDateTime),
    text: comment.content.ru,
    statistics: mapStatistics(comment),
  })) ?? [];

export const mapToShortMyOrder = (card: MyOrderCard) => ({
  avatarSrc: card.avatarSrc,
  rating: card.rating,
  reviewsCount: card.reviewsCount,
  header: card.company,
  date: card.date,
  services: [card.title],
  text: card.text,
  linkUrl: card.linkUrl,
});

export const mapToShortIncomingOrder = (card: IncomingOrderCard) => ({
  avatarSrc: card.avatarSrc,
  rating: card.rating,
  reviewsCount: card.reviewsCount,
  header: card.company,
  date: card.date,
  services: [card.title],
  text: card.text,
  linkUrl: card.linkUrl,
});

export const mapToShortMyResponse = (card: MyResponseCard) => ({
  avatarSrc: card.avatarSrc,
  rating: card.rating,
  reviewsCount: card.reviewsCount,
  header: card.company,
  date: card.date,
  services: [card.title],
  text: card.text,
  linkUrl: card.linkUrl,
});

export const mapToShortIncomingResponse = (card: IncomingResponseCard) => ({
  avatarSrc: card.avatarSrc,
  rating: card.rating,
  reviewsCount: card.reviewsCount,
  header: card.company,
  date: card.date,
  services: [card.title],
  text: card.text,
  linkUrl: card.linkUrl,
});

export const convertToMyOrderCard = (dto: OfferPostDto): MyOrderCard => ({
  id: dto.id,
  linkUrl: `${Paths.MyOrders}${dto.id}`,
  hidden: dto.hidden,
  avatarSrc: dto.businessCard.logo?.path,
  rating: dto.businessCard.rate ?? 0,
  reviewsCount: dto.businessCard.reviewCount ?? 0,
  status: dto.status === "CANCELLED" ? "CLOSED" : dto.status,
  company: dto.businessCard.name.ru,
  name: dto.creator.name,
  date: new Date(dto.createdDateTime),
  title: dto.title.ru,
  target: dto.receiverBusinessCard?.name?.ru,
  services: dto.serviceTypes.map((item) => item.display.ru),
  text: dto.content.ru,
  price: {
    value: dto.price,
    type: dto.priceUnits,
  },
  statistics: mapStatistics(dto),
  businessCardStatus: dto.businessCard.status,
});

export const convertToIncomingOrderCard = (
  dto: OfferPostDto
): IncomingOrderCard => ({
  id: dto.id,
  linkUrl: `${Paths.IncomingOrders}${dto.id}`,
  avatarSrc: dto.businessCard.logo?.path,
  rating: dto.businessCard.rate ?? 0,
  reviewsCount: dto.businessCard.reviewCount ?? 0,
  status: dto.status,
  company: dto.businessCard.name.ru,
  name: dto.creator.name,
  date: new Date(dto.createdDateTime),
  title: dto.title.ru,
  target: dto.receiverBusinessCard?.name?.ru,
  targetId: dto.receiverBusinessCard?.id,
  services: dto.serviceTypes.map((item) => item.display.ru),
  text: dto.content.ru,
  price: {
    value: dto.price,
    type: dto.priceUnits,
  },
  statistics: mapStatistics(dto),
  businessCardStatus: dto.businessCard.status,
});

export const convertToMyResponseCard = (
  dto: OfferAnswerPostDto
): MyResponseCard => ({
  id: dto.id,
  offerId: dto.offer.id,
  linkUrl: `${Paths.MyResponses}${dto.id}`,
  avatarSrc: dto.businessCard.logo?.path,
  rating: dto.businessCard.rate ?? 0,
  reviewsCount: dto.businessCard.reviewCount ?? 0,
  status: dto.status,
  company: dto.businessCard.name.ru,
  name: dto.creator.name,
  date: new Date(dto.createdDateTime),
  target: dto.offer.businessCard.name.ru,
  order: dto.offer.title.ru,
  title: dto.title.ru,
  text: dto.content.ru,
  price: {
    value: dto.price,
    type: dto.priceUnits,
  },
  statistics: mapStatistics(dto),
  businessCardStatus: dto.businessCard.status,
});

export const convertToIncomingResponseCard = (
  dto: OfferAnswerPostDto
): IncomingResponseCard => ({
  id: dto.id,
  offerId: dto.offer.id,
  linkUrl: `${Paths.IncomingResponses}${dto.id}`,
  avatarSrc: dto.businessCard.logo?.path,
  rating: dto.businessCard.rate ?? 0,
  reviewsCount: dto.businessCard.reviewCount ?? 0,
  status: dto.status,
  company: dto.businessCard.name.ru,
  name: dto.creator.name,
  date: new Date(dto.createdDateTime),
  target: dto.offer.businessCard.name.ru,
  order: dto.offer.title.ru,
  title: dto.title.ru,
  text: dto.content.ru,
  price: {
    value: dto.price,
    type: dto.priceUnits,
  },
  statistics: mapStatistics(dto),
  businessCardStatus: dto.businessCard.status,
});
