import { ResponseDetails } from "@features/orders-responses";
import { navChain } from "@shared/lib/navChain";
import { BaseLayout, Breadcrumbs, Container, PageTitle } from "@shared/ui";
import { Header, Footer } from "@widgets";

export const ViewIncomingResponsePage = () => (
  <BaseLayout header={<Header />} footer={<Footer />}>
    <Container>
      <Breadcrumbs
        items={[
          ...navChain.incomingResponses,
          navChain.create("Просмотр отклика"),
        ]}
        sx={{ mb: 3 }}
      />
      <PageTitle text="Просмотр отклика" sx={{ mb: 5 }} />
      <ResponseDetails />
    </Container>
  </BaseLayout>
);
