import { Box, SxProps, Theme } from "@mui/material";
import { Button } from "@shared/ui";
import { useState } from "react";
import { OfferStatus } from "../types";

const names = {
  ACTIVE: "Активные",
  COMPLETED: "Выполненные",
};

type Props = {
  defaultValue: OfferStatus;
  onChange?: (value?: string) => void;
  sx?: SxProps<Theme>;
};

export const OfferStateFilter = ({ defaultValue, onChange, sx }: Props) => {
  const [selectedOption, setSelectedOption] = useState(defaultValue);

  const handleChange = (value: OfferStatus) => {
    setSelectedOption(value);
    onChange?.(value);
  };

  const options: OfferStatus[] = ["ACTIVE", "COMPLETED"];

  return (
    <Box sx={[{ display: "flex" }, ...(Array.isArray(sx) ? sx : [sx])]}>
      {options.map((option) => (
        <Button
          key={option}
          variant="outlined"
          text={names[option]}
          sx={{ bgcolor: option === selectedOption ? "#FCFCCF" : null }}
          onClick={() => handleChange(option)}
        />
      ))}
    </Box>
  );
};
