import qs from "qs";
import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Filters } from "../types";

type Type = "string" | "number" | "boolean";

const config: Partial<Record<keyof Filters, Type | `${Type}[]`>> = {
  fromDate: "string",
  recommended: "boolean",
  text: "string",
  serviceTypes: "string[]",
  services: "string[]",
  cardTypes: "string[]",
  sort: "string",
  order: "string",
  unread: "boolean",
  statuses: "string[]",
  businessCardIds: "string[]",
  withClosed: "boolean",
  closed: "boolean",
  offerState: "string",
};

const isStringArray = (value: unknown): value is string[] => {
  return Array.isArray(value) && value.every((val) => typeof val === "string");
};

const toString = (value: string) => {
  return value;
};

const toNumber = (value: string) => {
  return parseFloat(value);
};

const toBoolean = (value: string) => {
  return value === "true";
};

const parseValue = (value: string, type: Type) => {
  if (type === "string") {
    return toString(value);
  }

  if (type === "number") {
    return toNumber(value);
  }

  if (type === "boolean") {
    return toBoolean(value);
  }
};

const parseArrayValue = (value: string[], type: Type) => {
  return value.map((v) => parseValue(v, type)) as Type[];
};

const isEmptyValue = (value: any) => {
  return (
    value == null ||
    (typeof value === "string" && !value.length) ||
    (typeof value === "number" && isNaN(value)) ||
    (Array.isArray(value) && !value.length)
  );
};

const parse = (searchParams: URLSearchParams) => {
  const obj = qs.parse(searchParams.toString());

  return Object.entries(config).reduce((acc, [name, type]) => {
    const value = obj[name];
    const isArrayType = /^.+\[\]$/.test(type);

    if (!isArrayType && typeof value === "string") {
      const val = parseValue(value, type as Type);
      return isEmptyValue(val) ? acc : { ...acc, [name]: val };
    }

    if (isArrayType && typeof value === "string") {
      const val = parseValue(value, type.replace("[]", "") as Type);
      return isEmptyValue(val) ? acc : { ...acc, [name]: [val] };
    }

    if (isArrayType && isStringArray(value) && !isEmptyValue(value)) {
      const baseType = type.substring(0, type.length - 2) as Type;
      const val = parseArrayValue(value, baseType);
      const isInvalidArray = val.some((item) => isEmptyValue(item));
      return isInvalidArray ? acc : { ...acc, [name]: val };
    }

    return acc;
  }, {}) as Filters;
};

export const useFilters = (defaultValue?: Filters) => {
  const [searchParams, setSearchParams] = useSearchParams(
    qs.stringify(defaultValue, { arrayFormat: "repeat" })
  );

  const filters = useMemo(() => parse(searchParams), [searchParams]);

  const handleChange = useCallback(
    (name: keyof Filters, name2?: keyof Filters) =>
      (
        value: Filters[typeof name] | null | undefined,
        value2?: Filters[typeof name] | null | undefined
      ) =>
        setSearchParams((prev) => {
          const { [name]: omitted, ...other } = parse(prev);
          let other2;
          if (name2) {
            const { [name]: omitted, ...other } = parse(prev);
            other2 = other;
          }

          const current = {
            ...other,
            ...other2,
            ...(isEmptyValue(value) ? {} : { [name]: value }),
            ...(name2 && isEmptyValue(value2) ? {} : { [name2!]: value2 }),
          };

          return new URLSearchParams(
            qs.stringify(current, { arrayFormat: "repeat" })
          );
        }),
    [setSearchParams]
  );

  return { filters, handleChange };
};
