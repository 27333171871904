import {
  styled,
  Switch as MuiSwitch,
  SwitchProps,
  SxProps,
  Theme,
} from "@mui/material";

export const StyledSwitch = styled(MuiSwitch)(({ theme }) => ({
  height: "1.25rem",
  width: "2.375rem",
  margin: 0,
  padding: 0,

  "& .MuiSwitch-track": {
    borderRadius: "calc(1.25rem / 2)",
    border: `1px solid ${theme.palette.text.disabled}`,
    backgroundColor: theme.palette.background.paper,
  },

  "& .MuiSwitch-thumb": {
    width: "0.75rem",
    height: "0.75rem",
    backgroundColor: theme.palette.text.disabled,
  },

  "& .MuiSwitch-switchBase": {
    padding: theme.spacing(0.5),

    "&.Mui-checked": {
      transform: `translateX(1.125rem)`,
    },

    "&.Mui-checked + .MuiSwitch-track": {
      border: `1px solid ${theme.palette.text.disabled}`,
      backgroundColor: theme.palette.background.paper,
    },

    "&.Mui-checked > .MuiSwitch-thumb": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const LabelContainer = styled("label")(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  columnGap: theme.spacing(1),
  fontSize: "0.875rem",
  lineHeight: 1.5,
  color: theme.palette.text.primary,
}));

const Label = styled("span")({ whiteSpace: "nowrap" });

type Props = Omit<SwitchProps, "className" | "sx" | "onChange"> & {
  className?: string;
  sx?: SxProps<Theme>;
  label?: string;
  onChange?: (value: boolean) => void;
};

export const Switch = ({ className, sx, label, onChange, ...other }: Props) => {
  const handleChange = (_: unknown, value: boolean) => onChange?.(value);

  return (
    <LabelContainer
      className={className}
      sx={[
        { "&:hover": { cursor: other.disabled ? "default" : "pointer" } },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <StyledSwitch {...other} onChange={handleChange} />
      <Label>{label}</Label>
    </LabelContainer>
  );
};
