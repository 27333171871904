import { Period } from "@shared/lib/period";
import { Radio, RadioGroup } from "@shared/ui";

type Props = {
  defaultValue?: string | undefined;
  onChange?: (value: string) => void;
};

export const PeriodFilter = ({ defaultValue, onChange }: Props) => {
  return (
    <RadioGroup
      defaultValue={defaultValue ?? Period.All}
      title="Период"
      onChange={onChange}
    >
      <Radio value={Period.All} label="За весь период" />
      <Radio value={Period.Today} label="За сегодня" />
      <Radio value={Period.Week} label="За неделю" />
      <Radio value={Period.Month} label="За месяц" />
    </RadioGroup>
  );
};
