import { BigCard, Section } from "@entities/my-marketplace";
import { useIncomingOrders } from "@features/orders-responses";
import { mapToShortIncomingOrder } from "@features/orders-responses/model/orderCardMapper";
import { Paths } from "@shared/lib/router";
import { Mask } from "@shared/ui";

export const IncomingOrders = () => {
  const mask = (
    <Mask
      beforeStyles={{
        width: "47rem",
        height: "47rem",
        bgcolor: "#FFFF5B",
        transform: "translate(-40%, -90%)",
      }}
      afterStyles={{
        width: "49rem",
        height: "49rem",
        bgcolor: "#8BFFFF",
        transform: "translate(70%, 10%)",
        zIndex: -2,
      }}
    />
  );
  const { items, isLoading } = useIncomingOrders(true);
  const slides = items.map(mapToShortIncomingOrder);

  return (
    <Section
      card={
        <BigCard
          title="У вас нет активных поступивших заказов от других участников Маркетплейса"
          description="Распишите подробнее про оказываемые Вами услуги для поиска новых партнеров"
          buttonText="Перейти в мои карточки"
          mask={mask}
          linkUrl={Paths.MyBusiness}
        />
      }
      title="Входящие заказы"
      items={slides ?? []}
      viewAllLink={Paths.IncomingOrders}
      isLoading={isLoading}
    />
  );
};
