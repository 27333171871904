import { SmallCard } from "@entities/my-marketplace";
import AllParticipantsImage from "@shared/assets/all-participants.png";
import { Paths } from "@shared/lib/router";
import { Mask } from "@shared/ui";
import { useNavigate } from "react-router-dom";

export const AllParticipantsCard = () => {
  const mask = (
    <Mask
      beforeStyles={{
        width: "17.5rem",
        height: "17.5rem",
        bgcolor: "#FFFF5B",
        transform: "translate(60%, -50%)",
      }}
    />
  );

  const navigate = useNavigate();
  const handleClick = () => navigate(Paths.Business);

  return (
    <SmallCard
      mask={mask}
      title="Хотите ознакомиться со всеми участниками Маркетплейса?"
      buttonText="Посмотреть всех участников"
      imageSrc={AllParticipantsImage}
      onClick={handleClick}
    />
  );
};
