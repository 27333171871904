import { Box, Tab as MuiTab, Tabs as MuiTabs, styled } from "@mui/material";
import { SxProps, Theme } from "@mui/system";
import { TabsProps } from "@mui/material/Tabs";
import { SyntheticEvent, useState } from "react";
import { Container } from "@shared/ui";
import { useParams } from "react-router-dom";

export type Tab = {
  label: string;
  component: JSX.Element;
  icon?: JSX.Element;
};

const StyledTabs = styled(MuiTabs)({
  background: "#FFFFFF",
  boxShadow: "0px 5px 7px rgba(0, 0, 0, 0.25)",
  "& .MuiTabs-scroller": {
    maxWidth: "1264px",
    padding: "0 1rem",
    margin: "0 auto 0.5rem",
  },
  "& .MuiTabs-flexContainer": {
    gap: "3rem",
  },
  "& button": {
    fontFamily: '"Circe", sans-serif',
    fontSize: "1.5rem",
    lineHeight: 1.35,
    textTransform: "initial",
    padding: "0.875rem 1.25rem 0.75rem",
    fontWeight: 700,
    color: "#A9A9A9",
  },
});

type Props = {
  sx?: SxProps<Theme>;
  tabs: Tab[];
  onChange?: (event: SyntheticEvent, newValue: number) => void;
};

export const Tabs = ({
  sx = [],
  tabs,
  onChange,
  ...otherProps
}: Props & TabsProps) => {
  const { id } = useParams();
  const [cardId, setCardId] = useState(id);
  const [tab, setTab] = useState(0);
  const TabContent = tabs[tab].component;

  const onChangeHandler = (event: SyntheticEvent, newValue: number) => {
    setTab(newValue);
    onChange?.(event, newValue);
  };

  if (id !== cardId) {
    setCardId(id);
    setTab(0);
  }

  return (
    <>
      <StyledTabs
        sx={[...(Array.isArray(sx) ? sx : [sx])]}
        value={tab}
        onChange={onChangeHandler}
        textColor="secondary"
        TabIndicatorProps={{
          style: { display: "none" },
        }}
        {...otherProps}
      >
        {tabs.map((tab) => (
          <MuiTab
            key={tab.label}
            label={tab.label}
            icon={tab.icon}
            iconPosition="end"
          />
        ))}
      </StyledTabs>
      <Box sx={{ bgcolor: "#F7F7F7" }}>
        <Container sx={{ pt: (theme) => theme.spacing(4) }}>
          {TabContent}
        </Container>
      </Box>
    </>
  );
};
