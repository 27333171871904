import {
  Box,
  SxProps,
  Theme,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import { ReactComponent as Pdf } from "@shared/assets/pdf.svg";
import { ReactComponent as PdfFile } from "@shared/assets/pdf-file.svg";
import { ReactComponent as CloseIcon } from "@shared/assets/close.svg";
import { useDropzone } from "react-dropzone";
import { Attachment } from "@shared/types/attachment";
import { MouseEvent } from "react";

const MAX_SIZE = 10 * 1024 * 1024;

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "secondary.main",
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: 1.4,
    color: "common.white",
    opacity: 0.9,
    borderRadiuse: 0.5,
    maxWidth: "18.75rem",
    padding: theme.spacing(1.5625, 2.1875),
  },
}));

type Props = {
  sx?: SxProps<Theme>;
  className?: string;
  attachment?: Attachment | null;
  onChange?: (file: File | null) => void;
};

export const AddIncludedFilesInput = ({
  sx,
  className,
  attachment,
  onChange,
}: Props) => {
  const handleDrop = (files: File[]) => {
    onChange?.(files[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "application/pdf": [".pdf"] },
    multiple: false,
    maxSize: MAX_SIZE,
    onDrop: handleDrop,
  });

  const handleRemove = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onChange?.(null);
  };

  return (
    <Box sx={sx} className={className}>
      <StyledTooltip
        placement="top"
        title="Приложите презентацию компании, прайс лист услуг для ознакомления
          партнерами (в формате pdf, размером не более 10 МБ)"
      >
        <Box
          sx={{
            fontWeight: 700,
            fontSize: "1rem",
            lineHeight: 1.5,
            color: "text.primary",
          }}
        >
          Вложенные файлы
        </Box>
      </StyledTooltip>
      <Box
        {...getRootProps()}
        sx={{
          position: "relative",
          minWidth: "16.4375rem",
          outline: "none",
        }}
      >
        <input {...getInputProps()} />
        {!attachment && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              rowGap: 1.5,
              border: "1px solid  #d9d9d9",
              height: "5.9375rem",
              width: "100%",
              bgcolor: "#f0f2f5",
              fontWeight: 400,
              fontSize: "1rem",
              lineHeight: 1.5,
              cursor: "pointer",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", columnGap: 1.5 }}>
              <Pdf />
              <Box sx={{ color: "text.primary" }}>Добавьте файл</Box>
            </Box>
            <Box sx={{ color: "#969696" }}>или перетащите сюда</Box>
          </Box>
        )}

        {!!attachment && (
          <Box sx={{ display: "flex", width: "100%" }}>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                pt: 1,
                rowGap: 0.5,
                alignItems: "center",
                fontSize: "0.875rem",
              }}
            >
              <PdfFile width={32} height={32} />
              <Box>{attachment.name}</Box>
              <Box>{attachment.contentSize}Кб</Box>
              <Box
                component="button"
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  margin: 0,
                  border: 0,
                  padding: 0,
                  background: "none",
                  "&:hover": { cursor: "pointer" },
                }}
                onClick={handleRemove}
              >
                <CloseIcon width={16} height={16} />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
