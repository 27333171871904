import {
  IncomingOrdersList,
  OrdersResponsesFilter,
} from "@features/orders-responses";
import { IncomingOrdersStatusFilter } from "@features/orders-responses/ui/IncomingOrdersStatusFilter";
import { navChain } from "@shared/lib/navChain";
import { BaseLayout, Breadcrumbs, Container, PageTitle } from "@shared/ui";
import { Header, Footer } from "@widgets";

export const IncomingOrdersPage = () => {
  return (
    <BaseLayout header={<Header />} footer={<Footer />}>
      <Container>
        <Breadcrumbs items={navChain.incomingOrders} />
        <PageTitle text="Входящие заказы" sx={{ mb: 3 }} />
        <OrdersResponsesFilter
          showServiceType
          statusFilter={<IncomingOrdersStatusFilter />}
        />
        <IncomingOrdersList />
      </Container>
    </BaseLayout>
  );
};
