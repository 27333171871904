import { BusinessCardStats } from "@shared/types/statistic";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Box,
  Collapse,
} from "@mui/material";
import { useBoolean } from "@shared/hooks";
import { ReactComponent as Arrow } from "@shared/assets/small-arrow-right.svg";
import {
  BodyTableCeil,
  BodyTableRow,
  HeadTableCeil,
  HeadTableRow,
} from "./table";
import { format } from "date-fns";

type Props = {
  statistic: BusinessCardStats;
};

export const InteractionsStatistic = ({ statistic }: Props) => {
  const [isTableOpen, table] = useBoolean(false);
  const [isGrantedAccessOpen, grantedAccessTable] = useBoolean();
  const [isGotAccessOpen, gotAccessTable] = useBoolean();

  const handleClick = (handler: () => void, count: number) => {
    if (count) return handler;
  };

  return (
    <Box>
      <Box
        sx={{
          fontWeight: 400,
          fontSize: "1.125rem",
          lineHeight: 1.5,
          color: "#63666A",
          "&:hover": { cursor: "pointer" },
        }}
        onClick={table.toggle}
      >
        <Arrow
          style={{
            display: "inline-block",
            marginRight: 16,
            fill: "#63666A",
            transform: isTableOpen ? "rotate(270deg)" : "rotate(90deg)",
          }}
        />
        Статистика по взаимодействию карточки бизнеса{" "}
        {statistic.businessCard.name.ru} с другими карточками бизнеса за период{" "}
        <Box component="span" sx={{ fontWeight: "700", color: "#363634" }}>
          {statistic.fromDate &&
            format(new Date(statistic.fromDate), "dd.MM.yyyy")}
          {"-"}
          {statistic.toDate && format(new Date(statistic.toDate), "dd.MM.yyyy")}
        </Box>
      </Box>
      <Collapse in={isTableOpen}>
        <TableContainer>
          <Table>
            <TableHead>
              <HeadTableRow>
                <HeadTableCeil width="25%" />
                <HeadTableCeil width="25%" align="center">
                  Карточки
                </HeadTableCeil>
                <HeadTableCeil width="50%" />
              </HeadTableRow>
            </TableHead>
            <TableBody>
              <BodyTableRow
                onClick={handleClick(
                  grantedAccessTable.toggle,
                  statistic.exchangeContacts.grantedAccess.length
                )}
              >
                <BodyTableCeil
                  type="category"
                  withArrow={!!statistic.exchangeContacts.grantedAccess.length}
                  open={isGrantedAccessOpen}
                >
                  Предоставил доступ
                </BodyTableCeil>
                <BodyTableCeil align="center">
                  {statistic.exchangeContacts.countGrantedAccess}
                </BodyTableCeil>
                <BodyTableCeil />
              </BodyTableRow>
              {statistic.exchangeContacts.grantedAccess.map((contact) => (
                <BodyTableRow
                  key={contact.account.id}
                  type="subcategory"
                  open={isGrantedAccessOpen}
                >
                  <BodyTableCeil sx={{ pl: 6.5 }} />
                  <BodyTableCeil align="center">
                    {contact.businessCard.name.ru}
                  </BodyTableCeil>
                  <BodyTableCeil />
                </BodyTableRow>
              ))}
              <BodyTableRow
                onClick={handleClick(
                  gotAccessTable.toggle,
                  statistic.exchangeContacts.gotAccess.length
                )}
              >
                <BodyTableCeil
                  type="category"
                  withArrow={!!statistic.exchangeContacts.gotAccess.length}
                  open={isGotAccessOpen}
                >
                  Получил доступ
                </BodyTableCeil>
                <BodyTableCeil align="center">
                  {statistic.exchangeContacts.countGotAccess}
                </BodyTableCeil>
                <BodyTableCeil />
              </BodyTableRow>
              {statistic.exchangeContacts.gotAccess.map((contact) => (
                <BodyTableRow
                  key={contact.account.id}
                  type="subcategory"
                  open={isGotAccessOpen}
                >
                  <BodyTableCeil sx={{ pl: 6.5 }} />
                  <BodyTableCeil align="center">
                    {contact.businessCard.name.ru}
                  </BodyTableCeil>
                  <BodyTableCeil />
                </BodyTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </Box>
  );
};
