import { ServiceDetailsCard } from "@entities/marketplace";
import { useService } from "@entities/service";
import { ServiceCompilationItem } from "@shared/types/service";
import {
  BaseLayout,
  Breadcrumbs,
  Button,
  Container,
  Loader,
  PageTitle,
} from "@shared/ui";
import { Header, Footer } from "@widgets";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { MakeOrderModal } from "@features/service-order";
import { useBoolean } from "@shared/hooks";
import { navChain } from "@shared/lib/navChain";
import { useDislikePost, useLikePost } from "@entities/posts";

export const ViewServicePage = () => {
  const likePost = useLikePost();
  const dislikePost = useDislikePost();

  const { id = "" } = useParams<{ id: string }>();

  const {
    data = {} as ServiceCompilationItem,
    isLoading,
    refetch,
  } = useService(id);

  const [isRequestOpen, requestModal] = useBoolean();

  const handleLike =
    !likePost.isLoading && data?.businessService?.id
      ? () =>
          likePost.mutate(data.businessService.id, {
            onSuccess: () => refetch(),
          })
      : undefined;

  const handleDislike =
    !dislikePost.isLoading && data?.businessService?.id
      ? () =>
          dislikePost.mutate(data.businessService.id, {
            onSuccess: () => refetch(),
          })
      : undefined;

  if (isLoading) return <Loader />;

  return (
    <BaseLayout header={<Header />} footer={<Footer />}>
      <Container>
        <Breadcrumbs
          items={[
            navChain.serviceCompilation,
            navChain.create("Просмотр услуги"),
          ]}
          sx={{ mb: 3 }}
        />
        <PageTitle text="Просмотр услуги" sx={{ mb: 5 }} />
        <ServiceDetailsCard
          data={data}
          onLike={handleLike}
          onDislike={handleDislike}
        />
        <Box sx={{ display: "flex", justifyContent: "center", mt: 7, mb: 4.5 }}>
          <Button text="Заказать услугу" onClick={requestModal.on} />
        </Box>
        <MakeOrderModal
          title="Заказать услугу"
          description="Заказать услугу"
          currentService={data.businessService.id ?? undefined}
          open={isRequestOpen}
          onClose={requestModal.off}
        />
      </Container>
    </BaseLayout>
  );
};
