import { Box, SxProps, Theme } from "@mui/material";
import { Modal } from "@shared/ui";
import { Children, ReactNode } from "react";
import { TabPanel } from "./TabPanel";
import { Tab } from "./Tab";
import { ReactComponent as CloseIcon } from "@shared/assets/close.svg";

type Props = {
  className?: string;
  sx?: SxProps<Theme>;
  height?: string | number;
  width?: string | number;
  step: number;
  children: ReactNode;
  open: boolean;
  options: { title: string; description: string }[];
  onClose: () => void;
};

export const MultiStepModal = ({
  height,
  width,
  step,
  children,
  open,
  options,
  onClose,
}: Props) => {
  return (
    <Modal
      height={height}
      width={width}
      open={open}
      onClose={onClose}
      header={
        <Box sx={{ pt: 2 }}>
          <Box sx={{ position: "relative", display: "flex", zIndex: 0 }}>
            {Array.isArray(children) &&
              Children.map(children, (_, index) => (
                <Tab
                  selected={index + 1 === step}
                  step={index + 1}
                  title={options[index].title}
                />
              ))}

            <Box
              component="button"
              sx={{
                position: "absolute",
                top: "50%",
                right: "24px",
                transform: "translateY(-50%)",
                border: 0,
                padding: 0,
                background: "none",
                "&:hover": { cursor: "pointer" },
              }}
              onClick={onClose}
            >
              <CloseIcon height={24} width={24} />
            </Box>
          </Box>
          <Box
            component="h6"
            sx={{
              m: 0,
              p: (theme) => theme.spacing(3, 0, 1.25),
              borderBottom: "1px solid",
              borderBottomColor: "divider",
              textAlign: "center",
              fontWeight: 400,
              fontSize: "1.125rem",
              lineHeight: 1.35,
              color: "text.primary",
            }}
          >
            {options[step - 1].description}
          </Box>
        </Box>
      }
    >
      {Array.isArray(children) &&
        Children.map(children, (child, index) => (
          <TabPanel selected={index + 1 === step}>{child}</TabPanel>
        ))}
    </Modal>
  );
};
