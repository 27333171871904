import { api } from "@shared/api";
import { convertToIncomingResponseCard } from "../model/orderCardMapper";
import { showErrorMessages } from "@shared/lib/error";
import { ordersResponsesKeys } from "./ordersResponsesKeys";
import { useFilters } from "@entities/filters";
import { useInfiniteQuery } from "react-query";
import { useMemo } from "react";
import { limit, getNextPageParam } from "@shared/lib/infiniteList";

export const useIncomingResponses = () => {
  const { filters } = useFilters();
  const query = useInfiniteQuery({
    queryKey: ordersResponsesKeys.incomingResponses(filters),
    queryFn: ({ pageParam: offset = 0 }) =>
      api.getIncomingResponses({ ...filters, offset, limit }),
    getNextPageParam,
    onError: showErrorMessages,
  });

  const items = useMemo(
    () =>
      query.data?.pages
        .flatMap((item) => item)
        .map(convertToIncomingResponseCard) ?? [],
    [query.data?.pages]
  );

  return {
    isLoading:
      query.isFetching && !query.isFetchingNextPage && !query.isRefetching,
    emptyList: "Нет откликов на мои заказы",
    size: items.length,
    items: items,
    hasMore: query.hasNextPage ?? false,
    next: query.fetchNextPage,
    refetch: query.refetch,
  };
};
