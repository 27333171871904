import { Popover, SxProps, Theme } from "@mui/material";
import { MouseEvent, PropsWithChildren, useState } from "react";
import { SelectValue } from "./SelectValue";
import { Column } from "../Column";

type Props = PropsWithChildren<{
  sx?: SxProps<Theme>;
  dropdownSx?: SxProps<Theme>;
  value?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
}>;

export const BaseSelect = ({
  sx,
  dropdownSx,
  value,
  placeholder = "",
  children,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);

  return (
    <>
      <SelectValue
        value={value ?? placeholder}
        sx={sx}
        expanded={open}
        onClick={handleOpen}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: "left",
        }}
        PaperProps={{
          sx: [
            {
              maxHeight: "22.5rem",
              maxWidth: "16.875rem",
              overflow: "auto",
              py: 2,
              minWidth: 30,
              boxShadow:
                "0 0.5rem 1rem rgba(0,0,0,.1), 0 0.5rem 3rem rgba(0,0,0,.2)",
            },
            ...(Array.isArray(dropdownSx) ? dropdownSx : [dropdownSx]),
          ],
        }}
        onClose={handleClose}
      >
        <Column children={children} />
      </Popover>
    </>
  );
};
