import {
  MarketplaceCard,
  OrdersResponsesHeader,
  OrdersShortDetails,
  ServicePrice,
} from "@entities/marketplace";
import { MyOrderCard } from "../types";
import { Paths } from "@shared/lib/router";
import { Box, styled } from "@mui/material";
import { tagsByStatus } from "@shared/ui";

import { ReactComponent as EyeIcon } from "@shared/assets/eye.svg";
import { ReactComponent as EyeHiddenIcon } from "@shared/assets/eye-hidden.svg";
import { CloseButton } from "./CloseButton";
import { useMyOrderActions } from "../hooks/useMyOrderActions";
import { useBoolean } from "@shared/hooks";

const VisibilityButton = styled("button")(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  background: "#FCFCCF",
  padding: theme.spacing(0.4, 1.5),
  outline: "none",
  border: "none",
  alignSelf: "flex-end",
  cursor: "pointer",
}));

export type Props = {
  item: MyOrderCard;
  onClose: () => void;
  onLike?: () => void;
  onDislike?: () => void;
};

export const MyOrdersListItem = ({
  item,
  onClose,
  onLike,
  onDislike,
}: Props) => {
  const [hidden, hiddenActions] = useBoolean(item.hidden);
  const { show, hide } = useMyOrderActions(item.id);

  const onShow = () => show().then(hiddenActions.off);
  const onHide = () => hide().then(hiddenActions.on);

  return (
    <MarketplaceCard
      key={item.id}
      sx={{ mt: 2, "&:first-of-type": { mt: 0 } }}
      avatarSrc={item.avatarSrc}
      header={
        <OrdersResponsesHeader
          title={item.company}
          name={item.name}
          date={item.date}
        />
      }
      rating={item.rating}
      reviewsCount={item.reviewsCount}
      firstName={item.company}
      linkUrl={`${Paths.MyOrders}${item.id}`}
      body={
        <Box sx={{ display: "flex", columnGap: 10, mr: 3, py: 0.5 }}>
          <OrdersShortDetails
            serviceName={item.title}
            serviceTypes={item.services}
            targetType="receiver"
            target={item.target}
            text={item.text}
            statistics={item.statistics}
            onLike={onLike}
            onDislike={onDislike}
          />
          {!!item.price.value && (
            <ServicePrice value={item.price.value} type={item.price.type} />
          )}
        </Box>
      }
      tags={
        <>
          {tagsByStatus[item.status]}
          {item.status === "PUBLISHED" &&
            !item.target &&
            item.businessCardStatus === "PUBLISHED" && (
              <VisibilityButton onClick={hidden ? onShow : onHide}>
                {hidden ? <EyeHiddenIcon /> : <EyeIcon />}
              </VisibilityButton>
            )}
        </>
      }
      buttons={
        (item.status === "PUBLISHED" &&
          item.businessCardStatus === "PUBLISHED" && (
            <CloseButton text="Закрыть заказ" onClick={onClose} />
          )) || <Box sx={{ minWidth: "11.5rem" }} />
      }
    />
  );
};
