import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      light: "#f8f787",
      main: "#f0ee0e",
      contrastText: "#363634",
    },
    secondary: {
      light: "#63666a",
      main: "#363634",
      contrastText: "#ffffff",
    },
    success: {
      main: "#15bc44",
    },
    error: {
      main: "#ff4b4b",
    },
    text: {
      primary: "#363634",
      disabled: "#a9a9a9",
    },
    divider: "#dddddd",
  },
  spacing: (factor: number) => `${0.5 * factor}rem`,
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html, body {
          box-sizing: border-box;
          margin: 0;
          padding: 0;
          height: 100%;
          min-width: 1200px;
          max-height: 100vh;
          font-family: "Circe", sans-serif;
          font-size: 16px;
          line-height: 1.15;
          color: #63666a;
          background-color: #fff;
        }
      
        *, *:before, *:after {
          box-sizing: inherit;
        }

        #root {
          width: 100%;
          height: 100%;
        },

        .slick-list {
          margin-right: 1em;
          overflow: visible;
        }
        .slick-track {
          margin-left: initial !important;
          margin-right: initial !important;
        }
        .slick-slide img {
          display: block;
          width: 100%;
        }

        input, select, textarea, button {
          font-family: inherit;
        }

        input[type=search]::-ms-clear {
          display: none;
          width : 0;
          height: 0;
        }

        input[type=search]::-ms-reveal {
          display: none;
          width : 0;
          height: 0;
        }

        input[type="search"]::-webkit-search-cancel-button,
        input[type="search"]::-webkit-search-decoration,
        input[type="search"]::-webkit-search-results-button,
        input[type="search"]::-webkit-search-results-decoration {
          display: none;
        }

        svg {
          display: block;
        }
      `,
    },
  },
});
