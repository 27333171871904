import { useCurrentUser, useSignOut } from "@entities/user";
import { CircularProgress } from "@mui/material";
import { client } from "@shared/lib/client";
import { AxiosError } from "axios";
import { useEffect } from "react";

type Props = {
  children: JSX.Element;
};

export const WithAuth = ({ children }: Props) => {
  const { isLoading } = useCurrentUser();
  const signOut = useSignOut();

  useEffect(() => {
    client.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        const isUnauthorized = error.response?.status === 401;

        if (isUnauthorized) {
          await signOut.mutateAsync();
          window.location.reload();
        }

        return Promise.reject(error);
      }
    );
  }, [signOut]);

  if (isLoading) {
    return (
      <CircularProgress
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        color="primary"
        size={48}
      />
    );
  }

  return children;
};
