import { ReactNode } from "react";
import { styled } from "@mui/material";

type Props = {
  text?: string;
  children?: ReactNode;
};

export const PageTitle = styled(({ text, children, ...otherProps }: Props) => (
  <h3 {...otherProps}>{text ?? children}</h3>
))(({ theme }) => ({
  margin: 0,
  fontWeight: 700,
  fontSize: "2rem",
  lineHeight: 1.25,
  color: theme.palette.text.primary,
}));
