import { Box, styled } from "@mui/material";
import { Button, Container } from "@shared/ui";
import AskCommunityBadge from "@shared/assets/ask-community.png";
import { getDomainUrl } from "@shared/lib/utils";

const Title = styled("h2")(({ theme }) => ({
  margin: 0,
  textAlign: "right",
  fontWeight: 700,
  fontSize: "2.5rem",
  lineHeight: 1.25,
  color: theme.palette.text.primary,
}));

export const AskCommunityBanner = () => {
  const handleButtonClick = () => {
    window.location.href = getDomainUrl("/community");
  };

  return (
    <Box sx={{ bgcolor: "#f2f6fa" }}>
      <Container
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          py: 0,
        }}
      >
        <Box
          sx={{
            ml: (theme) => theme.spacing(4),
            height: "9.375rem",
            width: "16.3125rem",
            backgroundImage: `url(${AskCommunityBadge})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        />
        <Title sx={{ ml: "auto" }}>
          У вас возникли вопросы <br /> по работе в Маркетплейсе?
        </Title>
        <Button
          sx={{ ml: (theme) => theme.spacing(8) }}
          role="link"
          variant="secondary"
          onClick={handleButtonClick}
          text="Спросить сообщество"
        />
      </Container>
    </Box>
  );
};
