import qs from "qs";
import { Filters } from "@entities/filters";

export const businessKeys = {
  businessCardCount: (filters: Filters) => [
    "business",
    "count",
    qs.stringify(filters, { arrayFormat: "repeat" }),
  ],
  businessCard: (id: string) => ["business", id],
  userPublishedCards: ["business", "user", "published"],
} as const;
