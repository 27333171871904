import { api } from "@shared/api";
import { showErrorMessages } from "@shared/lib/error";
import { CreateReview } from "@shared/types/ordersResponses";
import { enqueueSnackbar } from "notistack";
import { useMutation } from "react-query";

export const useSendReview = (offerId: string, answerId: string) => {
  const sendReview = useMutation(
    (data: CreateReview) => api.sendReview(offerId, answerId, data),
    {
      onSuccess: () => {
        enqueueSnackbar("Отзыв успешно отправлен", { variant: "success" });
      },
      onError: showErrorMessages,
    }
  );

  return (data: CreateReview) => sendReview.mutateAsync(data);
};
