import { api } from "@shared/api";
import { showErrorMessages } from "@shared/lib/error";
import { EntityStatus } from "@shared/types/entity";
import { CreateServiceOrder } from "@shared/types/service";
import { enqueueSnackbar } from "notistack";
import { useMutation } from "react-query";

export const useCreateServiceOrder = () => {
  return useMutation(
    ({ order, status }: { order: CreateServiceOrder; status?: EntityStatus }) =>
      api.createServiceOrder(order, status),
    {
      onSuccess: () => {
        enqueueSnackbar("Заказ сформирован и опубликован", {
          variant: "success",
        });
      },
      onError: showErrorMessages,
    }
  );
};
