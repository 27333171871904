import { styled } from "@mui/material";
import { ReactNode } from "react";

import { ReactComponent as UserIcon } from "@shared/assets/user.svg";
import { ReactComponent as PhoneIcon } from "@shared/assets/phone.svg";
import { ReactComponent as LocationIcon } from "@shared/assets/location.svg";
import { ReactComponent as EmailIcon } from "@shared/assets/email.svg";
import { ReactComponent as WebsiteIcon } from "@shared/assets/website.svg";
import { Link } from "@shared/ui";
import { BusinessCardContactPerson } from "../types";

type ItemProps = {
  icon: ReactNode;
  content: ReactNode | string;
};
const Item = styled(({ icon, content, ...otherProps }: ItemProps) => {
  return (
    <div {...otherProps}>
      {icon}
      {content}
    </div>
  );
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2.5),
  lineHeight: 1.35,
}));

type Props = BusinessCardContactPerson;

export const ViewBusinessCardContacts = ({
  name,
  phone,
  address,
  email,
}: Props) => {
  return (
    <>
      {!!name.length && <Item icon={<UserIcon />} content={name} />}
      {!!phone?.length && (
        <Item
          icon={<PhoneIcon />}
          content={
            <Link
              href={phone}
              text={phone}
              type="tel"
              sx={{ color: "text.primary" }}
            />
          }
        />
      )}
      {!!address?.length && <Item icon={<LocationIcon />} content={address} />}
      {!!email?.length && (
        <Item
          icon={<EmailIcon />}
          content={<Link href={email} text={email} type="email" />}
        />
      )}
    </>
  );
};

type ViewBusinessCardWebsiteProps = {
  website: string;
};
export const ViewBusinessCardWebsite = ({
  website,
}: ViewBusinessCardWebsiteProps) => {
  return (
    <Item
      icon={<WebsiteIcon />}
      content={<Link href={website} text={website} target="_blank" />}
    />
  );
};
