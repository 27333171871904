import { useAppDispatch } from "@app/store";
import { loadCard } from "@entities/business/model";
import { api } from "@shared/api";
import { showErrorMessages } from "@shared/lib/error";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { businessCardsKeys } from "./businessCardsKeys";

export const useViewBusinessCard = () => {
  const { id = "" } = useParams();
  const dispatch = useAppDispatch();

  return useQuery(businessCardsKeys.card(id), () => api.getBusinessCard(id), {
    onSuccess: (data) => dispatch(loadCard(data)),
    onError: showErrorMessages,
  });
};
