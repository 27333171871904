import { styled } from "@mui/joy";
import { Container } from "@shared/ui";

export const MyMarketplaceContainer = styled(Container)(({ theme }) => ({
  gap: theme.spacing(8),
  display: "flex",
  flexDirection: "column",
  paddingTop: theme.spacing(9),
  marginBottom: theme.spacing(11),
}));
