import { ServiceCard, businessModel } from "@entities/business";
import { Column } from "@shared/ui";
import { EmptyTabText } from "./EmptyTabText";
import { useAppSelector } from "@app/store";
import { MakeOrderModal } from "@features/service-order";
import { useBoolean } from "@shared/hooks";
import { useLocation, useNavigate } from "react-router";
import { useState } from "react";

export const ServicesTab = () => {
  const createdByCurrentAccount = useAppSelector(
    businessModel.cardDetailsSelector
  )?.createdByCurrentAccount;
  const services = useAppSelector(businessModel.cardServicesSelector);
  const location = useLocation();
  const navigate = useNavigate();
  const [makeOrderIsOpen, makeOrderModal] = useBoolean(
    location.hash.startsWith("#make-order")
  );

  const [currentService, setCurrentService] = useState<string | null>(
    makeOrderIsOpen ? location.hash.replace(/#make-order-?/, "") || null : null
  );

  const handleMakeOrder = (id?: string) => {
    setCurrentService(id ?? null);
    return makeOrderModal.on();
  };

  const handleCloseMakeOrder = () => {
    navigate(
      { pathname: location.pathname, search: location.search },
      { replace: true }
    );

    setCurrentService(null);
    makeOrderModal.off();
  };

  return services?.length ? (
    <>
      {" "}
      <Column sx={{ gap: 2.5 }}>
        {services.map((service) => (
          <ServiceCard
            key={service.name}
            {...service}
            canOrder={!createdByCurrentAccount}
            onClick={() => handleMakeOrder(service.id)}
          />
        ))}
      </Column>
      <MakeOrderModal
        title={currentService ? "Заказать услугу" : "Сформировать заказ"}
        description={currentService ? "Заказать услугу" : "Сформировать заказ"}
        currentService={currentService ?? undefined}
        open={makeOrderIsOpen}
        onClose={handleCloseMakeOrder}
      />
    </>
  ) : (
    <EmptyTabText text="Перечень оказываемых услуг пуст" />
  );
};
