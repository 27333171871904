import * as user from "./user";
import * as dictionary from "./dictionary";
import * as business from "./business";
import * as service from "./service";
import * as businessCards from "./businessCards";
import * as ordersResponses from "./ordersResponses";
import * as attachment from "./attachment";
import * as posts from "./posts";

export const api = {
  ...user,
  ...dictionary,
  ...business,
  ...service,
  ...businessCards,
  ...ordersResponses,
  ...attachment,
  ...posts,
};
