import { styled } from "@mui/material";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { Column, Tag, User, UserData } from "@shared/ui";

const SmallTag = styled(Tag)({ minWidth: 0 });

type Props = {
  id: string;
  sectionPath: string;
  isPrimary?: boolean;
  body?: ReactNode;
  tags?: ReactNode;
  buttons?: ReactNode;
} & UserData;

export const BusinessCard = styled(
  ({
    id,
    sectionPath,
    firstName,
    isPrimary = false,
    body,
    tags,
    buttons,
    avatarSrc,
    rating,
    reviewsCount,
    ...otherProps
  }: Props) => {
    const navigate = useNavigate();
    const handleClick = () => navigate(`${sectionPath}${id}`);

    return (
      <div {...otherProps} onClick={handleClick}>
        <Column sx={{ gap: 1, mr: 3, minWidth: "4.5rem" }}>
          <User
            avatarSrc={avatarSrc}
            rating={rating}
            reviewsCount={reviewsCount}
            avatarSize={56}
            firstName={firstName}
          />
          {isPrimary && <SmallTag text="Основная" color="#FCFA3C" />}
        </Column>
        <Column sx={{ flex: 1, lineHeight: 1.5 }} children={body} />
        <Column
          sx={{
            alignItems: "flex-end",
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <Column sx={{ gap: 2 }} children={tags} />
          <Column sx={{ gap: 2 }} children={buttons} />
        </Column>
      </div>
    );
  }
)(({ theme }) => ({
  display: "flex",
  borderRadius: ".1875rem",
  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2, 2.5),
  cursor: "pointer",
}));
