import { Box, styled } from "@mui/material";
import { ReactComponent as PdfIcon } from "@shared/assets/pdf-icon.svg";
import { ReactComponent as DocIcon } from "@shared/assets/doc-icon.svg";
import { ReactComponent as ExcelIcon } from "@shared/assets/pdf-icon.svg";
import { FileData } from "./types";

type Props = FileData;

const formatSize = (bytes: number) => {
  const sizes = ["Б", "Кб", "Мб", "Гб"];
  const index = Math.floor(Math.log(bytes) / Math.log(1024));
  const size = Math.ceil(bytes / Math.pow(1024, index));

  return `${size} ${sizes[index]}`;
};

export const File = styled(
  ({ name, type, weight, src, ...otherProps }: Props) => {
    return (
      <a {...otherProps} href={src}>
        {type === "pdf" && <PdfIcon />}
        {type === "word" && <DocIcon />}
        {type === "excel" && <ExcelIcon />}
        <Box
          sx={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: "100%",
          }}
        >
          {name}
        </Box>
        <Box>{formatSize(weight)}</Box>
      </a>
    );
  }
)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: theme.spacing(14),
  gap: theme.spacing(0.5),
  fontSize: "0.75rem",
  lineHeight: 1.5,
  color: "#666666",
  textDecoration: "none",
}));
