import { SxProps, Theme, styled } from "@mui/material";
import { ChangeEvent, HTMLInputTypeAttribute, ReactNode, useMemo } from "react";
import { InputContainer } from "../InputContainer";
import { useIMask } from "react-imask";

type Props = {
  id?: string;
  value?: string;
  onChange?: (value: string) => void;
  label?: string;
  placeholder?: string;
  required?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  name?: string;
  step?: string;
  type?: HTMLInputTypeAttribute;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  sx?: SxProps<Theme>;
  highlight?: boolean;
  pattern?: string;
  mask?: string;
};

const StyledInput = styled("input")(({ theme }) => ({
  flex: 1,
  padding: 0,
  backgroundColor: theme.palette.secondary.contrastText,
  fontSize: "1rem",
  lineHeight: 1,
  border: "none",
  color: theme.palette.secondary.light,
  "&::placeholder": {
    color: theme.palette.text.disabled,
  },
  "&:focus": {
    outline: "none",
  },
  "&[type=number]::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
  },
}));

export const Input = ({
  id,
  value,
  onChange,
  label,
  placeholder,
  required,
  readonly,
  disabled,
  name,
  type,
  startAdornment,
  endAdornment,
  sx,
  highlight,
  step,
  pattern,
  mask,
  ...otherProps
}: Props) => {
  const handler = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.value);
  };

  const maskObj = useMemo(() => ({ mask: mask }), [mask]);

  const { ref } = useIMask<HTMLInputElement, any>(maskObj, {
    onAccept: (value) => onChange?.(value),
  });

  return (
    <InputContainer
      id={id}
      label={label}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      disabled={disabled}
      required={required}
      sx={sx}
    >
      <StyledInput
        ref={ref}
        type={type}
        name={name}
        id={id}
        value={value}
        onChange={handler}
        placeholder={placeholder}
        readOnly={readonly}
        disabled={disabled}
        step={step}
        pattern={pattern}
        {...otherProps}
        sx={{ bgcolor: disabled ? "#F7F7F7" : "#fff" }}
      />
    </InputContainer>
  );
};
