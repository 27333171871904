import { api } from "@shared/api";
import { useMutation } from "react-query";
import { CreateFormData } from "../types";
import { toLocaleStr } from "@shared/lib/utils";
import { showErrorMessages } from "@shared/lib/error";
import { enqueueSnackbar } from "notistack";

const handleSuccess = (text: string) => {
  enqueueSnackbar(text, { variant: "success" });
};
export const useCreateComment = (
  businessCardId: string,
  relationId: string,
  withNotification = true
) => {
  const createComment = useMutation(
    ["posts", "comments"],
    (formData: CreateFormData) =>
      api.createComment({
        attachments: [],
        businessCardId: formData?.businessCard?.id ?? businessCardId,
        content: toLocaleStr(formData.content),
        relationId,
      }),
    {
      onSuccess: withNotification
        ? () => handleSuccess("Ответ на отзыв успешно добавлен")
        : undefined,
      onError: showErrorMessages,
    }
  );

  return {
    createComment: (formData: CreateFormData) =>
      createComment.mutateAsync(formData),
  };
};

export const useUpdateComment = (id: string) => {
  const updateComment = useMutation(
    ["posts", "comments"],
    (formData: CreateFormData) =>
      api.updateComment({
        attachments: [],
        content: toLocaleStr(formData.content),
        id,
      }),
    {
      onSuccess: () => handleSuccess("Ответ на отзыв успешно обновлен"),
      onError: showErrorMessages,
    }
  );

  return {
    updateComment: (formData: CreateFormData) =>
      updateComment.mutateAsync(formData),
  };
};
