import { Attachment } from "@shared/types/attachment";
import {
  BusinessCardDto,
  BusinessCardUpdateDto,
} from "@shared/types/businessCard";
import { FileData } from "@shared/ui";
import { BusinessCardData } from "../types";
import { Paths } from "@shared/lib/router";
import { getUserName, uniqObjectFilter } from "@shared/lib/utils";

const extractFiles = (attachments: Attachment[] = []): FileData[] =>
  attachments
    .filter((attachment) => attachment.iconType !== "image")
    .map((file) => ({
      name: file.name,
      type: file.iconType,
      weight: file.contentSize,
      src: file.path,
    }));

export const mapBusinessCard = (dto: BusinessCardDto): BusinessCardData => ({
  id: dto.id,
  type: dto.cardType.id,
  mainCard: dto.mainCard ?? false,
  details: {
    avatarSrc: dto.logo?.path,
    creationDate: dto.createdDateTime,
    title: dto.name.ru,
    rating: dto.rate ?? 0,
    reviewsCount: dto.reviewCount,
    services: [dto.cardType.display.ru],
    description: dto.description?.ru ?? "",
    status: dto.status,
    contactPerson: {
      name: dto.contactPerson?.ru ?? "",
      phone: dto.contactPhone ?? "",
      address: dto.address?.ru ?? "",
      email: dto.email,
      website: dto.website,
    },
    showContacts: dto.canCurrentUserWatchContacts,
    files: extractFiles(dto.attachments ?? []),
    createdByCurrentAccount: dto.isCreatedByCurrentAccount,
  },
  services: dto.services?.map((service) => ({
    id: service.id,
    type: service.serviceType.display.ru ?? "",
    name: service.name.ru,
    description: service.description.ru,
    price: service.price?.toString() ?? "",
    rating: service.rate ?? 0,
    reviewsCount: service.reviewCount ?? 0,
    canOrder: !service.blocked,
  })),
  reviews: dto.businessCardsReviews?.map((review) => ({
    relationId: review.relationId,
    avatarSrc: review.businessCard.logo?.path,
    src: `${Paths.Business}${review.businessCard.id}`,
    title: review.businessCard.name.ru,
    name: getUserName(review.creator),
    date: review.createdDateTime,
    text: review.content.ru,
    rating: review.grade,
    isCommentAllowed: review.isCommentAllowed,
    canProcessByCurrentUser: review.canProcessByCurrentUser,
    comments: review.comments?.map((comment) => {
      return {
        id: comment.id,
        content: comment.content.ru,
      };
    }),
    id: review.id,
  })),
  partners: uniqObjectFilter(
    dto.businessCardsReviews.map((review) => ({
      avatarSrc: review.businessCard.logo?.path,
      src: `${Paths.Business}${review.businessCard.id}`,
      title: review.businessCard.name.ru,
      name: getUserName(review.creator),
    })),
    "src"
  ),
  notifications: dto.notification.map((item) => {
    const fullTitle = item.title.ru;
    const pos = fullTitle.indexOf(" ");

    return {
      id: item.id,
      src: undefined,
      category: item.category,
      type: fullTitle.substring(0, pos),
      title: fullTitle.substring(pos + 1),
      text: item.content.ru,
      date: item.createdDateTime,
      read: item.read,
    };
  }),
  gallery: dto.attachments
    ?.filter((item) => item.iconType === "image")
    .map((item) => item.path),
});

const emptyLocaleString = { ru: "", en: "" };

export const updateMainCardDto = (
  dto: BusinessCardDto,
  mainCard: boolean
): BusinessCardUpdateDto => ({
  cardType: dto.cardType.id,
  name: dto.name ?? emptyLocaleString,
  address: dto.address ?? emptyLocaleString,
  contactPerson: dto.contactPerson,
  contactPhone: dto.contactPhone,
  description: dto.description,
  email: dto.email,
  website: dto.website ?? "",
  status: dto.status,
  logo: dto.logo?.id,
  attachments: dto.attachments?.map((item) => item.id) ?? [],
  services:
    dto.services?.map((item) => ({
      name: item.name,
      serviceType: item.serviceType.code,
      description: item.description,
      attachments: item.attachments,
      price: item.price,
      priceUnits: item.priceUnits,
    })) ?? [],
  mainCard: mainCard,
});
