import { Box, SxProps, Theme } from "@mui/material";
import { PriceType, PriceTypes } from "@shared/lib/price";

const formatPrice = (value: number) => {
  return `${new Intl.NumberFormat("ru-RU", { maximumFractionDigits: 0 })
    .format(value)
    .replace(",", ".")} p.`;
};

type Props = {
  sx?: SxProps<Theme>;
  value: number;
  type?: PriceType;
};

type CoreProps = {
  text: string;
} & Props;

const Price = ({ text, type, sx = [], value }: CoreProps) => {
  return (
    <Box
      sx={[
        {
          color: "text.disabled",
          lineHeight: 1.5,
          flex: "0 0 auto",
          ml: "auto",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box>{text}</Box>
      <Box sx={{ gap: 2.5, display: "flex", alignItems: "center" }}>
        <Box>/{PriceTypes[type ?? PriceType.SERVICE]}/</Box>
        <Box sx={{ fontSize: "1.375rem", color: "text.primary" }}>
          {!!value && formatPrice(value)}
        </Box>
      </Box>
    </Box>
  );
};

export const ServicePrice = (props: Props) => (
  <Price text="Стоимость услуги:" {...props} />
);

export const ResponsePrice = (props: Props) => (
  <Price text="Предложенная стоимость:" {...props} />
);
