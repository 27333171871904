import {
  AnswerType,
  BusinessCardStats,
  ServiceRequestStatus,
} from "@shared/types/statistic";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Box,
  Collapse,
} from "@mui/material";
import { useBoolean } from "@shared/hooks";
import { ReactComponent as Arrow } from "@shared/assets/small-arrow-right.svg";
import {
  BodyTableCeil,
  BodyTableCeilInner,
  BodyTableRow,
  HeadTableCeil,
  HeadTableRow,
} from "./table";
import { format } from "date-fns";

type Props = {
  statistic: BusinessCardStats;
};

export const OrdersStatistic = ({ statistic }: Props) => {
  const [isTableOpen, table] = useBoolean(false);

  const offerTables = [
    {
      firstTitle: "Исходящие заказы",
      secondTitle: "Поступившие отклики",
      items: statistic.offers.outgoingOffers,
    },
    {
      firstTitle: "Поступившие частные заказы",
      secondTitle: "Исходящие отклики",
      items: statistic.offers.incomingOffers,
    },
  ];

  const answerTables = [
    {
      firstTitle: "Поступившие заказы",
      secondTitle: "Исходящие отклики",
      items: statistic.offers.outgoingAnswers,
    },
    {
      firstTitle: "Исходящие заказы",
      secondTitle: "Поступившие отклики",
      items: statistic.offers.incomingAnswers,
    },
  ];

  return (
    <Box>
      <Box
        sx={{
          fontWeight: 400,
          fontSize: "1.125rem",
          lineHeight: 1.5,
          color: "#63666A",
          "&:hover": { cursor: "pointer" },
        }}
        onClick={table.toggle}
      >
        <Arrow
          style={{
            display: "inline-block",
            marginRight: 16,
            fill: "#63666A",
            transform: isTableOpen ? "rotate(270deg)" : "rotate(90deg)",
          }}
        />
        Статистика по заказам и откликам карточки бизнеса{" "}
        {statistic.businessCard.name.ru}, сформированным за период{" "}
        <Box component="span" sx={{ fontWeight: "700", color: "#363634" }}>
          {statistic.fromDate &&
            format(new Date(statistic.fromDate), "dd.MM.yyyy")}
          {"-"}
          {statistic.toDate && format(new Date(statistic.toDate), "dd.MM.yyyy")}
        </Box>
      </Box>
      <Collapse in={isTableOpen}>
        <Box sx={{ display: "flex", flexDirection: "column", rowGap: 3 }}>
          {offerTables.map((table, index) => {
            const publishedItem = table.items.find(
              (offer) => offer.offerStatus === ServiceRequestStatus.PUBLISHED
            );

            const completedItem = table.items.find(
              (offer) => offer.offerStatus === ServiceRequestStatus.COMPLETED
            );

            const cancelledItem = table.items.find(
              (offer) => offer.offerStatus === ServiceRequestStatus.CANCELLED
            );

            const declinedItem = table.items.find(
              (offer) => offer.offerStatus === ServiceRequestStatus.DECLINED
            );

            return (
              <TableContainer key={`offer-table-${index}`}>
                <Table>
                  <TableHead>
                    <HeadTableRow>
                      <HeadTableCeil colSpan={2}>
                        {table.firstTitle}
                      </HeadTableCeil>
                      <HeadTableCeil colSpan={6} align="center">
                        {table.secondTitle}
                      </HeadTableCeil>
                    </HeadTableRow>
                    <HeadTableRow>
                      <HeadTableCeil width="12.5%" />
                      <HeadTableCeil width="12.5%" align="center">
                        Всего заказов
                      </HeadTableCeil>
                      <HeadTableCeil width="12.5%" align="center">
                        Всего откликов
                      </HeadTableCeil>
                      <HeadTableCeil width="12.5%" align="center">
                        Опубликован
                      </HeadTableCeil>
                      <HeadTableCeil width="12.5%" align="center">
                        Принят
                      </HeadTableCeil>
                      <HeadTableCeil width="12.5%" align="center">
                        В работе
                      </HeadTableCeil>
                      <HeadTableCeil width="12.5%" align="center">
                        Завершен
                      </HeadTableCeil>
                      <HeadTableCeil width="12.5%" align="center">
                        Отклонен
                      </HeadTableCeil>
                    </HeadTableRow>
                  </TableHead>
                  <TableBody>
                    <BodyTableRow>
                      <BodyTableCeil type="category">Опубликован</BodyTableCeil>
                      <BodyTableCeil align="center">
                        {publishedItem?.offers ?? 0}
                      </BodyTableCeil>
                      <BodyTableCeil align="center">
                        {publishedItem?.answers ?? 0}
                      </BodyTableCeil>
                      <BodyTableCeil align="center">
                        {publishedItem?.publishedAnswers ?? 0}
                      </BodyTableCeil>
                      <BodyTableCeil align="center">
                        {publishedItem?.acceptedAnswers ?? 0}
                      </BodyTableCeil>
                      <BodyTableCeil align="center">
                        {publishedItem?.inWorkAnswers ?? 0}
                      </BodyTableCeil>
                      <BodyTableCeil align="center">
                        {publishedItem?.completedAnswers ?? 0}
                      </BodyTableCeil>
                      <BodyTableCeil align="center">
                        {publishedItem?.declinedAnswers ?? 0}
                      </BodyTableCeil>
                    </BodyTableRow>
                    <BodyTableRow>
                      <BodyTableCeil type="category">Завершен</BodyTableCeil>
                      <BodyTableCeil align="center">
                        {completedItem?.offers ?? 0}
                      </BodyTableCeil>
                      <BodyTableCeil align="center">
                        {completedItem?.answers ?? 0}
                      </BodyTableCeil>
                      <BodyTableCeil align="center">
                        {completedItem?.publishedAnswers ?? 0}
                      </BodyTableCeil>
                      <BodyTableCeil align="center">
                        {completedItem?.acceptedAnswers ?? 0}
                      </BodyTableCeil>
                      <BodyTableCeil align="center">
                        {completedItem?.inWorkAnswers ?? 0}
                      </BodyTableCeil>
                      <BodyTableCeil align="center">
                        {completedItem?.completedAnswers ?? 0}
                      </BodyTableCeil>
                      <BodyTableCeil align="center">
                        {completedItem?.declinedAnswers ?? 0}
                      </BodyTableCeil>
                    </BodyTableRow>
                    <BodyTableRow>
                      <BodyTableCeil type="category">Закрыт</BodyTableCeil>
                      <BodyTableCeil align="center">
                        {cancelledItem?.offers ?? 0}
                      </BodyTableCeil>
                      <BodyTableCeil align="center">
                        {cancelledItem?.answers ?? 0}
                      </BodyTableCeil>
                      <BodyTableCeil align="center">
                        {cancelledItem?.publishedAnswers ?? 0}
                      </BodyTableCeil>
                      <BodyTableCeil align="center">
                        {cancelledItem?.acceptedAnswers ?? 0}
                      </BodyTableCeil>
                      <BodyTableCeil align="center">
                        {cancelledItem?.inWorkAnswers ?? 0}
                      </BodyTableCeil>
                      <BodyTableCeil align="center">
                        {cancelledItem?.completedAnswers ?? 0}
                      </BodyTableCeil>
                      <BodyTableCeil align="center">
                        {cancelledItem?.declinedAnswers ?? 0}
                      </BodyTableCeil>
                    </BodyTableRow>
                    <BodyTableRow>
                      <BodyTableCeil type="category">Отклонен</BodyTableCeil>
                      <BodyTableCeil align="center">
                        {declinedItem?.offers ?? 0}
                      </BodyTableCeil>
                      <BodyTableCeil align="center">
                        {declinedItem?.answers ?? 0}
                      </BodyTableCeil>
                      <BodyTableCeil align="center">
                        {declinedItem?.publishedAnswers ?? 0}
                      </BodyTableCeil>
                      <BodyTableCeil align="center">
                        {declinedItem?.acceptedAnswers ?? 0}
                      </BodyTableCeil>
                      <BodyTableCeil align="center">
                        {declinedItem?.inWorkAnswers ?? 0}
                      </BodyTableCeil>
                      <BodyTableCeil align="center">
                        {declinedItem?.completedAnswers ?? 0}
                      </BodyTableCeil>
                      <BodyTableCeil align="center">
                        {declinedItem?.declinedAnswers ?? 0}
                      </BodyTableCeil>
                    </BodyTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            );
          })}

          {answerTables.map((table, index) => {
            return (
              <TableContainer key={`answer-table-${index}`}>
                <Table>
                  <TableHead>
                    <HeadTableRow>
                      <HeadTableCeil colSpan={2}>
                        {table.firstTitle}
                      </HeadTableCeil>
                      <HeadTableCeil colSpan={6} align="center">
                        {table.secondTitle}
                      </HeadTableCeil>
                    </HeadTableRow>
                    <HeadTableRow>
                      <HeadTableCeil width="12.5%" />
                      <HeadTableCeil width="12.5%" align="center">
                        Тип заказа
                      </HeadTableCeil>
                      <HeadTableCeil width="12.5%" align="center">
                        Всего откликов
                      </HeadTableCeil>
                      <HeadTableCeil width="12.5%" align="center">
                        Опубликован
                      </HeadTableCeil>
                      <HeadTableCeil width="12.5%" align="center">
                        Принят
                      </HeadTableCeil>
                      <HeadTableCeil width="12.5%" align="center">
                        В работе
                      </HeadTableCeil>
                      <HeadTableCeil width="12.5%" align="center">
                        Завершен
                      </HeadTableCeil>
                      <HeadTableCeil width="12.5%" align="center">
                        Отклонен
                      </HeadTableCeil>
                    </HeadTableRow>
                  </TableHead>
                  <TableBody>
                    {table.items.map((item) => (
                      <BodyTableRow key={item.offerId}>
                        <BodyTableCeil type="category">
                          <BodyTableCeilInner>
                            {item.name.ru}
                          </BodyTableCeilInner>
                        </BodyTableCeil>
                        <BodyTableCeil align="center">
                          {item.type === AnswerType.PUBLIC && "Публичный"}
                          {item.type === AnswerType.PRIVATE && "Частный"}
                        </BodyTableCeil>
                        <BodyTableCeil align="center">
                          {item.answers}
                        </BodyTableCeil>
                        <BodyTableCeil align="center">
                          {item.publishedAnswers}
                        </BodyTableCeil>
                        <BodyTableCeil align="center">
                          {item.acceptedAnswers}
                        </BodyTableCeil>
                        <BodyTableCeil align="center">
                          {item.inWorkAnswers}
                        </BodyTableCeil>
                        <BodyTableCeil align="center">
                          {item.completedAnswers}
                        </BodyTableCeil>
                        <BodyTableCeil align="center">
                          {item.declinedAnswers}
                        </BodyTableCeil>
                      </BodyTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            );
          })}
        </Box>
      </Collapse>
    </Box>
  );
};
