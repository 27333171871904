import { BigCard, Section } from "@entities/my-marketplace";
import { useIncomingResponses } from "@features/orders-responses/";
import { mapToShortIncomingResponse } from "@features/orders-responses/model/orderCardMapper";
import { Paths } from "@shared/lib/router";
import { Mask } from "@shared/ui";

export const IncomingResponses = () => {
  const mask = (
    <Mask
      beforeStyles={{
        width: "47rem",
        height: "47rem",
        bgcolor: "#FFFF5B",
        transform: "translate(95%, -70%)",
      }}
      afterStyles={{
        width: "49rem",
        height: "49rem",
        bgcolor: "#8BFFFF",
        transform: "translate(40%, 10%)",
        zIndex: -2,
      }}
    />
  );

  const { items, isLoading } = useIncomingResponses();
  const slides = items.map(mapToShortIncomingResponse);

  return (
    <Section
      card={
        <BigCard
          title="У вас нет поступивших откликов на ваши заказы"
          description="Перейдите в подборку  и сформируйте новые заказы"
          buttonText="Перейти в подборку услуг"
          mask={mask}
          linkUrl={Paths.Home}
        />
      }
      title="Отклики на мои заказы"
      hintText="Отклики на мои заказы - это предложения по оказанию услуг на ваши заказы "
      items={slides}
      viewAllLink={Paths.IncomingResponses}
      isLoading={isLoading}
    />
  );
};
