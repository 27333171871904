import { useAppSelector } from "@app/store";
import { userModel } from "@entities/user";
import {
  OrderCompilationFilters,
  OrderCompilationList,
  OrderCompilationTitle,
} from "@features/order-compilation";
import { RespondModal } from "@features/service-order";
import { decodeHash, encodeHash } from "@shared/lib/hash";
import { toAuth } from "@shared/lib/utils";
import { BaseLayout, Container } from "@shared/ui";
import { useMemo, useState } from "react";
import { Header, Footer } from "@widgets";
import { useLocation, useNavigate } from "react-router-dom";

enum Modal {
  CreateRespond = "CREATE_RESPOND",
}

type EntityModal = {
  name?: Modal;
  id?: string;
};

export const OrderCompilationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const entityModal = useMemo(
    () => decodeHash<EntityModal>(location.hash.slice(1)),
    [location.hash]
  );

  const [orderId, setOrderId] = useState<string | undefined>(
    entityModal?.name === Modal.CreateRespond ? entityModal?.id : undefined
  );

  const isSignedIn = useAppSelector(userModel.isSignedInSelector);

  const handleRespond = (id: string) => {
    if (isSignedIn) {
      return setOrderId(id);
    }

    const hashObj: EntityModal = { name: Modal.CreateRespond, id };
    toAuth(`/#${encodeHash(hashObj)}`);
  };

  const handleCloseRespond = () => {
    navigate(
      { pathname: location.pathname, search: location.search },
      { replace: true }
    );

    setOrderId(undefined);
  };

  return (
    <BaseLayout header={<Header />} footer={<Footer />}>
      <OrderCompilationTitle />
      <OrderCompilationFilters />
      <Container>
        <OrderCompilationList onRespond={handleRespond} />
      </Container>
      {!!orderId && (
        <RespondModal
          currentOrder={orderId}
          open={!!orderId}
          onClose={handleCloseRespond}
        />
      )}
    </BaseLayout>
  );
};
