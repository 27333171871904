import { api } from "@shared/api";
import { showErrorMessages } from "@shared/lib/error";
import { toLocaleStr } from "@shared/lib/utils";
import { enqueueSnackbar } from "notistack";
import { useMutation } from "react-query";

export const useDeclineRespond = () =>
  useMutation(
    ({ id, reason }: { id: string; reason: string }) =>
      api.declineIncomingOrder(id, toLocaleStr(reason)),
    {
      onSuccess: () => {
        enqueueSnackbar("Сформирован ответ на входящий заказ", {
          variant: "success",
        });
      },
      onError: showErrorMessages,
    }
  );
