import { Box, Popover } from "@mui/material";
import { Button } from "@shared/ui";

type Props = {
  anchor?: Element | null;
  onClose?: () => void;
  onRemove?: () => void;
};

export const RemoveServicePopover = ({ anchor, onClose, onRemove }: Props) => {
  return (
    <Popover
      open={!!anchor}
      anchorEl={anchor}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{ sx: { mt: 2, p: 2, width: "20rem" } }}
      onClose={onClose}
    >
      <Box sx={{ mb: 2 }}>
        Вы действительно хотите удалить оказываемую услугу?
      </Box>
      <Box sx={{ display: "flex", columnGap: 2 }}>
        <Button sx={{ flex: 1 }} variant="secondary" onClick={onClose}>
          Нет
        </Button>
        <Button
          sx={{
            flex: 1,
            border: "1px solid #ADADAD",
            bgcolor: "background.paper",
          }}
          onClick={onRemove}
        >
          Да
        </Button>
      </Box>
    </Popover>
  );
};
