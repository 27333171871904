import { Filters } from "@entities/filters";
import { client } from "@shared/lib/client";
import { getFromDate } from "@shared/lib/period";
import {
  BusinessCard,
  CreateBusinessCard,
  PublishedBusinessCard,
} from "@shared/types/business";
import { EntityStatus } from "@shared/types/entity";
import { BusinessCardStats } from "@shared/types/statistic";

export const getUserPublishedBusinessCards = async () => {
  const response = await client.get<PublishedBusinessCard[]>(
    "/business-cards/account/published"
  );
  return response.data;
};

export const createBusinessCard = async (
  data: CreateBusinessCard,
  status: EntityStatus = EntityStatus.PUBLISHED
) => {
  const response = await client.post<BusinessCard>("/business-cards", {
    ...data,
    status,
  });
  return response.data;
};

export const updatePublishedBusinessCard = async (
  data: CreateBusinessCard,
  status: EntityStatus = EntityStatus.PUBLISHED
) => {
  const { id, cardType, ...other } = data;

  const response = await client.put<BusinessCard>(
    `/business-cards/${data.id}`,
    { ...other, status }
  );
  return response.data;
};

export const getBusinessCardById = async (id: string) => {
  const response = await client.get<BusinessCard>(`/business-cards/${id}`);
  return response.data;
};

export const getBusinessCardStatistic = async (
  id: string,
  fromDate: Date,
  toDate: Date
) => {
  const response = await client.get<BusinessCardStats>(
    `statistics/business-cards/${id}`,
    { params: { fromDate: fromDate.getTime(), toDate: toDate.getTime() } }
  );

  return response.data;
};

export const getBusinessCardCount = async (filters: Filters) => {
  const response = await client.get<number>("/business-cards/count", {
    params: {
      ...filters,
      sort: filters?.sort ? `BY_${filters.sort}` : undefined,
      fromDate: getFromDate(filters.fromDate),
    },
  });
  return response.data;
};
