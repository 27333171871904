import { api } from "@shared/api";
import { CreateBusinessCard } from "@shared/types/business";
import { EntityStatus } from "@shared/types/entity";
import { useMutation, useQueryClient } from "react-query";
import { businessKeys } from "./businessKeys";
import { showErrorMessages } from "@shared/lib/error";
import { enqueueSnackbar } from "notistack";

export const useCreateBusinessCard = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ card, status }: { card: CreateBusinessCard; status?: EntityStatus }) =>
      api.createBusinessCard(card, status),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(businessKeys.userPublishedCards);
        enqueueSnackbar("Карточка успешно опубликована", {
          variant: "success",
        });
      },
      onError: showErrorMessages,
    }
  );
};
