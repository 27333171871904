import { Box, styled } from "@mui/material";
import { Tag } from "@shared/ui";
import { ReactComponent as EditIcon } from "@shared/assets/edit.svg";
import { ReactComponent as RemoveIcon } from "@shared/assets/remove-service.svg";
import { ServicePrice } from "@entities/marketplace";
import { PriceType } from "@shared/lib/price";
import { RemoveServicePopover } from "@features/service-order";
import { MouseEvent, useState } from "react";

const Action = styled("button")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  columnGap: theme.spacing(1),
  border: "none",
  backgroundColor: "transparent",
  fontWeight: 700,
  fontSize: "0.875rem",
  lineHeight: 1.4,
  color: theme.palette.text.disabled,

  "&:hover": { cursor: "pointer" },
}));

type Props = {
  serviceType: string;
  name: string;
  description: string;
  price?: number;
  priceUnits?: PriceType;
  onEdit?: () => void;
  onRemove?: () => void;
};

export const BusinessServiceCard = ({
  serviceType,
  name,
  description,
  price,
  priceUnits,
  onEdit,
  onRemove,
}: Props) => {
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

  const handleClose = () => setAnchor(null);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
  };

  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridAutoFlow: "column",
          gridTemplateColumns: "1fr 1fr auto",
          gridTemplateRows: "1fr 1fr",
          gap: 3,
          padding: (theme) => theme.spacing(2.25, 2.5),
          borderRadius: 0.5,
          border: "1px solid #ddd",
          fontWeight: 400,
          fontSize: "1rem",
          lineHeight: 1.5,
          color: "text.primary",

          "&:not(:first-of-type)": { mt: 3 },
        }}
      >
        <Box>
          <Box sx={{ color: "secondary.light" }}>Тип:</Box>
          <Box>{serviceType}</Box>
        </Box>
        <Box>
          <Box sx={{ color: "secondary.light" }}>Наименование услуги:</Box>
          <Box>{name}</Box>
        </Box>
        <Box sx={price ? {} : { gridRow: "1 / 3" }}>
          <Box sx={{ color: "secondary.light" }}>Описание:</Box>
          <Box>{description}</Box>
        </Box>
        {price && (
          <Box>
            <ServicePrice value={price} type={priceUnits} />
          </Box>
        )}
        <Box
          sx={{
            gridRow: "1 / 3",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            rowGap: 2,
          }}
        >
          <Tag text="Опубликована" color="#B9FFAE" />
          <Action onClick={onEdit}>
            <EditIcon /> Изменить
          </Action>
          <Action onClick={handleOpen}>
            <RemoveIcon /> Удалить
          </Action>
        </Box>
      </Box>
      <RemoveServicePopover
        anchor={anchor}
        onClose={handleClose}
        onRemove={onRemove}
      />
    </>
  );
};
