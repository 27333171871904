import { useAppSelector } from "@app/store";
import { businessModel, useBusinessCardStatistic } from "@entities/business";
import { DateTimeRangeFilter } from "@entities/filters";
import {
  ActiveOrdersStatistic,
  InteractionsStatistic,
  OrdersStatistic,
  ServiceTypesStatistic,
  ViewsStatistic,
} from "@entities/statistic";
import { Box, Divider } from "@mui/material";
import { BusinessCardStats } from "@shared/types/statistic";
import { Button, Column } from "@shared/ui";
import { useState } from "react";

export const StatisticsTab = () => {
  const card = useAppSelector(businessModel.cardSelector);

  const [[startDate, endDate], setDates] = useState<[Date | null, Date | null]>(
    [null, null]
  );

  const [statistic, setStatistic] = useState<BusinessCardStats | null>(null);

  const getStatistic = useBusinessCardStatistic();

  const handleDateTimeChanges = (start: Date | null, end: Date | null) =>
    setDates([start, end]);

  const handleRequestStatistic = () => {
    if (!card?.id || !startDate || !endDate) return;

    getStatistic.mutateAsync(
      {
        id: card.id,
        fromDate: startDate,
        toDate: endDate,
      },
      { onSuccess: setStatistic }
    );
  };

  return (
    <Column>
      <Box sx={{ display: "flex", gap: 2 }}>
        <DateTimeRangeFilter
          startDate={startDate}
          endDate={endDate}
          onDateTimesChange={handleDateTimeChanges}
        />
        <Button
          text="Сформировать статистику"
          sx={{
            py: 1,
            px: 2,
            width: "15rem",
            height: "2rem",
            fontWeight: 700,
            fontSize: "0.875rem",
            lineHeight: 1.5,
          }}
          disabled={!startDate || !endDate}
          onClick={handleRequestStatistic}
        />
      </Box>
      <Divider
        flexItem
        sx={{
          flex: 1,
          alignSelf: "center",
          width: "100%",
          mt: 4,
          mb: 2,
        }}
      />
      <Box
        sx={{
          minHeight: "30rem",
          fontSize: "1.125rem",
          lineHeight: 1.5,
          color: "text.primary",
        }}
      >
        {!statistic && "Выберите период для формирования статистики."}

        {!!statistic && (
          <Box sx={{ display: "flex", flexDirection: "column", rowGap: 3 }}>
            <ViewsStatistic statistic={statistic} />
            <InteractionsStatistic statistic={statistic} />
            <OrdersStatistic statistic={statistic} />
            <ServiceTypesStatistic statistic={statistic} />
            <ActiveOrdersStatistic statistic={statistic} />
          </Box>
        )}
      </Box>
    </Column>
  );
};
