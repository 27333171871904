import { ButtonHTMLAttributes, ReactNode } from "react";
import { Box, styled } from "@mui/material";

export type ButtonVariant =
  | "primary"
  | "secondary"
  | "secondary-inverse"
  | "link"
  | "outlined";

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: ButtonVariant;
  text?: string;
  left?: ReactNode;
  right?: ReactNode;
};

export const Button = styled(
  ({
    variant = "primary",
    text,
    left,
    right,
    children,
    ...otherProps
  }: Props) => {
    return (
      <button {...otherProps}>
        {left}
        <Box component="span" sx={{ flex: 1 }}>
          {text ?? children}
        </Box>
        {right}
      </button>
    );
  }
)(({ theme, variant = "primary" }) => ({
  border: "none",
  margin: 0,
  padding: 0,
  width: "auto",
  overflow: "visible",
  background: "transparent",
  color: "inherit",
  font: "inherit",
  appearance: "none",

  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  columnGap: theme.spacing(1.5),
  fontWeight: 700,
  fontSize: "1rem",
  lineHeight: 1.25,
  textAlign: "center",
  textDecoration: "none",
  cursor: "pointer",

  ...(variant === "primary" && {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1.75, 5),
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    transition: "color .3s,background-color .3s,border-color .3s",

    "&:hover:not(:disabled)": {
      backgroundColor: theme.palette.primary.light,
    },
    "&:disabled": {
      background: "#E8E8E8",
      cursor: "not-allowed",
    },
  }),

  ...(variant === "secondary" && {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1.75, 5),
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    transition: "color .3s,background-color .3s,border-color .3s",

    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
  }),

  ...(variant === "secondary-inverse" && {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1.75, 5),
    color: theme.palette.text.primary,
    backgroundColor: "#f7f7f7",
    transition: "color .3s,background-color .3s,border-color .3s",

    "&:hover": {
      backgroundColor: theme.palette.divider,
    },
  }),

  ...(variant === "link" && {
    padding: theme.spacing(1.5, 0),
    color: theme.palette.text.primary,
  }),

  ...(variant === "outlined" && {
    padding: theme.spacing(1, 3),
    color: theme.palette.text.primary,
    lineHeight: 1.5,
    background: "#fff",
    borderRadius: theme.spacing(1),
    border: "1px solid #E8E8E8",
  }),
}));
