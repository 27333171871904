import {
  MyOrdersList,
  OrdersResponsesFilter,
} from "@features/orders-responses";
import { MyOrdersStatusFilter } from "@features/orders-responses/ui/MyOrdersStatusFilter";
import { navChain } from "@shared/lib/navChain";
import { BaseLayout, Breadcrumbs, Container, PageTitle } from "@shared/ui";
import { Header, Footer } from "@widgets";

export const MyOrdersPage = () => (
  <BaseLayout header={<Header />} footer={<Footer />}>
    <Container>
      <Breadcrumbs items={navChain.myOrders} />
      <PageTitle text="Мои заказы" sx={{ mb: 3 }} />
      <OrdersResponsesFilter
        statusFilter={<MyOrdersStatusFilter />}
        showServiceType
      />
      <MyOrdersList />
    </Container>
  </BaseLayout>
);
