import { Box, styled } from "@mui/material";
import { ReactComponent as DateIcon } from "@shared/assets/datetime.svg";
import { formatDate } from "@shared/lib/utils";

type Props = {
  date: Date;
};

export const DateTime = styled(({ date, ...otherProps }: Props) => {
  const formattedDate = formatDate(date);

  return (
    <Box {...otherProps}>
      <DateIcon />
      <span>{formattedDate}</span>
    </Box>
  );
})(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  columnGap: theme.spacing(1),
  color: theme.palette.text.disabled,
  fontSize: "0.875rem",
  lineHeight: 1.5,
}));
