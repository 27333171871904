import {
  MyBusinessCardsList,
  BusinessListFilters,
  MyBusinessListTitle,
} from "@features/business";
import { navChain } from "@shared/lib/navChain";
import { BaseLayout, Breadcrumbs, Container } from "@shared/ui";
import { Header, Footer } from "@widgets";

export const MyBusinessPage = () => {
  return (
    <BaseLayout header={<Header />} footer={<Footer />}>
      <Container>
        <Breadcrumbs items={navChain.myBusiness} />
        <MyBusinessListTitle />
        <BusinessListFilters />
        <MyBusinessCardsList />
      </Container>
    </BaseLayout>
  );
};
