import Slider from "react-slick";
import { useRef, ReactNode, useState, Children } from "react";
import { CarouselItem } from "./CarouselItem";
import { Box, SxProps, Theme, styled } from "@mui/material";
import { ArrowButton } from "../ArrowButton/ArrowButton";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const StyledSlider = styled(Slider)({});

type Props = {
  className?: string;
  slidesToShow?: number;
  slidesToScroll?: number;
  infinite?: boolean;
  children?: ReactNode;
  buttonsSx?: SxProps<Theme>;
  button?: ReactNode;
};

export const Carousel = ({
  className,
  slidesToShow = 1,
  slidesToScroll = 1,
  infinite = false,
  children,
  buttonsSx = [],
  button,
}: Props) => {
  const ref = useRef<Slider | null>(null);

  const [index, setIndex] = useState(0);

  const hasPrev = infinite || index > 0;

  const hasNext = infinite || index < Children.count(children) - slidesToShow;

  const handlePrev = () => {
    hasPrev && ref.current?.slickPrev();
  };

  const handleNext = () => {
    hasNext && ref.current?.slickNext();
  };

  return (
    <Box
      className={className}
      sx={{
        overflow: "hidden",
        margin: (theme) => theme.spacing(-3),
      }}
    >
      <StyledSlider
        ref={ref}
        slidesToShow={slidesToShow}
        slidesToScroll={slidesToScroll}
        infinite={infinite}
        afterChange={setIndex}
        arrows={false}
      >
        {Children.toArray(children).map((child) => (
          <Box
            key={child.toString()}
            sx={{ padding: (theme) => theme.spacing(3) }}
          >
            {child}
          </Box>
        ))}
      </StyledSlider>
      <Box
        sx={[
          {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: (theme) => theme.spacing(3),
          },
          ...(Array.isArray(buttonsSx) ? buttonsSx : [buttonsSx]),
        ]}
      >
        <Box sx={{ display: "flex", gap: (theme) => theme.spacing(2) }}>
          <ArrowButton
            variant="left"
            disabled={!hasPrev}
            onClick={handlePrev}
          />
          <ArrowButton
            variant="right"
            disabled={!hasNext}
            onClick={handleNext}
          />
        </Box>
        {button}
      </Box>
    </Box>
  );
};

Carousel.Item = CarouselItem;
