import { api } from "@shared/api";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { mapComments } from "../model/orderCardMapper";
import { showErrorMessages } from "@shared/lib/error";
import { ordersResponsesKeys } from "./ordersResponsesKeys";

export const useMyOrderDetails = () => {
  const { id = "" } = useParams();

  const query = useQuery(
    ordersResponsesKeys.myOrder(id),
    () => api.getOrderAnswer(id),
    { onError: showErrorMessages }
  );

  const order = query.data?.offer;
  const comments = mapComments(query.data?.comments ?? []);

  return {
    query,
    answer: query.data,
    order,
    comments,
  };
};
