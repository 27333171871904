import { api } from "@shared/api";
import { showErrorMessages } from "@shared/lib/error";
import { enqueueSnackbar } from "notistack";
import { useMutation } from "react-query";

export const useShareContacts = (offerId: string, answerId: string) => {
  const share = useMutation(() => api.shareContacts(offerId, answerId), {
    onSuccess: () => {
      enqueueSnackbar("Вы успешно поделились контактами", {
        variant: "success",
      });
    },
    onError: showErrorMessages,
  });

  return () => share.mutateAsync();
};
