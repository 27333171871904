import { api } from "@shared/api";
import { showErrorMessages } from "@shared/lib/error";
import { enqueueSnackbar } from "notistack";
import { useMutation } from "react-query";
import { useCloseMyOrder } from "./useCloseMyOrder";

export const useMyOrderActions = (id: string) => {
  const onSuccess = (text: string) => {
    enqueueSnackbar(text, { variant: "success" });
  };
  const show = useMutation(["offers", id, "show"], () => api.showOrder(id), {
    onSuccess: () => onSuccess("Заказ доступен другим пользователям"),
    onError: showErrorMessages,
  });
  const hide = useMutation(["offers", id, "hide"], () => api.hideOrder(id), {
    onSuccess: () => onSuccess("Заказ скрыт от других пользователей"),
    onError: showErrorMessages,
  });
  const close = useCloseMyOrder();

  return {
    show: () => show.mutateAsync(),
    hide: () => hide.mutateAsync(),
    close: (id: string, onClose: () => Promise<void>) =>
      close(id).then(onClose),
  };
};
