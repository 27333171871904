import { api } from "@shared/api";
import { showErrorMessages } from "@shared/lib/error";
import { enqueueSnackbar } from "notistack";
import { useMutation } from "react-query";
import { useSendReview } from "./useSendReview";
import { useShareContacts } from "./useShareContacts";
import { CreateReview } from "@shared/types/ordersResponses";

export const useMyOrderDetailsActions = (
  offerId: string,
  answerId: string,
  refetch: () => void
) => {
  const handleSuccess = (text: string) => {
    enqueueSnackbar(text, { variant: "success" });
  };

  const accept = useMutation(() => api.acceptOrderAnswer(offerId, answerId), {
    onError: showErrorMessages,
  });

  const complete = useMutation(
    () => api.completeOrderAnswer(offerId, answerId),
    {
      onError: showErrorMessages,
    }
  );

  const decline = useMutation(() => api.declineOrderAnswer(offerId, answerId), {
    onSuccess: () => handleSuccess("Поступивший отклик отклонен"),
    onError: showErrorMessages,
  });

  const sendReview = useSendReview(offerId, answerId);

  const shareContacts = useShareContacts(offerId, answerId);

  return {
    handleAccept: () => accept.mutateAsync().then(refetch),
    handleDecline: () => decline.mutateAsync().then(refetch),
    handleComplete: () => complete.mutateAsync().then(refetch),
    sendReview: (review: CreateReview) => sendReview(review).then(refetch),
    shareContacts: () => shareContacts().then(refetch),
  };
};
