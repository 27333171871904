import {
  BusinessServiceCard,
  useBusinessCardById,
  useCreateBusinessCard,
  useUpdateBusinessCard,
} from "@entities/business";
import { useServiceTypes } from "@entities/dictionary";
import { AddServiceModal } from "@features/service-order";
import { CreateBusinessCardForm } from "@features/service-order/ui/CreateBusinessCardForm";
import { Box, styled } from "@mui/material";
import { useBoolean } from "@shared/hooks";
import { CreateBusinessCard } from "@shared/types/business";
import { Dictionary } from "@shared/types/dictionary";
import { CreateService } from "@shared/types/service";
import { BaseLayout, Button, Container, PageTitle } from "@shared/ui";
import { Header, Footer } from "@widgets";
import { BaseSyntheticEvent, useEffect, useId, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

type TabProps = {
  active?: boolean;
};

const Tab = styled(Button)<TabProps>(({ active }) => ({
  cursor: "default",
  outline: "none",
  backgroundColor: active ? "#FCFCCF" : undefined,

  "&:hover": {
    cursor: "pointer",
    outline: "none",
  },
}));

enum Tabs {
  CARD = 1,
  SERVICE,
}

export const CreateBusinessCardPage = () => {
  const formId = useId();
  const submitButtonId = useId();

  const navigate = useNavigate();
  const { id } = useParams();

  const { data: defaultValue, isSuccess } = useBusinessCardById(id);

  const back = () => navigate(-1);

  const createBusinessCard = useCreateBusinessCard();
  const updateBusinessCard = useUpdateBusinessCard();

  const { data: serviceTypeOptions = [] } = useServiceTypes();

  const flatOptions = serviceTypeOptions.reduce((acc, current) => {
    return current.children
      ? [...acc, current, ...current.children]
      : [...acc, current];
  }, [] as (Dictionary | NonNullable<Dictionary["children"]>[number])[]);

  const [tab, setTab] = useState(Tabs.CARD);

  const handleTabClick = (t: Tabs) => () => setTab(t);

  const prevTab = () => setTab((prev) => prev - 1);

  const nextTab = () => setTab((prev) => prev + 1);

  const [serviceIndex, setServiceIndex] = useState<number | null>(null);
  const [services, setServices] = useState<CreateService[]>([]);
  const [createServiceIsOpen, createServiceModal] = useBoolean();
  const [updatedServices, setUpdatedServices] = useState<string[]>([])

  const handleCloseService = () => {
    setServiceIndex(null);
    createServiceModal.off();
  };

  const handleRemoveService = (index: number) => () => {
    setServices((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)]);
  };

  const handleEditService = (index: number) => () => {
    setServiceIndex(index);
    createServiceModal.on();
  };

  const handleSubmitService = (data: CreateService, id?: string) => {
    setServices((prev) =>
      serviceIndex === null
        ? [...prev, data]
        : [
            ...prev.slice(0, serviceIndex),
            data,
            ...prev.slice(serviceIndex + 1),
          ]
    );
    createServiceModal.off();
    id && setUpdatedServices(prevState => [...prevState, id])
  };

  const handleSubmitBusinessCard = (
    data: CreateBusinessCard,
    event?: BaseSyntheticEvent
  ) => {
    const submitButton = (event?.nativeEvent as any)?.submitter;

    if (submitButton?.id !== submitButtonId) {
      return nextTab();
    }

    const card = { ...data, services: services.map(({ createdDateTime,...service}) => {
        return {
          ...service,
          updateDateTime: service?.id && updatedServices.includes(service.id) ? new Date().valueOf() : undefined
        }
      })
    };

    id
      ? updateBusinessCard.mutateAsync({ card }, { onSuccess: back })
      : createBusinessCard.mutateAsync({ card }, { onSuccess: back });
  };

  const renderService = (service: CreateService, index: number) => {
    const serviceType = flatOptions.find(
      (option) => option.id === service.serviceType
    );

    const serviceTypeName = serviceType?.text?.ru || "";

    return (
      <BusinessServiceCard
        key={index}
        name={service.name.ru}
        description={service.description.ru}
        serviceType={serviceTypeName}
        price={service.price ? parseInt(service.price) : undefined}
        priceUnits={service.priceUnits}
        onEdit={handleEditService(index)}
        onRemove={handleRemoveService(index)}
      />
    );
  };

  useEffect(() => {
    isSuccess &&
      setServices(
        (defaultValue.services ?? []).map((service) => ({
          ...service,
          serviceType: service.serviceType.code,
        }))
      );
  }, [defaultValue?.services, isSuccess]);

  return (
    <BaseLayout header={<Header />} footer={<Footer />}>
      <Container>
        {(isSuccess || !id) && (
          <>
            <PageTitle
              text={
                id
                  ? "Редактирование карточки бизнеса"
                  : "Новая карточка бизнеса"
              }
              sx={{ mb: 3 }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 2,
                mb: 3.5,
              }}
            >
              <Tab
                active={tab === Tabs.CARD}
                variant="outlined"
                text="Общая информация"
                onClick={handleTabClick(Tabs.CARD)}
              />
              <Tab
                active={tab === Tabs.SERVICE}
                variant="outlined"
                text="Оказываемые услуги"
                onClick={handleTabClick(Tabs.SERVICE)}
              />
              {tab === Tabs.SERVICE && (
                <Button
                  text="Добавить услугу"
                  sx={{ ml: "auto" }}
                  onClick={createServiceModal.on}
                />
              )}
            </Box>

            <Box>
              <Box hidden={tab !== Tabs.CARD}>
                <CreateBusinessCardForm
                  isEdit={!!id}
                  id={formId}
                  innerSx={{ border: "1px solid", borderColor: "divider" }}
                  data={defaultValue}
                  cancelButtonText="Отменить"
                  withGallery
                  onCancel={back}
                  onSubmit={handleSubmitBusinessCard}
                />
              </Box>

              <Box hidden={tab !== Tabs.SERVICE}>
                {!services.length && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      rowGap: 2,
                      border: "1px solid",
                      borderColor: "divider",
                      height: "13.625rem",
                    }}
                  >
                    <Box
                      sx={{
                        fontWeight: 700,
                        fontSize: "1.5rem",
                        color: "text.primary",
                        lineHeight: 1.35,
                      }}
                    >
                      Внесите информацию об оказываемых услугах
                    </Box>
                    <Box
                      sx={{
                        fontWeight: 400,
                        fontSize: "1rem",
                        color: "text.primary",
                        lineHeight: 1.5,
                      }}
                    >
                      Описание предоставляемых Вашей компанией услуг помогут
                      найти потенциальных партнеров в Маркетплейсе
                    </Box>
                  </Box>
                )}

                {!!services.length && services.map(renderService)}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    columnGap: 3.75,
                    p: [8, 3],
                  }}
                >
                  <Button
                    sx={{ minWidth: "15rem" }}
                    type="button"
                    variant="secondary-inverse"
                    onClick={prevTab}
                    text="Отменить"
                  />
                  <Button
                    sx={{ minWidth: "15rem" }}
                    id={submitButtonId}
                    form={formId}
                    type="submit"
                    variant="primary"
                    text="Опубликовать"
                  />
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Container>
      <AddServiceModal
        title={
          services[serviceIndex ?? -1]
            ? "Редактирование услуги"
            : "Новая услуга"
        }
        data={services[serviceIndex ?? -1]}
        open={createServiceIsOpen}
        onClose={handleCloseService}
        onSubmit={handleSubmitService}
      />
    </BaseLayout>
  );
};
