import qs from "qs";
import { Box, SxProps, Theme } from "@mui/material";
import { PriceType, PriceTypes, PriceTypesOptions } from "@shared/lib/price";
import {
  Autocomplete,
  Button,
  FieldError,
  Input,
  RadioSelect,
  RequiredFieldsError,
  Textarea,
} from "@shared/ui";
import { Controller, useForm } from "react-hook-form";
import { array, number, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useServiceTypes } from "@entities/dictionary";
import {
  useBusinessCardCount,
  useUserPublishedBusinessCards,
} from "@entities/business";
import { useEffect, useMemo } from "react";
import { CreateServiceOrder } from "@shared/types/service";
import { useService } from "@entities/service";
import { Link } from "react-router-dom";
import { Paths } from "@shared/lib/router";
import { useAppSelector } from "@app/store";
import { userModel } from "@entities/user";

export type FormData = {
  title: string;
  businessCardId: string;
  serviceTypes: string[];
  content: string;
  price?: string;
  priceUnits?: PriceType;
  receiverBusinessCardId?: string;
  receiverBusinessCardName?: string;
};

const convertToFormData = (
  data: Partial<CreateServiceOrder>
): Partial<FormData> => ({
  ...data,
  title: data.title?.ru,
  content: data.content?.ru,
});

const convertToEntity = ({
  receiverBusinessCardName,
  ...data
}: FormData): CreateServiceOrder => ({
  ...data,
  title: { ru: data.title },
  content: { ru: data.content },
});

const validationSchema = object({
  title: string()
    .optional()
    .nullable()
    .required("Обязательное поле")
    .max(250, "Максимум 250 символов"),
  businessCardId: string().optional().nullable().required("Обязательное поле"),
  serviceTypes: array(string())
    .optional()
    .nullable()
    .required("Обязательное поле"),
  content: string()
    .optional()
    .nullable()
    .required("Обязательное поле")
    .max(4000, "Максимум 4000 символов"),
  price: number()
    .typeError("Стоимость должна быть числом")
    .optional()
    .nullable()
    .positive("Должно быть положительным числом")
    .transform((_, val) => (val === "" ? null : Number(val))),
  priceUnits: string().optional().nullable(),
});

type Props = {
  sx?: SxProps<Theme>;
  className?: string;
  currentBusinessCard?: string;
  currentService?: string;
  data?: Partial<CreateServiceOrder>;
  cancelButtonText?: string;
  submitButtonText?: string;
  onSubmit?: (data: CreateServiceOrder) => void;
  onCancel?: () => void;
};

export const MakeOrderForm = ({
  sx = [],
  className,
  currentBusinessCard,
  currentService,
  data,
  cancelButtonText = "Назад",
  submitButtonText = "Опубликовать",
  onSubmit,
  onCancel,
}: Props) => {
  const isSignedIn = useAppSelector(userModel.isSignedInSelector);

  const { data: serviceTypesOptions = [] } = useServiceTypes();
  const { data: businessCards = [] } = useUserPublishedBusinessCards();
  const { data: service } = useService(currentService);

  const mainBusinessCard = useMemo(
    () => businessCards.find((card) => !!card.mainCard),
    [businessCards]
  )?.id;

  const businessCardOptions = useMemo(
    () =>
      businessCards.map((type) => ({
        id: type.id,
        text: type.name,
        disabled: false,
      })),
    [businessCards]
  );

  const defaultBusinessCard = currentBusinessCard || mainBusinessCard;

  const { control, formState, watch, handleSubmit, setValue } =
    useForm<FormData>({
      defaultValues: data && convertToFormData(data),
      resolver: yupResolver(validationSchema),
    });

  const serviceTypes = watch("serviceTypes");

  const { data: businessCardCount } = useBusinessCardCount(
    serviceTypes ? { services: serviceTypes } : {}
  );

  const hasRequiredErrors = Object.values(formState.errors).some(
    (error) => error?.type === "required" || error?.type === "optionality"
  );

  const submit = (data: FormData) => {
    onSubmit?.(convertToEntity(data));
  };

  useEffect(() => {
    if (!service) return;
    setValue("receiverBusinessCardId", service.businessCard.id);
    setValue("receiverBusinessCardName", service.businessCard.name.ru);
    setValue("serviceTypes", [service.businessService.serviceType.code]);
  }, [service, setValue]);

  useEffect(() => {
    const isEmpty = !watch("businessCardId");
    isEmpty &&
      defaultBusinessCard &&
      setValue("businessCardId", defaultBusinessCard);
  }, [defaultBusinessCard, businessCardOptions, setValue, watch]);

  return (
    <Box
      component="form"
      className={className}
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      onSubmit={handleSubmit(submit)}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: (theme) => theme.spacing(1.75),
          p: (theme) => theme.spacing(1, 3, 3),
        }}
      >
        {hasRequiredErrors && <RequiredFieldsError />}
        {!!currentService && (
          <Controller
            control={control}
            name="receiverBusinessCardName"
            render={({ field, fieldState }) => (
              <FieldError
                error={fieldState.error?.message}
                hideError={
                  fieldState.error?.type === "required" ||
                  fieldState.error?.type === "optionality"
                }
              >
                <Input
                  id="receiverBusinessCardName"
                  label="Компания, которая оказывает выбранную услугу"
                  placeholder="Компания"
                  value={field.value ?? ""}
                  disabled
                  onChange={field.onChange}
                />
              </FieldError>
            )}
          />
        )}
        <Controller
          control={control}
          name="title"
          render={({ field, fieldState }) => (
            <FieldError
              error={fieldState.error?.message}
              hideError={
                fieldState.error?.type === "required" ||
                fieldState.error?.type === "optionality"
              }
            >
              <Input
                id="title"
                label="Какая услуга вам нужна (например, Поставка ПО для разработки этикеток)"
                placeholder="Тема"
                value={field.value ?? ""}
                required
                onChange={field.onChange}
              />
            </FieldError>
          )}
        />
        <Controller
          defaultValue={defaultBusinessCard}
          control={control}
          name="businessCardId"
          render={({ field, fieldState }) => (
            <FieldError
              error={fieldState.error?.message}
              hideError={
                fieldState.error?.type === "nullable" ||
                fieldState.error?.type === "required" ||
                fieldState.error?.type === "optionality"
              }
            >
              <div hidden>{JSON.stringify(fieldState) ?? ""}</div>
              <Autocomplete
                label="Ваша компания"
                placeholder="Ваша компания"
                options={businessCardOptions}
                required
                value={field.value ?? null}
                onChange={field.onChange}
              />
            </FieldError>
          )}
        />
        <Controller
          control={control}
          name="serviceTypes"
          render={({ field, fieldState }) => (
            <FieldError
              error={fieldState.error?.message}
              hideError={
                fieldState.error?.type === "required" ||
                fieldState.error?.type === "optionality"
              }
            >
              <Autocomplete
                label="Какие типы услуг Вам нужны (например, Поставка ПО )"
                placeholder="Тип услуг"
                options={serviceTypesOptions}
                multiple
                required
                disabled={!!currentService}
                value={field.value ?? []}
                onChange={field.onChange}
              />
            </FieldError>
          )}
        />
        <Controller
          control={control}
          name="content"
          render={({ field, fieldState }) => (
            <FieldError
              error={fieldState.error?.message}
              hideError={
                fieldState.error?.type === "required" ||
                fieldState.error?.type === "optionality"
              }
            >
              <Textarea
                label="Детальное описание Вашего заказа"
                placeholder="Описание"
                value={field.value ?? ""}
                required
                onChange={field.onChange}
              />
            </FieldError>
          )}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: (theme) => theme.spacing(0.5),
          }}
        >
          <Box
            sx={{
              fontWeight: 700,
              fontSize: "1rem",
              lineHeight: 1.5,
              color: (theme) => theme.palette.text.primary,
            }}
          >
            Стоимость
          </Box>
          <FieldError
            error={
              (formState.errors.price?.type !== "required" &&
              formState.errors.price?.type !== "optionality"
                ? formState.errors.price?.message
                : "") ||
              (formState.errors.priceUnits?.type !== "required" &&
              formState.errors.priceUnits?.type !== "optionality"
                ? formState.errors.priceUnits?.message
                : "")
            }
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: (theme) => theme.spacing(3),
              }}
            >
              <Controller
                control={control}
                name="price"
                render={({ field, fieldState }) => (
                  <Input
                    sx={{ width: "28.8125rem" }}
                    placeholder="Стоимость"
                    value={field.value ?? ""}
                    type="number"
                    step="1"
                    endAdornment={
                      <Box
                        sx={{
                          fontWeight: 700,
                          fontSize: "1rem",
                          lineHeight: 1.5,
                          color: "common.black",
                        }}
                      >
                        ₽
                      </Box>
                    }
                    onChange={field.onChange}
                  />
                )}
              />
              <Controller
                control={control}
                name="priceUnits"
                defaultValue={PriceType.SERVICE}
                render={({ field }) => (
                  <RadioSelect
                    sx={{ flex: "0 0 auto", width: "11.25rem" }}
                    items={PriceTypesOptions}
                    selectedItem={PriceTypes[field.value!]}
                    onChange={(_, id) => field.onChange(id)}
                  />
                )}
              />
              <Box
                sx={{
                  width: "19.5rem",
                  fontWeight: 400,
                  fontSize: "0.875rem",
                  lineHeight: 1.4,
                  color: (theme) => theme.palette.text.disabled,
                }}
              >
                Стоимость услуги будет доступна для просмотра в Маркетплейсе для
                потребителей услуг
              </Box>
            </Box>
          </FieldError>
        </Box>
      </Box>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          columnGap: (theme) => theme.spacing(3.75),
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          p: (theme) => theme.spacing(8, 3),
        }}
      >
        {isSignedIn && !!serviceTypes && !!serviceTypes.length && (
          <Box
            sx={{
              position: "absolute",
              top: (theme) => theme.spacing(1),
              right: (theme) => theme.spacing(3),
              fontWeight: 400,
              fontSize: "1rem",
              lineHeight: 1.25,
              color: "text.primary",
            }}
          >
            Компании, оказывающие такой тип услуги:{" "}
            <Link
              style={{
                font: "inherit",
                lineHeight: "inherit",
                color: "#2386E0",
                textDecoration: "none",
              }}
              to={{
                pathname: Paths.Business,
                search: qs.stringify(
                  { services: serviceTypes },
                  { arrayFormat: "repeat" }
                ),
              }}
            >
              {businessCardCount ?? 0}
            </Link>
          </Box>
        )}
        <Button
          sx={{ minWidth: "15rem" }}
          type="button"
          variant="secondary-inverse"
          onClick={onCancel}
        >
          {cancelButtonText}
        </Button>
        <Button sx={{ minWidth: "15rem" }} type="submit" variant="primary">
          {submitButtonText}
        </Button>
      </Box>
    </Box>
  );
};
