import { styled, Skeleton } from "@mui/material";

const SkeletonCard = styled(Skeleton)(({ theme }) => ({
  display: "flex",
  flex: "0 0 auto",
  background: "#f7f7f7",
  borderRadius: theme.spacing(2),
  gap: theme.spacing(2),
  padding: theme.spacing(2.5, 2, 3, 2),
  minWidth: "24rem",
  height: "13.5rem",
  "&:hover": {
    filter: " drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.3))",
  },
  transform: "none"
}));

export const SliderCardSkeleton = () => {
  return <SkeletonCard />
}
