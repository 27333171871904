import { Box } from "@mui/material";
import { Column } from "@shared/ui";

type TextRowProps = {
  title: string;
  value: string;
};

const TextRow = ({ title, value }: TextRowProps) => {
  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <Box sx={{ color: "#a9a9a9", whiteSpace: "nowrap" }}>{title}:</Box>
      {value}
    </Box>
  );
};

type Props = {
  type?: string;
  target?: TextRowProps;
  title: string;
  text: string;
  moveTitle?: boolean;
};

export const OrdersShortDetails = ({
  type,
  target,
  title,
  text,
  moveTitle,
}: Props) => {
  return (
    <Column
      sx={{
        gap: 1,
        lineHeight: 1.5,
        maxWidth: "47rem",
        color: "text.primary",
        minWidth: 0,
      }}
    >
      <Box
        sx={{
          fontSize: "1.25rem",
          lineHeight: 1.35,
          fontWeight: 700,
          order: moveTitle ? 2 : undefined,
        }}
        children={title}
      />
      {!!target && <TextRow {...target} />}
      {type && <TextRow title="Тип услуги" value={type} />}
      <Box sx={{ order: 3 }}>{text}</Box>
    </Column>
  );
};
