import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { Button, Column, FieldError, Modal, Textarea } from "@shared/ui";
import { Controller, useForm } from "react-hook-form";
import { object, string } from "yup";

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: (reason: string) => void;
};

type FormData = {
  reason: string;
};

const validationSchema = object({
  reason: string()
    .optional()
    .nullable()
    .required("Обязательное поле")
    .max(4000, "Максимум 4000 символов"),
});

export const DeclineIncomingOrderModal = ({
  open,
  onConfirm,
  onClose,
}: Props) => {
  const { control, handleSubmit } = useForm<FormData>({
    defaultValues: { reason: "" },
    resolver: yupResolver(validationSchema),
  });

  const submitForm = handleSubmit((data: FormData) => onConfirm(data.reason));

  return (
    <Modal
      header="Отклонить входящий заказ"
      width="32rem"
      open={open}
      onClose={onClose}
      headerSx={{ fontSize: "1rem" }}
    >
      <Box
        component="form"
        sx={{ px: 3, py: 4, color: "#4B4B4B" }}
        onSubmit={submitForm}
      >
        <Column>
          <Controller
            control={control}
            name="reason"
            render={({ field, fieldState }) => (
              <FieldError
                error={fieldState.error?.message}
                hideError={
                  fieldState.error?.type === "required" ||
                  fieldState.error?.type === "optionality"
                }
              >
                <Textarea
                  label="Причина отклонения"
                  placeholder="Укажите причину отклонения входящего заказа"
                  value={field.value}
                  onChange={field.onChange}
                  sx={{ mb: 4 }}
                  required
                />
              </FieldError>
            )}
          />
          <Button
            text="Подтвердить"
            type="submit"
            sx={{ width: "8.2rem", alignSelf: "center" }}
          />
        </Column>
      </Box>
    </Modal>
  );
};
