import { Box } from "@mui/material";

type Props = {
  title: string;
  value: string;
};

export const ResponseShortDetailsItem = ({ title, value }: Props) => {
  return (
    <Box
      sx={{
        fontWeight: 700,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      }}
    >
      <Box
        sx={{
          display: "inline",
          color: "text.disabled",
          fontWeight: 400,
          mr: 0.5,
        }}
      >
        {title}:{" "}
      </Box>
      {value}
    </Box>
  );
};
