import { useState } from "react";
import { ReactComponent as ArrowDownIcon } from "@shared/assets/small-arrow-right.svg";
import { Popover } from "@mui/material";
import { ClockPicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Button } from "@shared/ui";
import moment from "moment";

type Props = {
  date: Date | null;
  maxTime?: Date | null;
  minTime?: Date | null;
  onChange?: (date: Date | null) => void;
};

export const TimePicker = ({ date, maxTime, minTime, onChange }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const handleChange = (value: Date | null) => onChange?.(value);

  const id = Boolean(anchorEl) ? "time-picker-popover" : undefined;

  const time = date ? moment(date).format("HH:mm") : "";

  return (
    <>
      <Button
        text={time}
        onClick={handleOpen}
        variant="secondary-inverse"
        right={<ArrowDownIcon />}
        sx={{ p: 2, lineHeight: 1.5 }}
      />
      <Popover
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ClockPicker
            date={date}
            maxTime={maxTime ?? undefined}
            minTime={minTime ?? undefined}
            onChange={handleChange}
          />
        </LocalizationProvider>
      </Popover>
    </>
  );
};
