import { SmallCard } from "@entities/my-marketplace";
import CreateBusinessCardImage from "@shared/assets/create-business-card.png";
import { Paths } from "@shared/lib/router";
import { Mask } from "@shared/ui";
import { useNavigate } from "react-router-dom";

export const CreateBusinessCard = () => {
  const navigate = useNavigate();

  const handleClick = () => navigate(Paths.CreateBusinessCard);

  const mask = (
    <Mask
      beforeStyles={{
        width: "17.5rem",
        height: "17.5rem",
        bgcolor: "#8bffff",
        transform: "translate(60%, -50%)",
      }}
    />
  );

  return (
    <SmallCard
      mask={mask}
      title="Сформируйте и опубликуйте портфолио своего бизнеса"
      buttonText="Создать карточку бизнеса"
      imageSrc={CreateBusinessCardImage}
      onClick={handleClick}
    />
  );
};
