import { client } from "@shared/lib/client";
import { Attachment, AttachmentType } from "@shared/types/attachment";

export const uploadAttachment = async (file: File, type: AttachmentType) => {
  const formData = new FormData();
  formData.append("file", file);
  const response = await client.post<Attachment>(
    `/attachments?type=${type}`,
    formData
  );
  return response.data;
};
