import { ReactComponent as FilterIcon } from "@shared/assets/filter.svg";
import { Box, Popover, styled } from "@mui/material";
import { MouseEvent, ReactNode, useState } from "react";

const Button = styled("button")(({ theme }) => ({
  margin: 0,
  border: "none",
  padding: theme.spacing(0.5),
  width: "auto",
  background: "transparent",
  font: "inherit",
  display: "inline-flex",
  alignItems: "center",
  columnGap: theme.spacing(0.5),
  fontSize: "0.875rem",
  lineHeight: 1.5,
  color: theme.palette.text.disabled,
  cursor: "pointer",
}));

type Props = {
  children?: ReactNode;
  popupMaxWidth?: string;
  popupMaxHeight?: string;
};

export const FilterButton = ({
  children,
  popupMaxWidth,
  popupMaxHeight,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);

  return (
    <>
      <Button onClick={handleOpen}>
        <FilterIcon /> Фильтр
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            maxHeight: popupMaxHeight ?? "22.5rem",
            maxWidth: popupMaxWidth ?? "16.875rem",
            overflow: "auto",
          },
        }}
        onClose={handleClose}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: 2,
            p: 1.5,
          }}
        >
          {children}
        </Box>
      </Popover>
    </>
  );
};
