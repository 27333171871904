import { BusinessCardStats, ServicesStats } from "@shared/types/statistic";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Box,
  Collapse,
} from "@mui/material";
import { useBoolean } from "@shared/hooks";
import { ReactComponent as Arrow } from "@shared/assets/small-arrow-right.svg";
import {
  BodyTableCeil,
  BodyTableRow,
  HeadTableCeil,
  HeadTableRow,
} from "./table";
import { format } from "date-fns";

const Row = ({ item }: { item: ServicesStats }) => {
  const [isChildrenOpen, childrenRows] = useBoolean();

  return (
    <>
      <BodyTableRow
        onClick={item.children?.length ? childrenRows.toggle : undefined}
      >
        <BodyTableCeil
          type="category"
          withArrow={!!item.children?.length}
          open={isChildrenOpen}
        >
          {item.serviceType.ru}
        </BodyTableCeil>
        <BodyTableCeil align="center">{item.all}</BodyTableCeil>
        <BodyTableCeil align="center">{item.published}</BodyTableCeil>
        <BodyTableCeil align="center">{item.completed}</BodyTableCeil>
        <BodyTableCeil align="center">{item.canceled}</BodyTableCeil>
        <BodyTableCeil align="center">{item.declined}</BodyTableCeil>
      </BodyTableRow>
      {item.children?.map((child) => (
        <BodyTableRow
          key={child.serviceCode}
          type="subcategory"
          open={isChildrenOpen}
        >
          <BodyTableCeil sx={{ pl: 6.5 }}>{child.serviceType.ru}</BodyTableCeil>
          <BodyTableCeil align="center">{child.all}</BodyTableCeil>
          <BodyTableCeil align="center">{child.published}</BodyTableCeil>
          <BodyTableCeil align="center">{child.completed}</BodyTableCeil>
          <BodyTableCeil align="center">{child.canceled}</BodyTableCeil>
          <BodyTableCeil align="center">{child.declined}</BodyTableCeil>
        </BodyTableRow>
      ))}
    </>
  );
};

type Props = {
  statistic: BusinessCardStats;
};

export const ServiceTypesStatistic = ({ statistic }: Props) => {
  const [isTableOpen, table] = useBoolean(false);

  const serviceTables = [
    {
      firstTitle: "Типы услуг",
      secondTitle: "Исходящие заказы",
      items: statistic.offerServices.outgoingOffers,
    },
    {
      firstTitle: "Типы услуг",
      secondTitle: "Поступившие частные заказы",
      items: statistic.offerServices.incomingOffers,
    },
  ];

  return (
    <Box>
      <Box
        sx={{
          fontWeight: 400,
          fontSize: "1.125rem",
          lineHeight: 1.5,
          color: "#63666A",
          "&:hover": { cursor: "pointer" },
        }}
        onClick={table.toggle}
      >
        <Arrow
          style={{
            display: "inline-block",
            marginRight: 16,
            fill: "#63666A",
            transform: isTableOpen ? "rotate(270deg)" : "rotate(90deg)",
          }}
        />
        Статистика по типам услуг заказов карточки бизнеса{" "}
        {statistic.businessCard.name.ru} за период{" "}
        <Box component="span" sx={{ fontWeight: "700", color: "#363634" }}>
          {statistic.fromDate &&
            format(new Date(statistic.fromDate), "dd.MM.yyyy")}
          {"-"}
          {statistic.toDate && format(new Date(statistic.toDate), "dd.MM.yyyy")}
        </Box>
      </Box>
      <Collapse in={isTableOpen}>
        <Box sx={{ display: "flex", flexDirection: "column", rowGap: 3 }}>
          {serviceTables.map((table, index) => {
            return (
              <TableContainer key={`answer-table-${index}`}>
                <Table>
                  <TableHead>
                    <HeadTableRow>
                      <HeadTableCeil>{table.firstTitle}</HeadTableCeil>
                      <HeadTableCeil colSpan={5} align="center">
                        {table.secondTitle}
                      </HeadTableCeil>
                    </HeadTableRow>
                    <HeadTableRow>
                      <HeadTableCeil width="25%" />
                      <HeadTableCeil width="15%" align="center">
                        Всего заказов
                      </HeadTableCeil>
                      <HeadTableCeil width="15%" align="center">
                        Опубликован
                      </HeadTableCeil>
                      <HeadTableCeil width="15%" align="center">
                        Завершен
                      </HeadTableCeil>
                      <HeadTableCeil width="15%" align="center">
                        Закрыт
                      </HeadTableCeil>
                      <HeadTableCeil width="15%" align="center">
                        Отклонен
                      </HeadTableCeil>
                    </HeadTableRow>
                  </TableHead>
                  <TableBody>
                    {table.items.map((item) => (
                      <Row key={item.serviceCode} item={item} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            );
          })}
        </Box>
      </Collapse>
    </Box>
  );
};
