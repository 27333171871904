import { Box, Grid } from "@mui/material";
import { ViewBusinessCardBlockTitle } from "./ViewBusinessCardBlockTitle";
import {
  ViewBusinessCardContacts,
  ViewBusinessCardWebsite,
} from "./ViewBusinessCardContacts";
import { IncludedFiles } from "./IncludedFiles";
import { BusinessCardContactPerson } from "../types";
import { Column, FileData } from "@shared/ui";

type Props = {
  services: string[];
  description: string;
  files: FileData[];
  contactPerson: BusinessCardContactPerson;
  showContacts: boolean;
};

export const ViewBusinessCardGeneralInfo = ({
  services,
  description,
  files,
  contactPerson,
  showContacts,
}: Props) => {
  return (
    <Grid
      container
      columnSpacing={3}
      rowSpacing={2}
      sx={{ lineHeight: 1.5, color: "text.primary" }}
    >
      <Grid item xs={7}>
        <ViewBusinessCardBlockTitle text="О Компании" />
        <Box sx={{ fontWeight: 700, mb: 1.75 }}>{services.join(", ")}</Box>
        <Box>{description}</Box>
      </Grid>
      <Grid item xs={5}>
        <Column sx={{ gap: 1, fontSize: "1.125rem" }}>
          <ViewBusinessCardBlockTitle text="Контактное лицо" />
          {!!showContacts && <ViewBusinessCardContacts {...contactPerson} />}
          {!!contactPerson.website?.length && (
            <ViewBusinessCardWebsite website={contactPerson.website} />
          )}
        </Column>
      </Grid>
      <Grid item xs={12}>
        <IncludedFiles files={files} />
      </Grid>
    </Grid>
  );
};
