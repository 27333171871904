import { Box, Popover, SxProps, Theme } from "@mui/material";
import { MouseEvent, useState } from "react";
import { SelectValue } from "./SelectValue";
import { RenderSelectItemOptions, SelectItem as SelectItemType } from "./types";
import { SelectItem } from "./SelectItem";
import { Column } from "../Column";

export type SelectProps = {
  sx?: SxProps<Theme>;
  dropdownSx?: SxProps<Theme>;
  initValue?: string;
  id?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  selectedItem?: string;
  selectedItemId?: string;
  onChange?: (value: string, id: string) => void;
  items: SelectItemType[];
  renderItem?: (options: RenderSelectItemOptions) => JSX.Element;
};

export const Select = ({
  sx,
  dropdownSx,
  id,
  label,
  placeholder,
  onChange,
  required,
  selectedItem,
  selectedItemId,
  items,
  renderItem,
}: SelectProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const handleItemClick = (id: string, value: string) => {
    onChange?.(value, id);
    handleClose();
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Column sx={{ gap: (theme) => theme.spacing(0.25) }}>
        {label && (
          <Box
            component="label"
            htmlFor={id}
            sx={{
              fontSize: ".875rem",
              lineHeight: 1.5,
              color: required ? "red" : "text.disabled",
            }}
          >
            {required ? `${label} *` : label}
          </Box>
        )}
        <SelectValue
          value={selectedItem}
          placeholder={placeholder}
          sx={sx}
          expanded={open}
          onClick={handleOpen}
        />
      </Column>
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: "left",
        }}
        PaperProps={{
          sx: [
            {
              maxHeight: "22.5rem",
              maxWidth: "16.875rem",
              boxShadow:
                "0 0.5rem 1rem rgba(0,0,0,.1), 0 0.5rem 3rem rgba(0,0,0,.2)",
            },
            ...(Array.isArray(dropdownSx) ? dropdownSx : [dropdownSx]),
          ],
        }}
        onClose={handleClose}
      >
        <Column sx={{ py: 2, minWidth: (theme) => theme.spacing(30) }}>
          {items.map((item) =>
            renderItem ? (
              renderItem({
                selected:
                  item.value === selectedItem || item.value === selectedItemId,
                onClick: handleItemClick,
                ...item,
              })
            ) : (
              <SelectItem
                key={item.id}
                id={item.id}
                value={item.value}
                onClick={handleItemClick}
                selected={
                  item.value === selectedItem || item.value === selectedItemId
                }
              />
            )
          )}
        </Column>
      </Popover>
    </>
  );
};
