import { useQuery } from "react-query";
import { serviceKeys } from "./serviceKeys";
import { api } from "@shared/api";
import { showErrorMessages } from "@shared/lib/error";

export const useService = (id: string = "") =>
  useQuery(serviceKeys.service(id), () => api.getService(id), {
    enabled: !!id,
    onError: showErrorMessages,
  });
