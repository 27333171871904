import { Divider, styled } from "@mui/material";

type Props = { text: string | JSX.Element };

export const ViewBusinessCardBlockTitle = styled(
  ({ text, ...otherProps }: Props) => {
    return (
      <div {...otherProps}>
        {text}
        <Divider flexItem sx={{ flex: 1, alignSelf: "center" }} />
      </div>
    );
  }
)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  marginBottom: theme.spacing(1.5),
  fontSize: "1.125rem",
  lineHeight: 1.5,
  color: theme.palette.text.disabled,
}));
