import { Box, styled } from "@mui/material";
import { ReactComponent as ArrowRight } from "@shared/assets/arrow-right.svg";
import { ReactNode } from "react";
import { Button } from "@shared/ui";
import { DateTime } from "@shared/ui";
import { Column } from "../Column";
import { User, UserData } from "../User";
import { useNavigate } from "react-router-dom";

const Header = styled("div")({
  fontSize: "1.25rem",
  lineHeight: 1.35,
  fontWeight: 700,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
});
const Text = styled("div")({
  lineHeight: 1.5,
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
});

export type SliderCardProps = {
  header: string;
  date?: Date;
  services: string[];
  text: string;
  tag?: ReactNode;
  isCompact?: boolean;
  linkUrl?: string;
} & UserData;

export const SliderCard = styled(
  ({
    avatarSrc,
    rating,
    reviewsCount,
    header,
    date,
    services,
    text,
    tag,
    isCompact = true,
    linkUrl,
    ...otherProps
  }: SliderCardProps) => {
    const navigate = useNavigate();
    const handleClick = linkUrl ? () => navigate(linkUrl) : undefined;

    return (
      <div {...otherProps}>
        <Column sx={{ gap: 2, flex: "0 0 auto" }}>
          <User
            avatarSrc={avatarSrc}
            rating={rating}
            reviewsCount={reviewsCount}
            firstName={header}
          />
          {isCompact && tag && <Box>{tag}</Box>}
        </Column>
        <Column
          sx={{
            flex: 1,
            lineHeight: 1.5,
            color: "text.primary",
            mt: 1,
            pr: isCompact ? 0 : 2,
            minWidth: 0,
          }}
        >
          <Box sx={{ mb: 1 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Header>{header}</Header>
              {!isCompact && tag && <Box sx={{ lineHeight: 1 }}>{tag}</Box>}
            </Box>
            {date && <DateTime date={date} />}
          </Box>
          {services[0] && (
            <Box
              sx={{
                fontWeight: 700,
                justifyContent: "flex-end",
                flex: "0 0 auto",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {services[0]}
            </Box>
          )}
          <Text>{text}</Text>
          <Button
            variant="link"
            right={<ArrowRight color="#ADADAD" />}
            onClick={handleClick}
            sx={{
              alignSelf: "flex-end",
              p: 0,
              mr: isCompact ? 2 : 0,
              mt: "auto",
            }}
          />
        </Column>
      </div>
    );
  }
)(({ theme, isCompact = true }) => ({
  display: "flex",
  flex: isCompact ? "0 0 auto" : undefined,
  background: "#f7f7f7",
  borderRadius: theme.spacing(2),
  gap: theme.spacing(2),
  padding: theme.spacing(2.5, 2, 3, 2),
  minWidth: isCompact ? "24rem" : "49.5rem",
  height: "13.5rem",
  "&:hover": {
    filter: " drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.3))",
  },
}));
