import { MyOrderPreview } from "@features/orders-responses";
import { navChain } from "@shared/lib/navChain";
import { Paths } from "@shared/lib/router";
import {
  BaseLayout,
  Breadcrumbs,
  BreadcrumbsItem,
  Container,
  PageTitle,
} from "@shared/ui";
import { Header, Footer } from "@widgets";
import { useLocation } from "react-router";

const fromMap: Partial<Record<Paths, BreadcrumbsItem[]>> = {
  [Paths.OrderCompilation]: [
    navChain.orderCompilation,
    navChain.create("Просмотр заказа"),
  ],
};

export const MyOrderPreviewPage = () => {
  const location = useLocation();
  const from = (location.state ? location.state.from : "") as Paths;

  const breadcrumbs = fromMap[from] ?? [
    ...navChain.myOrders,
    navChain.create("Просмотр заказа"),
  ];

  return (
    <BaseLayout header={<Header />} footer={<Footer />}>
      <Container>
        <Breadcrumbs items={breadcrumbs} sx={{ mb: 3 }} />
        <PageTitle
          text="Просмотр заказа"
          sx={{ ml: "calc(32% + 1.5rem)", mb: 3 }}
        />
        <MyOrderPreview />
      </Container>
    </BaseLayout>
  );
};
