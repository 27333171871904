import { api } from "@shared/api";
import { showErrorMessages } from "@shared/lib/error";
import { enqueueSnackbar } from "notistack";
import { useMutation } from "react-query";

export const useCloseMyOrder = () => {
  const close = useMutation(
    ["offers", "close"],
    (id: string) => api.closeOrder(id),
    {
      onSuccess: () => {
        enqueueSnackbar("Заказ закрыт", { variant: "success" });
      },
      onError: showErrorMessages,
    }
  );

  return (id: string) => close.mutateAsync(id);
};
