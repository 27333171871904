import { SxProps, Theme } from "@mui/material";
import { Avatar } from "../Avatar/Avatar";
import { Column } from "../Column";
import { Rating } from "../Rating/Rating";
import { UserData } from "./types";
import { getLogoPlaceholder } from "@shared/lib/utils";

type UserProps = { sx?: SxProps<Theme> } & UserData;

export const User = ({
  avatarSrc,
  rating,
  reviewsCount,
  avatarSize = 60,
  sx = [],
  firstName,
  lastName,
}: UserProps) => (
  <Column gap={1} sx={sx}>
    <Avatar src={avatarSrc} sx={{ width: avatarSize, height: avatarSize }}>
      {getLogoPlaceholder(`${firstName} ${lastName}`)}
    </Avatar>
    <Rating
      showCount
      value={rating ?? 0}
      count={reviewsCount ?? 0}
      sx={{ fontSize: "0.875rem" }}
    />
  </Column>
);
