import { Box } from "@mui/material";
import { Column, DateTime } from "@shared/ui";

type Props = {
  title: string;
  name?: string;
  date: Date;
};

export const ServiceCardHeader = ({ title, name, date }: Props) => (
  <Column sx={{ lineHeight: 1.5 }}>
    <Box sx={{ color: "secondary.main", fontSize: "1.25rem", fontWeight: 700 }}>
      {title}
    </Box>
    {!!name && (
      <Box sx={{ color: "secondary.light", fontSize: "0.875rem" }}>{name}</Box>
    )}
    <DateTime date={date} />
  </Column>
);
