import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "@app/store";
import { User, UserRole } from "@shared/types/user";

type State = {
  me: User | null;
};

const initialState: State = {
  me: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    signIn: (state, action: PayloadAction<User>) => {
      state.me = action.payload.role === UserRole.Guest ? null : action.payload;
    },
    signOut: (state) => {
      state.me = null;
    },
  },
});

export const { name, reducer } = userSlice;
export const { signIn, signOut } = userSlice.actions;

const selfSelector = (state: RootState) => state.user;

export const isSignedInSelector = createSelector(
  selfSelector,
  (state) => !!state.me
);

export const userSelector = createSelector(selfSelector, (state) => state.me);
