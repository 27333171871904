import { api } from "@shared/api";
import { showErrorMessages } from "@shared/lib/error";
import { enqueueSnackbar } from "notistack";
import { useMutation } from "react-query";

export const useIncomingResponsesActions = (
  offerId: string,
  answerId: string,
  onRespond?: () => void
) => {
  const handleSuccess = (text: string) => {
    enqueueSnackbar(text, { variant: "success" });
  };

  const accept = useMutation(() => api.acceptOrderAnswer(offerId, answerId), {
    onSuccess: () => handleSuccess("Поступивший отклик принят в работу"),
    onError: showErrorMessages,
  });

  const complete = useMutation(
    () => api.completeOrderAnswer(offerId, answerId),
    {
      onSuccess: () => handleSuccess("Работа с поступившим откликом завершена"),
      onError: showErrorMessages,
    }
  );

  const decline = useMutation(() => api.declineOrderAnswer(offerId, answerId), {
    onSuccess: () => handleSuccess("Поступивший отклик отклонен"),
    onError: showErrorMessages,
  });

  return {
    handleAcceptOrder: () => accept.mutateAsync().then(onRespond),
    handleDeclineOrder: () => decline.mutateAsync().then(onRespond),
    handleCompleteOrder: () => complete.mutateAsync().then(onRespond),
  };
};
