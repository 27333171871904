import { useAppSelector } from "@app/store";
import { userModel } from "@entities/user";
import { Paths } from "@shared/lib/router";
import { Navigate } from "react-router-dom";

type Props = {
  children: JSX.Element;
};

export const AuthGuard = ({ children }: Props) => {
  const isSignedIn = useAppSelector(userModel.isSignedInSelector);
  return isSignedIn ? children : <Navigate to={Paths.Home} replace />;
};
