import { Box, styled } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { DayPickerRangeController, FocusedInputShape } from "react-dates";
import { ReactComponent as ArrowIcon } from "@shared/assets/small-arrow-right.svg";

type Props = {
  startDate: Date | null;
  endDate: Date | null;
  onChange?: (startDate: Date | null, endDate: Date | null) => void;
};

type HandleDatesChangeProps = {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
};

const Wrapper = styled(Box)(({ theme }) => ({
  "& .DayPicker_transitionContainer, .DayPicker, .DayPicker > div > div": {
    width: "auto !important",
  },
  "& .DayPicker_transitionContainer": {
    minHeight: "275px",
  },
  "& .CalendarMonth_table": {
    borderCollapse: "separate !important",

    "& tr": {
      display: "flex",
      columnGap: "16px",
      rowGap: "8px",
    },
    "& td": {
      width: "32px",
      height: "32px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
    },
  },
  "& .CalendarMonthGrid": {
    display: "flex",
    columnGap: "32px",
  },
  "& .DayPicker_weekHeaders": {
    display: "flex",
    columnGap: "32px",
    justifyContent: "space-between",
  },
  "& .DayPicker_weekHeader": {
    padding: "0 !important",
    position: "relative",
    left: "0 !important",
    top: "100px",
  },
  "& .DayPicker_weekHeader_ul": {
    display: "flex",
    columnGap: "16px",
  },
  "& .CalendarMonth": {
    padding: "0 !important",
  },
  "& .CalendarMonth_caption": {
    paddingBottom: "72px",
    fontSize: "1rem",
    lineHeight: 1.5,
    color: "#393939",
    "& strong": {
      fontWeight: 400,
    },
  },
  "& .DayPickerNavigation_button": {
    position: "absolute",
    top: "18px",
    lineHeight: 0.78,
    borderRadius: "3px",
    padding: "6px 9px",
    "&:first-of-type": { left: 0 },
    "&:last-child": { right: 0 },
  },
  "& .CalendarDay__default": {
    border: "none",
    color: theme.palette.text.primary,
    "&:hover:not(&.CalendarDay__selected)": {
      border: "1px solid #F0EE0E",
      borderRadius: theme.spacing(1),
      background: "#fff",
    },
  },
  "& .CalendarDay__selected_span": {
    backgroundColor: "#EEEEEE",
    color: theme.palette.text.primary,
    border: "none",
    "&:hover": {
      border: "1px double #F0EE0E",
    },
    "&:not(:first-of-type):before": {
      position: "absolute",
      content: "''",
      top: 0,
      bottom: 0,
      left: "-16px",
      width: "16px",
      background: "#EEEEEE",
    },
    "&:not(:last-child):after": {
      position: "absolute",
      content: "''",
      top: 0,
      bottom: 0,
      left: "auto",
      right: "-16px",
      width: "16px",
      background: "#EEEEEE",
    },
  },
  "& .CalendarDay__selected:not(.CalendarDay__outside)": {
    backgroundColor: "#F0EE0E",
    borderRadius: theme.spacing(1),
  },
  "& .CalendarDay__outside": {
    color: "#CBCBCB",
    background: "#fff",
    pointerEvents: "none",
    "&:before, :after": {
      display: "none",
    },
  },
}));

export const DatePicker = ({ startDate, endDate, onChange }: Props) => {
  const [focused, setFocused] = useState<FocusedInputShape>("startDate");

  const handleFocusedChange = (value: FocusedInputShape | null) => {
    setFocused(value ?? "startDate");
  };
  const handleDatesChange = ({
    startDate,
    endDate,
  }: HandleDatesChangeProps) => {
    const start = (startDate ?? endDate)?.startOf("day").toDate() ?? null;
    const end = (endDate ?? startDate)?.startOf("day").toDate() ?? null;

    onChange?.(start, end);
  };

  return (
    <Wrapper>
      <DayPickerRangeController
        focusedInput={focused}
        startDate={startDate ? moment(startDate) : null}
        endDate={endDate ? moment(endDate) : null}
        initialVisibleMonth={null}
        numberOfMonths={2}
        minimumNights={0}
        hideKeyboardShortcutsPanel
        enableOutsideDays={true}
        daySize={32}
        noBorder
        navPrev={<ArrowIcon style={{ transform: "rotate(180deg)" }} />}
        navNext={<ArrowIcon />}
        onFocusChange={handleFocusedChange}
        onDatesChange={handleDatesChange}
      />
    </Wrapper>
  );
};
