import { Box, styled } from "@mui/material";
import { Column, DateTime, User } from "@shared/ui";
import { OrderPreviewResponse } from "../types";
import { useMyOrderPreviewResponses } from "../hooks";
import { useNavigate } from "react-router-dom";
import { Paths } from "@shared/lib/router";
import { MouseEventHandler } from "react";
import LinesEllipsis from "react-lines-ellipsis";

type HeaderProps = {
  count: number;
};
const Header = styled(({ count, ...otherProps }: HeaderProps) => {
  return <div {...otherProps}>Отклики ({count})</div>;
})({
  padding: "1rem 1.5rem 0.5rem",
  fontSize: "1.5rem",
  lineHeight: 1.35,
  fontWeight: 700,
  borderBottom: "1px solid #E4E4E4",
});

const avatarSize = 40;

type ReviewProps = OrderPreviewResponse & {
  onClick: MouseEventHandler<HTMLDivElement> | undefined;
};

const Review = ({
  avatarSrc,
  title,
  name,
  date,
  text,
  rating,
  reviewsCount,
  onClick,
}: ReviewProps) => (
  <Box
    onClick={onClick}
    sx={{ display: "flex", flexDirection: "column", gap: 3, cursor: "pointer" }}
  >
    <Box sx={{ display: "flex", gap: 2 }}>
      <User
        avatarSrc={avatarSrc}
        avatarSize={avatarSize}
        firstName={title}
        rating={rating}
        reviewsCount={reviewsCount}
      />
      <Column>
        <Box sx={{ lineHeight: 1.35, fontWeight: 700 }}>
          <LinesEllipsis maxLine={2} basedOn="words" text={title} />
        </Box>
        <Box
          sx={{ lineHeight: 1.5, color: "secondary.light" }}
          children={name}
        />
        <DateTime date={date} sx={{ fontSize: "0.875rem" }} />
      </Column>
    </Box>
    <LinesEllipsis maxLine={3} text={text} />
  </Box>
);

export const MyOrderPreviewResponses = styled(({ ...otherProps }) => {
  const reviews = useMyOrderPreviewResponses();
  const navigate = useNavigate();

  return (
    <div {...otherProps}>
      <Header count={reviews.length} />
      <Column sx={{ px: 3, py: 2, gap: 6 }}>
        {reviews.map((review) => (
          <Review
            key={review.text}
            onClick={() => navigate(`${Paths.MyOrders}${review.id}/full`)}
            {...review}
          />
        ))}
      </Column>
    </div>
  );
})({
  display: "flex",
  flexDirection: "column",
  maxHeight: "41.625rem",
  background: "#f7f7f7",
  borderRadius: "0.3125rem",
  border: "1px solid #E4E4E4",
  overflowY: "auto",
});
