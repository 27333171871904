import { styled } from "@mui/material";
import { Button, Switch } from "@shared/ui";
import { ReactComponent as EditIcon } from "@shared/assets/edit.svg";
import { businessModel } from "..";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { generatePath } from "react-router-dom";
import { Paths } from "@shared/lib/router";

export const ViewBusinessCardControls = styled(({ ...otherProps }) => {
  const card = useSelector(businessModel.cardSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const handlePrimarySwitcherClick = (value: boolean) =>
    dispatch(businessModel.setMainCard(value));

  const handleEditClick = () =>
    id && navigate(generatePath(Paths.EditBusinessCard, { id }));

  return (
    <div {...otherProps}>
      <Switch
        checked={card?.mainCard ?? false}
        label="Использовать как основную"
        onChange={handlePrimarySwitcherClick}
      />
      <Button
        variant="link"
        left={<EditIcon />}
        text="Редактировать карточку бизнеса"
        sx={{ fontWeight: 700, mr: 8, lineHeight: 1.5 }}
        onClick={handleEditClick}
      />
    </div>
  );
})(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  lineHeight: 1.5,
  marginBottom: theme.spacing(2.25),
}));
