import MainLogo from "@shared/assets/main-logo.svg";
import { Container, NavList, NavListItem } from "@shared/ui";
import { Box } from "@mui/material";
import { UserMenu } from "./UserMenu";
import { useAppSelector } from "@app/store";
import { userModel } from "@entities/user";
import { getAuthUrl, getDomainUrl } from "@shared/lib/utils";

export const Header = () => {
  const user = useAppSelector(userModel.userSelector)!;

  return (
    <Container
      sx={{
        display: "grid",
        gridTemplateColumns: "108px 1fr auto",
        alignItems: "center",
        columnGap: (theme) => theme.spacing(8),
      }}
    >
      <a href={getDomainUrl()}>
        <img src={MainLogo} alt="Logo" />
      </a>
      <NavList>
        <NavListItem to={getDomainUrl("/community")} label="Сообщество" />
        <NavListItem to={getDomainUrl("/#knowledge")} label="База знаний" />
        <NavListItem to="/" label="Маркетплейс" />
        <NavListItem
          to={getDomainUrl("/virtual_education")}
          label="Виртуальное обучающее пространство"
        />
        <NavListItem to={getDomainUrl("/#knowledge")} label="Видеоинструкции" />
      </NavList>
      {!!user ? (
        <UserMenu user={user} />
      ) : (
        <Box
          sx={{ textDecoration: "none", color: "inherit" }}
          component="a"
          href={getAuthUrl()}
        >
          Войти
        </Box>
      )}
    </Container>
  );
};
