import { Box } from "@mui/material";
import { Column } from "@shared/ui";
import { CardStatistics } from "../types";
import { OrdersResponsesStatistics } from "./OrdersResponsesStatistics";
import { ResponseShortDetailsItem } from "./ResponseShortDetailsItem";
import { ReactNode } from "react";

type BaseProps = {
  details: ReactNode;
  title: string;
  text: string;
  statistics: CardStatistics;
  onLike?: () => void;
  onDislike?: () => void;
};

const Details = ({
  details,
  title,
  text,
  statistics,
  onLike,
  onDislike,
}: BaseProps) => {
  return (
    <Column
      sx={{ color: "text.primary", lineHeight: 1.5, gap: 0.75, minWidth: 0 }}
    >
      {details}
      <Box
        sx={{ color: "text.primary", fontSize: "1.125rem", fontWeight: 700 }}
        children={title}
      />
      <Box>{text}</Box>
      {statistics && (
        <OrdersResponsesStatistics
          {...statistics}
          onLike={onLike}
          onDislike={onDislike}
        />
      )}
    </Column>
  );
};

type ResponseProps = { orderSender: string } & Omit<BaseProps, "details">;

export const ResponseShortDetails = ({
  orderSender,
  title,
  text,
  statistics,
  onLike,
  onDislike,
}: ResponseProps) => (
  <Details
    details={
      <ResponseShortDetailsItem
        title="Получатель отклика"
        value={orderSender}
      />
    }
    title={title}
    text={text}
    statistics={statistics}
    onLike={onLike}
    onDislike={onDislike}
  />
);

type IncomingProps = {
  responseReceiver: string;
  order: string;
} & Omit<BaseProps, "details">;

export const IncomingResponseShortDetails = ({
  responseReceiver,
  order,
  title,
  text,
  statistics,
  onLike,
  onDislike,
}: IncomingProps) => (
  <Details
    details={
      <>
        <ResponseShortDetailsItem
          title="Получатель отклика"
          value={responseReceiver}
        />
        <ResponseShortDetailsItem title="Заказ" value={order} />
      </>
    }
    title={title}
    text={text}
    statistics={statistics}
    onLike={onLike}
    onDislike={onDislike}
  />
);
