import {
  Button,
  Dislike,
  InfinityList,
  ItemRenderOptions,
  Like,
  ViewCount,
} from "@shared/ui";
import {
  MarketplaceCard,
  ServiceCardHeader,
  ServicePrice,
  ServiceShortDetails,
} from "@entities/marketplace";
import { Box } from "@mui/material";
import { ServiceCompilationItem } from "@shared/types/service";
import { useServiceCompilation } from "@entities/service";
import { generatePath } from "react-router-dom";
import { Paths } from "@shared/lib/router";
import { useAppSelector } from "@app/store";
import { userModel } from "@entities/user";
import { useDislikePost, useLikePost } from "@entities/posts";
import { ReactionType } from "@shared/types/posts";

type Props = {
  onOrderService?: (id: string) => void;
};

export const ServiceCompilationList = ({ onOrderService }: Props) => {
  const isSignedIn = useAppSelector(userModel.isSignedInSelector);

  const {
    list,
    isFetching,
    isFetchingNextPage,
    isRefetching,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = useServiceCompilation();

  const likePost = useLikePost();

  const dislikePost = useDislikePost();

  const handleOrderService = (id: string) => () => {
    onOrderService?.(id);
  };

  const handleLike = (id: string) =>
    isSignedIn && !likePost.isLoading
      ? () =>
          likePost.mutate(id, {
            onSuccess: () =>
              refetch({
                refetchPage: (page: ServiceCompilationItem[]) =>
                  !!page.find((item) => item.businessService.id === id),
              }),
          })
      : undefined;

  const handleDislike = (id: string) =>
    isSignedIn && !dislikePost.isLoading
      ? () =>
          dislikePost.mutate(id, {
            onSuccess: () =>
              refetch({
                refetchPage: (page: ServiceCompilationItem[]) =>
                  !!page.find((item) => item.businessService.id === id),
              }),
          })
      : undefined;

  const renderItem = ({ item }: ItemRenderOptions<ServiceCompilationItem>) => (
    <MarketplaceCard
      key={item.businessService.id}
      sx={{ mt: 2, "&:first-of-type": { mt: 0 } }}
      avatarSrc={item.businessCard.logo?.path}
      firstName={item.businessCard.name.ru}
      rating={item.businessCard.rate}
      reviewsCount={item.businessCard.reviewCount}
      header={
        <ServiceCardHeader
          title={item.businessCard.name.ru}
          date={new Date(item.businessService.updateDateTime)}
        />
      }
      body={
        <>
          <Box sx={{ display: "flex", columnGap: 10, mr: 3, py: 2.5 }}>
            <ServiceShortDetails
              serviceName={item.businessService.name.ru}
              serviceTypes={item.businessService.serviceType.display.ru}
              text={item.businessService.description.ru}
            />
            {!!item.businessService.price && (
              <ServicePrice
                sx={{ flex: "0 0 auto", ml: "auto" }}
                value={parseInt(item.businessService.price)}
                type={item.businessService.priceUnits}
              />
            )}
          </Box>

          <Box sx={{ display: "flex", columnGap: 2.25 }}>
            <Like
              active={
                item.businessService.reactions?.[ReactionType.Like]
                  ?.hasByUser ?? false
              }
              count={
                item.businessService.reactions?.[ReactionType.Like]?.count ?? 0
              }
              onClick={handleLike(item.businessService.id)}
            />
            <Dislike
              active={
                item.businessService.reactions?.[ReactionType.Dislike]
                  ?.hasByUser ?? false
              }
              count={
                item.businessService.reactions?.[ReactionType.Dislike]?.count ??
                0
              }
              onClick={handleDislike(item.businessService.id)}
            />
            <ViewCount
              active={
                item.businessService.viewsCounter?.viewedByCurrentUser ?? false
              }
              count={item.businessService.viewsCounter?.uniqueUsers ?? 0}
            />
          </Box>
        </>
      }
      buttons={
        <Button
          text="Заказать услугу"
          onClick={handleOrderService(item.businessService.id)}
        />
      }
      linkUrl={
        isSignedIn
          ? generatePath(Paths.ViewService, { id: item.businessService.id })
          : undefined
      }
    />
  );

  return (
    <InfinityList
      isLoading={isFetching && !isFetchingNextPage && !isRefetching}
      emptyList="Нет услуг"
      size={list.length}
      items={list}
      hasMore={Boolean(hasNextPage)}
      next={fetchNextPage}
      renderItem={renderItem}
    />
  );
};
