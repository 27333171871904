import { Box, SxProps, Theme } from "@mui/material";
import { Container } from "@shared/ui";
import { ReactNode } from "react";

type Props = {
  className?: string;
  sx?: SxProps<Theme>;
  children?: ReactNode;
};

export const FiltersContainer = ({ className, sx = [], children }: Props) => {
  return (
    <Box
      className={className}
      sx={[
        {
          borderTop: `1px solid`,
          borderBottom: `1px solid`,
          borderColor: (theme) => theme.palette.divider,
          backgroundColor: (theme) => theme.palette.background.paper,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          columnGap: (theme) => theme.spacing(5),
          py: (theme) => theme.spacing(1),
        }}
      >
        {children}
      </Container>
    </Box>
  );
};
