import { CircularProgress } from "@mui/material";
import { Modal, MultiStepModal } from "@shared/ui";
import { useEffect, useRef, useState } from "react";
import { CreateBusinessCardForm } from "./CreateBusinessCardForm";
import { RespondForm } from "./RespondForm";
import {
  useCreateBusinessCard,
  useUserPublishedBusinessCards,
} from "@entities/business";
import { CreateBusinessCard } from "@shared/types/business";
import { useCreateRespond } from "@entities/service";
import { CreateRespond } from "@shared/types/respond";
import { CREATE_BUSINESS_CARD_OPTIONS } from "../lib";

type Props = {
  currentOrder: string;
  open: boolean;
  title?: string;
  description?: string;
  onClose: () => void;
};

export const RespondModal = ({
  currentOrder,
  title = "Сформировать отклик",
  description = "Сформировать отклик",
  open,
  onClose,
}: Props) => {
  const {
    data: businessCards = [],
    isLoading,
    isSuccess,
  } = useUserPublishedBusinessCards();

  const createBusinessCard = useCreateBusinessCard();
  const createRespond = useCreateRespond("Отклик сформирован и опубликован");

  const [hasBusinessCards, setHasBusinessCards] = useState(false);

  const [currentBusiness, setCurrentBusiness] = useState<string | null>(null);

  const [step, setStep] = useState(1);

  const next = () => setStep((prev) => prev + 1);

  const handleSubmitBusiness = (card: CreateBusinessCard) => {
    createBusinessCard
      .mutateAsync({ card })
      .then((response) => setCurrentBusiness(response.id))
      .then(next);
  };

  const handleClose = () => {
    setCurrentBusiness(null);
    setStep(1);
    onClose();
  };

  const handleSubmitRespond = (respond: CreateRespond) => {
    createRespond.mutateAsync({ id: currentOrder, respond }).then(handleClose);
  };

  const isRequestedBusinessCount = useRef(false);

  useEffect(() => {
    if (!isRequestedBusinessCount.current && isSuccess) {
      setHasBusinessCards(!!businessCards.length);
      isRequestedBusinessCount.current = true;
    }
  }, [businessCards.length, isSuccess]);

  if (isLoading && open) {
    return (
      <CircularProgress
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        color="primary"
        size={48}
      />
    );
  }

  return hasBusinessCards ? (
    <Modal header={title} width="75rem" open={open} onClose={handleClose}>
      <RespondForm
        currentBusinessCard={currentBusiness ?? undefined}
        onCancel={handleClose}
        onSubmit={handleSubmitRespond}
      />
    </Modal>
  ) : (
    <MultiStepModal
      width="75rem"
      step={step}
      open={open}
      options={[CREATE_BUSINESS_CARD_OPTIONS, { title, description }]}
      onClose={handleClose}
    >
      <CreateBusinessCardForm
        cancelButtonText="Отменить"
        submitButtonText="Далее"
        onCancel={handleClose}
        onSubmit={handleSubmitBusiness}
      />
      <RespondForm
        currentBusinessCard={currentBusiness ?? undefined}
        onCancel={handleClose}
        onSubmit={handleSubmitRespond}
      />
    </MultiStepModal>
  );
};
