import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import { userModel } from "@entities/user";
import { configureStore } from "@reduxjs/toolkit";
import { businessModel } from "@entities/business";

export const store = configureStore({
  reducer: {
    [userModel.name]: userModel.reducer,
    [businessModel.name]: businessModel.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
