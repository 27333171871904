export enum Paths {
  Home = "/",
  OrderCompilation = "/order-compilation",
  MyMarketplace = "/my/",
  Business = "/business/",
  ViewBusinessCard = "/business/:id/",
  MyBusiness = "/my/business/",
  ViewMyBusinessCard = "/my/business/:id/",
  MyOrders = "/my/orders/",
  MyOrderPreview = "/my/orders/:id",
  ViewMyOrder = "/my/orders/:id/full/",
  IncomingOrders = "/my/incoming-orders/",
  ViewIncomingOrder = "/my/incoming-orders/:id",
  MyResponses = "/my/responses/",
  ViewMyResponse = "/my/responses/:id",
  IncomingResponses = "/my/incoming-responses/",
  ViewIncomingResponse = "/my/incoming-responses/:id",
  CreateBusinessCard = "/business/create",
  EditBusinessCard = "/business/:id/edit",
  ViewService = "/service/:id",
}
