import { OrderCardHeader } from "@features/orders-responses";
import { Box } from "@mui/material";
import { Column, DateTime } from "@shared/ui";

type Props = OrderCardHeader;
export const OrdersResponsesHeader = ({ title, name, date }: Props) => (
  <Column sx={{ minWidth: 0 }}>
    <Box
      sx={{
        color: "secondary.main",
        fontSize: "1.25rem",
        fontWeight: 700,
        lineHeight: 1.35,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      }}
      children={title}
    />
    <Box
      sx={{ color: "secondary.light", fontSize: "0.875rem", lineHeight: 1.5 }}
      children={name}
    />
    <DateTime date={date} />
  </Column>
);
