import {
  BusinessCardsFilter,
  FilterButton,
  PeriodFilter,
  Search,
  ServiceTypesFilter,
  Sort,
  useFilters,
} from "@entities/filters";
import { Box } from "@mui/material";
import { Column } from "@shared/ui";
import { ReactNode } from "react";

type Props = {
  showServiceType?: boolean;
  hideBusinessCards?: boolean;
  statusFilter?: ReactNode;
};

export const OrdersResponsesFilter = ({
  showServiceType = false,
  hideBusinessCards = false,
  statusFilter,
}: Props) => {
  const { filters, handleChange } = useFilters();

  return (
    <Column sx={{ mb: 3.5 }}>
      {statusFilter}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2.5,
          p: (theme) => theme.spacing(2, 1, 2, 0),
          borderTop: "1px solid #E4E4E4",
          boxShadow: "1px 2px 3px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Sort
          defaultValue={
            filters.sort
              ? `${filters.sort}_${filters.order ?? "ASC"}`
              : undefined
          }
          onChange={handleChange("sort", "order")}
        />
        <FilterButton popupMaxWidth="62rem" popupMaxHeight="12rem">
          <Box
            sx={{ display: "flex", alignItems: "flex-start", gap: 4, mr: 6 }}
          >
            <PeriodFilter
              defaultValue={filters.fromDate}
              onChange={handleChange("fromDate")}
            />
            {!hideBusinessCards && (
              <BusinessCardsFilter
                defaultValue={filters.businessCardIds}
                onChange={handleChange("businessCardIds")}
              />
            )}
            {showServiceType && (
              <ServiceTypesFilter
                defaultValue={filters.serviceTypes}
                onChange={handleChange("serviceTypes")}
              />
            )}
          </Box>
        </FilterButton>
        <Search
          sx={{ width: "30.25rem", ml: "auto" }}
          defaultValue={filters.text}
          placeholder="Искать по разделу"
          onChange={handleChange("text")}
        />
      </Box>
    </Column>
  );
};
