import { useMutation, useQueryClient } from "react-query";
import { api } from "@shared/api";
import { useAppDispatch } from "@app/store";
import { signOut } from "../model";
import { showErrorMessages } from "@shared/lib/error";
import { userQueryKeys } from "./userQueryKeys";

export const useSignOut = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation(api.signOut, {
    onSuccess: () => {
      dispatch(signOut());
      queryClient.invalidateQueries(userQueryKeys.current);
    },
    onError: showErrorMessages,
  });
};
