import { Box, Popover, SxProps, Theme, styled } from "@mui/material";
import { Avatar } from "@shared/ui";
import { ReactComponent as ChevronDown } from "@shared/assets/chevron-down.svg";
import { useState } from "react";
import { useSignOut } from "@entities/user";
import { Link } from "react-router-dom";
import { Paths } from "@shared/lib/router";
import { User } from "@shared/types/user";
import { getDomainUrl, getFullName, getInitials } from "@shared/lib/utils";

const ListItem = styled("li")(({ theme }) => ({
  padding: theme.spacing(1, 2),
  fontWeight: 400,
  fontSize: "1.125rem",
  lineHeight: 1.35,
  color: theme.palette.text.primary,

  "& > a, & > a:hover, & > a:visited": {
    color: "inherit",
    textDecoration: "none",
  },

  "&:hover": {
    backgroundColor: "#ddedfd",
    cursor: "pointer",
  },
}));

type Props = {
  sx?: SxProps<Theme>;
  user: User;
};

export const UserMenu = ({ sx, user }: Props) => {
  const signOut = useSignOut();

  const fullName = getFullName(user.name, user.lastName);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const handleSignOut = () => signOut.mutate();

  return (
    <>
      <Box
        sx={[
          {
            display: "flex",
            alignItems: "center",
            columnGap: 2,
            "&:hover": { cursor: "pointer" },
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        onClick={handleClick}
      >
        <Avatar sx={{ width: 40, height: 40 }}>
          {getInitials(user.lastName, user.name)}
        </Avatar>
        <Box sx={{ transform: open ? "rotate(180deg)" : "rotate(0deg)" }}>
          <ChevronDown />
        </Box>
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          sx: { mt: 1, width: "20rem" },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ py: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: 0.5,
              px: 2,
            }}
          >
            {user.company && (
              <Box
                sx={{
                  fontWeight: 400,
                  fontSize: "0.75rem",
                  lineHeight: 1.35,
                  color: "#adadad",
                }}
              >
                {user.company}
              </Box>
            )}
            {fullName && (
              <Box
                sx={{
                  fontWeight: 700,
                  fontSize: "1rem",
                  lineHeight: 1.5,
                  color: "#2386e0",
                }}
              >
                {fullName}
              </Box>
            )}
          </Box>
          <Box
            component="ul"
            sx={{
              display: "flex",
              flexDirection: "column",
              columnGap: 2,
              m: 0,
              mt: user.company || fullName ? 2 : 0,
              p: 0,
              listStyle: "none",
            }}
          >
            <ListItem>
              <a href={getDomainUrl("/personal/profile")}>Мой профиль</a>
            </ListItem>
            <ListItem onClick={handleSignOut}>Выйти</ListItem>
            <Box
              component="hr"
              sx={{
                display: "block",
                my: 2,
                mx: 2,
                height: 1,
                backgroundColor: "#e8e8e8",
              }}
            />
            <ListItem>
              <a href={getDomainUrl("/personal/profile/#ribbon")}>
                Лента активности
              </a>
            </ListItem>
            <ListItem>
              <a href={getDomainUrl("/personal/profile/#subscribes")}>
                Подписки
              </a>
            </ListItem>
            <ListItem>
              <a href={getDomainUrl("/personal/profile/#bookmarks")}>
                Закладки
              </a>
            </ListItem>
            <ListItem>
              <a href={getDomainUrl("/personal/profile/#notifications")}>
                Уведомления
              </a>
            </ListItem>
            <ListItem>
              <Link to={Paths.MyMarketplace}>Мой Маркетплейс</Link>
            </ListItem>
          </Box>
        </Box>
      </Popover>
    </>
  );
};
