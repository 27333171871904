import { InfinityList, ItemRenderOptions } from "@shared/ui";
import { useIncomingOrders } from "../hooks/useIncomingOrders";
import { IncomingOrderCard } from "../types";
import { IncomingOrdersListItem } from "./IncomingOrdersListItem";
import { useDislikePost, useLikePost } from "@entities/posts";
import { DeclineIncomingOrderModal } from "./DeclineIncomingOrderModal";
import { useDeclineRespond } from "../hooks/useDeclineRespond";
import { useBoolean } from "@shared/hooks";
import { useState } from "react";
import { ordersResponsesKeys } from "../hooks/ordersResponsesKeys";
import { useRefetchList } from "../hooks/useRefetchList";

export const IncomingOrdersList = () => {
  const listData = useIncomingOrders();
  const likePost = useLikePost();
  const dislikePost = useDislikePost();
  const [confirmCancelIsOpen, confirmCancelModal] = useBoolean();
  const [declinedId, setDeclinedId] = useState<string | undefined>();
  const decline = useDeclineRespond();
  const refetch = useRefetchList(ordersResponsesKeys.incoming);

  const handleClose = async (reason: string) =>
    decline.mutateAsync(
      { id: declinedId ?? "", reason },
      {
        onSuccess: () => {
          confirmCancelModal.off();
          refetch();
        },
      }
    );

  const handleDecline = (id: string) => {
    setDeclinedId(id);
    confirmCancelModal.on();
  };

  const renderItem = ({ item }: ItemRenderOptions<IncomingOrderCard>) => {
    const handleLike = !likePost.isLoading
      ? () => likePost.mutate(item.id, { onSuccess: () => refetch() })
      : undefined;

    const handleDislike = !dislikePost.isLoading
      ? () => dislikePost.mutate(item.id, { onSuccess: () => refetch() })
      : undefined;

    return (
      <IncomingOrdersListItem
        key={item.id}
        item={item}
        onLike={handleLike}
        onDislike={handleDislike}
        onDecline={handleDecline}
      />
    );
  };

  return (
    <>
      <InfinityList {...listData} renderItem={renderItem} />
      <DeclineIncomingOrderModal
        open={confirmCancelIsOpen}
        onClose={confirmCancelModal.off}
        onConfirm={handleClose}
      />
    </>
  );
};
