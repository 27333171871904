import { Attachment } from "./attachment";
import { LocaleString } from "./lang";

export enum ReactionType {
  Like = "LIKE",
  Dislike = "DISLIKE",
}

export type CreateComment = {
  attachments: Attachment["id"][];
  businessCardId: string;
  content: LocaleString;
  relationId: string;
};

export type UpdateComment = {
  attachments: Attachment["id"][];
  id: string;
  content: LocaleString;
};

export type Reactions = {
  [ReactionType.Dislike]: { count: number; hasByUser: boolean };
  [ReactionType.Like]: { count: number; hasByUser: boolean };
};

export type ViewsCounter = {
  uniqueUsers: number;
  viewedByCurrentUser: boolean;
};
