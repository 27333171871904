import { styled, Avatar as MuiAvatar } from "@mui/material";

export const Avatar = styled(MuiAvatar)({
  height: "3.5rem",
  width: "3.5rem",
  fontSize: "1.25rem",
  lineHeight: 1.16,
  fontWeight: 400,
  background: "linear-gradient(180deg, #F199E3 0%, #B786DE 100%)",
});
