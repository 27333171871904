import { styled } from "@mui/material";
import { ReactComponent as LikeIcon } from "@shared/assets/like.svg";
import { ReactComponent as DislikeIcon } from "@shared/assets/dislike.svg";
import { MouseEvent } from "react";

type Props = {
  count: number;
  active?: boolean;
  onClick?: (count: number) => void;
};

export const Dislike = styled(
  ({ count, active = false, onClick, ...otherProps }: Props) => {
    const handleClick = (event: MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      onClick?.(active ? count - 1 : count + 1);
    };

    return (
      <div {...otherProps} onClick={handleClick}>
        {active ? (
          <LikeIcon style={{ color: "#63666A", transform: "rotate(180deg)" }} />
        ) : (
          <DislikeIcon style={{ color: "#a9a9a9" }} />
        )}
        {!!count && <span>{count}</span>}
      </div>
    );
  }
)(({ theme, onClick }) => ({
  display: "inline-flex",
  alignItems: "center",
  columnGap: theme.spacing(1),
  color: theme.palette.error.main,
  fontSize: "0.875rem",
  lineHeight: 1.4,
  "&:hover": { cursor: onClick ? "pointer" : "default" },
}));
