import {
  MarketplaceCard,
  OrdersResponsesHeader,
  ResponsePrice,
  ResponseShortDetails,
} from "@entities/marketplace";
import { Box } from "@mui/material";
import { Paths } from "@shared/lib/router";
import { Button, tagsByStatus } from "@shared/ui";
import { CloseButton } from "./CloseButton";
import { useMyResponsesActions } from "../hooks/useMyResponsesActions";
import { MyResponseCard } from "../types";

type Props = {
  item: MyResponseCard;
  onLike?: () => void;
  onDislike?: () => void;
  onRespond?: () => void;
};

export const MyResponsesListItem = ({
  item,
  onLike,
  onDislike,
  onRespond,
}: Props) => {
  console.log(item);
  const { confirm, discard } = useMyResponsesActions(item.id);

  const handleConfirm = () => confirm().then(() => onRespond?.());
  const handleDiscard = () => discard().then(() => onRespond?.());

  return (
    <MarketplaceCard
      key={item.id}
      sx={{ mt: 2, "&:first-of-type": { mt: 0 } }}
      avatarSrc={item.avatarSrc}
      header={
        <OrdersResponsesHeader
          title={item.company}
          name={item.name}
          date={item.date}
        />
      }
      rating={item.rating}
      reviewsCount={item.reviewsCount}
      firstName={item.company}
      linkUrl={`${Paths.MyResponses}${item.id}`}
      body={
        <Box
          sx={{
            display: "flex",
            columnGap: 10,
            mr: 3,
            py: 2.5,
            maxWidth: item.price ? undefined : "37rem",
          }}
        >
          <ResponseShortDetails
            orderSender={item.target}
            title={item.title}
            text={item.text}
            statistics={item.statistics}
            onLike={onLike}
            onDislike={onDislike}
          />
          {!!item.price.value && (
            <ResponsePrice value={item.price.value} type={item.price.type} />
          )}
        </Box>
      }
      tags={tagsByStatus[item.status]}
      buttons={
        <>
          {((item.status === "PUBLISHED" || item.status === "ACCEPTED") &&
            item.businessCardStatus === "PUBLISHED" && (
              <CloseButton text="Отозвать" onClick={handleDiscard} />
            )) || <Box sx={{ minWidth: "11.5rem" }} />}
          {item.status === "ACCEPTED" &&
            item.businessCardStatus === "PUBLISHED" && (
              <Button
                sx={{
                  width: "11.25rem",
                  fontSize: "0.875rem",
                  lineHeight: 1.5,
                }}
                text="Подтвердить"
                onClick={handleConfirm}
              />
            )}
        </>
      }
    />
  );
};
