import { InfinityList, ItemRenderOptions } from "@shared/ui";
import { useMyResponses } from "../hooks/useMyResponses";
import { MyResponseCard } from "../types";
import { MyResponsesListItem } from "./MyResponsesListItem";
import { useDislikePost, useLikePost } from "@entities/posts";
import { ordersResponsesKeys } from "../hooks/ordersResponsesKeys";
import { useRefetchList } from "../hooks/useRefetchList";

export const MyResponsesList = () => {
  const listData = useMyResponses();
  const likePost = useLikePost();
  const dislikePost = useDislikePost();
  const refetch = useRefetchList(ordersResponsesKeys.myResponses);

  const renderItem = ({ item }: ItemRenderOptions<MyResponseCard>) => {
    const handleLike = !likePost.isLoading
      ? () => likePost.mutate(item.id, { onSuccess: refetch })
      : undefined;

    const handleDislike = !dislikePost.isLoading
      ? () => dislikePost.mutate(item.id, { onSuccess: refetch })
      : undefined;

    return (
      <MyResponsesListItem
        key={item.id}
        item={item}
        onLike={handleLike}
        onDislike={handleDislike}
        onRespond={refetch}
      />
    );
  };

  return <InfinityList {...listData} renderItem={renderItem} />;
};
