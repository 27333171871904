import { BigCard, Section, SmallCard } from "@entities/my-marketplace";
import { useMyOrders } from "@features/orders-responses";
import { mapToShortMyOrder } from "@features/orders-responses/model/orderCardMapper";
import { MakeOrderModal } from "@features/service-order";
import { Box } from "@mui/material";
import { useBoolean } from "@shared/hooks";
import { Paths } from "@shared/lib/router";
import { Mask } from "@shared/ui";
import { useLocation, useNavigate } from "react-router";

export const MyOrders = () => {
  const { items, refetch, isLoading } = useMyOrders();
  const slides = items.map(mapToShortMyOrder);

  const location = useLocation();
  const navigate = useNavigate();

  const [makeOrderIsOpen, makeOrderModal] = useBoolean(
    location.hash.startsWith("#make-order")
  );

  const handleCloseMakeOrder = async () => {
    await refetch();
    navigate(
      { pathname: location.pathname, search: location.search },
      { replace: true }
    );
    makeOrderModal.off();
  };

  const bigMask = (
    <Mask
      beforeStyles={{
        width: "47rem",
        height: "47rem",
        bgcolor: "#FFFF5B",
        transform: "translate(-25%, -80%)",
      }}
    />
  );

  const smallMask = (
    <Mask
      beforeStyles={{
        width: "17.5rem",
        height: "17.5rem",
        bgcolor: "#8bffff",
        transform: "translate(-30%, -50%)",
      }}
    />
  );

  return (
    <>
      <Section
        card={
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "792px 384px",
              gap: (theme) => theme.spacing(3),
              justifyContent: "space-between",
            }}
          >
            <BigCard
              title="У вас нет опубликованных заказов на услуги"
              description="Перейдите в подборку и найдите нужного участника Маркетплейса"
              buttonText="Перейти в подборку"
              mask={bigMask}
              linkUrl={Paths.Home}
            />
            <SmallCard
              title="Опубликуйте заказ и поставщики услуг найдут Вас сами!"
              buttonText="Сформировать заказ"
              mask={smallMask}
              onClick={makeOrderModal.on}
            />
          </Box>
        }
        title="Мои заказы"
        items={slides}
        viewAllLink={Paths.MyOrders}
        isLoading={isLoading}
      />
      {!slides.length && (
        <MakeOrderModal
          title="Сформировать заказ"
          description="Сформировать заказ"
          open={makeOrderIsOpen}
          onClose={handleCloseMakeOrder}
        />
      )}
    </>
  );
};
