import { Box, styled } from "@mui/material";
import { Column, DateTime, Loader } from "@shared/ui";
import { ViewBusinessCardBlockTitle } from "./ViewBusinessCardBlockTitle";
import DOMPurify from "dompurify";
import { useQuery } from "react-query";
import { api } from "@shared/api";
import { mapNotification } from "@features/business/hooks/useNotificationsTab";

type Props = {
  id: string;
};

const Title = styled("div")(({ theme }) => ({
  fontSize: "1.5rem",
  lineHeight: 1.35,
  fontWeight: 700,
  color: theme.palette.text.primary,
}));

export const NotificationDetailCard = ({ id }: Props) => {
  const { data } = useQuery(["notifications", id], () =>
    api.getNotification(id)
  );

  if (!data) {
    return <Loader />;
  }

  const item = mapNotification(data);

  return (
    <Column sx={{ bgcolor: "#fff", py: 2.5, pl: 6, borderRadius: 0.625 }}>
      <Title children={[item.type, item.title].join(" ")} />
      <DateTime date={new Date(item.date)} sx={{ mb: 3 }} />
      <ViewBusinessCardBlockTitle text="Уведомление" />
      <Box
        sx={{ color: "text.primary", pr: 6 }}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(item.text),
        }}
      />
    </Column>
  );
};
