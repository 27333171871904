import { Filters, useFilters } from "@entities/filters";
import { useQueryClient } from "react-query";

export const useRefetchList = (
  queryKey: (filters: Filters) => (string | Filters)[]
) => {
  const queryClient = useQueryClient();
  const { filters } = useFilters();

  return () => queryClient.invalidateQueries(queryKey(filters));
};
