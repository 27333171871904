import { Box } from "@mui/material";

type Props = {
  text: string;
};
export const EmptyTabText = ({ text }: Props) => (
  <Box sx={{ fontSize: "1.125rem", lineHeight: 1.5, color: "text.primary" }}>
    {text}
  </Box>
);
