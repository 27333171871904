import { ReactComponent as SearchIcon } from "@shared/assets/search.svg";
import { Box, SxProps, Theme } from "@mui/material";
import { theme } from "@shared/lib/theme";
import { ChangeEvent } from "react";

type Props = {
  sx?: SxProps<Theme>;
  inputSx?: SxProps<Theme>;
  className?: string;
  placeholder?: string;
  value?: string | null | undefined;
  onChange?: (value: string) => void;
};

export const Search = ({
  sx = [],
  inputSx = [],
  className,
  placeholder,
  value,
  onChange,
}: Props) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.value);
  };

  return (
    <Box
      className={className}
      sx={[
        {
          boxSizing: "border-box",
          display: "flex",
          borderRadius: "0.5rem",
          border: "1px solid transparent",
          width: "100%",
          backgroundColor: "#f7f7f7",
          overflow: "hidden",

          "&:focus-within": {
            borderColor: theme.palette.primary.main,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box component="label" sx={{ position: "relative", flexBasis: "100%" }}>
        <Box
          component="input"
          sx={[
            {
              boxSizing: "border-box",
              margin: 0,
              border: 0,
              borderRadius: "0.5rem",
              padding: "0.64rem 1rem",
              minWidth: 0,
              width: "100%",
              fontFamily: "inherit",
              fontSize: "1rem",
              lineHeight: 1,
              backgroundColor: "transparent",
              appearance: "textfield",
              transition: "background-color .3s",
              outline: "none",
            },
            ...(Array.isArray(inputSx) ? inputSx : [inputSx]),
          ]}
          type="search"
          placeholder={placeholder}
          value={value ?? ""}
          onChange={handleChange}
        />
      </Box>
      <Box
        sx={{
          flex: "0 0 auto",
          display: "flex",
          alignItems: "center",
          px: (theme) => theme.spacing(2),
          color: (theme) => theme.palette.text.primary,
        }}
      >
        <SearchIcon height="1rem" width="1rem" />
      </Box>
    </Box>
  );
};
