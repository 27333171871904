import { Box, styled } from "@mui/material";
import { ReactComponent as Star } from "@shared/assets/star.svg";

type Props = {
  value: number;
  count?: number;
  showCount?: boolean;
};

export const Rating = styled(
  ({ value, count = 0, showCount = false, ...otherProps }: Props) => {
    return (
      <div {...otherProps}>
        <Box sx={{ color: "primary.main" }} component={Star} />
        <span>{`${value.toFixed(1)} ${showCount ? `(${count})` : ""}`}</span>
      </div>
    );
  }
)(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  columnGap: theme.spacing(1),
  color: theme.palette.text.primary,
  textAlign: "center",
  fontSize: "0.75rem",
  lineHeight: 1.5,
  whiteSpace: "nowrap",
}));
