import {Box} from "@mui/material";
import {ReactComponent as Star} from "@shared/assets/star.svg";
import {BusinessCardReview} from "../types";
import {
  Avatar,
  Button,
  Column,
  DateTime,
  FieldError,
  Input,
  Link,
} from "@shared/ui";
import {useState} from "react";
import {ReactComponent as EditIcon} from "@shared/assets/edit.svg";
import {styled} from "@mui/joy";
import {getLogoPlaceholder} from "@shared/lib/utils";
import {yupResolver} from "@hookform/resolvers/yup";
import {object, string} from "yup";
import {Controller, useForm} from "react-hook-form";
import {CreateFormData} from "@entities/orders-responses";
import {useCreateComment, useUpdateComment} from "@entities/orders-responses/hooks/useCreateComment";

type RatingProps = {
  value: number;
};
const Rating = ({value}: RatingProps) => (
  <Box sx={{display: "flex", gap: 1, mb: 2}}>
    {Array.from({length: 5}).map((_, index) => (
      <Star
        key={index}
        width="0.875rem"
        height="0.875rem"
        color={index < Math.floor(value) ? "#F0EE0E" : "#D9D9D9"}
      />
    ))}
  </Box>
);

type HeaderProps = {
  src: string;
  title: string;
  name: string;
  date: Date;
};

const Header = ({src, title, name, date}: HeaderProps) => (
  <Column>
    <Link href={src} text={title} sx={{fontWeight: 700}}/>
    <Box sx={{fontSize: "0.875rem", lineHeight: 1.5}}>{name}</Box>
    <DateTime date={date}/>
  </Column>
);

const ReplyButton = styled(Button)(({theme}) => ({
  alignSelf: "flex-end",
  justifyContent: "center",
  fontWeight: 700,
  width: "12.75rem",
  paddingTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(2.5),
}));

const validationSchema = object({
  content: string()
    .optional()
    .nullable()
    .required("Обязательное поле")
    .max(4000, "Максимум 4000 символов"),
});

type Props = BusinessCardReview & {
  onComment?: () => void;
  businessCardId: string;
};

export const ReviewCard = ({
  avatarSrc,
  rating,
  src,
  title,
  name,
  date,
  text,
  comments,
  isCommentAllowed,
  businessCardId,
  onComment,
  id
}: Props) => {
  const comment = comments?.[0];
  const [isEditMode, setIsEditMode] = useState(false);
  const handleEditClick = () => setIsEditMode((value) => !value);

  const canEdit = (isEditMode || !comment) && isCommentAllowed;
  const {control, handleSubmit, reset} = useForm<CreateFormData>({
    defaultValues: {content: comment?.content},
    resolver: yupResolver(validationSchema),
  });
  const {createComment} = useCreateComment(businessCardId, id);
  const {updateComment} = useUpdateComment(comment?.id)
  const submitForm = handleSubmit(async (formData: CreateFormData) => {
    comment?.id ? updateComment(formData)
        .then(() => reset())
        .then(onComment) :
      createComment(formData)
        .then(() => reset())
        .then(onComment);
  });

  return (
    <Box sx={{borderBottom: "1px solid #E4E4E4", pb: 3}}>
      <Box sx={{display: "flex", gap: 2, mb: 3}}>
        <Avatar src={avatarSrc}>{getLogoPlaceholder(title)}</Avatar>
        <Header src={src} title={title} name={name} date={new Date(date)}/>
      </Box>
      <Rating value={rating}/>
      <Column sx={{pl: 9, gap: 4}}>
        <Box sx={{color: "#000", lineHeight: 1.5}}>{text}</Box>
        <Box component="form" onSubmit={submitForm}>
          <Column sx={{gap: 2}}>
            <Controller
              control={control}
              name="content"
              render={({field, fieldState}) => (
                <>
                  <FieldError
                    error={fieldState.error?.message}
                    hideError={
                      fieldState.error?.type === "required" ||
                      fieldState.error?.type === "optionality"
                    }
                  >
                    {(field.value || isCommentAllowed) &&
                      <Input
                        placeholder={
                          field.value ? undefined : "Написать комментарий"
                        }
                        value={field.value}
                        onChange={field.onChange}
                        readonly={!canEdit}
                        endAdornment={
                          comment && isCommentAllowed && (
                            <Box
                              onClick={handleEditClick}
                              sx={{cursor: "pointer"}}
                              children={<EditIcon/>}
                            />
                          )
                        }
                      />
                    }
                  </FieldError>
                  {canEdit && (
                    <ReplyButton
                      text={comment ? "Сохранить" : "Ответить"}
                      disabled={!canEdit || !field.value?.length}
                    />
                  )}
                </>
              )}
            />
          </Column>
        </Box>
      </Column>
    </Box>
  );
};
