import { AxiosError } from "axios";
import { enqueueSnackbar } from "notistack";

export type FieldError = {
  fieldName: string;
  fieldError: string;
};

export type ResponseError = {
  globalErrors: string[];
  fieldErrors: FieldError[];
  success: boolean;
};

export const showErrorMessages = (error: AxiosError<ResponseError>) => {
  const errors = error.response?.data;

  const globalMessages = errors?.globalErrors ?? [];

  const fieldMessages = errors?.fieldErrors
    ? errors.fieldErrors.map((msg) => `Поле ${msg.fieldName} ${msg.fieldError}`)
    : [];

  [...globalMessages, ...fieldMessages].forEach((msg) =>
    enqueueSnackbar(msg, { variant: "error" })
  );
};
