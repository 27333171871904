import { Box } from "@mui/material";
import { Column } from "@shared/ui";
import { CardStatistics } from "../types";
import { OrdersResponsesStatistics } from "./OrdersResponsesStatistics";

type Props = {
  serviceName: string;
  serviceTypes: string | string[];
  targetType: "sender" | "receiver";
  target?: string;
  text: string;
  statistics: CardStatistics;
  onLike?: () => void;
  onDislike?: () => void;
};

export const OrdersShortDetails = ({
  serviceName,
  serviceTypes,
  targetType,
  target,
  text,
  statistics,
  onLike,
  onDislike,
}: Props) => {
  return (
    <Column
      sx={{ color: "text.primary", lineHeight: 1.5, gap: 0.75, minWidth: 0 }}
    >
      <Box sx={{ fontSize: "1.125rem" }}>{serviceName}</Box>
      {target && (
        <Box
          sx={{
            color: "text.disabled",
            fontSize: ".875rem",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >{`${
          targetType === "sender" ? "Отправитель" : "Получатель"
        } заказа: ${target}`}</Box>
      )}
      <Box sx={{ color: "text.disabled", fontSize: ".875rem" }}>
        {`Тип услуги: ${
          Array.isArray(serviceTypes) ? serviceTypes.join(", ") : serviceTypes
        }`}
      </Box>
      <Box>{text}</Box>
      {statistics && (
        <OrdersResponsesStatistics
          {...statistics}
          onLike={onLike}
          onDislike={onDislike}
        />
      )}
    </Column>
  );
};
