import { SxProps, Theme, styled } from "@mui/material";
import { ChangeEvent, PropsWithChildren, ReactNode } from "react";
import { InputContainer } from "../InputContainer";

const StyledTextarea = styled("textarea")(({ theme }) => ({
  flex: 1,
  resize: "none",
  backgroundColor: theme.palette.secondary.contrastText,
  fontSize: "1rem",
  lineHeight: 1,
  border: "none",
  padding: 0,
  color: theme.palette.secondary.light,
  "&::placeholder": {
    color: theme.palette.text.disabled,
  },
  "&:focus": {
    outline: "none",
  },
}));

type Props = PropsWithChildren<{
  id?: string;
  label?: string;
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  sx?: SxProps<Theme>;
  rows?: number;
  readOnly?: boolean;
}>;

export const Textarea = ({
  id,
  label,
  value,
  onChange,
  placeholder,
  required,
  disabled,
  children,
  startAdornment,
  endAdornment,
  rows,
  sx,
  ...otherProps
}: Props) => {
  const handler = (event: ChangeEvent<HTMLTextAreaElement>) =>
    onChange?.(event.target.value);

  return (
    <InputContainer
      id={id}
      label={label}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      disabled={disabled}
      required={required}
      sx={sx}
    >
      <StyledTextarea
        {...otherProps}
        value={value}
        onChange={handler}
        placeholder={placeholder}
        rows={rows}
        sx={{ bgcolor: disabled ? "#F7F7F7" : "#fff" }}
      />
    </InputContainer>
  );
};
