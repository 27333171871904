import { Box } from "@mui/material";
import {
  ViewBusinessCardControls,
  ViewBusinessCardGeneralInfo,
  ViewBusinessCardHeader,
  businessModel,
} from "@entities/business";
import { Avatar, Column, Loader, TagPrimary, TagPublished } from "@shared/ui";
import { formatDate, getLogoPlaceholder } from "@shared/lib/utils";
import { useAppSelector } from "@app/store";

const avatarSize = "11.25rem";

type LeftProps = {
  avatarSrc?: string;
  avatarPlaceholder?: string;
  date: Date;
};

const Left = ({ avatarSrc, date, avatarPlaceholder }: LeftProps) => {
  return (
    <Column sx={{ fontSize: "1.125rem", lineHeight: 1.5, mt: 2.5 }}>
      <Avatar
        src={avatarSrc}
        sx={{ width: avatarSize, height: avatarSize, mb: 2.5 }}
      >
        {getLogoPlaceholder(avatarPlaceholder)}
      </Avatar>
      <Box sx={{ fontWeight: 700, color: "text.disabled", mb: 0.75 }}>
        Дата создания:
      </Box>
      <Box sx={{ color: "text.primary" }}>{formatDate(date)}</Box>
    </Column>
  );
};

export const DetailCard = () => {
  const data = useAppSelector(businessModel.cardDetailsSelector);
  const mainCard =
    useAppSelector(businessModel.cardSelector)?.mainCard ?? false;

  if (!data) {
    return <Loader />;
  }
  return (
    <Box sx={{ display: "flex", gap: 3 }}>
      <Left
        avatarSrc={data.avatarSrc}
        avatarPlaceholder={data.title}
        date={new Date(data.creationDate)}
      />
      <Column sx={{ flex: 1 }}>
        {data.createdByCurrentAccount && data.status === "PUBLISHED" ? (
          <ViewBusinessCardControls />
        ) : (
          <Box sx={{ height: "4.125rem" }} />
        )}
        <ViewBusinessCardHeader
          name={data.title}
          rating={data.rating}
          reviewsCount={data.reviewsCount}
          tags={
            <>
              {mainCard && <TagPrimary />}
              {data.status === "PUBLISHED" && (
                <TagPublished text="Опубликована" />
              )}
            </>
          }
        />
        <ViewBusinessCardGeneralInfo
          files={data.files}
          services={data.services}
          description={data.description}
          contactPerson={data.contactPerson}
          showContacts={data.showContacts}
        />
      </Column>
    </Box>
  );
};
