import { OrdersResponsesStatistics } from "@entities/marketplace";
import { Box } from "@mui/material";
import {
  Avatar,
  Button,
  Column,
  DateTime,
  FieldError,
  Rating,
  RequiredFieldsError,
  Textarea,
} from "@shared/ui";
import { CreateFormData, OrdersComment } from "../types";
import { Controller, useForm } from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DOMPurify from "dompurify";
import { useCreateComment } from "../hooks/useCreateComment";
import { getLogoPlaceholder } from "@shared/lib/utils";

type CommentProps = OrdersComment & {
  onLike: (id: string) => (() => void) | undefined;
  onDislike: (id: string) => (() => void) | undefined;
};

const Comment = ({
  id,
  avatarSrc,
  rating,
  reviewsCount,
  title,
  name,
  date,
  text,
  statistics,
  onLike,
  onDislike,
}: CommentProps) => {
  return (
    <Column sx={{ pt: 1, pb: 4, px: 5, border: "1px solid #E4E4E4", gap: 1.5 }}>
      <Box sx={{ display: "flex", gap: 5, lineHeight: 1.5 }}>
        <Column gap={1}>
          <Avatar src={avatarSrc} sx={{ width: 56, height: 56 }}>
            {getLogoPlaceholder(title)}
          </Avatar>
          <Rating value={rating} count={reviewsCount} showCount />
        </Column>
        <Column sx={{ minWidth: 0 }}>
          <Box
            sx={{
              color: "#000",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {title}
          </Box>
          <Box sx={{ color: "text.disabled" }}>{name}</Box>
          <DateTime date={date} />
        </Column>
      </Box>
      <Box
        sx={{ ml: 12, maxWidth: "40rem", color: "#000" }}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
      />
      <OrdersResponsesStatistics
        {...statistics}
        onLike={onLike(id)}
        onDislike={onDislike(id)}
        sx={{ mt: 0, ml: 12 }}
        showComments={false}
        showViews={false}
      />
    </Column>
  );
};

const validationSchema = object({
  content: string()
    .optional()
    .nullable()
    .required("Обязательное поле")
    .max(4000, "Максимум 4000 символов"),
});

type Props = {
  comments: OrdersComment[];
  relationId: string;
  businessCardId: string;
  isCommentAllowed: boolean;
  onLike: (id: string) => (() => void) | undefined;
  onDislike: (id: string) => (() => void) | undefined;
  onSubmit?: () => void;
};

export const CommentsForm = ({
  comments,
  relationId,
  businessCardId,
  isCommentAllowed,
  onLike,
  onDislike,
  onSubmit,
}: Props) => {
  const { control, formState, handleSubmit, reset } = useForm<CreateFormData>({
    defaultValues: { content: "" },
    resolver: yupResolver(validationSchema),
  });
  const hasRequiredErrors = Object.values(formState.errors).some(
    (error) => error?.type === "required" || error?.type === "optionality"
  );

  const { createComment } = useCreateComment(businessCardId, relationId, false);

  const submitForm = handleSubmit(async (formData: CreateFormData) => {
    createComment(formData)
      .then(() => reset())
      .then(onSubmit);
  });

  return (
    <Column sx={{ gap: 2, ml: "5.625rem" }}>
      {isCommentAllowed && (
        <Box sx={{ px: 4, py: 3, bgcolor: "#F0F1F3" }}>
          <Box component="form" onSubmit={submitForm}>
            {hasRequiredErrors && <RequiredFieldsError />}
            <Controller
              control={control}
              name="content"
              render={({ field, fieldState }) => (
                <FieldError
                  error={fieldState.error?.message}
                  hideError={
                    fieldState.error?.type === "required" ||
                    fieldState.error?.type === "optionality"
                  }
                >
                  <Textarea
                    placeholder="Написать комментарий"
                    value={field.value}
                    onChange={field.onChange}
                    sx={{ mb: 4 }}
                    required
                  />
                </FieldError>
              )}
            />
            <Button type="submit" text="Комментировать" />
          </Box>
        </Box>
      )}
      {comments.map((comment) => (
        <Comment
          key={comment.id}
          onLike={onLike}
          onDislike={onDislike}
          {...comment}
        />
      ))}
    </Column>
  );
};
