export enum PriceType {
  SERVICE = "FOR_SERVICES",
  HOUR = "IN_AN_HOUR",
  MONTH = "PER_MONTH",
  YEAR = "IN_A_YEAR",
  OTHER = "OTHER",
}

export const PriceTypes = {
  [PriceType.SERVICE]: "За услугу",
  [PriceType.HOUR]: "За час",
  [PriceType.MONTH]: "За месяц",
  [PriceType.YEAR]: "За год",
  [PriceType.OTHER]: "Иное",
};

export const PriceTypesOptions = [
  { id: PriceType.SERVICE, value: PriceTypes[PriceType.SERVICE] },
  { id: PriceType.HOUR, value: PriceTypes[PriceType.HOUR] },
  { id: PriceType.MONTH, value: PriceTypes[PriceType.MONTH] },
  { id: PriceType.YEAR, value: PriceTypes[PriceType.YEAR] },
  { id: PriceType.OTHER, value: PriceTypes[PriceType.OTHER] },
];
