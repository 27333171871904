import { BusinessCardBasicDto } from "@shared/types/businessCard";
import { TagPublished } from "@shared/ui";
import { BusinessCard } from "./BusinessCard";
import { Paths } from "@shared/lib/router";
import { Box } from "@mui/material";
import { MyBusinessCardDetails } from "@entities/business";

type Props = {
  item: BusinessCardBasicDto;
};

export const MyBusinessCardsListItem = ({ item }: Props) => (
  <BusinessCard
    id={item.id}
    sectionPath={Paths.MyBusiness}
    isPrimary={!!item.mainCard}
    sx={{ mt: 2, "&:first-of-type": { mt: 0 } }}
    avatarSrc={item.logo?.path}
    firstName={item.name.ru}
    rating={item.rate}
    reviewsCount={item.reviewCount}
    tags={item.status === "PUBLISHED" && <TagPublished text="Опубликована" />}
    body={
      <Box sx={{ display: "flex", columnGap: 10, mr: 3 }}>
        <MyBusinessCardDetails
          id={item.id}
          name={item.name.ru}
          type={item.cardType.display.ru}
          address={item.address?.ru ?? ""}
          website={item.website}
          viewCount={item.viewsCounter.uniqueUsers}
        />
      </Box>
    }
  />
);
