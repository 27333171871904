import {
  Autocomplete as MuiAutocomplete,
  Box,
  Chip,
  SxProps,
  Theme,
  styled,
} from "@mui/material";
import { Dictionary } from "@shared/types/dictionary";
import { InputContainer } from "../InputContainer";
import { ReactComponent as ArrowDown } from "@shared/assets/arrow-down.svg";
import { Checkbox } from "../Checkbox";
import { ReactComponent as CloseIcon } from "@shared/assets/close.svg";

type FlatOption = Dictionary | NonNullable<Dictionary["children"]>[number];

const StyledTag = styled(Chip)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  height: "1.5rem",
  fontWeight: 400,
  fontSize: "0.875rem",
  lineHeight: 1.5,
  color: theme.palette.text.primary,
  backgroundColor: "#f7f7f7",

  "&.Mui-disabled": {
    opacity: 1,
  },
}));

const Input = styled("input")(({ theme }) => ({
  flex: 1,
  padding: 0,
  backgroundColor: theme.palette.secondary.contrastText,
  fontSize: "1rem",
  lineHeight: 1,
  border: "none",
  color: theme.palette.secondary.light,

  "&::placeholder": {
    color: theme.palette.text.disabled,
  },

  "&:focus": {
    outline: "none",
  },
}));

type Props = {
  sx?: SxProps<Theme>;
  className?: string;
  options: Dictionary[];
  value?: string | string[];
  placeholder?: string;
  multiple?: boolean;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  onChange?: (value: string | string[] | null) => void;
};

export const Autocomplete = ({
  sx,
  className,
  placeholder,
  value,
  multiple = false,
  options,
  label,
  required,
  disabled,
  onChange,
}: Props) => {
  const flatOptions = options.reduce((acc, current) => {
    return current.children
      ? [...acc, current, ...current.children]
      : [...acc, current];
  }, [] as FlatOption[]);

  const autocompleteValue =
    value === undefined
      ? undefined
      : Array.isArray(value)
      ? flatOptions.filter((option) => value.includes(option.id))
      : flatOptions.find((option) => option.id === value) ?? null;

  const handleChange = (
    _event: any,
    value: FlatOption | FlatOption[] | null
  ) => {
    const current = Array.isArray(value)
      ? value.map((v) => v.id)
      : value?.id ?? null;
    onChange?.(current);
  };

  return (
    <MuiAutocomplete
      sx={[
        {
          "& .MuiAutocomplete-tag": {
            margin: (theme) => theme.spacing(0.5, 1),
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      className={className}
      placeholder={placeholder}
      disabled={disabled}
      multiple={multiple}
      value={autocompleteValue}
      options={flatOptions}
      getOptionDisabled={(option) => (option as FlatOption).disabled}
      getOptionLabel={(option) => (option as FlatOption).text.ru}
      // @ts-ignore
      onChange={handleChange}
      renderInput={(params) => (
        <Box sx={{ width: "100%" }} ref={params.InputProps.ref}>
          <InputContainer
            required={required}
            disabled={params.inputProps.disabled}
            label={label}
            startAdornment={params.InputProps.startAdornment}
            endAdornment={<ArrowDown />}
          >
            <Input
              type="text"
              {...params.inputProps}
              sx={{ bgcolor: disabled ? "#f7f7f7" : "#fff" }}
              placeholder={disabled ? "" : placeholder}
            />
          </InputContainer>
        </Box>
      )}
      renderOption={(props, option, { selected }) => (
        <Box
          component="li"
          {...props}
          sx={{ p: (theme) => theme.spacing(1, 2) }}
        >
          {multiple ? (
            <Checkbox
              sx={{ pl: (option as any).parent ? 2 : 0 }}
              label={(option as FlatOption).text.ru}
              checked={selected}
            />
          ) : (
            (option as FlatOption).text.ru
          )}
        </Box>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <StyledTag
            label={(option as FlatOption).text.ru}
            deleteIcon={<CloseIcon />}
            {...getTagProps({ index })}
          />
        ))
      }
    />
  );
};
