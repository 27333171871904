import {
  Avatar,
  Button,
  Column,
  FieldError,
  Modal,
  RequiredFieldsError,
  Textarea,
} from "@shared/ui";
import { Box, Divider, Rating } from "@mui/material";
import { object, string } from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { ReactComponent as Star } from "@shared/assets/star-outlined.svg";
import { Review, useViewReview } from "../hooks/useViewReview";
import {
  useCreateComment,
  useUpdateComment,
} from "@entities/orders-responses/hooks/useCreateComment";
import { getLogoPlaceholder } from "@shared/lib/utils";
import { CreateFormData } from "@entities/orders-responses";
import { ReactComponent as EditIcon } from "@shared/assets/edit.svg";
import { useState } from "react";

type FormData = {
  content: string;
};

type CardProps = Review & { title: string };

const Card = ({ title, avatarSrc, company, name, rating, text }: CardProps) => (
  <Column sx={{ px: 3, mb: 1.5 }}>
    <Box sx={{ mb: 1.5, fontSize: "1.125rem", fontWeight: 700 }}>{title}</Box>
    <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
      <Avatar src={avatarSrc} sx={{ width: "2.5rem", height: "2.5rem" }}>
        {getLogoPlaceholder(company)}
      </Avatar>
      <Column>
        <Box sx={{ fontWeight: 700, mb: 1 }}>{company}</Box>
        <Box sx={{ fontSize: "0.875rem", lineHeight: 1.5 }} children={name} />
      </Column>
    </Box>
    <Rating
      icon={<Star fill="#F0EE0E" width="1.25rem" height="1.25rem" />}
      emptyIcon={<Star width="1.25rem" height="1.25rem" />}
      defaultValue={rating}
      readOnly
      sx={{ mb: 1.5, "& .MuiRating-icon": { paddingRight: 1 } }}
    />
    <Box sx={{ lintHeight: 1.5 }}>{text}</Box>
  </Column>
);

const validationSchema = object({
  content: string()
    .optional()
    .nullable()
    .required("Обязательное поле")
    .max(4000, "Максимум 4000 символов"),
});

type CommentFormProps = {
  relationId: string;
  businessCardId: string;
  onSubmit: () => void;
  comments?: { id: string; content: string }[];
};

const CommentForm = ({
  relationId,
  businessCardId,
  onSubmit,
  comments,
}: CommentFormProps) => {
  const comment = comments?.[0];
  const [isEditMode, setIsEditMode] = useState(false);
  const canEdit = isEditMode || !comment;
  const handleEditClick = () => setIsEditMode((value) => !value);
  const { control, formState, handleSubmit } = useForm<FormData>({
    defaultValues: { content: comment?.content || "" },
    resolver: yupResolver(validationSchema),
  });
  const { createComment } = useCreateComment(businessCardId, relationId);
  const { updateComment } = useUpdateComment(comment?.id || "");
  const hasRequiredErrors = Object.values(formState.errors).some(
    (error) => error?.type === "required" || error?.type === "optionality"
  );

  const submitForm = handleSubmit(async (formData: CreateFormData) => {
    comment?.id
      ? updateComment(formData).then(onSubmit)
      : createComment(formData).then(onSubmit);
    setIsEditMode(false);
  });

  return (
    <Box component="form" sx={{ px: 3 }} onSubmit={submitForm}>
      {hasRequiredErrors && <RequiredFieldsError />}
      <Controller
        control={control}
        name="content"
        render={({ field, fieldState }) => (
          <FieldError
            error={fieldState.error?.message}
            hideError={
              fieldState.error?.type === "required" ||
              fieldState.error?.type === "optionality"
            }
          >
            <Textarea
              placeholder="Ответить"
              value={field.value}
              onChange={field.onChange}
              sx={{ mb: 4 }}
              required
              readOnly={!canEdit}
              endAdornment={
                comment && (
                  <Box
                    onClick={handleEditClick}
                    sx={{ cursor: "pointer" }}
                    children={<EditIcon />}
                  />
                )
              }
            />
          </FieldError>
        )}
      />

      {canEdit && (
        <Button
          text={comment ? "Сохранить" : "Ответить"}
          type="submit"
          sx={{
            px: 1,
            width: "12.5rem",
            display: "block",
            ml: "auto",
            fontSize: "0.875rem",
            lineHeight: 1.5,
          }}
        />
      )}
    </Box>
  );
};

type Props = {
  offerId: string;
  answerId: string;
  open: boolean;
  onClose: () => void;
};

export const ViewReviewModal = ({
  offerId,
  answerId,
  open,
  onClose,
}: Props) => {
  const { order, answer, refetch } = useViewReview(offerId, answerId);

  return (
    <Modal
      header="Отзывы к проделанной работе в рамках заказа на услугу"
      width="28.5rem"
      open={open}
      onClose={onClose}
      headerSx={{ fontSize: "1rem", px: 13 }}
    >
      <Column sx={{ pb: 4, pt: 1, color: "#4B4B4B" }}>
        {order && (
          <>
            <Card title="Владелец заказа" {...order} />
            {!order.canProcessByCurrentUser &&
              order.businessCardStatus === "PUBLISHED" &&
              order.businessCardReviewStatus === "PUBLISHED" && (
                <CommentForm
                  relationId={order.id}
                  businessCardId={answer?.businessCardId ?? ""}
                  comments={order.comments}
                  onSubmit={refetch}
                />
              )}
            {answer && <Divider sx={{ my: 4 }} />}
          </>
        )}
        {answer && (
          <>
            <Card title="Владелец отклика" {...answer} />
            {!answer.canProcessByCurrentUser &&
              answer.businessCardStatus === "PUBLISHED" &&
              answer.businessCardReviewStatus === "PUBLISHED" && (
                <CommentForm
                  relationId={answer.id}
                  businessCardId={order?.businessCardId ?? ""}
                  comments={answer.comments}
                  onSubmit={refetch}
                />
              )}
          </>
        )}
      </Column>
    </Modal>
  );
};
