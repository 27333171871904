import { MouseEvent } from "react";
import {
  Box,
  SxProps,
  Theme,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { Attachment } from "@shared/types/attachment";
import { ReactComponent as AddIcon } from "@shared/assets/add-galery.svg";

const MAX_SIZE = 5 * 1024 * 1024;
const MAX_FILES = 10;

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "secondary.main",
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: 1.4,
    color: "common.white",
    opacity: 0.9,
    borderRadiuse: 0.5,
    maxWidth: "100%",
    padding: theme.spacing(1.5625, 2.1875),
  },
}));

type Props = {
  sx?: SxProps<Theme>;
  className?: string;
  attachments?: Attachment[] | null;
  onAdd?: (file: File[]) => void;
  onRemove?: (id: string) => void;
};

export const GalleryFileInput = ({
  sx,
  className,
  attachments,
  onAdd,
  onRemove,
}: Props) => {
  const handleDrop = (files: File[]) => onAdd?.(files);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: { "image/jpeg": [".jpeg", ".jpg"], "image/png": [".png"] },
    maxFiles: MAX_FILES - (attachments?.length ?? 0),
    maxSize: MAX_SIZE,
    disabled: (attachments?.length ?? 0) >= MAX_FILES,
    onDrop: handleDrop,
  });

  const handleRemove = (id: string) => (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onRemove?.(id);
  };

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    open();
  };

  return (
    <Box sx={sx} className={className}>
      <StyledTooltip
        placement="top-start"
        title="Добавьте фото о вашей компании (в форматах png/ jpeg, размером не более 5 МБ)"
      >
        <Box
          sx={{
            fontWeight: 700,
            fontSize: "1rem",
            lineHeight: 1.5,
            color: "text.primary",
          }}
        >
          Галерея
        </Box>
      </StyledTooltip>
      <Box
        {...getRootProps()}
        sx={{
          minWidth: "16.4375rem",
          outline: "none",
        }}
      >
        <input {...getInputProps()} />
        <Box
          sx={{
            display: "flex",
            columnGap: 3,
            borderRadius: 1,
            border: "1px solid #A9A9A9",
            py: 1,
            px: 2.5,
            bgcolor: "#F0F2F5",
          }}
        >
          <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
            {attachments?.map((attachment) => (
              <Box
                sx={{
                  borderRadius: 1,
                  border: "1px solid #A9A9A9",
                  height: "4.625rem",
                  width: "7.375rem",
                  backgroundImage: `url(${attachment.path})`,
                  backgroundColor: "background.paper",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                onClick={handleRemove(attachment.id)}
              />
            ))}
            {(attachments?.length ?? 0) < MAX_FILES && (
              <Box
                component="button"
                sx={{
                  display: "grid",
                  placeItems: "center",
                  borderRadius: 1,
                  border: "1px solid #A9A9A9",
                  p: 0,
                  height: "4.625rem",
                  width: "7.375rem",
                  backgroundColor: "background.paper",
                  "&:hover": { cursor: "pointer" },
                }}
                onClick={handleOpen}
              >
                <AddIcon />
              </Box>
            )}
          </Box>
          <Box
            sx={{
              ml: "auto",
              minWidth: "17.5rem",
              textAlign: "center",
              fontWeight: 400,
              fontSize: "1rem",
              lineHeight: 1.5,
              cursor: "pointer",
            }}
          >
            <Box component="span" sx={{ color: "text.primary" }}>
              Добавьте файл
            </Box>{" "}
            <Box component="span" sx={{ color: "#969696" }}>
              или перетащите сюда
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
