import { Box } from "@mui/material";
import { Button } from "@shared/ui";
import { useState } from "react";
import { useFilters } from "../hooks";

type Props = {
  openedText?: string;
};

export const WithClosedFilterButtons = ({
  openedText = "Опубликованные",
}: Props) => {
  const { filters, handleChange } = useFilters();
  const [value, setValue] = useState(filters.closed);

  const onChange = (value?: boolean) => {
    setValue(value);
    handleChange("closed")?.(value);
  };

  return (
    <Box sx={{ display: "flex", gap: 1.5, mb: 4 }}>
      <Button
        variant="outlined"
        text="Все"
        sx={{ bgcolor: value === undefined ? "#FCFCCF" : null }}
        onClick={() => onChange(undefined)}
      />
      <Button
        variant="outlined"
        text={openedText}
        sx={{ bgcolor: value === false ? "#FCFCCF" : null }}
        onClick={() => onChange(false)}
      />
      <Button
        variant="outlined"
        text="Закрытые"
        sx={{ bgcolor: value === true ? "#FCFCCF" : null }}
        onClick={() => onChange(true)}
      />
    </Box>
  );
};
