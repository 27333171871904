import { MarketplaceHeader } from "@entities/marketplace";
import { MakeOrderCard } from "./MakeOrderCard";
import { navChain } from "@shared/lib/navChain";

type Props = {
  onMakeOrder?: () => void;
};

export const ServiceCompilationHeader = ({ onMakeOrder }: Props) => {
  const handleMakeOrder = () => onMakeOrder?.();

  return (
    <MarketplaceHeader
      breadcrumbs={[navChain.serviceCompilation]}
      title=" Есть потребность в новых поставщиках услуг?"
      description={[
        "Маркетплейс – это сервис, который объединяет бизнес!",
        "Найдите надежного поставщика товаров и услуг для маркировки за пару кликов.",
      ]}
      cards={<MakeOrderCard onMakeOrder={handleMakeOrder} />}
    />
  );
};
