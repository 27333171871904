import { api } from "@shared/api";
import { showErrorMessages } from "@shared/lib/error";
import { enqueueSnackbar } from "notistack";
import { useMutation } from "react-query";

export const useMyResponsesActions = (id: string) => {
  const discard = useMutation(() => api.discardMyResponse(id), {
    onSuccess: () => {
      enqueueSnackbar("Отклик скрыт и недоступен получателю", {
        variant: "success",
      });
    },
    onError: showErrorMessages,
  });

  const confirm = useMutation(() => api.toWorkMyResponse(id), {
    onError: showErrorMessages,
  });

  return {
    confirm: () => confirm.mutateAsync(),
    discard: () => discard.mutateAsync(),
  };
};
