import { Paths } from "@shared/lib/router";
import { Breadcrumbs, Button, Container, PageTitle } from "@shared/ui";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowRightIcon } from "@shared/assets/arrow-right.svg";
import { navChain } from "@shared/lib/navChain";
import { Box } from "@mui/material";

export const OrderCompilationTitle = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => navigate(Paths.Home);

  return (
    <Container
      sx={{
        pt: (theme) => theme.spacing(2.75),
        pb: (theme) => theme.spacing(1.25),
      }}
    >
      <Breadcrumbs sx={{ mb: 2 }} items={[navChain.orderCompilation]} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          columnGap: (theme) => theme.spacing(3),
        }}
      >
        <PageTitle text="Подборка заказов" />
        <Button
          variant="secondary"
          text="Для потребителей услуг"
          right={<ArrowRightIcon />}
          onClick={handleButtonClick}
        />
      </Box>
    </Container>
  );
};
