import { MakeOrderModal } from "@features/service-order";
import {
  ServiceCompilationFilters,
  ServiceCompilationHeader,
  ServiceCompilationList,
  ServiceCompilationTitle,
} from "@features/service-compilation";
import { useBoolean } from "@shared/hooks";
import { BaseLayout, Container } from "@shared/ui";
import { Header, Footer } from "@widgets";
import { useAppSelector } from "@app/store";
import { userModel } from "@entities/user";
import { toAuth } from "@shared/lib/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import { decodeHash, encodeHash } from "@shared/lib/hash";

enum Modal {
  MakeOrder = "MAKE_ORDER",
}

type EntityModal = {
  name?: Modal;
  id?: string;
};

export const ServiceCompilationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const entityModal = useMemo(
    () => decodeHash<EntityModal>(location.hash.slice(1)),
    [location.hash]
  );

  const [makeOrderIsOpen, makeOrderModal] = useBoolean(
    entityModal?.name === Modal.MakeOrder
  );

  const [currentService, setCurrentService] = useState<string | undefined>(
    makeOrderIsOpen ? entityModal?.id : undefined
  );

  const isSignedIn = useAppSelector(userModel.isSignedInSelector);

  const handleMakeOrder = (id?: string) => {
    if (isSignedIn) {
      setCurrentService(id);
      return makeOrderModal.on();
    }

    const hashObj: EntityModal = { name: Modal.MakeOrder, id };
    toAuth(`/#${encodeHash(hashObj)}`);
  };

  const handleCloseMakeOrder = () => {
    navigate(
      { pathname: location.pathname, search: location.search },
      { replace: true }
    );

    setCurrentService(undefined);
    makeOrderModal.off();
  };

  return (
    <BaseLayout header={<Header />} footer={<Footer />}>
      <ServiceCompilationHeader onMakeOrder={handleMakeOrder} />
      <ServiceCompilationTitle />
      <ServiceCompilationFilters />
      <Container>
        <ServiceCompilationList onOrderService={handleMakeOrder} />
      </Container>
      <MakeOrderModal
        title={currentService ? "Заказать услугу" : "Сформировать заказ"}
        description={currentService ? "Заказать услугу" : "Сформировать заказ"}
        currentService={currentService}
        open={makeOrderIsOpen}
        onClose={handleCloseMakeOrder}
      />
    </BaseLayout>
  );
};
