import { styled } from "@mui/material";
import { ReactComponent as SmallArrowRight } from "@shared/assets/small-arrow-right.svg";
import { useNavigate } from "react-router-dom";

export type BreadcrumbsItem = {
  name: string;
  href?: string;
};

type Props = {
  items: BreadcrumbsItem[];
};

type NavItemProps = {
  isLast: boolean;
} & BreadcrumbsItem;

const NavItem = styled(
  ({ isLast, name, href, ...otherProps }: NavItemProps) => {
    const navigate = useNavigate();
    const handleClick = (
      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
      event.preventDefault();
      event.stopPropagation();
      navigate(href ?? "");
    };

    return (
      <>
        <a
          onClick={isLast ? undefined : handleClick}
          href={href}
          {...otherProps}
        >
          {name}
        </a>
        {!isLast && <SmallArrowRight />}
      </>
    );
  }
)(({ theme, isLast }) => ({
  color: isLast ? theme.palette.text.disabled : "inherit",
  textDecoration: "none",
  cursor: "pointer",
}));

export const Breadcrumbs = styled(({ items, ...otherProps }: Props) => {
  return (
    <div {...otherProps}>
      <NavItem name="Маркетплейс" href="/" isLast={false} />
      {items.map((item, index) => (
        <NavItem
          key={item.name}
          {...item}
          isLast={index === items.length - 1}
        />
      ))}
    </div>
  );
})(({ theme }) => ({
  fontSize: "0.875rem",
  lineHeight: 1.4,
  display: "flex",
  gap: theme.spacing(1),
  alignItems: "center",
  marginBottom: theme.spacing(3),
}));
