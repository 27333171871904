import { Paths } from "@shared/lib/router";
import { Button, Container, PageTitle } from "@shared/ui";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowRightIcon } from "@shared/assets/arrow-right.svg";

export const ServiceCompilationTitle = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => navigate(Paths.OrderCompilation);

  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
        columnGap: (theme) => theme.spacing(3),
        mt: (theme) => theme.spacing(7),
        py: (theme) => theme.spacing(1.25),
      }}
    >
      <PageTitle text="Подборка услуг" />
      <Button
        variant="secondary"
        text="Для поставщиков услуг"
        right={<ArrowRightIcon />}
        onClick={handleButtonClick}
      />
    </Container>
  );
};
