import { useFilters } from "@entities/filters";
import { OfferStateFilter } from "@entities/filters/ui/OfferStateFilter";

export const IncomingOrdersStatusFilter = () => {
  const { handleChange } = useFilters();

  return (
    <OfferStateFilter
      defaultValue={"ACTIVE"}
      onChange={handleChange("offerState")}
      sx={{ gap: 1.5, mb: 4 }}
    />
  );
};
