import { InfinityList, ItemRenderOptions } from "@shared/ui";
import { useMyBusinessCardsList } from "../hooks";
import { BusinessCardBasicDto } from "@shared/types/businessCard";
import { MyBusinessCardsListItem } from "./MyBusinessCardsListItem";

export const MyBusinessCardsList = () => {
  const listData = useMyBusinessCardsList();

  const renderItem = ({ item }: ItemRenderOptions<BusinessCardBasicDto>) => (
    <MyBusinessCardsListItem key={item.id} item={item} />
  );

  return <InfinityList {...listData} renderItem={renderItem} />;
};
