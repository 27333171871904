import { Box, Popover, styled } from "@mui/material";
import { MouseEvent, useState } from "react";
import { Button, Column, Input } from "@shared/ui";

import { ReactComponent as ArrowRight } from "@shared/assets/small-arrow-right.svg";
import { DatePicker } from "@entities/pickers";
import moment from "moment";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "moment/min/locales.min";
import { TimePicker } from "@entities/pickers/TimePicker";

type Props = {
  startDate: Date | null;
  endDate: Date | null;
  onDateTimesChange?: (startDate: Date | null, endDate: Date | null) => void;
};

moment.locale("ru");

type DateTimeInputProps = {
  date: Date | null;
  onChange: (value: Date | null) => void;
};
const DateTimeInput = styled(
  ({ date, onChange, ...otherProps }: DateTimeInputProps) => {
    const formatted = date ? moment(date).format("DD.MM.YYYY") : "";

    return (
      <div {...otherProps}>
        <Input
          sx={{
            border: "none",
            bgcolor: "#F8F8F8",
            "& input": { bgcolor: "#F8F8F8" },
          }}
          readonly
          value={formatted}
        />
        {date && <TimePicker date={date} onChange={onChange} />}
      </div>
    );
  }
)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: theme.spacing(1),
  width: "20rem",
  height: "3.5rem",
  background: "#F8F8F8",
}));

export const DateTimeRangeFilter = ({
  startDate,
  endDate,
  onDateTimesChange,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const handleDatesChange = (start: Date | null, end: Date | null) => {
    onDateTimesChange?.(start, end);
  };

  const handleStartDateChange = (value: Date | null) => {
    const safeEndDate =
      value && endDate && value.getTime() > endDate.getTime() ? value : endDate;

    onDateTimesChange?.(value, safeEndDate);
  };

  const handleEndDateChange = (value: Date | null) =>
    onDateTimesChange?.(startDate, value);

  const handleReset = () => onDateTimesChange?.(null, null);

  return (
    <>
      <Button
        variant="secondary-inverse"
        onClick={handleOpen}
        text="Период"
        right={
          <ArrowRight
            style={{
              fill: "#63666A",
              transform: "rotate(90deg)",
            }}
          />
        }
        sx={{
          justifyContent: "space-between",
          border: "1px solid #E8E8E8",
          py: 0,
          px: 2,
          width: "15rem",
          height: "2rem",
          fontWeight: 400,
          fontSize: "0.875rem",
          lineHeight: 1.4,
          bgcolor: "#fff",
          "& > span": { flex: "0 0 auto" },
        }}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        PaperProps={{ sx: { width: "47.5rem", overflow: "auto" } }}
        onClose={handleClose}
      >
        <Column sx={{ gap: 2, px: 5, pt: 3.5 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <DateTimeInput date={startDate} onChange={handleStartDateChange} />
            <DateTimeInput date={endDate} onChange={handleEndDateChange} />
          </Box>
          <DatePicker
            startDate={startDate}
            endDate={endDate}
            onChange={handleDatesChange}
          />
          <Box
            sx={{
              borderTop: "1px solid #EDEDED",
              py: 1,
              display: "flex",
              justifyContent: "space-between",
              fontSize: "0.875rem",
            }}
          >
            <Button
              variant="link"
              text="Сбросить выбранное"
              onClick={handleReset}
              sx={{ color: "text.disabled" }}
              left={<ArrowRight style={{ transform: "rotate(-180deg)" }} />}
            />
            <Button
              variant="link"
              onClick={handleClose}
              text="Далее"
              sx={{ fontWeight: 700 }}
              right={<ArrowRight />}
            />
          </Box>
        </Column>
      </Popover>
    </>
  );
};
