import { useQuery } from "react-query";
import { api } from "@shared/api";
import { TagPrimary } from "@shared/ui";
import { Paths } from "@shared/lib/router";
import { DictionaryConceptsDto } from "@shared/types/dictionaryConcepts";
import { showErrorMessages } from "@shared/lib/error";
import { businessCardsKeys } from "./businessCardsKeys";

const getServices = (dto: DictionaryConceptsDto): string[] => {
  return [
    dto.display.ru,
    ...(dto.children?.flatMap((child) => getServices(child)) ?? []),
  ];
};

export const useBusinessCardsSlider = () => {
  const params = { offset: 0, limit: 10 };
  const { data, isLoading } = useQuery(
    businessCardsKeys.my(params),
    () => api.getMyBusinessCards(params),
    { onError: showErrorMessages }
  );

  const businessSlides =
    data?.map((item) => ({
      avatarSrc: item.logo?.path,
      rating: item.rate,
      reviewsCount: item.reviewCount,
      header: item.name.ru,
      services: getServices(item.cardType),
      text: "",
      tag: item.mainCard ? <TagPrimary /> : undefined,
      linkUrl: `${Paths.MyBusiness}${item.id}`,
    })) ?? [];

  const hasBusinessCards = !!businessSlides?.length;

  return { businessSlides, hasBusinessCards, isLoading };
};
