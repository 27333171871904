import { Box, styled, SxProps, Theme } from "@mui/material";

type Props = {
  sx?: SxProps<Theme>;
  className?: string;
  selected?: boolean;
  step: number;
  title: string;
  onClick?: () => void;
};

export const Tab = styled(({ step, title, onClick, ...otherProps }: Props) => {
  return (
    <Box onClick={onClick} {...otherProps}>
      <Box>ШАГ {step}</Box>
      <Box>{title}</Box>
    </Box>
  );
})<Props>(({ selected = false, theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(0.5, 2),
  width: "20.5rem",
  backgroundColor: selected ? "#f0ee0e" : "#d9d9d9",
  fontWeight: 400,
  fontSize: "0.875rem",
  lineHeight: 1.5,
  color: theme.palette.common.black,

  "&:after": {
    content: "''",
    position: "absolute",
    top: 0,
    right: 0,
    width: 0,
    height: 0,
    borderTop: "1.5625rem solid transparent",
    borderBottom: "1.5625rem solid transparent",
    borderLeft: "1.5625rem solid",
    borderLeftColor: selected ? "#f0ee0e" : "#d9d9d9",
    transform: "translateX(100%)",
    zIndex: 1,
  },
}));
