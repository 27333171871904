import { BigCard, Section } from "@entities/my-marketplace";
import { useMyResponses } from "@features/orders-responses";
import { mapToShortMyResponse } from "@features/orders-responses/model/orderCardMapper";
import { Box } from "@mui/material";
import { Paths } from "@shared/lib/router";

export const MyResponses = () => {
  const mask = (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        height: "100%",
        pointerEvents: "none",
        "&:before": {
          content: "''",
          position: "absolute",
          top: 0,
          left: 0,
          width: "47rem",
          height: "47rem",
          bgcolor: "#FFFF5B",
          opacity: 0.7,
          filter: "blur(6.25rem)",
          transform: "translate(75%, -15%)",
          zIndex: -1,
        },
      }}
    />
  );

  const { items, isLoading } = useMyResponses();
  const slides = items.map(mapToShortMyResponse);

  return (
    <Section
      card={
        <BigCard
          title="Вы не формировали и не публиковали отклики на чужие заказы"
          description="Перейдите в подборку и найдите подходящие для себя заказы на услуги"
          buttonText="Перейти в подборку заказов  "
          mask={mask}
          linkUrl={Paths.OrderCompilation}
        />
      }
      title={"Мои отклики"}
      hintText="Мои отклики - это ваши предложения на заказы других участников Маркетплейса"
      items={slides ?? []}
      viewAllLink={Paths.MyResponses}
      isLoading={isLoading}
    />
  );
};
