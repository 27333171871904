import { WithClosedFilterButtons } from "@entities/filters/ui/WithClosedFilterButtons";
import {
  MyResponsesList,
  OrdersResponsesFilter,
} from "@features/orders-responses";
import { navChain } from "@shared/lib/navChain";
import { BaseLayout, Breadcrumbs, Container, PageTitle } from "@shared/ui";
import { Header, Footer } from "@widgets";

export const MyResponsesPage = () => (
  <BaseLayout header={<Header />} footer={<Footer />}>
    <Container>
      <Breadcrumbs items={navChain.myResponses} />
      <PageTitle text="Мои отклики" sx={{ mb: 3 }} />
      <OrdersResponsesFilter statusFilter={<WithClosedFilterButtons />} />
      <MyResponsesList />
    </Container>
  </BaseLayout>
);
