import {
  BusinessCardCategoriesFilterSelect,
  Search,
  ServiceTypesFilterSelect,
  Sort,
  useFilters,
} from "@entities/filters";
import { Box } from "@mui/material";

export const BusinessListFilters = () => {
  const { filters, handleChange } = useFilters();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 2.5,
        p: (theme) => theme.spacing(1, 1, 1, 0),
        mb: 2,
        borderTop: "1px solid #E4E4E4",
        boxShadow: "1px 2px 3px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Sort
        defaultValue={
          filters.sort
            ? `${filters.sort}_${filters.order ?? "ASC"}`
            : "POPULARITY_ASC"
        }
        hiddenPrice
        onChange={handleChange("sort", "order")}
      />
      <BusinessCardCategoriesFilterSelect
        defaultValue={filters.cardTypes}
        onChange={handleChange("cardTypes")}
      />
      <ServiceTypesFilterSelect
        defaultValue={filters.services}
        onChange={handleChange("services")}
      />
      <Search
        sx={{ width: "23.4375rem" }}
        inputSx={{ fontSize: "0.875rem", lineHeight: 1.4 }}
        defaultValue={filters.text}
        placeholder="Искать по разделу"
        onChange={handleChange("text")}
      />
    </Box>
  );
};
