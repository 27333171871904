import {
  CommentsForm,
  OrdersResponsesCard,
  OrdersResponsesDetailButton,
} from "@entities/orders-responses";
import { Column, Link, Loader, tagsByStatus } from "@shared/ui";
import { OrdersShortDetails } from "./OrdersShortDetails";
import { Box } from "@mui/material";
import { ResponsePrice, ServicePrice } from "@entities/marketplace";
import { mapStatistics } from "../model/orderCardMapper";
import { useMyOrderDetails } from "../hooks/useMyOrderDetails";
import { useMyOrderDetailsActions } from "../hooks/useMyOrderDetailsActions";
import { useDislikePost, useLikePost } from "@entities/posts";
import { AddReviewModal } from "./AddReviewModal";
import { useBoolean } from "@shared/hooks";
import { toLocaleStr } from "@shared/lib/utils";
import { ViewReviewModal } from "./ViewReviewModal";
import { useQueryClient } from "react-query";
import { ordersResponsesKeys } from "../hooks/ordersResponsesKeys";

export const MyOrderDetails = () => {
  const likePost = useLikePost();
  const dislikePost = useDislikePost();
  const queryClient = useQueryClient();

  const { query, answer, order, comments } = useMyOrderDetails();

  const {
    handleAccept,
    handleDecline,
    handleComplete,
    sendReview,
    shareContacts,
  } = useMyOrderDetailsActions(
    order?.id ?? "",
    answer?.id ?? "",
    query.refetch
  );

  const handleLike = (id: string) =>
    !likePost.isLoading
      ? () => likePost.mutate(id, { onSuccess: () => query.refetch() })
      : undefined;

  const handleDislike = (id: string) =>
    !dislikePost.isLoading
      ? () => dislikePost.mutate(id, { onSuccess: () => query.refetch() })
      : undefined;

  const [sendReviewIsOpen, sendReviewModal] = useBoolean(false);
  const [viewReviewIsOpen, viewReviewModal] = useBoolean(false);

  const handleSendReview = async (data: { content: string; grade: number }) =>
    sendReview({
      grade: data.grade,
      content: toLocaleStr(data.content),
      attachments: [],
    })
      .then(() =>
        queryClient.invalidateQueries(
          ordersResponsesKeys.reviews(order?.id, answer?.id)
        )
      )
      .then(sendReviewModal.off);

  if (!answer || !order) {
    return <Loader />;
  }
  const answerIsActive = ["PUBLISHED", "ACCEPTED", "IN_WORK"].includes(
    answer.status
  );

  return (
    <Column sx={{ gap: 2, mb: 4.5, minWidth: 0 }}>
      <OrdersResponsesCard
        header="Заказ"
        avatarSrc={order.businessCard.logo?.path}
        tag={
          tagsByStatus[order.status === "CANCELLED" ? "CLOSED" : order.status]
        }
        rating={order.businessCard?.rate}
        reviewsCount={order.businessCard.reviewCount}
        price={
          order.price && (
            <ServicePrice value={order.price} type={order.priceUnits} />
          )
        }
        title={order?.businessCard.name.ru}
        name={order?.creator.name}
        date={new Date(order?.createdDateTime)}
        body={
          <OrdersShortDetails
            type={
              order.serviceTypes?.map((type) => type.display.ru).join(", ") ??
              ""
            }
            title={order.title?.ru}
            text={order.content?.ru}
          />
        }
        statistics={mapStatistics(order)}
        showComments={false}
        onLike={handleLike(order.id)}
        onDislike={handleDislike(order.id)}
      />
      <OrdersResponsesCard
        header="Отклик"
        avatarSrc={answer.businessCard.logo?.path}
        tag={tagsByStatus[answer.status]}
        rating={answer.businessCard.rate}
        reviewsCount={answer.businessCard.reviewCount}
        price={<ResponsePrice value={answer.price} type={answer.priceUnits} />}
        title={answer.businessCard.name.ru}
        name={answer.creator.name}
        date={new Date(answer.createdDateTime)}
        body={
          <OrdersShortDetails
            title={answer.title.ru}
            text={answer.content.ru}
          />
        }
        statistics={mapStatistics(answer)}
        showComments={false}
        onLike={handleLike(answer.id)}
        onDislike={handleDislike(answer.id)}
      />
      <Box
        sx={{
          p: 2.5,
          pl: 5.5,
          fontWeight: 700,
          lineHeight: 1.5,
          border: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        Комментарии: {answer.commentsCount}
      </Box>
      <CommentsForm
        relationId={answer.id}
        businessCardId={order.businessCard.id}
        comments={comments}
        isCommentAllowed={answer.isCommentAllowed}
        onLike={handleLike}
        onDislike={handleDislike}
        onSubmit={() => query.refetch()}
      />
      {answerIsActive && (
        <Link
          text="Поделитесь контактами своей организации"
          href=""
          onClick={shareContacts}
        />
      )}
      {!answer?.hasCurrentAccountReview && answer?.status === "COMPLETED" && (
        <Link text="Добавить отзыв" href="" onClick={sendReviewModal.on} />
      )}
      {answer?.hasCurrentAccountReview && answer?.status === "COMPLETED" && (
        <Link text="Посмотреть отзывы" href="" onClick={viewReviewModal.on} />
      )}
      <Box sx={{ display: "flex", justifyContent: "center", gap: 3.75 }}>
        {answerIsActive && (
          <OrdersResponsesDetailButton
            text="Отклонить"
            variant="secondary-inverse"
            onClick={handleDecline}
          />
        )}
        {answer.status === "PUBLISHED" && (
          <OrdersResponsesDetailButton text="Принять" onClick={handleAccept} />
        )}
        {answer.status === "IN_WORK" && (
          <OrdersResponsesDetailButton
            text="Завершить"
            onClick={() => handleComplete().then(() => sendReviewModal.on())}
          />
        )}
      </Box>
      <AddReviewModal
        avatarSrc={answer.businessCard.logo?.path}
        company={answer.businessCard.name.ru}
        open={sendReviewIsOpen}
        onClose={sendReviewModal.off}
        onConfirm={handleSendReview}
      />
      <ViewReviewModal
        offerId={order.id}
        answerId={answer.id}
        open={viewReviewIsOpen}
        onClose={viewReviewModal.off}
      />
    </Column>
  );
};
