export enum Sort {
  PopularityAsc = "POPULARITY_ASC",
  PopularityDesc = "POPULARITY_DESC",

  NewestAsc = "NEWEST_ASC",
  NewestDesc = "NEWEST_DESC",

  PriceAsc = "PRICE_ASC",
  PriceDesc = "PRICE_DESC",

  RatingAsc = "RATING_ASC",
  RatingDesc = "RATING_DESC",
}
