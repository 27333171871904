import { WithClosedFilterButtons } from "@entities/filters/ui/WithClosedFilterButtons";
import {
  IncomingResponsesList,
  OrdersResponsesFilter,
} from "@features/orders-responses";
import { navChain } from "@shared/lib/navChain";
import { BaseLayout, Breadcrumbs, Container, PageTitle } from "@shared/ui";
import { Header, Footer } from "@widgets";

export const IncomingResponsesPage = () => {
  return (
    <BaseLayout header={<Header />} footer={<Footer />}>
      <Container>
        <Breadcrumbs items={navChain.incomingResponses} />
        <PageTitle text="Отклики на мои заказы" sx={{ mb: 3 }} />
        <OrdersResponsesFilter
          statusFilter={<WithClosedFilterButtons openedText="Открытые" />}
          hideBusinessCards
        />
        <IncomingResponsesList />
      </Container>
    </BaseLayout>
  );
};
