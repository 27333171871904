import { Box, SxProps, Theme, styled } from "@mui/material";
import { PropsWithChildren, ReactNode } from "react";
import { Column } from "../Column";

type Props = PropsWithChildren<{
  id?: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  sx?: SxProps<Theme>;
}>;

export const InputContainer = styled(
  ({
    children,
    id,
    label,
    disabled,
    required,
    startAdornment,
    endAdornment,
    sx,
    ...otherProps
  }: Props) => {
    return (
      <Column gap={0.25} sx={sx}>
        {label && (
          <Box
            component="label"
            htmlFor={id}
            sx={{
              fontSize: ".875rem",
              lineHeight: 1.5,
              color: required && !disabled ? "red" : "text.disabled",
            }}
          >
            {required && !disabled ? `${label} *` : label}
          </Box>
        )}
        <Box {...otherProps}>
          {startAdornment}
          <Box sx={{ display: "flex", flex: 1 }}>{children}</Box>
          {endAdornment}
        </Box>
      </Column>
    );
  }
)(({ theme, disabled }) => ({
  display: "flex",
  flexWrap: "wrap",
  border: "1px solid #e8e8e8",
  borderRadius: "0.5rem",
  padding: theme.spacing(1.28, 2),
  alignItems: "center",
  flex: "0 0 auto",
  backgroundColor: disabled ? "#F7F7F7" : theme.palette.secondary.contrastText,
  fontSize: "1rem",
  lineHeight: 1,
  color: theme.palette.secondary.light,
}));
