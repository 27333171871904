import { styled } from "@mui/material";
import { Column, ViewCount } from "@shared/ui";
import { Link, Title, Value } from "./CoreTexts";

type Props = {
  id: string;
  name: string;
  type: string;
  address?: string;
  website?: string;
  viewCount: number;
};

export const BusinessCardDetails = styled(
  ({ name, type, address, website, viewCount, ...otherProps }: Props) => {
    return (
      <div {...otherProps}>
        <Column
          sx={{
            display: "grid",
            gridTemplateColumns: "min-content auto",
            columnGap: "2.5rem",
            rowGap: "0.5rem",
            width: "32rem",
          }}
        >
          <Title text="Наименование" />
          <Value text={name} />
          <Title text="Тип" />
          <Value text={type} />
          {!!address && (
            <>
              <Title text="Адрес" />
              <Value text={address} />
            </>
          )}
          <ViewCount count={viewCount} />
        </Column>
        {!!website && (
          <Column>
            <Title text="Веб сайт" />
            <Link text={website} />
          </Column>
        )}
      </div>
    );
  }
)(({ theme }) => ({
  display: "flex",
  lineHeight: 1.5,
  gap: theme.spacing(10),
}));
