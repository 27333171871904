import { BusinessCardBasicDto } from "@shared/types/businessCard";
import { BusinessCard } from "./BusinessCard";
import { Paths } from "@shared/lib/router";
import { Box } from "@mui/material";
import { BusinessCardDetails } from "@entities/business";

type Props = {
  item: BusinessCardBasicDto;
};

export const BusinessCardsListItem = ({ item }: Props) => (
  <BusinessCard
    id={item.id}
    sectionPath={Paths.Business}
    sx={{ mt: 2, "&:first-of-type": { mt: 0 } }}
    avatarSrc={item.logo?.path}
    firstName={item.name.ru}
    rating={item.rate}
    reviewsCount={item.reviewCount ?? 0}
    body={
      <Box sx={{ display: "flex", columnGap: 10, mr: 3 }}>
        <BusinessCardDetails
          id={item.id}
          name={item.name.ru}
          type={item.cardType.display.ru}
          website={item.website}
          viewCount={item.viewsCounter.uniqueUsers}
        />
      </Box>
    }
  />
);
