import { BusinessCardStats } from "@shared/types/statistic";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Box,
  Collapse,
} from "@mui/material";
import { useBoolean } from "@shared/hooks";
import { ReactComponent as Arrow } from "@shared/assets/small-arrow-right.svg";
import {
  BodyTableCeil,
  BodyTableRow,
  HeadTableCeil,
  HeadTableRow,
} from "./table";
import { format } from "date-fns";

type Props = {
  statistic: BusinessCardStats;
};

export const ViewsStatistic = ({ statistic }: Props) => {
  const [isTableOpen, table] = useBoolean(false);
  const [isServicesOpen, servicesTable] = useBoolean();
  const [isOffersOpen, offersTable] = useBoolean();

  const handleClick = (handler: () => void, count: number) => {
    if (count) return handler;
  };

  return (
    <Box>
      <Box
        sx={{
          fontWeight: 400,
          fontSize: "1.125rem",
          lineHeight: 1.5,
          color: "#63666A",
          "&:hover": { cursor: "pointer" },
        }}
        onClick={table.toggle}
      >
        <Arrow
          style={{
            display: "inline-block",
            marginRight: 16,
            fill: "#63666A",
            transform: isTableOpen ? "rotate(270deg)" : "rotate(90deg)",
          }}
        />
        Статистика по просмотрам карточки бизнеса{" "}
        {statistic.businessCard.name.ru} за период{" "}
        <Box component="span" sx={{ fontWeight: "700", color: "#363634" }}>
          {statistic.fromDate &&
            format(new Date(statistic.fromDate), "dd.MM.yyyy")}
          {"-"}
          {statistic.toDate && format(new Date(statistic.toDate), "dd.MM.yyyy")}
        </Box>
      </Box>
      <Collapse in={isTableOpen}>
        <TableContainer>
          <Table>
            <TableHead>
              <HeadTableRow>
                <HeadTableCeil width="25%" />
                <HeadTableCeil width="25%" align="center">
                  Количество просмотров
                </HeadTableCeil>
                <HeadTableCeil width="50%" />
              </HeadTableRow>
            </TableHead>
            <TableBody>
              <BodyTableRow>
                <BodyTableCeil type="category">
                  Просмотры карточки
                </BodyTableCeil>
                <BodyTableCeil align="center">
                  {statistic.views.views}
                </BodyTableCeil>
                <BodyTableCeil />
              </BodyTableRow>
              <BodyTableRow>
                <BodyTableCeil type="category">
                  Поиск данной карточки
                </BodyTableCeil>
                <BodyTableCeil align="center">
                  {statistic.views.viewsInList}
                </BodyTableCeil>
                <BodyTableCeil />
              </BodyTableRow>
              <BodyTableRow
                onClick={handleClick(
                  servicesTable.toggle,
                  statistic.views.services.length
                )}
              >
                <BodyTableCeil
                  type="category"
                  withArrow={!!statistic.views.services.length}
                  open={isServicesOpen}
                >
                  Просмотры услуг
                </BodyTableCeil>
                <BodyTableCeil align="center">
                  {statistic.views.viewsServices}
                </BodyTableCeil>
                <BodyTableCeil />
              </BodyTableRow>
              {statistic.views.services.map((service) => (
                <BodyTableRow
                  key={service.objectId}
                  type="subcategory"
                  open={isServicesOpen}
                >
                  <BodyTableCeil sx={{ pl: 6.5 }}>
                    {service.name.ru}
                  </BodyTableCeil>
                  <BodyTableCeil align="center">{service.views}</BodyTableCeil>
                  <BodyTableCeil />
                </BodyTableRow>
              ))}
              <BodyTableRow
                onClick={handleClick(
                  offersTable.toggle,
                  statistic.views.offers.length
                )}
              >
                <BodyTableCeil
                  type="category"
                  withArrow={!!statistic.views.offers.length}
                  open={isOffersOpen}
                >
                  Просмотры заказов
                </BodyTableCeil>
                <BodyTableCeil align="center">
                  {statistic.views.viewsOffers}
                </BodyTableCeil>
                <BodyTableCeil />
              </BodyTableRow>
              {statistic.views.offers.map((offer) => (
                <BodyTableRow
                  key={offer.objectId}
                  type="subcategory"
                  open={isOffersOpen}
                >
                  <BodyTableCeil sx={{ pl: 6.5 }}>
                    {offer.name.ru}
                  </BodyTableCeil>
                  <BodyTableCeil align="center">{offer.views}</BodyTableCeil>
                  <BodyTableCeil />
                </BodyTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </Box>
  );
};
