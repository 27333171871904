import { MyOrderDetails } from "@features/orders-responses";
import { navChain } from "@shared/lib/navChain";
import { BaseLayout, Breadcrumbs, Container, PageTitle } from "@shared/ui";
import { Header, Footer } from "@widgets";

export const ViewMyOrderPage = () => (
  <BaseLayout header={<Header />} footer={<Footer />}>
    <Container>
      <Breadcrumbs
        items={[...navChain.myOrders, navChain.create("Просмотр заказа")]}
        sx={{ mb: 3 }}
      />
      <PageTitle text="Просмотр заказа" sx={{ mb: 5 }} />
      <MyOrderDetails />
    </Container>
  </BaseLayout>
);
