import { Box, styled } from "@mui/material";
import { Avatar, Column, DateTime } from "@shared/ui";
import { BusinessCardNotification } from "../types";
import DOMPurify from "dompurify";

import { ReactComponent as HomeIcon } from "@shared/assets/notification-main-icon.svg";
import { ReactComponent as NavigationBusinessIcon } from "@shared/assets/notification-business-icon.svg";
import { ReactComponent as MarketplaceIcon } from "@shared/assets/notification-marketplace-icon.svg";
import { ReactComponent as NewsIcon } from "@shared/assets/notification-news-icon.svg";
import { ReactComponent as QAIcon } from "@shared/assets/notification-qa-icon.svg";

const iconByCategory = {
  HOME: <HomeIcon stroke={"white"} />,
  MARKETPLACE: <MarketplaceIcon stroke={"white"} />,
  BUSINESS: <NavigationBusinessIcon stroke={"white"} />,
  QUESTION: <QAIcon stroke={"white"} />,
  NEWS: <NewsIcon stroke={"white"} />,
};

type HeaderProps = {
  type: string;
  text: string;
};
const Header = styled(({ type, text, ...otherProps }: HeaderProps) => (
  <div {...otherProps}>
    {type} <Box sx={{ color: "#2386E0", lineHeight: 1.5 }} children={text} />
  </div>
))({
  display: "inline-flex",
  whiteSpace: "pre-wrap",
  fontSize: "1.125rem",
  lineHeight: 1.5,
  color: "#000",
});

type TextProps = {
  text: string;
};
const Text = styled(({ text, ...otherProps }: TextProps) => (
  <div
    {...otherProps}
    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
  />
))(({ theme }) => ({
  fontSize: "0.875rem",
  lineHeight: 1.5,
  color: theme.palette.text.primary,
  maxHeight: "1.5rem",
  overflow: "hidden",
}));

const SmallDateTime = styled(DateTime)({
  fontSize: "0.75rem",
  lineHeight: 1.25,
});

type Props = BusinessCardNotification & { read: boolean; onClick?: () => void };

export const NotificationCard = styled(
  ({
    category,
    type,
    title,
    text,
    date,
    onClick,
    read,
    ...otherProps
  }: Props) => {
    return (
      <div {...otherProps} onClick={onClick}>
        <Box sx={{ display: "flex", gap: 4 }}>
          <Avatar>{iconByCategory[category]}</Avatar>
          <Column>
            <Header type={type} text={title} />
            <Text text={text} />
            <SmallDateTime date={new Date(date)} />
          </Column>
        </Box>
      </div>
    );
  }
)(({ theme, read }) => ({
  borderBottom: "1px solid #E4E4E4",
  cursor: "pointer",
  padding: theme.spacing(1, 2),
  background: read ? "#fff" : "#FCFCCF",
}));
