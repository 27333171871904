import { api } from "@shared/api";
import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { OrderPreviewResponse } from "../types";
import { showErrorMessages } from "@shared/lib/error";
import { ordersResponsesKeys } from "./ordersResponsesKeys";
import { Paths } from "@shared/lib/router";

export const useMyOrderPreviewResponses = (): OrderPreviewResponse[] => {
  const { id = "" } = useParams();
  const location = useLocation();

  const { data = [] } = useQuery(
    ordersResponsesKeys.responses(id),
    location.state?.from === Paths.OrderCompilation
      ? () => undefined
      : () => api.getOrderPreviewResponses(id),
    { onError: showErrorMessages }
  );
  return data.map((item) => ({
    id: item.id,
    avatarSrc: item.businessCard.logo?.path,
    rating: item.businessCard.rate,
    reviewsCount: item.businessCard.reviewCount,
    title: item.businessCard.name.ru,
    name: item.creator.name,
    date: new Date(item.createdDateTime),
    text: item.content.ru,
  }));
};
