import { Box, SxProps, Theme, styled } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { Attachment } from "@shared/types/attachment";
import { ReactComponent as EditIcon } from "@shared/assets/edit.svg";
import { ReactComponent as RemoveIcon } from "@shared/assets/remove.svg";

const Action = styled("button")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  columnGap: theme.spacing(1),
  border: "none",
  backgroundColor: "transparent",
  fontWeight: 700,
  fontSize: "0.875rem",
  lineHeight: 1.4,
  color: theme.palette.text.disabled,

  "&:hover": { cursor: "pointer" },
}));

const MAX_SIZE = 5 * 1024 * 1024;

type Props = {
  sx?: SxProps<Theme>;
  className?: string;
  attachment?: Attachment | null;
  onChange?: (file: File | null) => void;
};

export const AddLogoInput = ({
  sx,
  className,
  attachment,
  onChange,
}: Props) => {
  const handleDrop = (files: File[]) => onChange?.(files[0]);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: { "image/jpeg": [".jpeg", ".jpg"], "image/png": [".png"] },
    multiple: false,
    maxSize: MAX_SIZE,
    onDrop: handleDrop,
    onError: (error) => console.log(error),
  });

  const handleRemove = () => onChange?.(null);

  return (
    <Box
      sx={[
        {
          display: "flex",
          flexDirection: "column",
          rowGap: 3,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      className={className}
    >
      <Box
        sx={{
          fontWeight: 400,
          fontSize: "1rem",
          lineHeight: 1.25,
          color: "text.secondary.light",
        }}
      >
        Добавьте логотип Вашей компании, логотип будет отображаться при
        просмотре карточки бизнеса
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          {...getRootProps()}
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            columnGap: 0.5,
            borderRadius: "50%",
            border: "1px solid  #d9d9d9",
            height: "6.875rem",
            width: "6.875rem",
            bgcolor: "#f7f7f7",
            textAlign: "center",
            fontWeight: 400,
            fontSize: "1rem",
            lineHeight: 1.5,
            color: "text.primary",
            backgroundImage: attachment ? `url(${attachment.path})` : undefined,
            backgroundPosition: "center center",
            backgroundSize: "cover",
            cursor: "pointer",
            outline: "none",
          }}
        >
          <input {...getInputProps()} />
          {!attachment && (
            <>
              <Box>+</Box>
              <Box>
                Добавить
                <br />
                логотип
              </Box>
            </>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            rowGap: 2,
          }}
        >
          <Action type="button" onClick={open}>
            <EditIcon /> Изменить
          </Action>
          <Action type="button" onClick={handleRemove}>
            <RemoveIcon /> Удалить
          </Action>
        </Box>
      </Box>
    </Box>
  );
};
