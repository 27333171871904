import { useQuery } from "react-query";
import { businessKeys } from "./businessKeys";
import { api } from "@shared/api";
import { showErrorMessages } from "@shared/lib/error";

export const useBusinessCardById = (id?: string) =>
  useQuery(
    businessKeys.businessCard(id ?? ""),
    () => api.getBusinessCardById(id ?? ""),
    {
      enabled: !!id,
      onError: showErrorMessages,
    }
  );
