import { Paths } from "@shared/lib/router";
import { createBrowserRouter, redirect } from "react-router-dom";
import { ServiceCompilationPage } from "./service-compilation";
import { OrderCompilationPage } from "./order-compilation";
import { MyMarketplacePage } from "./my-marketplace";
import { MyBusinessPage } from "./my-business";
import { ViewMyBusinessCardPage } from "./view-my-business-card";
import { MyOrdersPage } from "./my-orders";
import { IncomingOrdersPage } from "./incoming-orders";
import { MyResponsesPage } from "./my-responses";
import { IncomingResponsesPage } from "./incoming-responses";
import { ViewMyResponsePage } from "./view-my-response";
import { ViewIncomingResponsePage } from "./view-incoming-response";
import { ViewIncomingOrderPage } from "./view-incoming-order";
import { MyOrderPreviewPage } from "./my-order-preview";
import { ViewMyOrderPage } from "./view-my-order";
import { CreateBusinessCardPage } from "./create-business";
import { BusinessPage } from "./business";
import { ViewBusinessCardPage } from "./view-business-card";
import { getDomainUrl } from "@shared/lib/utils";
import { ViewServicePage } from "./view-service";
import { AuthGuard } from "@features/auth";

export const router = createBrowserRouter([
  {
    path: Paths.Home,
    element: <ServiceCompilationPage />,
  },
  {
    path: Paths.OrderCompilation,
    element: <OrderCompilationPage />,
  },
  {
    path: Paths.MyMarketplace,
    element: (
      <AuthGuard>
        <MyMarketplacePage />
      </AuthGuard>
    ),
  },
  {
    path: Paths.MyBusiness,
    element: (
      <AuthGuard>
        <MyBusinessPage />
      </AuthGuard>
    ),
  },
  {
    path: Paths.ViewMyBusinessCard,
    element: (
      <AuthGuard>
        <ViewMyBusinessCardPage />
      </AuthGuard>
    ),
  },
  {
    path: Paths.Business,
    element: (
      <AuthGuard>
        <BusinessPage />
      </AuthGuard>
    ),
  },
  {
    path: Paths.ViewBusinessCard,
    element: (
      <AuthGuard>
        <ViewBusinessCardPage />
      </AuthGuard>
    ),
  },
  {
    path: Paths.MyOrders,
    element: (
      <AuthGuard>
        <MyOrdersPage />
      </AuthGuard>
    ),
  },
  {
    path: Paths.MyOrderPreview,
    element: (
      <AuthGuard>
        <MyOrderPreviewPage />
      </AuthGuard>
    ),
  },
  {
    path: Paths.ViewMyOrder,
    element: (
      <AuthGuard>
        <ViewMyOrderPage />
      </AuthGuard>
    ),
  },
  {
    path: Paths.IncomingOrders,
    element: (
      <AuthGuard>
        <IncomingOrdersPage />
      </AuthGuard>
    ),
  },
  {
    path: Paths.ViewIncomingOrder,
    element: (
      <AuthGuard>
        <ViewIncomingOrderPage />
      </AuthGuard>
    ),
  },
  {
    path: Paths.MyResponses,
    element: (
      <AuthGuard>
        <MyResponsesPage />
      </AuthGuard>
    ),
  },
  {
    path: Paths.ViewMyResponse,
    element: (
      <AuthGuard>
        <ViewMyResponsePage />
      </AuthGuard>
    ),
  },
  {
    path: Paths.IncomingResponses,
    element: (
      <AuthGuard>
        <IncomingResponsesPage />
      </AuthGuard>
    ),
  },
  {
    path: Paths.ViewIncomingResponse,
    element: (
      <AuthGuard>
        <ViewIncomingResponsePage />
      </AuthGuard>
    ),
  },
  {
    path: Paths.CreateBusinessCard,
    element: (
      <AuthGuard>
        <CreateBusinessCardPage />
      </AuthGuard>
    ),
  },
  {
    path: Paths.EditBusinessCard,
    element: (
      <AuthGuard>
        <CreateBusinessCardPage />
      </AuthGuard>
    ),
  },
  {
    path: Paths.ViewService,
    element: (
      <AuthGuard>
        <ViewServicePage />
      </AuthGuard>
    ),
  },
  {
    path: "*",
    loader: () => redirect(getDomainUrl("/404")),
  },
]);
