import { config } from "./config";
import { UserShort } from "@shared/types/user";

export const debounce = (fn: Function | undefined = () => {}, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const unique = <T extends any>(arr: T[]) => {
  return Array.from(new Set(arr));
};

export const getInitials = (first?: string, second?: string) => {
  return (
    [second?.[0], first?.[0]].map((str) => str?.toUpperCase()).join("") ||
    undefined
  );
};

export const getFullName = (first?: string, second?: string) => {
  return (
    [second, first].filter((item) => item !== undefined).join(" ") || undefined
  );
};

export const getUserName = (user: UserShort) =>
  getFullName(user.name, user.lastName) ?? "";

export const getDomainUrl = (path?: string) => {
  return !!path ? `${config.domain}${path}` : config.domain;
};

export const getAuthUrl = (path?: string) => {
  const original = window.location.href.endsWith("/")
    ? window.location.href.slice(0, -1)
    : window.location.href;

  const returnUrl = path ? `${original}${path}` : original;

  return getDomainUrl(`/?backReference=${btoa(returnUrl)}/#auth-user`);
};

export const toAuth = (path?: string) => {
  const authUrl = getAuthUrl(path);
  window.location.href = authUrl;
};

export const formatDate = (date: Date) => {
  const pad = (value: number) => value.toString().padStart(2, "0");

  return (
    [pad(date.getDate()), pad(date.getMonth() + 1), date.getFullYear()].join(
      "."
    ) +
    " " +
    [pad(date.getHours()), pad(date.getMinutes()), pad(date.getSeconds())].join(
      ":"
    )
  );
};

export const toLocaleStr = (value: string) => ({ ru: value, en: "" });

export const getLogoPlaceholder = (value: string = "") => {
  const [first = "", second = ""] = value
    .replace(/[^a-zA-ZЁёА-я0-9]/g, "")
    .split(" ");

  return getInitials(first, second);
};

export const uniqObjectFilter = <T>(
  arr: T[],
  fieldName: string,
  data = new Set()
) => {
  return arr.filter((el) => {
    if (typeof el === "object" && el && fieldName in el) {
      const key = el[fieldName as keyof typeof el];
      return data.has(key) ? false : data.add(key);
    }
    return true;
  });
};
