import { useQuery } from "react-query";
import { userQueryKeys } from "./userQueryKeys";
import { api } from "@shared/api";
import { useAppDispatch } from "@app/store";
import { signIn } from "../model";
import { showErrorMessages } from "@shared/lib/error";

export const useCurrentUser = () => {
  const dispatch = useAppDispatch();

  return useQuery(userQueryKeys.current, api.getCurrentUser, {
    refetchInterval: 60 * 1000,
    onSuccess: (user) => dispatch(signIn(user)),
    onError: showErrorMessages,
  });
};
