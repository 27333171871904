import { CircularProgress } from "@mui/material";

export const Loader = () => (
  <CircularProgress
    color="primary"
    size={32}
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }}
  />
);
