import {
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  styled,
  TableCellProps,
} from "@mui/material";
import { ReactComponent as Arrow } from "@shared/assets/small-arrow-right.svg";

export const HeadTableRow = styled(MuiTableRow)(({ theme }) => ({}));

export const HeadTableCeil = styled(MuiTableCell)(({ theme }) => ({
  padding: theme.spacing(0.5, 2.5),
  fontFamily: "Circe",
  fontWeight: 400,
  fontSize: "1rem",
  lineHeight: 1.5,
  color: "#63666A",
}));

type BodyTableRowProps = {
  type?: "default" | "subcategory";
  open?: boolean;
};

export const BodyTableRow = styled(MuiTableRow)<BodyTableRowProps>(
  ({ theme, type = "default", open = false, onClick }) => ({
    background: theme.palette.background.paper,

    "&:hover": { cursor: onClick ? "pointer" : "default" },

    ...(type === "subcategory" && {
      background: "#FCFCCF",
      visibility: open ? "visible" : "collapse",
    }),
  })
);

type BodyTableCeilProps = TableCellProps & {
  type?: "default" | "category";
  withArrow?: boolean;
  open?: boolean;
};

export const BodyTableCeil = styled(
  ({
    children,
    type = "default",
    withArrow = false,
    open = false,
    ...other
  }: BodyTableCeilProps) => (
    <MuiTableCell {...other}>
      {children}{" "}
      {type === "category" && withArrow && (
        <Arrow
          style={{
            display: "inline-block",
            marginLeft: 8,
            fill: "#3D3D3D",
            transform: open ? "rotate(270deg)" : "rotate(90deg)",
          }}
        />
      )}
    </MuiTableCell>
  )
)<BodyTableCeilProps>(({ theme, type = "default" }) => ({
  padding: theme.spacing(0.5, 2.5),
  borderBottom: "1px solid rgba(169, 169, 169, 0.51)",
  fontFamily: "Circe",
  fontWeight: 400,
  fontSize: "0.875rem",
  lineHeight: "1.5rem",
  color: "#363634",

  ...(type === "category" && {
    fontWeight: 700,
    fontSize: "1.125rem",
    lineHeight: 1.5,
    color: "#3D3D3D",
  }),
}));

export const BodyTableCeilInner = styled("div")(() => ({
  display: "-webkit-box",
  overflow: "hidden",
  "-webkit-box-orient": "vertical",
  "-webkit-line-clamp": "3",
}));
