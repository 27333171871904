import { CssBaseline, ThemeProvider } from "@mui/material";
import { router } from "@pages";
import { theme } from "@shared/lib/theme";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { store } from "./store";
import { WithAuth } from "./WithAuth";
import { SnackbarProvider, closeSnackbar } from "notistack";
import { Box } from "@mui/material";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider
            action={(key) => (
              <Box
                onClick={() => closeSnackbar(key)}
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                  "&:hover": { cursor: "pointer" },
                }}
              />
            )}
          >
            <WithAuth>
              <RouterProvider router={router} />
            </WithAuth>
          </SnackbarProvider>
        </ThemeProvider>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
