import { Button, Column, Modal } from "@shared/ui";
import { Box } from "@mui/material";

type Props = {
  reviews: Record<string, number>;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const names: Record<string, string> = {
  CANCELLED: "Закрыт",
  PUBLISHED: "Опубликован",
  ACCEPTED: "Принят",
  IN_WORK: "В Работе",
  COMPLETED: "Завершен",
  DECLINED: "Отклонен",
};

export const ConfirmCancelModal = ({
  reviews,
  open,
  onClose,
  onConfirm,
}: Props) => {
  return (
    <Modal
      header="Подтвердить закрытие заказа"
      width="32rem"
      open={open}
      onClose={onClose}
      headerSx={{ fontSize: "1rem" }}
    >
      <Column sx={{ px: 3, py: 4, color: "#4B4B4B" }}>
        <Box sx={{ mb: 3 }}>
          В рамках текущего заказа имеются незавершенные отклики
        </Box>
        {Object.entries(reviews)
          .filter(([, count]) => count > 0)
          .map(([status, count]) => (
            <Box key={status}>
              Со статусом “{names[status]}” - {count}
            </Box>
          ))}
        <Button
          text="Подтвердить"
          onClick={onConfirm}
          sx={{ mt: 4, width: "8.2rem", alignSelf: "center" }}
        />
      </Column>
    </Modal>
  );
};
