import { Box } from "@mui/material";
import { Paths } from "@shared/lib/router";
import { Button, PageTitle } from "@shared/ui";
import { useNavigate } from "react-router-dom";

export const MyBusinessListTitle = () => {
  const navigate = useNavigate();
  const handleClick = () => navigate(Paths.CreateBusinessCard);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
        columnGap: 3,
        mt: 1,
        pb: 1.25,
      }}
    >
      <PageTitle text="Мои карточки" />
      <Button
        text="Создать карточку бизнеса"
        onClick={handleClick}
        sx={{
          fontSize: "0.875rem",
          lineHeight: 1.5,
          maxWidth: "13.75rem",
          px: 3,
        }}
      />
    </Box>
  );
};
