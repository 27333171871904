import {
  OrdersResponsesHeader,
  OrdersResponsesStatistics,
} from "@entities/marketplace";
import { CardStatistics } from "@entities/marketplace/types";
import { Box, styled } from "@mui/material";
import { User, UserData } from "@shared/ui";
import { ReactNode } from "react";

const Title = styled("span")(({ theme }) => ({
  fontSize: "1.5rem",
  lineHeight: 1.35,
  color: theme.palette.text.primary,
  fontWeight: 700,
}));

type Props = {
  header: string;
  tag: ReactNode;
  price: ReactNode;
  title: string;
  name: string;
  date: Date;
  body: ReactNode;
  statistics: CardStatistics;
  showComments?: boolean;
  onLike?: () => void;
  onDislike?: () => void;
} & UserData;

export const OrdersResponsesCard = styled(
  ({
    header,
    tag,
    avatarSrc,
    rating,
    reviewsCount,
    price,
    title,
    name,
    date,
    body,
    statistics,
    showComments = true,
    onLike,
    onDislike,
    ...otherProps
  }: Props) => {
    return (
      <div {...otherProps}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1.25,
          }}
        >
          <Title>{header}</Title>
          {tag}
        </Box>
        <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
          <User
            avatarSrc={avatarSrc}
            rating={rating}
            reviewsCount={reviewsCount}
            firstName={title}
          />
          <OrdersResponsesHeader title={title} name={name} date={date} />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {body}
          {price}
        </Box>
        <OrdersResponsesStatistics
          {...statistics}
          showComments={showComments}
          onLike={onLike}
          onDislike={onDislike}
        />
      </div>
    );
  }
)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2.5),
  paddingLeft: theme.spacing(5.5),
  minWidth: 0,
}));
