import { Box, SxProps, Theme } from "@mui/material";
import {
  Autocomplete,
  Button,
  FieldError,
  Input,
  RequiredFieldsError,
  Textarea,
} from "@shared/ui";
import { Controller, useForm } from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  AddIncludedFilesInput,
  AddLogoInput,
  GalleryFileInput,
} from "@entities/business";
import { useBusinessCardTypes } from "@entities/dictionary";
import { BusinessCard, CreateBusinessCard } from "@shared/types/business";
import { Attachment, AttachmentType } from "@shared/types/attachment";
import { useUploadAttachment } from "@entities/attachment";
import { userModel } from "@entities/user";
import { useAppSelector } from "@app/store";
import { BaseSyntheticEvent } from "react";

export type FormData = {
  cardType: string;
  name: string;
  address: string;
  email: string;
  website: string;
  contactPerson: string;
  contactPhone: string;
  description?: string;
  logo?: Attachment | null;
  file?: Attachment | null;
  gallery?: Attachment[];
  updateDateTime?: number;
};

const convertToFormData = (data: Partial<BusinessCard>): Partial<FormData> => ({
  ...data,
  cardType: data.cardType?.code,
  address: data.address?.ru,
  contactPerson: data.contactPerson?.ru,
  description: data.description?.ru,
  name: data.name?.ru,
  file: data.attachments?.find(
    (item) => item.contentType === "application/pdf"
  ),
  gallery: data.attachments?.filter(
    (item) => item.contentType !== "application/pdf"
  ),
});

const convertToEntity = ({
  file,
  gallery,
  ...data
}: FormData): CreateBusinessCard => ({
  ...data,
  address: { ru: data.address },
  contactPerson: { ru: data.contactPerson },
  description: data.description ? { ru: data.description } : undefined,
  name: { ru: data.name },
  logo: data.logo?.id,
  attachments: [file?.id, ...(gallery?.map((item) => item.id) ?? [])].filter(
    Boolean
  ) as string[],
});

const validationSchema = object({
  cardType: string().optional().nullable().required("Обязательное поле"),
  name: string()
    .optional()
    .nullable()
    .required("Обязательное поле")
    .max(250, "Максимум 250 символов"),
  address: string().optional().nullable().max(250, "Максимум 250 символов"),
  email: string()
    .optional()
    .nullable()
    .required("Обязательное поле")
    .max(250, "Максимум 250 символов")
    .email("Неверный адрес электронной почты"),
  website: string().optional().nullable().max(250, "Максимум 250 символов"),
  contactPerson: string()
    .optional()
    .nullable()
    .required("Обязательное поле")
    .max(250, "Максимум 250 символов"),
  contactPhone: string()
    .optional()
    .nullable()
    .required("Обязательное поле")
    .max(250, "Максимум 250 символов"),
  description: string()
    .optional()
    .nullable()
    .max(4000, "Максимум 4000 символов"),
});

type Props = {
  isEdit?: boolean;
  id?: string;
  sx?: SxProps<Theme>;
  className?: string;
  data?: Partial<BusinessCard>;
  innerSx?: SxProps<Theme>;
  withGallery?: boolean;
  cancelButtonText?: string;
  submitButtonText?: string;
  onSubmit?: (data: CreateBusinessCard, event?: BaseSyntheticEvent) => void;
  onCancel?: () => void;
};

export const CreateBusinessCardForm = ({
  isEdit = false,
  id,
  sx = [],
  className,
  data,
  innerSx = [],
  withGallery = false,
  cancelButtonText = "Назад",
  submitButtonText = "Опубликовать",
  onSubmit,
  onCancel,
}: Props) => {
  const user = useAppSelector(userModel.userSelector);
  const uploadAttachment = useUploadAttachment();

  const { data: businessCardOptions = [] } = useBusinessCardTypes();

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    watch,
  } = useForm<FormData>({
    defaultValues: data && convertToFormData(data),
    resolver: yupResolver(validationSchema),
  });

  const hasRequiredErrors = Object.values(errors).some(
    (error) => error?.type === "required" || error?.type === "optionality"
  );

  const submit = (data: FormData, event?: BaseSyntheticEvent) => {
    onSubmit?.(
      convertToEntity({
        ...data,
        updateDateTime: isDirty ? new Date().valueOf() : undefined,
      }),
      event
    );
  };

  const handleUploadLogo = (file: File | null) => {
    if (!file) return setValue("logo", null);

    uploadAttachment
      .mutateAsync({ file, type: AttachmentType.BusinessCardLogo })
      .then((attachment) => setValue("logo", attachment));
  };

  const handleUploadFile = (file: File | null) => {
    if (!file) return setValue("file", null);

    uploadAttachment
      .mutateAsync({ file, type: AttachmentType.BusinessCard })
      .then((attachment) => setValue("file", attachment));
  };

  const handleUploadGallery = async (files: File[]) => {
    const result = await Promise.all(
      files.map((file) =>
        uploadAttachment.mutateAsync({
          file,
          type: AttachmentType.BusinessCard,
        })
      )
    );

    setValue("gallery", [...(watch("gallery") ?? []), ...result]);
  };

  const handleRemoveFromGallery = (id: string) => {
    setValue(
      "gallery",
      (watch("gallery") ?? []).filter((item) => item.id !== id)
    );
  };

  return (
    <Box
      id={id}
      component="form"
      className={className}
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      onSubmit={handleSubmit(submit)}
    >
      <Box
        sx={[
          {
            display: "grid",
            gridTemplateColumns: "1fr 17.5rem",
            rowGap: 1.75,
            columnGap: 3,
            p: [1, 3, 3],
            borderBottom: "1px solid",
            borderBottomColor: "divider",
          },
          ...(Array.isArray(innerSx) ? innerSx : [innerSx]),
        ]}
      >
        {hasRequiredErrors && (
          <RequiredFieldsError sx={{ gridColumn: "1 / 3" }} />
        )}
        <Box sx={{ display: "flex", flexDirection: "column", rowGap: 1.75 }}>
          <Controller
            control={control}
            name="cardType"
            render={({ field, fieldState }) => (
              <FieldError
                error={fieldState.error?.message}
                hideError={
                  fieldState.error?.type === "required" ||
                  fieldState.error?.type === "optionality"
                }
              >
                <Autocomplete
                  label="Каким направлением деятельности бизнеса занимается Ваша компания  (например, Импортер)"
                  placeholder="Направление деятельности бизнеса"
                  options={businessCardOptions}
                  disabled={isEdit}
                  required
                  value={field.value ?? null}
                  onChange={field.onChange}
                />
              </FieldError>
            )}
          />
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState }) => (
              <FieldError
                error={fieldState.error?.message}
                hideError={
                  fieldState.error?.type === "required" ||
                  fieldState.error?.type === "optionality"
                }
              >
                <Input
                  id="name"
                  label="Наименование Вашей компании"
                  placeholder="Наименование Организации/ИП"
                  value={field.value ?? ""}
                  required
                  onChange={field.onChange}
                />
              </FieldError>
            )}
          />
          <Controller
            control={control}
            name="address"
            render={({ field, fieldState }) => (
              <FieldError
                error={fieldState.error?.message}
                hideError={
                  fieldState.error?.type === "required" ||
                  fieldState.error?.type === "optionality"
                }
              >
                <Input
                  id="address"
                  label="Адрес Вашей компании"
                  placeholder="Адрес"
                  value={field.value ?? ""}
                  onChange={field.onChange}
                />
              </FieldError>
            )}
          />
          <Controller
            defaultValue={user?.email}
            control={control}
            name="email"
            render={({ field, fieldState }) => (
              <FieldError
                error={fieldState.error?.message}
                hideError={
                  fieldState.error?.type === "required" ||
                  fieldState.error?.type === "optionality"
                }
              >
                <Input
                  id="email"
                  type="email"
                  label="Электронная почта, по которой партнеры смогут связаться с Вами"
                  placeholder="Электронная почта"
                  value={field.value ?? ""}
                  required
                  onChange={field.onChange}
                />
              </FieldError>
            )}
          />
          <Controller
            control={control}
            name="website"
            render={({ field, fieldState }) => (
              <FieldError
                error={fieldState.error?.message}
                hideError={
                  fieldState.error?.type === "required" ||
                  fieldState.error?.type === "optionality"
                }
              >
                <Input
                  id="website"
                  label="Веб-сайт, на котором  партнеры смогут узнать о Вашей компании больше информации"
                  placeholder="Веб-сайт"
                  value={field.value ?? ""}
                  onChange={field.onChange}
                />
              </FieldError>
            )}
          />
          <Controller
            defaultValue={user?.name}
            control={control}
            name="contactPerson"
            render={({ field, fieldState }) => (
              <FieldError
                error={fieldState.error?.message}
                hideError={
                  fieldState.error?.type === "required" ||
                  fieldState.error?.type === "optionality"
                }
              >
                <Input
                  id="contactPerson"
                  label="Контактное лицо"
                  placeholder="Контактное лицо"
                  value={field.value ?? ""}
                  required
                  onChange={field.onChange}
                />
              </FieldError>
            )}
          />
          <Controller
            control={control}
            name="contactPhone"
            render={({ field, fieldState }) => (
              <FieldError
                error={fieldState.error?.message}
                hideError={
                  fieldState.error?.type === "required" ||
                  fieldState.error?.type === "optionality"
                }
              >
                <Input
                  id="contactPhone"
                  type="tel"
                  label="Контактный телефон"
                  placeholder="Контактный телефон"
                  value={field.value ?? ""}
                  required
                  onChange={field.onChange}
                  mask="+7(000) 000-0000"
                />
              </FieldError>
            )}
          />
          <Controller
            control={control}
            name="description"
            render={({ field, fieldState }) => (
              <FieldError
                error={fieldState.error?.message}
                hideError={
                  fieldState.error?.type === "required" ||
                  fieldState.error?.type === "optionality"
                }
              >
                <Textarea
                  label="Опишите Вашу компанию"
                  placeholder="Краткое описание"
                  value={field.value ?? ""}
                  onChange={field.onChange}
                />
              </FieldError>
            )}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            pt: 3,
          }}
        >
          <Controller
            control={control}
            name="logo"
            render={({ field }) => (
              <AddLogoInput
                attachment={field.value}
                onChange={handleUploadLogo}
              />
            )}
          />
          <Controller
            control={control}
            name="file"
            render={({ field }) => (
              <AddIncludedFilesInput
                attachment={field.value}
                onChange={handleUploadFile}
              />
            )}
          />
        </Box>
        {withGallery && (
          <Controller
            control={control}
            name="gallery"
            render={({ field }) => (
              <GalleryFileInput
                sx={{ gridColumn: "1 / 3" }}
                attachments={field.value}
                onAdd={handleUploadGallery}
                onRemove={handleRemoveFromGallery}
              />
            )}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          columnGap: 3.75,
          p: [8, 3],
        }}
      >
        <Button
          sx={{ minWidth: "15rem" }}
          type="button"
          variant="secondary-inverse"
          onClick={onCancel}
        >
          {cancelButtonText}
        </Button>
        <Button sx={{ minWidth: "15rem" }} type="submit" variant="primary">
          {submitButtonText}
        </Button>
      </Box>
    </Box>
  );
};
