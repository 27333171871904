import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "@app/store";
import { BusinessCardData } from "../types";
import { BusinessCardDto } from "@shared/types/businessCard";
import { mapBusinessCard, updateMainCardDto } from "./mapper";
import { api } from "@shared/api";

type State = {
  card: BusinessCardData | null;
  dto: BusinessCardDto | null;
};

const initialState: State = {
  card: null,
  dto: null,
};

export const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    loadCard: (state, action: PayloadAction<BusinessCardDto>) => {
      state.dto = action.payload;
      state.card = mapBusinessCard(action.payload);
    },
    setMainCard: (state, action: PayloadAction<boolean>) => {
      if (!state.dto || !state.card) {
        return;
      }
      api.updateBusinessCard(
        state.dto.id,
        updateMainCardDto(state.dto, action.payload)
      );
      state.card = { ...state.card, mainCard: action.payload };
    },
    setNotificationRead: (state, action: PayloadAction<string>) => {
      if (!state.dto || !state.card) {
        return;
      }
      const newNotifications = state?.card?.notifications.map((el) =>
        el.id === action.payload ? { ...el, read: true } : el
      );
      state.card = { ...state.card, notifications: newNotifications || [] };
    },
  },
});

export const { name, reducer } = businessSlice;
export const { loadCard, setMainCard, setNotificationRead } =
  businessSlice.actions;

const selfSelector = (state: RootState) => state.business;

export const cardSelector = createSelector(selfSelector, (state) => state.card);

export const cardDetailsSelector = createSelector(
  cardSelector,
  (state) => state?.details
);

export const cardGallerySelector = createSelector(
  cardSelector,
  (state) => state?.gallery
);

export const cardServicesSelector = createSelector(
  cardSelector,
  (state) => state?.services
);

export const cardReviewsSelector = createSelector(
  cardSelector,
  (state) => state?.reviews
);

export const cardPartnersSelector = createSelector(
  cardSelector,
  (state) => state?.partners
);

export const cardNotificationsSelector = createSelector(
  cardSelector,
  (state) => state?.notifications || []
);
