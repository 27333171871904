import { ReactNode } from "react";
import { Box, SxProps, Theme, styled } from "@mui/material";

const Title = styled("h6")(({ theme }) => ({
  margin: 0,
  marginBottom: theme.spacing(1),
  fontWeight: 700,
  fontSize: "1.5rem",
  lineHeight: 1.35,
  color: theme.palette.text.primary,
}));

const Description = styled("p")(({ theme }) => ({
  margin: 0,
  marginBottom: theme.spacing(1),
  fontSize: "1rem",
  lineHeight: 1.5,
  color: theme.palette.text.primary,
}));

type Props = {
  sx?: SxProps<Theme>;
  className?: string;
  title: string;
  description: string;
  button: ReactNode;
  mask?: ReactNode;
};

export const AdviceCard = ({ sx, title, description, button, mask }: Props) => {
  return (
    <Box
      sx={[
        {
          position: "relative",
          pt: (theme) => theme.spacing(1.75),
          pb: (theme) => theme.spacing(2),
          px: (theme) => theme.spacing(3),
          borderRadius: (theme) => theme.spacing(2),
          width: "24rem",
          overflow: "hidden",
          zIndex: 0,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -1,
        }}
      >
        {mask}
      </Box>
      <Title>{title}</Title>
      <Description>{description}</Description>
      {button}
    </Box>
  );
};
