import { SmallCard } from "@entities/my-marketplace";
import HasQuestionsImage from "@shared/assets/has-questions.png";
import { getDomainUrl } from "@shared/lib/utils";
import { Mask } from "@shared/ui";

export const HasQuestionsCard = () => {
  const mask = (
    <Mask
      beforeStyles={{
        width: "17.5rem",
        height: "17.5rem",
        bgcolor: "#8bffff",
        transform: "translate(60%, -50%)",
      }}
    />
  );

  return (
    <SmallCard
      mask={mask}
      title="У вас возникли вопросы по работе в Маркетплейсе?"
      buttonText="Спросить сообщество"
      buttonVariant="secondary"
      imageSrc={HasQuestionsImage}
      onClick={() =>
        (window.location.href = getDomainUrl("/createquestion.php"))
      }
    />
  );
};
