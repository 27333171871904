import { Filters } from "@entities/filters";
import { client } from "@shared/lib/client";
import { getFromDate } from "@shared/lib/period";
import { ReviewsDto } from "@shared/types/businessCard";
import { LocaleString } from "@shared/types/lang";
import {
  CreateReview,
  OfferAnswerPostDto,
  OfferPostDto,
  ResponsesCount,
} from "@shared/types/ordersResponses";

type Params = Filters & { offset?: number; limit?: number };

const convertParams = (params?: Params) => ({
  ...params,
  sort: params?.sort ? `BY_${params.sort}` : undefined,
  fromDate: params?.fromDate ? getFromDate(params.fromDate) : undefined,
});

const load = async <T>(url: string, params?: Params): Promise<T> => {
  const response = await client.get<T>(url, { params: convertParams(params) });

  return response.data;
};

export const getMyOrders = async (params: Params) =>
  load<OfferPostDto[]>("/offers/account", params);

export const getOrder = async (id: string) =>
  load<OfferPostDto>(`/offers/${id}`, {});

export const closeOrder = async (id: string) =>
  await client.patch(`/offers/${id}/close`);

export const showOrder = async (id: string): Promise<void> =>
  await client.patch(`/offers/${id}/show`);

export const hideOrder = async (id: string): Promise<void> =>
  await client.patch(`/offers/${id}/hide`);

export const getOrderPreviewResponses = async (id: string) =>
  load<OfferAnswerPostDto[]>(`/offers/${id}/answers`);

export const getOrderResponsesCount = async (id: string) =>
  load<ResponsesCount>(`/offers/${id}/answers/count`);

export const getIncomingResponses = async (params: Params) =>
  load<OfferAnswerPostDto[]>("/offers/answers", params);

export const getOrderAnswer = async (id: string) =>
  load<OfferAnswerPostDto>(`/offers/answers/${id}`);

export const discardMyResponse = async (id: string) =>
  await client.patch(`/offers/answers/${id}/close`, {});

export const toWorkMyResponse = (id: string) =>
  client.patch(`/offers/answers/${id}/work`, {});

export const declineIncomingOrder = async (id: string, title: LocaleString) =>
  client.post(`/offers/${id}/answers/decline`, { ...title });

export const getIncomingOrders = async (params?: object) =>
  load<OfferPostDto[]>("/offers/incoming", params);

export const getMyResponses = async (params: Params) =>
  load<OfferAnswerPostDto[]>("/offers/answers/account", params);

export const acceptOrderAnswer = (offerId: string, answerId: string) =>
  client.patch(`/offers/${offerId}/answers/${answerId}/accept`, {});

export const completeOrderAnswer = (offerId: string, answerId: string) =>
  client.patch(`/offers/${offerId}/answers/${answerId}/complete`, {});

export const declineOrderAnswer = (offerId: string, answerId: string) =>
  client.patch(`/offers/${offerId}/answers/${answerId}/decline`, {});

export const shareContacts = async (offerId: string, answerId: string) =>
  client.post(`/offers/${offerId}/answers/${answerId}/contacts`, {});

export const getReviews = (offerId: string, answerId: string) =>
  load<ReviewsDto>(`/offers/${offerId}/answers/${answerId}/reviews`);

export const sendReview = (
  offerId: string,
  answerId: string,
  data: CreateReview
) => client.post(`/offers/${offerId}/answers/${answerId}/reviews`, data);
