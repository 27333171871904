import { Button, Column, tagsByStatus } from "@shared/ui";
import {
  MarketplaceCard,
  OrdersResponsesHeader,
  IncomingResponseShortDetails,
  ResponsePrice,
} from "@entities/marketplace";
import { Box } from "@mui/material";
import { Paths } from "@shared/lib/router";
import { CloseButton } from "./CloseButton";
import { useIncomingResponsesActions } from "../hooks/useIncomingResponsesActions";
import { IncomingResponseCard } from "../types";

type Props = {
  item: IncomingResponseCard;
  onLike?: () => void;
  onDislike?: () => void;
  onRespond?: () => void;
};

export const IncomingResponsesListItem = ({
  item,
  onLike,
  onDislike,
  onRespond,
}: Props) => {
  const { handleAcceptOrder, handleCompleteOrder, handleDeclineOrder } =
    useIncomingResponsesActions(item.offerId ?? "", item.id ?? "", onRespond);

  return (
    <MarketplaceCard
      key={item.id}
      sx={{ mt: 2, "&:first-of-type": { mt: 0 } }}
      rating={item.rating}
      reviewsCount={item.reviewsCount}
      avatarSrc={item.avatarSrc}
      firstName={item.company}
      header={
        <OrdersResponsesHeader
          title={item.company}
          name={item.name}
          date={item.date}
        />
      }
      linkUrl={`${Paths.IncomingResponses}${item.id}`}
      body={
        <Box sx={{ display: "flex", columnGap: 10, mr: 3, py: 0.5 }}>
          <IncomingResponseShortDetails
            responseReceiver={item.target}
            order={item.order}
            title={item.title}
            text={item.text}
            statistics={item.statistics}
            onLike={onLike}
            onDislike={onDislike}
          />
          {!!item.price.value && (
            <ResponsePrice value={item.price.value} type={item.price.type} />
          )}
        </Box>
      }
      tags={tagsByStatus[item.status]}
      buttons={
        <Column sx={{ minWidth: "11.5rem", gap: 2 }}>
          {item.status === "PUBLISHED" && (
            <Button text="Принять" onClick={handleAcceptOrder} />
          )}
          {item.status === "IN_WORK" && (
            <Button text="Завершить" onClick={handleCompleteOrder} />
          )}
          {["PUBLISHED", "ACCEPTED", "IN_WORK"].includes(item.status) && (
            <CloseButton text="Отклонить" onClick={handleDeclineOrder} />
          )}
        </Column>
      }
    />
  );
};
