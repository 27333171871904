import { Box, SxProps, Theme } from "@mui/material";
import { PriceType, PriceTypes, PriceTypesOptions } from "@shared/lib/price";
import {
  Autocomplete,
  Button,
  FieldError,
  Input,
  RadioSelect,
  RequiredFieldsError,
  Textarea,
} from "@shared/ui";
import { Controller, useForm } from "react-hook-form";
import { number, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useServiceTypes } from "@entities/dictionary";
import { CreateService } from "@shared/types/service";

const convertToFormData = (
  data: Partial<CreateService>
): Partial<FormData> => ({
  ...data,
  name: data.name?.ru,
  description: data.description?.ru,
});

const convertToEntity = (data: FormData): CreateService => ({
  ...data,
  name: { ru: data.name },
  description: { ru: data.description },
});

export type FormData = {
  serviceType: string;
  name: string;
  description: string;
  price: string;
  priceUnits: PriceType;
  id?: string
};

const validationSchema = object({
  serviceType: string().optional().nullable().required("Обязательное поле"),
  name: string()
    .optional()
    .nullable()
    .required("Обязательное поле")
    .max(250, "Максимум 250 символов"),
  description: string()
    .optional()
    .nullable()
    .max(4000, "Максимум 4000 символов"),
  price: number()
    .typeError("Стоимость должна быть числом")
    .optional()
    .nullable()
    .positive("Должно быть положительным числом")
    .transform((_, val) => (val === "" ? null : Number(val))),
  priceUnits: string().optional().nullable(),
});

type Props = {
  sx?: SxProps<Theme>;
  className?: string;
  data?: Partial<CreateService>;
  cancelButtonText?: string;
  submitButtonText?: string;
  onSubmit?: (data: CreateService, id?: string) => void;
  onCancel?: () => void;
};

export const AddServiceForm = ({
  sx = [],
  className,
  data,
  cancelButtonText = "Назад",
  submitButtonText = "Опубликовать",
  onSubmit,
  onCancel,
}: Props) => {
  const { data: serviceTypeOptions = [] } = useServiceTypes();

  const { control, formState: { isDirty, errors }, handleSubmit, reset } = useForm<FormData>({
    defaultValues: data && convertToFormData(data),
    resolver: yupResolver(validationSchema),
  });

  const hasRequiredErrors = Object.values(errors).some(
    (error) => error?.type === "required" || error?.type === "optionality"
  );

  const submit = (formData: FormData) => {
    onSubmit?.(convertToEntity(formData), isDirty && data?.id ? data.id : undefined);
    reset({});
  };

  return (
    <Box
      component="form"
      className={className}
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      onSubmit={handleSubmit(submit)}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: (theme) => theme.spacing(1.75),
          p: (theme) => theme.spacing(1, 3, 3),
        }}
      >
        {hasRequiredErrors && <RequiredFieldsError />}
        <Controller
          control={control}
          name="serviceType"
          render={({ field, fieldState }) => (
            <FieldError
              error={fieldState.error?.message}
              hideError={
                fieldState.error?.type === "required" ||
                fieldState.error?.type === "optionality"
              }
            >
              <Autocomplete
                label="Какой тип услуги оказывает Ваша компания"
                placeholder="Тип услуги"
                value={field.value}
                options={serviceTypeOptions}
                required
                onChange={field.onChange}
              />
            </FieldError>
          )}
        />
        <Controller
          control={control}
          name="name"
          render={({ field, fieldState }) => (
            <FieldError
              error={fieldState.error?.message}
              hideError={
                fieldState.error?.type === "required" ||
                fieldState.error?.type === "optionality"
              }
            >
              <Input
                label="Как называется оказываемая услуга"
                placeholder="Наименование услуги"
                value={field.value}
                required
                onChange={field.onChange}
              />
            </FieldError>
          )}
        />
        <Controller
          control={control}
          name="description"
          render={({ field, fieldState }) => (
            <FieldError
              error={fieldState.error?.message}
              hideError={
                fieldState.error?.type === "required" ||
                fieldState.error?.type === "optionality"
              }
            >
              <Textarea
                label="Краткое описание оказываемой услуги"
                placeholder="Краткое описание услуги"
                value={field.value}
                onChange={field.onChange}
              />
            </FieldError>
          )}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: (theme) => theme.spacing(0.5),
          }}
        >
          <Box
            sx={{
              fontWeight: 700,
              fontSize: "1rem",
              lineHeight: 1.5,
              color: (theme) => theme.palette.text.primary,
            }}
          >
            Стоимость
          </Box>
          <Box
            sx={{
              width: "19.5rem",
              fontWeight: 400,
              fontSize: "0.875rem",
              lineHeight: 1.4,
              color: (theme) => theme.palette.text.disabled,
            }}
          >
            Стоимость услуги будет доступна для просмотра в Маркетплейсе для
            потребителей услуг
          </Box>
          <FieldError
            error={
              (errors.price?.type !== "required" &&
              errors.price?.type !== "optionality"
                ? errors.price?.message
                : "") ||
              (errors.priceUnits?.type !== "required" &&
              errors.priceUnits?.type !== "optionality"
                ? errors.priceUnits?.message
                : "")
            }
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: (theme) => theme.spacing(3),
              }}
            >
              <Controller
                control={control}
                name="price"
                render={({ field }) => (
                  <Input
                    sx={{ width: "28.8125rem" }}
                    placeholder="Стоимость"
                    value={field.value}
                    type="number"
                    step="1"
                    endAdornment={
                      <Box
                        sx={{
                          fontWeight: 700,
                          fontSize: "1rem",
                          lineHeight: 1.5,
                          color: "common.black",
                        }}
                      >
                        ₽
                      </Box>
                    }
                    onChange={field.onChange}
                  />
                )}
              />
              <Controller
                control={control}
                name="priceUnits"
                defaultValue={PriceType.SERVICE}
                render={({ field }) => (
                  <RadioSelect
                    sx={{ flex: "0 0 auto", width: "11.25rem" }}
                    items={PriceTypesOptions}
                    selectedItem={PriceTypes[field.value!]}
                    onChange={(_, id) => field.onChange(id)}
                  />
                )}
              />
            </Box>
          </FieldError>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          columnGap: (theme) => theme.spacing(3.75),
          p: (theme) => theme.spacing(6.25, 3, 5.75),
        }}
      >
        <Button
          sx={{ minWidth: "15rem" }}
          type="button"
          variant="secondary-inverse"
          onClick={onCancel}
        >
          {cancelButtonText}
        </Button>
        <Button sx={{ minWidth: "15rem" }} type="submit" variant="primary">
          {submitButtonText}
        </Button>
      </Box>
    </Box>
  );
};
