import { Modal } from "@shared/ui";
import { AddServiceForm } from "./AddServiceForm";
import { CreateService } from "@shared/types/service";

type Props = {
  title?: string;
  data?: Partial<CreateService>;
  open: boolean;
  onClose: () => void;
  onSubmit: (data: CreateService, id?: string) => void;
};

export const AddServiceModal = ({
  title = "Новая услуга",
  data,
  open,
  onClose,
  onSubmit,
}: Props) => {
  return (
    <Modal header={title} width="49rem" open={open} onClose={onClose}>
      <AddServiceForm data={data} onCancel={onClose} onSubmit={onSubmit} />
    </Modal>
  );
};
