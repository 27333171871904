import { Box, SxProps, Theme } from "@mui/material";

import { ReactComponent as AttentionIcon } from "@shared/assets/attention.svg";

type Props = {
  sx?: SxProps<Theme>;
};

export const RequiredFieldsError = ({ sx = [] }: Props) => (
  <Box
    sx={[
      {
        display: "flex",
        alignItems: "center",
        columnGap: 1,
        fontWeight: 400,
        fontSize: "0.75rem",
        lineHeight: 1.5,
        color: "error.main",
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  >
    <AttentionIcon />
    <span>Красные поля обязательные для заполнения</span>
  </Box>
);
