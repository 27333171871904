import { api } from "@shared/api";
import { showErrorMessages } from "@shared/lib/error";
import { AttachmentType } from "@shared/types/attachment";
import { useMutation } from "react-query";

export const useUploadAttachment = () => {
  return useMutation(
    ({ file, type }: { file: File; type: AttachmentType }) =>
      api.uploadAttachment(file, type),
    { onError: showErrorMessages }
  );
};
