import { useState } from "react";
import { SectionFilter } from "./SectionFilter";
import { useServiceTypes } from "@entities/dictionary";

type Props = {
  defaultValue?: string[] | undefined;
  onChange?: (value: string[]) => void;
};

export const ServiceTypesFilter = ({ defaultValue, onChange }: Props) => {
  const { data = [] } = useServiceTypes();

  const [value, setValue] = useState(defaultValue);

  const handleChange = (value: string[]) => {
    setValue(value);
    onChange?.(value);
  };

  return (
    <SectionFilter
      title="Тип услуг"
      sections={data}
      value={value}
      onChange={handleChange}
    />
  );
};
