import { useState } from "react";
import { Box } from "@mui/material";
import { File, FileData } from "@shared/ui";
import { ViewBusinessCardBlockTitle } from "./ViewBusinessCardBlockTitle";
import { ReactComponent as ArrowIcon } from "@shared/assets/small-arrow-right.svg";

type Props = {
  files: FileData[];
};
export const IncludedFiles = ({ files }: Props) => {
  const [isExpanded, setIsExpanded] = useState(!!files.length);
  const handleClick = () => setIsExpanded((value) => !value);

  return (
    <>
      <ViewBusinessCardBlockTitle
        text={
          <Box
            onClick={handleClick}
            sx={{ display: "flex", gap: 2, cursor: "pointer" }}
          >
            <span>Вложенные файлы ({files.length})</span>
            <Box
              sx={{
                transform: `rotate(${isExpanded ? "-90" : "90"}deg)`,
                alignSelf: "center",
              }}
            >
              <ArrowIcon />
            </Box>
          </Box>
        }
      />
      {isExpanded && files.length > 0 && (
        <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
          {files.map((file) => (
            <File key={file.name} {...file} />
          ))}
        </Box>
      )}
    </>
  );
};
